// icon:infinite | Ionicons https://ionicons.com/ | Ionic Framework
import * as React from "react";

function IconInfinite(props) {
    return (
        <svg
            viewBox="0 0 512 512"
            fill="currentColor"
            height="1em"
            width="1em"
            style={{ verticalAlign: 'sub', fontSize: 'larger' }}
            {...props}
        >
            <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={48}
                d="M256 256s-48-96-126-96c-54.12 0-98 43-98 96s43.88 96 98 96c30 0 56.45-13.18 78-32M256 256s48 96 126 96c54.12 0 98-43 98-96s-43.88-96-98-96c-29.37 0-56.66 13.75-78 32"
            />
        </svg>
    );
}

export default IconInfinite;
