import React from 'react';
import { Form } from '@ant-design/compatible';

import { Modal, Input, Button, Col } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

const GaugeUnlinkForm = Form.create({ name: 'gaugeUnlinkForm_into_modal' })(

    class extends React.Component {
        render() {
            const {
                visible, onCancel, onUnlink, form, gauge
            } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Modal
                    open={visible}
                    title={`Desenlazar medidor de volumen ${gauge.gaugeCode}`}
                    onCancel={onCancel}
                    footer={[
                        <Button className="back-button" key="back" onClick={onCancel}>Cancelar</Button>,
                        <Button className="submit-button" key="submit" onClick={onUnlink}>Desenlazar</Button>
                    ]}
                >
                    <p>{`¿Estás seguro que quieres desenlazar el medidor de volumen ${gauge.gaugeCode}?`}</p>
                    <Form layout='vertical' >
                        <Form.Item label="Id de la manguera" hidden>
                            {getFieldDecorator('gaugeCode', {
                                initialValue: gauge.gaugeCode,
                                rules: [{ required: true, message: 'Por favor introduce la clave de medidor de volumen' }]
                            })(
                                <Input disabled />
                            )}
                        </Form.Item>
                        <Col span={24}>
                            <Form.Item label="Motivo de desenlace">
                                {getFieldDecorator('unlinkReason', {
                                    rules: [{
                                        required: true, message: 'Por favor ingresa el motivo del desenlace.'
                                    }]
                                })(
                                    <TextArea autoSize={{ minRows: 2, maxRows: 6 }} maxLength={60} placeholder="Ingrese motivo del desenlace."/>
                                )}
                            </Form.Item>
                        </Col>
                    </Form>
                </Modal>
            );
        }
    }
);

export default GaugeUnlinkForm;
