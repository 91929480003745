import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Row, Badge, Col, Progress, Spin } from 'antd';

import {
    dispensaries as dispensaryServices
} from '../../../services';

class HealthApplicationInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingForm: false,
            uploading: false,
            healthInfo: [],
            dbInfo: [],
            ramUsed: [],
            diskStatus: [],
            diskStatusText: 'error',
            textDiskStatus: 'Inactivo',
            diskInfo: [],
            ethernetInfo: [],
            statusDb: 'error',
            textDbStatus: 'Inactivo',
            statusEthernet: 'error',
            textEthernetStatus: 'Inactivo',
            percentDiskUsed: 0,
            ramMax: 0,
            percetMemRam: 0
        }
    };


    componentDidMount() {
        this.getHealthInfo();
    }

    getHealthInfo = () => {
        this.setState({ loadingForm: true });
        dispensaryServices.get('diagnostic/health')
            .then(response => {
                this.setState({
                    healthInformation: response,
                    dbInfo: response.components.db,
                    diskStatus: response.components.diskSpace.status,
                    diskInfo: response.components.diskSpace.details,
                    ethernetInfo: response.components.internetConection
                });

                this.setState({ loadingForm: false });
                if (this.state.dbInfo.status === 'UP') {
                    this.setState({
                        statusDb: 'success',
                        textDbStatus: 'Activo'
                    });
                }
                if (this.state.ethernetInfo.status === 'UP') {
                    this.setState({
                        statusEthernet: 'success',
                        textEthernetStatus: 'Activo'
                    });
                }
                if (this.state.diskStatus === 'UP') {
                    this.setState({
                        diskStatusText: 'success',
                        textDiskStatus: 'Activo'
                    });
                }
                this.setState({
                    percentDiskUsed: ((((this.state.diskInfo.total - this.state.diskInfo.free) * 100) / this.state.diskInfo.total))
                });

            });
        this.getMemRamUsed();
    }
    getMemRamUsed = () => {
        dispensaryServices.get('diagnostic/metrics/jvm.memory.used')
            .then(response => {
                this.setState({
                    ramUsed: response.measurements[0].value
                });
            });
        this.getMemMax();
    }

    getMemMax = () => {
        this.setState({ loadingForm: true });
        dispensaryServices.get('diagnostic/metrics/jvm.memory.max')
            .then(response => {
                this.setState({
                    ramMax: response.measurements[0].value
                });
                const ramPercent = this.state.ramUsed * 100 / this.state.ramMax;
                this.setState({
                    percetMemRam: ramPercent
                });
            });

    }

    render() {
        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loadingForm}>

                <Form layout='vertical' >
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item label="Estatus de base de datos">
                                <Badge status={this.state.statusDb} text={this.state.textDbStatus} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item label="Uso de memoria RAM ">
                                <Progress type="circle" percent={Math.ceil(this.state.percetMemRam)} width={100} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={4}>
                            <Form.Item label="Estatus disco duro">
                                <Badge status={this.state.statusDb} text={this.state.textDbStatus} />
                            </Form.Item>

                        </Col>
                        <Col span={6}>
                            <Form.Item label="Uso de disco duro">
                                <Progress type="circle" percent={Math.ceil(this.state.percentDiskUsed)} width={100} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={6}>
                        <Col span={6}>
                            <Form.Item label="Estado de la red">
                                <Badge status={this.state.statusEthernet} text={this.state.textEthernetStatus} />
                            </Form.Item>
                        </Col>
                    </Row>

                </Form>
            </Spin>
        );
    }
}

export default Form.create({ name: 'healthApplicationInfo' })(HealthApplicationInfo);
