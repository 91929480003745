import moment from 'moment';
import dayjs from "dayjs";
import React, { Component } from 'react';
import { FileExcelOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import {
    Row,
    Table,
    Col,
    Spin,
    Select,
    DatePicker,
    Pagination,
    Divider,
    Button,
    message,
} from 'antd';
import { connect } from 'react-redux';

import { dispensaries as dispensaryServices } from '../../../services';

import {
    getPagePermissions,
    EVENTS_PERMISSION,
    EXPORT_REPORT_PERMISSION,
    INVENTORY_CONTROL_PERMISSION
} from '../../../security/permissions';
import ManualVolumeRegisterForm from './manualVolumeRegisterForm';
import { getSelectedDistributionPoint } from '../../../common/components/widgets/DistributionPoint/distributionPointStorage';
import { saveJsonToFile } from '../../stockControl/components/jsonViewer';
import request from '../../../services/request';
import { serializeParams } from '../../../common/helpers';

const Option = Select.Option;
const { RangePicker } = DatePicker;
let filters = {};
const responseIdEquipments = [];
class VolumeMeasurerEdit extends Component {

    _columns = [{
        title: 'Clave auditable del SAT por equipo',
        dataIndex: 'equipment.auditableEquipmentKey',
        width: '15%',
    }, {
        title: 'Fecha registro de medición',
        dataIndex: 'measuredTime',
        render: date => moment(date).format('YYYY/MM/DD HH:mm:ss'),
        width: '15%'
    }, {
        title: 'Porcentaje de volumen',
        dataIndex: 'volumePercentage',
        render: volumePercentage => volumePercentage + ' %',
        width: '10%'
    }, {
        title: 'Volumen',
        dataIndex: 'volume',
        render: volume => Intl.NumberFormat('en', { notation: 'standard' }).format(volume),
        width: '10%'
    }, {
        title: 'Presión ',
        dataIndex: 'pressure',
        width: '10%'
    }, {
        title: 'Temperatura',
        dataIndex: 'temperature',
        width: '10%'
    }];

    _isMount = false;
    constructor(props) {
        super(props);
        this.state = {
            loadingForm: false,
            pagination: {},
            responseIdEquipments,
            currentPage: 1,
            volumeMeasurerRegistriesData: [],
            volumeMeasurerRegistriesDataReport: [],
            activeDowload: true,
            csvReport: {
                data: [],
                headers: [],
                filename: 'Report.csv'
            },
            distributionPointId: getSelectedDistributionPoint(),
            allowManualWarehouseLevel: false
        };
    }


    componentDidMount() {
        this.fetchConfig();
        this._isMount = true;
        filters = {
            volumeMeasurerRegisterDisplayPage: 0,
            volumeMeasurerRegisterDisplayAmount: 10,
            idEquipment: null,
            filterStartDate: moment('00:00:00', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            filterEndDate: moment('23:59:59', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            distributionPointId: this.state.distributionPointId,
        };
        this.getIdEquipments(filters);
        window.addEventListener("distributionPointChange", (e) => {
            if (this._isMount) {
                if (filters.distributionPointId === getSelectedDistributionPoint()) {
                    return;
                }
                filters.distributionPointId = getSelectedDistributionPoint();
                filters.idEquipment = null;
                this.props.form.setFields({ 'idEquipments': '' });
                this.setState({ distributionPointId: filters.distributionPointId }, () => {
                    this.getIdEquipments(filters);
                });
            }
        });
    }
    componentWillUnmount() {
        this._isMount = false;
    }

    fetchVolumeMeasurerRegistries = (params = {}) => {
        this.setState({ loadingForm: true });
        request().post('volumeMeasurerRegister/getfilters', params)
            .then(response => {
                const pagination = { ...this.state.pagination };
                pagination.total = response.data.totalElements;
                this.setState({
                    volumeMeasurerRegistriesData: response.data.content,
                    pagination,
                    currentPage: params.volumeMeasurerRegisterDisplayPage + 1
                });
                if (pagination.total > 0) {
                    this.setState({ activeDowload: false })
                } else {
                    this.setState({ activeDowload: true })
                }
            }).finally(() => {
            this.setState({ loadingForm: false, });
        });
    }

    fetchConfig = () => {
        request().get('configApplication/getConfig/allowManualWarehouseLevel')
            .then(response => {
                this.setState({ allowManualWarehouseLevel: response.data });
            });
    }

    getIdEquipments = (params) => {
        this.setState({ loadingForm: true });
        const EqParams = {
            applicationType: 'MEDIDOR_NIVEL'
        };
        request().get(`distributionPoints/${this.state.distributionPointId}/inputEquipment${serializeParams(EqParams)}`)
            .then(response => {
                this.setState({
                    responseIdEquipments: [...response.data]
                });
                this.fetchVolumeMeasurerRegistries(params);
            }).finally(() => {
            this.setState({ loadingForm: false });
        });
    }

    handleIdEquipment = (e) => {
        filters.volumeMeasurerRegisterDisplayPage = 0;
        if (e === '') {
            filters.idEquipment = null;
        } else {
            filters.idEquipment = e;
        }
        this.fetchVolumeMeasurerRegistries(filters);
    }

    handlePeriodChange = (value) => {
        filters.volumeMeasurerRegisterDisplayPage = 0;
        filters.filterStartDate = value[0].format('YYYY-MM-DD HH:mm:ss');
        filters.filterEndDate = value[1].format('YYYY-MM-DD HH:mm:ss');
        this.fetchVolumeMeasurerRegistries(filters);

    }

    onPageChange = (page, pageSize) => {
        filters.volumeMeasurerRegisterDisplayPage = page - 1;
        this.fetchVolumeMeasurerRegistries(filters);
    }


    onShowSizeChange = (current, pageSize) => {
        filters.volumeMeasurerRegisterDisplayPage = 0;
        filters.volumeMeasurerRegisterDisplayAmount = pageSize;
        this.fetchVolumeMeasurerRegistries(filters);
    }

    downloadCSV = () => {
        dispensaryServices.post(`volumeMeasurerRegister/reports/csv`, filters)
            .then(response => {
                saveJsonToFile(response, "text/csv", "volumeMeasurerRegister.csv");
            }).catch((error) => {
            message.error(error.response.data.message);
        }).finally(() => {
            this.setState({ loading: false });
        });
    }

    render() {
        const { responseIdEquipments } = this.state;
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const { exportReportsPermissions } = this.props;
        const optionIdEquipment = types => types.map(item => (
            <Option key={item.idEquipment} value={item.idEquipment}>{item.auditableEquipmentKey}</Option>
        ));

        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loadingForm}>

                <Form layout='vertical' >
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="Clave auditable de equipo"
                            >
                                {getFieldDecorator('idEquipments', {
                                    initialValue: ''
                                })(
                                    <Select
                                        onChange={this.handleIdEquipment}
                                    >
                                        <Option value="">Todos</Option>
                                        {optionIdEquipment(responseIdEquipments)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Seleccionar periodo"
                            >
                                {getFieldDecorator('period', {
                                    initialValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
                                })(
                                    <RangePicker
                                        lang='es'
                                        allowClear={false}
                                        style={{ width: '100%' }}
                                        format="YYYY/MM/DD HH:mm:ss"
                                        showTime={{ defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')] }}
                                        placeholder={['Desde', 'Hasta']}
                                        ranges={{
                                            Hoy: [dayjs().startOf('day'), dayjs().endOf('day')],
                                            'Esta semana': [dayjs().startOf('week'), dayjs().endOf('week')],
                                            'Este mes': [dayjs().startOf('month'), dayjs().endOf('month')],
                                        }}
                                        onOk={this.handlePeriodChange}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className="transparent-label"
                                label=" "
                            >
                                {exportReportsPermissions.read &&
                                    <Button type="primary" icon={<FileExcelOutlined />} disabled={this.state.activeDowload} onClick={this.downloadCSV}>Descargar CSV</Button>
                                }
                            </Form.Item>
                        </Col>
                        {(exportReportsPermissions.read && this.state.allowManualWarehouseLevel) &&
                            <Col span={4}>
                                <Form.Item
                                    className="transparent-label"
                                    label=" "
                                >

                                    <ManualVolumeRegisterForm
                                        measurementEquipments={this.state.responseIdEquipments}
                                        fetchVolumeMeasurerRegistries={() => {
                                            let filters = {
                                                volumeMeasurerRegisterDisplayPage: 0,
                                                volumeMeasurerRegisterDisplayAmount: 10,
                                                idEquipment: null,
                                                filterStartDate: moment('00:00:00', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                                                filterEndDate: moment('23:59:59', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                                                distributionPointId: this.state.distributionPointId,
                                            };
                                            this.props.form.resetFields();
                                            this.fetchVolumeMeasurerRegistries(filters);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        }
                    </Row>
                </Form>
                <Table
                    columns={this._columns}
                    expandIconAsCell={false}
                    expandIcon={() => null}
                    scroll={{ x: true }}
                    rowKey={record => record.id}
                    dataSource={this.state.volumeMeasurerRegistriesData}
                    pagination={false}
                    loading={this.state.loading}
                />
                <br />
                <Pagination showSizeChanger onChange={this.onPageChange} current={this.state.currentPage}
                            onShowSizeChange={this.onShowSizeChange} total={this.state.pagination.total} pageSizeOptions={['10', '20', '50', '100', '200']}
                            showTotal={total => `Total ${total} elementos`} />
                <Divider />
            </Spin>
        );
    }
}

const VolumeMeasurerEditForm = Form.create({ name: 'volumeMeasurerEdit' })(VolumeMeasurerEdit);


const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(EVENTS_PERMISSION, permissions),
    exportReportsPermissions: getPagePermissions(EXPORT_REPORT_PERMISSION, permissions),
    registerManualVolumePermissions: getPagePermissions(INVENTORY_CONTROL_PERMISSION, permissions)
});


export default connect(mapStateToProps)(VolumeMeasurerEditForm);
