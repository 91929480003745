import React from 'react';
import moment from 'moment';
import dayjs from "dayjs";
import axios from 'axios';
import { DownloadOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Table, Row, Col, DatePicker, Button, Pagination, Divider, Popover, message } from 'antd';
import {
    operationCertificate as operationCertificateServices
} from '../../services';
import { connect } from 'react-redux';
import {
    getPagePermissions,
    OPERATION_CERTIFICATE
} from '../../security/permissions';
import { getSelectedDistributionPoint, getSelectedDistributionPointName } from '../../common/components/widgets/DistributionPoint/distributionPointStorage';
import OperationCertificateRegistration from './components/operationCertificateRegistration';
import { saveJsonToFile } from '../stockControl/components/jsonViewer';
import { getRemoteIp } from '../../common/helpers/remoteIp';
import { getToken } from '../../security';
import { apiUrl } from 'app.config';

const { RangePicker } = DatePicker;

let displayPage = 0;
let displayAmount = 10;

class OperationCertificates extends React.Component {
    _isMount = false;
    constructor(props) {
        super(props);

        this.state = {
            dataSource: [],
            mainView: true,
            pagination: {},
            currentPage: 1,
            loadingTable: false,
            selectedDistributionPoint: getSelectedDistributionPoint(),
            distributionPointId: getSelectedDistributionPoint(),
            distributionPointName: getSelectedDistributionPointName(),
            hideCertificateRegister: true,
            certificateId: 0
        };

        this.columns = [
            {
                title: 'Folio',
                dataIndex: 'rgOCFolio',
            }, {
                title: 'Emisión del certificado',
                dataIndex: 'rgOCIssuedDate',
                align: 'center',
                render: date => moment(date).format('YYYY/MM/DD')
            }, {
                title: 'Emisión de la verificación',
                dataIndex: 'rgOCStartDate',
                align: 'center',
                render: date => moment(date).format('YYYY/MM/DD')
            },
            {
                title: 'Fin de la verificación',
                dataIndex: 'rgOCEndDate',
                align: 'center',
                render: date => moment(date).format('YYYY/MM/DD')
            },
            {
                title: 'Normatividad',
                dataIndex: 'rgOCNormativity'
            },
            {
                title: 'Resultado',
                dataIndex: 'rgOCResult',
            },
            {
                title: 'Firmas',
                render: (text, record) =>
                (<div style={{ display: 'inline-block' }}>
                    <Button.Group>
                        <Popover content="Proveedor autorizado">
                            <Button onClick={() => this.onClickDownloadSignatureProvider(record.rgOCIdOperationCertificate, record.rgOCFolio)} shape="circle" icon={<DownloadOutlined />} />
                        </Popover>
                        <Popover content="Representante legal">
                            <Button onClick={() => this.onClickDownloadSignatureLegalRepresentative(record.rgOCIdOperationCertificate, record.rgOCFolio)} shape="circle" icon={<DownloadOutlined />} />
                        </Popover>
                    </Button.Group>
                </div>)
            },
            {
                title: 'Acciones',
                render: (text, record) =>
                    // eslint-disable-next-line no-nested-ternary
                    record.isEditable ?
                        this.props.pagePermissions.edit ?
                            <div style={{ display: 'inline-block' }}>
                                <Button.Group>
                                    <Popover content="Editar certificado"><Button icon={<EditOutlined />}  onClick={()=>{
                                        this.setState({hideCertificateRegister: false, certificateId: record.rgOCIdOperationCertificate}, ()=>{
                                            this.fechSingleCertificate();
                                        }); }}/>
                                    </Popover>
                                </Button.Group>

                            </div>
                            :
                            <div>
                                <Popover content="Ver certificado"><Button icon={<EyeOutlined />}  onClick={()=>{
                                    this.setState({hideCertificateRegister: false, certificateId: record.rgOCIdOperationCertificate}, ()=>{
                                        this.fechSingleCertificate();
                                    }); }}/>
                                </Popover>
                            </div>
                        :
                        <div>
                            <Popover content="Ver certificado"><Button icon={<EyeOutlined />}  onClick={()=>{
                                this.setState({hideCertificateRegister: false, certificateId: record.rgOCIdOperationCertificate}, ()=>{
                                    this.fechSingleCertificate();
                                }); }}/>
                            </Popover>
                        </div>
            },
        ];
    }

    componentDidMount() {
        this._isMount = true;
        displayPage = 0;
        displayAmount = 10;
        this.getOperationCertificateInfo();
        window.addEventListener("distributionPointChange",(e) => {
            if(this._isMount){
                console.log(getSelectedDistributionPoint())
                if (this.state.distributionPointId === getSelectedDistributionPoint()) {
                    return;
                }
                this.setState({ distributionPointId: getSelectedDistributionPoint()}, ()=> {
                    this.getOperationCertificateInfo();
                });
            }
        });
    }
    componentWillUnmount() {
        this._isMount = false;
    }

    onPageChangeOperationCertificate = (page, pageSize) => {
        displayPage = page - 1;
        this.getOperationCertificateInfo();
    }

    downloadSignature = (uuid, type) =>{
        getRemoteIp().then(ip =>{
            axios({
                url: `${apiUrl}operationCertificate/${uuid}/signature/${type}`,
                method: 'GET',
                headers: {
                    Authorization: getToken(),
                    originIp: ip
                },
                responseType: 'blob',
            }).then((response) => {
                saveJsonToFile(response.data,response.headers['content-type'], response.headers['x-filename']);
            }).catch((error)=>{
                message.error("No fue posible obtener el documento");
            });
        });
    }

    onClickDownloadSignatureProvider = (uuid, folio) => {
        this.downloadSignature(uuid, 'PROVIDER');
    }


    onClickDownloadSignatureLegalRepresentative = (uuid, folio) => {
        this.downloadSignature(uuid, 'LEGAL');
    }

    onShowSizeChangeOperationCertificate = (current, pageSize) => {
        displayPage = 0;
        displayAmount = pageSize;
        this.getOperationCertificateInfo();
    }

    getOperationCertificateInfo = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const rangeTimeValue = fieldsValue.period;
                const filters = {
                    requestOCStartDate: rangeTimeValue[0].format('YYYY-MM-DD'),
                    requestOCEndDate: rangeTimeValue[1].format('YYYY-MM-DD'),
                    requestOCDisplayPage: displayPage,
                    requestOCDisplayAmount: displayAmount,
                    distributionPointId: this.state.distributionPointId,
                };
                this.fetchRegistersOnOperationCertificate(filters);
            }
        });
    }

    handlePeriodChangeOnOperationCertificate = e => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                // eslint-disable-next-line no-param-reassign
                fieldsValue.period = e;
                const rangeTimeValue = fieldsValue.period;
                const filters = {
                    requestOCStartDate: rangeTimeValue[0].format('YYYY-MM-DD'),
                    requestOCEndDate: rangeTimeValue[1].format('YYYY-MM-DD'),
                    requestOCDisplayPage: displayPage,
                    requestOCDisplayAmount: displayAmount,
                    distributionPointId: this.state.distributionPointId,
                };
                this.fetchRegistersOnOperationCertificate(filters);
            }
        });
    }

    fetchRegistersOnOperationCertificate = (params = {}) => {
        this.setState({ dataSource: [], loadingTable: true });
        operationCertificateServices.post('operationCertificate/getRegisters', params)
            .then(response => {
                const pagination = { ...this.state.pagination };
                pagination.total = response.responseOCTotalRecords;
                this.setState({
                    dataSource: response.responseOperationCertificates,
                    pagination,
                    currentPage: displayPage + 1,
                    loadingTable: false,
                });
            });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <div hidden={!this.state.hideCertificateRegister}>
                {this.props.pagePermissions.read &&
                    <div>
                        {this.state.mainView &&
                            <div>
                                <Form layout='vertical' >
                                    <Row gutter={24}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Consultar período por fecha de certificado"
                                            >
                                                {getFieldDecorator('period', {
                                                    rules: [{ required: true, message: 'Por favor selecciona un periodo de tiempo.' }],
                                                    initialValue: [dayjs().startOf('week'), dayjs().endOf('week')]
                                                })(
                                                    <RangePicker
                                                        lang='es'
                                                        format="YYYY/MM/DD"
                                                        placeholder={['Desde', 'Hasta']}
                                                        onChange={this.handlePeriodChangeOnOperationCertificate}
                                                    />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        {this.props.pagePermissions.add &&
                                            <Col span={6} offset={6}>
                                                <Form.Item
                                                    className="transparent-label"
                                                    label=" "
                                                >
                                                    <Button
                                                        type="primary"
                                                        icon={<PlusOutlined />}
                                                        onClick={()=>{
                                                            this.setState({hideCertificateRegister: false, certificateId:0}, ()=>{
                                                                this.fechSingleCertificate();
                                                            }); }}
                                                    >
                                                        Agregar certificado
                                                    </Button>
                                                </Form.Item >
                                            </Col>
                                        }
                                    </Row>
                                </Form>
                                <Table
                                    dataSource={this.state.dataSource}
                                    columns={this.columns}
                                    scroll={{ x: true }}
                                    pagination={false}
                                    rowKey={record => record.rgOCFolio}
                                    loading={this.state.loadingTable}
                                />
                                <br />
                                <Pagination
                                    showSizeChanger
                                    onChange={this.onPageChangeOperationCertificate}
                                    current={this.state.currentPage}
                                    onShowSizeChange={this.onShowSizeChangeOperationCertificate}
                                    total={this.state.pagination.total}
                                    pageSizeOptions={['10', '20', '30']}
                                    // locale={{ items_per_page: '/ página' }} 
                                    showTotal={total => `Total ${total} elementos`}
                                />
                                <Divider />
                            </div>
                        }
                    </div>}
                </div>
                <div>
                    <OperationCertificateRegistration 
                        certificateId={this.state.certificateId} 
                        distributionPointId={this.state.distributionPointId} 
                        distributionPointName={this.state.distributionPointName} 
                        hideCertificateRegister={this.state.hideCertificateRegister}
                        fetchCertificate={click => this.fechSingleCertificate = click}
                        onReturn={()=>{this.closeCertificateRegister();}}
                        />
                </div>
                
            </div>
        );
    }
    closeCertificateRegister = () =>{
        this.setState({hideCertificateRegister: true, certificateId:'0'}, ()=>{
            this.getOperationCertificateInfo();
        });
    }
}

const OperationCertificatesTable = Form.create({ name: 'operationCertificatesTable' })(OperationCertificates);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(OPERATION_CERTIFICATE, permissions)
});

export default connect(mapStateToProps)(OperationCertificatesTable);
