import React from "react";
import { PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Modal, Input, Button, Row, Col, Select, message } from "antd";
import { isValidRfc } from "./../../../services/utils/rfcValidator"

const RfcProveedoresModal = Form.create({ name: "rfc_proveedores_modal" })(
	class extends React.Component {
		state = {
			newRfc: "",
		};

		onWriteRfc = (value) => {
			this.setState({ newRfc: value.target.value });
		};

		onAddRfc = (rfcList) => {
			let newRfc = this.state.newRfc.toUpperCase();
			if (!isValidRfc(newRfc)) {
				message.error('El RFC ' + newRfc + ' no es valido');
				return;
			}
			if (rfcList.includes(newRfc)) {
				message.error('El RFC ' + newRfc + ' ya esta registrado');
				return;
			}
			rfcList.push(newRfc);
			this.props.onUpdateRfcList(rfcList);
			this.setState({ newRfc: "" });
		};

		render() {
			const { originalRfcList } = this.props;
			return (
                <Modal
					open={this.props.visible}
					title={"Lista de los RFC de los proveedores"}
					onCancel={this.props.onAccept}
					onAccept={this.props.onAccept}
					footer={[
						<Button className="back-button" key="back" onClick={this.props.onAccept}>
							Aceptar
						</Button>,
					]}
				>
					<p>
						{
							"Expresa el RFC de los proveedores de equipos, sistemas, programas o cualquier otro componente de los controles volumétricos."
						}
					</p>
					<Form layout='vertical' >
						<Row gutter={24}>
							<Col span={24}>
								<p>Agregar un RFC a la lista de proveedores</p>
							</Col>
							<Row gutter={24}>
								<Col span={20}>
									<Form.Item
										placeholder="RFC Proveedor"
										rules={[{ isValidRfc, message: "RFC no valido" }]}>
										<Input
											placeholder="RFC Proveedor"
											onChange={this.onWriteRfc}
											value={this.state.newRfc} />
									</Form.Item>
								</Col>
								<Col span={4}>
									<Button size="large" type="primary" onClick={() => { this.onAddRfc(originalRfcList); }}>
										<PlusCircleOutlined />
									</Button>
								</Col>
							</Row>
						</Row>
						<Row gutter={24}>
							{originalRfcList &&
							<Select
								style={{ width: 300 }}
								value={originalRfcList}
								options={originalRfcList.map(e => ({value: e}))}
								open={false}
								placeholder="Lista de RFC Proveedores"
								allowClear
								mode="multiple"
								onChange={(value) => {
									this.props.onUpdateRfcList(value);
								}}
							></Select>
							}
						</Row>
					</Form>
				</Modal>
            );
		}
	}
);

export default RfcProveedoresModal;
