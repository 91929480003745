import React from 'react';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Modal, Button, Row, Col, DatePicker, Select, InputNumber, message } from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';
const { Option } = Select;

const ManualVolumeRegisterForm = Form.create({ name: 'jsonShowInfo_into_modal' })(

    class extends React.Component {
        state = {
            reportsInfo: [],
            visible: false,
        };

        showModal = () => {
            this.setState({
                visible: true,
            });
        }

        handleReturn = () => {
            this.setState({
                visible: false,
            });
            this.props.form.resetFields();
        };

        handleSubmit = () => {
            this.props.form.validateFields((err, fieldsValue) => {
                if (!err) {
                    const filters = {
                        idMeasurerEquipment: fieldsValue.idMeasurerEquipment,
                        registerVolumePercentage: fieldsValue.registerVolumePercentage,
                        registerMeasurementDateTime: fieldsValue.registerMeasurementDateTime.format('YYYY-MM-DD HH:mm:ss'),
                        registerVolumeTemperature: fieldsValue.registerVolumeTemperature,
                        registerVolumePressure: fieldsValue.registerVolumePressure,
                        registerVolumeDensity: fieldsValue.registerVolumeDensity
                    };
                    dispensaryServices.post('volumeMeasurerRegister/insertManualRegister', filters)
                    .then(response => {
                        const jsonResponse = JSON.parse(JSON.stringify(response));
                        if (jsonResponse.hasOwnProperty('response')) {
                            message.error(jsonResponse.response.data);
                        } else {
                            message.success(response);
                        }
                        this.handleReturn();
                        this.props.fetchVolumeMeasurerRegistries();
                    });
                    
                }
            });
        };

        render() {
            const { getFieldDecorator } = this.props.form;
            const optionIdEquipment = types => types.map(item => (
                <Option key={item.idEquipment} value={item.idEquipment}>{item.auditableEquipmentKey}</Option>
            ));

            return (
                <div style={{ display: 'inline-block' }}>
                    <Button type="primary" icon={<DownloadOutlined />} onClick={() => this.showModal()}>Insertar registro de volumen a tanque</Button>
                    <Modal
                        width={1200}
                        open={this.state.visible}
                        title="Insertar registro manual"
                        onCancel={this.handleReturn}
                        footer={[
                            <Button className="back-button" key="back" onClick={this.handleReturn}>Volver</Button>
                        ]}
                    >
                        <Form layout='vertical' >
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Seleccione el medidor de volumen">
                                        {getFieldDecorator(`idMeasurerEquipment`, {
                                            rules: [
                                                { required: true, message: 'Seleccione el medidor de volumen al que se le agregará un registro' },
                                            ]
                                        })(
                                            <Select>
                                                {optionIdEquipment(this.props.measurementEquipments)}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Fecha y hora de la medicion">
                                        {getFieldDecorator('registerMeasurementDateTime', {
                                            rules: [{
                                                required: true, message: 'Por favor introduce la fecha y hora de la medición.'
                                            }],
                                        })(
                                            <DatePicker  lang='es' 
                                                showTime={true}
                                                showToday={false}
                                                placeholder={"Fecha y hora de factura"}
                                                style={{ width: '100%' }} 
                                                format={'DD-MM-YYYY HH:mm:ss'} 
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Porcentaje actual del tanque">
                                        {getFieldDecorator('registerVolumePercentage', {
                                            rules: [{ required: true, message: 'Por favor introduce el porcentaje de nivel actual del tanque.' }]
                                        })(
                                            <InputNumber style={{ width: '100%' }} min={0.00} max={100.00} precision={2}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Temperatura observada">
                                        {getFieldDecorator('registerVolumeTemperature', {
                                            rules: [{ required: true, message: 'Por favor introduce la temperatura actual del tanque.' }]
                                        })(
                                            <InputNumber style={{ width: '100%' }} precision={1}/>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Presión observada">
                                        {getFieldDecorator('registerVolumePressure', {
                                            rules: [{ required: true, message: 'Por favor introduce la presión actual del tanque.' }]
                                        })(
                                            <InputNumber style={{ width: '100%' }} min={0.00} precision={2}/>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Densidad observada">
                                        {getFieldDecorator('registerVolumeDensity', {
                                            rules: [{ required: false, message: 'Por favor introduce la densidad actual del tanque.' }]
                                        })(
                                            <InputNumber style={{ width: '100%' }} min={0.00} precision={4}/>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Button type="primary" icon={<PlusOutlined />} onClick={() => this.handleSubmit()}>Agregar Registro</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </div>
            );
        }
    }
);

export default ManualVolumeRegisterForm;
