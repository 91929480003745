import React, { Component } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import {
    Row,
    Col,
    Input,
    Button,
    message,
    DatePicker,
    Modal,
    Divider,
    Select,
    InputNumber,
    Upload,
    Spin,
} from 'antd';
import { Redirect } from 'react-router-dom';
import {
    register as registerServices
} from '../../../services';
import dayjs from "dayjs";
import moment from 'moment';
import { connect } from 'react-redux';
import {
    getPagePermissions,
    DICTUM,
} from '../../../security/permissions';
import DictumDetailsPR03 from './dictumDetailsPR03';
import DictumDetailsPR07 from './dictumDetailsPR07';
import DictumDetailsPR08 from './dictumDetailsPR08';
import DictumDetailsPR09And10 from './dictumDetailsPR09And10';
import DictumDetailsPR11 from './dictumDetailsPR11';
import DictumDetailsPR12 from './dictumDetailsPR12';
import DictumDetailsPR15 from './dictumDetailsPR15';
import { getSelectedDistributionPoint, getSelectedDistributionPointName } from '../../../common/components/widgets/DistributionPoint/distributionPointStorage';
import '../../../styles/index.css';
import { handlePreview, openBase64InModal } from '../../../services/utils/generalUtils';

const success = () => {
    message.success('Dictamen registrado exitosamente');
};

const Option = Select.Option;

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class DictumRegistration extends Component {

    constructor(props) {
        super(props);
        this.fetch = this.fetch.bind(this);
    }

    _isMount = false;
    state = {
        loading: false,
        visible: false,
        redirectToRecepcion: false,
        data: {},
        products: [],
        selectedProduct: '',
        markingExists: false,
        isCheckedCheckbox: false,
        fileList: [],
        fileList1: [],
        dictumlessReceptions: [],
        currentReception: null,
        dictumFolioValidator: {},
        distributionPointId: getSelectedDistributionPoint(),
        distributionPointName: getSelectedDistributionPointName(),
    };

    componentDidMount() {
        this.props.fetchDictum(this.fetch);
        this._isMount = true;
        window.addEventListener("distributionPointChange",(e) => {
            if(this._isMount){
                this.setState({distributionPointId: getSelectedDistributionPoint(), distributionPointName: getSelectedDistributionPointName()}, ()=>{
                    if (this.props.dictumId !== undefined && this.props.dictumId !=='0') {
                        this.setRedirect();
                    }else{
                        this.fetchProducts();
                        this.fetchDictumlessReceptions();
                        this.props.form.setFieldsValue({ 'productType': '', 'receptionUUID': ''});
                        this.setState({selectedProduct: ''});
                    }
                });
            }
        });
    }
    componentWillUnmount() {
        this._isMount = false;
    }

    setRedirect = () => {
        this.props.onReturn();
    }

    fetch = (params = {}) => {
        this.fetchDictumlessReceptions().finally(()=>{
            this.fetchProducts();
            if (this.props.dictumId === undefined || this.props.dictumId ==='0') {
                this.setState({
                    data: {},
                    markingExists: false,
                    selectedProduct: '',
                    currentReception: null,
                });
                this.props.form.resetFields();
                return;
            }
            this.setState({loading: true});
            const dictumId = this.props.dictumId;
            registerServices.get(`dictum/${dictumId}`)
                .then(response => {
                    if (response.productType === 'PR03' || response.productType === 'PR07' || response.productType === 'PR11') {
                        response.dictumDetails[0].responseDictumDetailValue = response.dictumDetails[0].responseDictumDetailValue === 'true' ? true : false;
                    }
                    this.setState({
                        data: response,
                        selectedProduct: response.productType,
                        markingExists: response.marking === "" ? false : true,
                        dictumlessReceptions: (response.isEditable)? [...this.state.dictumlessReceptions, response.dictumReception]: [response.dictumReception],
                        currentReception: response.dictumReception.id,
                        loading: false,
                    }, ()=>{
                        this.props.form.setFieldsValue({ 'receptionUUID': response.dictumReception.id, 'productType': response.productType });
                        this.calculateSum();
                    });
                });
        });
        
    };

    fetchProducts = () => {
        registerServices.get(`distributionPoints/${this.state.distributionPointId}/activeProducts`)
            .then(response => {
                this.setState({
                    products: response
                });
            });
    };

    fetchDictumlessReceptions = () => {
        return new Promise((resolve, reject)=>{
            registerServices.get(`distributionPoints/${this.state.distributionPointId}/dictumLessReceptions`)
            .then(response => {
                this.setState({dictumlessReceptions: response}, ()=> {
                    resolve(response);
                });
            }).catch((error)=>{
                reject(error);
            });
        });
        
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleCancel = () => {
        this.setState({ visible: false });
        this.props.onReturn();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.showModal();
            }
        });
    }

    handleOk = () => {
        this.setState({ loading: true });
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                let dictumDetails = [];

                if (this.state.selectedProduct === 'PR12') {
                    dictumDetails = [
                        { requestDictumDetailName: 'GASLP_PROPANO', requestDictumDetailValue: values.gasPropanoRequestDictumDetailValue },
                        { requestDictumDetailName: 'GASLP_BUTANO', requestDictumDetailValue: values.gasButanoRequestDictumDetailValue },
                        { requestDictumDetailName: 'GASLP_OTROS', requestDictumDetailValue: values.gasOtrosRequestDictumDetailValue },
                        { requestDictumDetailName: 'GASLP_PROPANO_NORMALIZADO', requestDictumDetailValue: values.gasPropanoNormalizadoRequestDictumDetailValue },
                        { requestDictumDetailName: 'GASLP_BUTANO_NORMALIZADO', requestDictumDetailValue: values.gasButanoNormalizadoRequestDictumDetailValue },
                    ]
                } else if (this.state.selectedProduct === 'PR08') {

                    dictumDetails = [
                        { requestDictumDetailName: 'PETROLEO_SUBPRODUCTO', requestDictumDetailValue: values.petroleumSubproductType },
                        { requestDictumDetailName: 'PETROLEO_DENSIDAD', requestDictumDetailValue: values.petroleumDensity },
                        { requestDictumDetailName: 'PETROLEO_AZUFRE', requestDictumDetailValue: values.petroleumSulphurCompose },
                    ]
                } else if (this.state.selectedProduct === 'PR15') {

                    dictumDetails = [
                        { requestDictumDetailName: 'BIOENERGETICO_SUBPRODUCTO', requestDictumDetailValue: values.bioenergeticSubproductType, requestAdditionalDictumDetailValue: values.bioenergeticComponents },
                    ]
                } else if (this.state.selectedProduct === 'PR07') {

                    dictumDetails = [
                        { requestDictumDetailName: 'GASOLINA_OCTANAJE', requestDictumDetailValue: values.gasolineOctaneValue },
                        { requestDictumDetailName: 'GASOLINA_COMBUSTIBLE_NOFOSIL', requestDictumDetailValue: values.gasolineNonFosilCombustibleValue },
                        { requestDictumDetailName: 'GASOLINA_COMPOS_COMBUSTIBLE_NOFOSIL', requestDictumDetailValue: values.gasolineNonFosilCombustibleComposValue },
                    ]
                } else if (this.state.selectedProduct === 'PR03') {

                    dictumDetails = [
                        { requestDictumDetailName: 'DIESEL_COMBUSTIBLE_NOFOSIL', requestDictumDetailValue: values.dieselNonFosilCombustibleValue },
                        { requestDictumDetailName: 'DIESEL_COMPOS_COMBUSTIBLE_NOFOSIL', requestDictumDetailValue: values.dieselNonFosilCombustibleComposValue },
                    ]
                } else if (this.state.selectedProduct === 'PR11') {

                    dictumDetails = [
                        { requestDictumDetailName: 'TURBOSINA_COMBUSTIBLE_NOFOSIL', requestDictumDetailValue: values.turbosineNonFosilCombustibleValue },
                        { requestDictumDetailName: 'TURBOSINA_COMPOS_COMBUSTIBLE_NOFOSIL', requestDictumDetailValue: values.turbosineNonFosilCombustibleComposValue },
                    ]
                } else if (this.state.selectedProduct === 'PR09' || this.state.selectedProduct === 'PR10') {

                    dictumDetails = [
                        { requestDictumDetailName: 'GNC01', requestDictumDetailValue: values.gnc01MolarFraction, requestAdditionalDictumDetailValue: values.gnc01HeathPower },
                        { requestDictumDetailName: 'GNC02', requestDictumDetailValue: values.gnc02MolarFraction, requestAdditionalDictumDetailValue: values.gnc02HeathPower },
                        { requestDictumDetailName: 'GNC03', requestDictumDetailValue: values.gnc03MolarFraction, requestAdditionalDictumDetailValue: values.gnc03HeathPower },
                        { requestDictumDetailName: 'GNC04', requestDictumDetailValue: values.gnc04MolarFraction, requestAdditionalDictumDetailValue: values.gnc04HeathPower },
                        { requestDictumDetailName: 'GNC05', requestDictumDetailValue: values.gnc05MolarFraction, requestAdditionalDictumDetailValue: values.gnc05HeathPower },
                        { requestDictumDetailName: 'GNC06', requestDictumDetailValue: values.gnc06MolarFraction, requestAdditionalDictumDetailValue: values.gnc06HeathPower },
                        { requestDictumDetailName: 'GNC07', requestDictumDetailValue: values.gnc07MolarFraction, requestAdditionalDictumDetailValue: values.gnc07HeathPower },
                        { requestDictumDetailName: 'GNC08', requestDictumDetailValue: values.gnc08MolarFraction, requestAdditionalDictumDetailValue: values.gnc08HeathPower },
                        { requestDictumDetailName: 'GNC09', requestDictumDetailValue: values.gnc09MolarFraction, requestAdditionalDictumDetailValue: values.gnc09HeathPower },
                        { requestDictumDetailName: 'GNC10', requestDictumDetailValue: values.gnc10MolarFraction, requestAdditionalDictumDetailValue: values.gnc10HeathPower },
                    ]
                }

                const filters = {
                    sampleContainerIdentification: values.sampleContainerIdentification,
                    laboratoryRfc: values.laboratoryRfc,
                    laboratoryBusinessName: values.laboratoryBusinessName,
                    providerSignature: values.providerSignature,
                    providerName: values.providerName,
                    legalRepresentativeSignature: values.legalRepresentativeSignature,
                    legalRepresentativeName: values.legalRepresentativeName,
                    dictumIssueDate: values.dictumIssueDate.format('DD/MM/YYYY'),
                    dictumFolio: values.dictumFolio,
                    testAppliedDate: values.testAppliedDate.format('DD/MM/YYYY'),
                    sampleTakenDate: values.sampleTakenDate.format('DD/MM/YYYY'),
                    analizedProductBatch: values.analizedProductBatch,
                    testResultDate: values.testResultDate.format('DD/MM/YYYY'),
                    analizedProductOrigin: values.analizedProductOrigin,
                    analizedProductVolume: values.analizedProductVolume,
                    analizedProductComposition: values.analizedProductComposition,
                    analizedProductFeatures: values.analizedProductFeatures,
                    testResult: values.testResult,
                    testMethod: values.testMethod,
                    productType: values.productType,
                    commercialBrand: values.commercialBrand,
                    marking: values.marking,
                    markingComposition: values.markingComposition,
                    dictumDetails,
                    receptionUUID: values.receptionUUID,
                };
                this.fetchRegisters(filters);
            }
        });
    }

    handleEdit = () => {
        this.setState({ loading: true });
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {

                let dictumDetails = [];

                if (this.state.selectedProduct === 'PR12') {
                    dictumDetails = [
                        { requestDictumDetailName: 'GASLP_PROPANO', requestDictumDetailValue: values.gasPropanoRequestDictumDetailValue },
                        { requestDictumDetailName: 'GASLP_BUTANO', requestDictumDetailValue: values.gasButanoRequestDictumDetailValue },
                        { requestDictumDetailName: 'GASLP_OTROS', requestDictumDetailValue: values.gasOtrosRequestDictumDetailValue },
                        { requestDictumDetailName: 'GASLP_PROPANO_NORMALIZADO', requestDictumDetailValue: values.gasPropanoNormalizadoRequestDictumDetailValue },
                        { requestDictumDetailName: 'GASLP_BUTANO_NORMALIZADO', requestDictumDetailValue: values.gasButanoNormalizadoRequestDictumDetailValue },
                    ]
                } else if (this.state.selectedProduct === 'PR08') {

                    dictumDetails = [
                        { requestDictumDetailName: 'PETROLEO_SUBPRODUCTO', requestDictumDetailValue: values.petroleumSubproductType },
                        { requestDictumDetailName: 'PETROLEO_DENSIDAD', requestDictumDetailValue: values.petroleumDensity },
                        { requestDictumDetailName: 'PETROLEO_AZUFRE', requestDictumDetailValue: values.petroleumSulphurCompose },
                    ]
                } else if (this.state.selectedProduct === 'PR15') {

                    dictumDetails = [
                        { requestDictumDetailName: 'BIOENERGETICO_SUBPRODUCTO', requestDictumDetailValue: values.bioenergeticSubproductType, requestAdditionalDictumDetailValue: values.bioenergeticComponents },
                    ]
                } else if (this.state.selectedProduct === 'PR07') {

                    dictumDetails = [
                        { requestDictumDetailName: 'GASOLINA_OCTANAJE', requestDictumDetailValue: values.gasolineOctaneValue },
                        { requestDictumDetailName: 'GASOLINA_COMBUSTIBLE_NOFOSIL', requestDictumDetailValue: values.gasolineNonFosilCombustibleValue },
                        { requestDictumDetailName: 'GASOLINA_COMPOS_COMBUSTIBLE_NOFOSIL', requestDictumDetailValue: values.gasolineNonFosilCombustibleComposValue },
                    ]
                } else if (this.state.selectedProduct === 'PR03') {

                    dictumDetails = [
                        { requestDictumDetailName: 'DIESEL_COMBUSTIBLE_NOFOSIL', requestDictumDetailValue: values.dieselNonFosilCombustibleValue },
                        { requestDictumDetailName: 'DIESEL_COMPOS_COMBUSTIBLE_NOFOSIL', requestDictumDetailValue: values.dieselNonFosilCombustibleComposValue },
                    ]
                } else if (this.state.selectedProduct === 'PR11') {

                    dictumDetails = [
                        { requestDictumDetailName: 'TURBOSINA_COMBUSTIBLE_NOFOSIL', requestDictumDetailValue: values.turbosineNonFosilCombustibleValue },
                        { requestDictumDetailName: 'TURBOSINA_COMPOS_COMBUSTIBLE_NOFOSIL', requestDictumDetailValue: values.turbosineNonFosilCombustibleComposValue },
                    ]
                } else if (this.state.selectedProduct === 'PR09' || this.state.selectedProduct === 'PR10') {

                    dictumDetails = [
                        { requestDictumDetailName: 'GNC01', requestDictumDetailValue: values.gnc01MolarFraction, requestAdditionalDictumDetailValue: values.gnc01HeathPower },
                        { requestDictumDetailName: 'GNC02', requestDictumDetailValue: values.gnc02MolarFraction, requestAdditionalDictumDetailValue: values.gnc02HeathPower },
                        { requestDictumDetailName: 'GNC03', requestDictumDetailValue: values.gnc03MolarFraction, requestAdditionalDictumDetailValue: values.gnc03HeathPower },
                        { requestDictumDetailName: 'GNC04', requestDictumDetailValue: values.gnc04MolarFraction, requestAdditionalDictumDetailValue: values.gnc04HeathPower },
                        { requestDictumDetailName: 'GNC05', requestDictumDetailValue: values.gnc05MolarFraction, requestAdditionalDictumDetailValue: values.gnc05HeathPower },
                        { requestDictumDetailName: 'GNC06', requestDictumDetailValue: values.gnc06MolarFraction, requestAdditionalDictumDetailValue: values.gnc06HeathPower },
                        { requestDictumDetailName: 'GNC07', requestDictumDetailValue: values.gnc07MolarFraction, requestAdditionalDictumDetailValue: values.gnc07HeathPower },
                        { requestDictumDetailName: 'GNC08', requestDictumDetailValue: values.gnc08MolarFraction, requestAdditionalDictumDetailValue: values.gnc08HeathPower },
                        { requestDictumDetailName: 'GNC09', requestDictumDetailValue: values.gnc09MolarFraction, requestAdditionalDictumDetailValue: values.gnc09HeathPower },
                        { requestDictumDetailName: 'GNC10', requestDictumDetailValue: values.gnc10MolarFraction, requestAdditionalDictumDetailValue: values.gnc10HeathPower },
                    ]
                }

                const filters = {
                    id: this.props.dictumId,
                    sampleContainerIdentification: values.sampleContainerIdentification,
                    laboratoryRfc: values.laboratoryRfc,
                    laboratoryBusinessName: values.laboratoryBusinessName,
                    providerSignature: values.providerSignature,
                    providerName: values.providerName,
                    legalRepresentativeSignature: values.legalRepresentativeSignature,
                    legalRepresentativeName: values.legalRepresentativeName,
                    dictumIssueDate: values.dictumIssueDate.format('DD/MM/YYYY'),
                    dictumFolio: values.dictumFolio,
                    testAppliedDate: values.testAppliedDate.format('DD/MM/YYYY'),
                    sampleTakenDate: values.sampleTakenDate.format('DD/MM/YYYY'),
                    analizedProductBatch: values.analizedProductBatch,
                    testResultDate: values.testResultDate.format('DD/MM/YYYY'),
                    analizedProductOrigin: values.analizedProductOrigin,
                    analizedProductVolume: values.analizedProductVolume,
                    analizedProductComposition: values.analizedProductComposition,
                    analizedProductFeatures: values.analizedProductFeatures,
                    testResult: values.testResult,
                    testMethod: values.testMethod,
                    productType: values.productType,
                    commercialBrand: values.commercialBrand,
                    marking: values.marking,
                    markingComposition: values.markingComposition,
                    dictumDetails,
                    receptionUUID: values.receptionUUID,
                };
                this.editRegisters(filters);
            }
        });
    }

    fetchRegisters = (values = {}) => {
        values.distributionPointId = this.state.distributionPointId;
        registerServices.post('dictum', values)
                .then(response => {
                    this.setRedirect();
                    this.handleUpload(response.id);
                });
    }

    editRegisters = (values = {}) => {
        values.distributionPointId = this.state.distributionPointId;
        registerServices.put('dictum', values)
            .then(response => {
                // setTimeout(() => {
                    // this.setState({ loading: false, visible: false });
                    this.handleUpload(response.id);
                    this.setRedirect();
                // }, 3000);
            });
    }

    handleUpload = URL => {
        const { fileList, fileList1 } = this.state;
        const formData = new FormData();

        if(fileList.length === 0 && fileList1.length === 0){
            this.setState({ loading: false, visible: false });
            this.setRedirect();
            success();
        }else{
            formData.append('providerSignature', fileList[0]);
            formData.append('legalRepresentativeSignature', fileList1[0]);
    
            registerServices.post(`dictum/signature/${URL}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                const jsonResponse = JSON.parse(JSON.stringify(response));
                if (jsonResponse.hasOwnProperty('response')) {
                    message.error( 
                        (jsonResponse.response.data.hasOwnProperty('message'))
                            ?   jsonResponse.response.data.message
                            :   jsonResponse.response.data
                    );
                }
            }).finally(()=>{
                this.setState({ loading: false, visible: false, fileList: [], fileList1: [] });
                this.setRedirect();
                success();
            });
        }
    };

    renderRedirect = () => {
        if (this.state.redirectToRecepcion) {
            return <Redirect to="/dictum" />;
        }
    }

    dropdownProductsChange = productCode => {
        this.setState({ selectedProduct: productCode });

        if (productCode !== 'PR12') {
            this.props.form.setFieldsValue({
                gasPropanoRequestDictumDetailValue: '', gasButanoRequestDictumDetailValue: '', gasOtrosRequestDictumDetailValue: '',
                gasPropanoNormalizadoRequestDictumDetailValue: '', gasButanoNormalizadoRequestDictumDetailValue: ''
            });

        }
        if (this.state.selectedProduct === 'PR08') {
            this.props.form.setFieldsValue({ petroleumSubproductType: '', petroleumDensity: '', petroleumSulphurCompose: '' });
        }
        if (this.state.selectedProduct === 'PR15') {
            this.props.form.setFieldsValue({ bioenergeticSubproductType: '', bioenergeticComponents: '' });
        }
        if (this.state.selectedProduct === 'PR07') {
            this.props.form.setFieldsValue({ gasolineOctaneValue: '', gasolineNonFosilCombustibleComposValue: '' });
        }
        if (this.state.selectedProduct === 'PR03') {
            this.props.form.setFieldsValue({ dieselNonFosilCombustibleComposValue: '' });
        }
        if (this.state.selectedProduct === 'PR11') {
            this.props.form.setFieldsValue({ turbosineNonFosilCombustibleComposValue: '' });
        }
        if (this.state.selectedProduct === 'PR09' || this.state.selectedProduct === 'PR10') {
            this.props.form.setFieldsValue({
                gnc01MolarFraction: '', gnc01HeathPower: '', gnc02MolarFraction: '', gnc02HeathPower: '',
                gnc03MolarFraction: '', gnc03HeathPower: '', gnc04MolarFraction: '', gnc04HeathPower: '', gnc05MolarFraction: '', gnc05HeathPower: '',
                gnc06MolarFraction: '', gnc06HeathPower: '', gnc07MolarFraction: '', gnc07HeathPower: '', gnc08MolarFraction: '', gnc08HeathPower: '',
                gnc09MolarFraction: '', gnc09HeathPower: '', gnc10MolarFraction: '', gnc10HeathPower: '',
            });
        }
    };

    markingExists = evt => {
        if (evt.target.value === undefined || evt.target.value === '') {
            this.setState({ markingExists: false });
            this.props.form.setFieldsValue({ markingComposition: '' });
        } else {
            this.setState({ markingExists: true });
        }
    };

    calculateSum = () => {
        if (this.state.selectedProduct === 'PR12') {
            const num1 = parseFloat(this.props.form.getFieldValue('gasPropanoRequestDictumDetailValue'), 10);
            const num2 = parseFloat(this.props.form.getFieldValue('gasButanoRequestDictumDetailValue'), 10);
            const sum = 100 - (num1 + num2);
            this.props.form.setFieldsValue({ gasOtrosRequestDictumDetailValue: sum });
            this.props.form.validateFieldsAndScroll(['gasPropanoRequestDictumDetailValue', 'gasButanoRequestDictumDetailValue'], (err, values) => {
                if (!err) {
                    const normalize = {
                        requestPropanePercentage: values.gasPropanoRequestDictumDetailValue,
                        requestButanePercentage: values.gasButanoRequestDictumDetailValue,
                    };
                    this.normalized(normalize);
                }
            });
        }
    }

    normalized = (normalize) => {
        this.props.form.validateFieldsAndScroll(['gasOtrosRequestDictumDetailValue'], (err, values) => {
            if (!err) {
                registerServices.post('dictum/calculateNormalizedGasValues', normalize)
                    .then(response => {
                        this.props.form.setFieldsValue({ gasPropanoNormalizadoRequestDictumDetailValue: response.responsePropanePercentage });
                        this.props.form.setFieldsValue({ gasButanoNormalizadoRequestDictumDetailValue: response.responseButanePercentage });
                    });
            }
        });
    }

    onBlurDictumFolio = (e) => {
        const { value } = e.target;
        const dictumFolioPattern = /^([A-ZÑ]|\\&){3}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}[0-9]{5}[12][0-9]{3}$/;

        if (dictumFolioPattern.test(value)) {
            this.setState({ dictumFolioValidator: { ...this.validateDictumFolioFormat(true), } });
        }else{
            this.setState({ dictumFolioValidator: { ...this.validateDictumFolioFormat(false), } });
        }
    }

    validateDictumFolioFormat(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `Formato de folio de dictamen no válido, el folio debe seguir la estructura: ^([A-ZÑ]|\\&){4}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    onChangeFile = (file) => {
        if(file.file.size > 10000000){
            message.error("El fichero no puede ser mayor a 10 MB");
        }
    }
    getOptionReceptions(listRecepcions){
        return listRecepcions.map(item => (
            <Option key={item.id} value={item.id}>Folio: {item.folio} - Fecha de finalización: {moment(item.endDate).format('YYYY/MM/DD HH:mm:ss')} - Volumen: {item.volume} - Equipo que realizó la entrada: {item.equipment.equipmentKey}</Option>
        ));
    }


    getImage(signatureType){
        if(this.state.data.signatures!== undefined && this.state.data.signatures.length>0){
            let imageFile = this.state.data.signatures.find(e => e.signatureType===signatureType);
            if(imageFile!==undefined){
                return "data:".concat(imageFile.contentType).concat(";base64,").concat(imageFile.signatureFile);
            }
        }
    }

    render() {
        const { getFieldDecorator, getFieldsError } = this.props.form;
        const dateFormatList = 'DD/MM/YYYY';
        const { visible, loading, fileList, fileList1 } = this.state;
        const { pagePermissions } = this.props;

        const optionItems = types => types.map(item => (
            <Option key={item.idProduct} value={item.productCode}>{item.productDescription} ({item.productCode})</Option>
        ));

        const props = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList
                    };
                });
                this.props.form.resetFields(['legalSignature']);
            },
            onChange: this.onChangeFile,
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;
            },
            accept: `image/*,.pdf`,
        };

        const props1 = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList1.indexOf(file);
                    const newFileList = state.fileList1.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList1: newFileList
                    };
                });
                this.props.form.resetFields(['providerSignature']);
            },
            onChange: this.onChangeFile,
            beforeUpload: file => {
                this.setState(state => ({
                    fileList1: [...state.fileList1, file]
                }));
                return false;
            },
            accept: `image/*,.pdf`,

        };

        return (
            <Spin spinning={this.state.loading}>
                <Form layout='vertical' onSubmit={this.handleSubmit} hidden={this.props.hideDictumRegister}>
                    {this.renderRedirect()}
                    <div >
                        <label>Llena los campos a continuación para registrar un nuevo dictamen.</label>
                        <Form.Item />
                    </div>
                    <Row gutter={24}>
                        <Col span={16}>
                            <Form.Item label="Punto de Distribución" help="Punto de Distribución seleccionado">
                                <Input className="common-input" disabled value={this.state.distributionPointName} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8} hidden>
                            <Form.Item label="UUID">
                                {getFieldDecorator('inputRegisterId', {
                                    initialValue: this.props.dictumId
                                })(
                                    <Input disabled />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Identificación de transporte o almacenamiento" className='text-wrap'>
                                {getFieldDecorator('sampleContainerIdentification', {
                                    initialValue: this.state.data.sampleContainerIdentification === undefined ? '' : this.state.data.sampleContainerIdentification,
                                    rules: [{
                                        required: true, message: 'Por favor introduce la identificación del transporte o almacenamiento de donde se tomó la muestra'
                                    }],
                                })(
                                    <Input disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="RFC del laboratorio autorizado">
                                {getFieldDecorator('laboratoryRfc', {
                                    initialValue: this.state.data.laboratoryRfc === undefined ? '' : this.state.data.laboratoryRfc,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el RFC del laboratorio autorizado por el SAT para hacer muestreo'
                                    }],
                                })(
                                    <Input disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Razón social del laboratorio">
                                {getFieldDecorator('laboratoryBusinessName', {
                                    initialValue: this.state.data.laboratoryBusinessName === undefined ? '' : this.state.data.laboratoryBusinessName,
                                    rules: [{
                                        required: true, message: 'Por favor introduce la denominación o razón social del laboratorio autorizado por el SAT para muestreo.'
                                    }],
                                })(
                                    <Input disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="Proveedor que emite el dictamen" className='text-wrap'>
                                {getFieldDecorator('providerName', {
                                    initialValue: this.state.data.providerName === undefined ? '' : this.state.data.providerName,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el nombre del personal del proveedor autorizado que emite el dictamen.'
                                    }],
                                })(
                                    <Input disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Firma del proveedor que emite el dictamen">
                                {getFieldDecorator('providerDictum', {
                                    rules: [{
                                        required: this.props.dictumId === '0' || fileList.length > 0,
                                        message: 'Por favor carga la firma del personal del proveedor autorizado que emite el dictamen.'
                                    }],
                                })(
                                    <Upload listType='picture' onPreview={handlePreview} {...props}  >
                                        <Button disabled={fileList[0] !== undefined || this.state.isEditable === false} style={{ width: '100%' }} hidden={this.state.data.isEditable===false}>
                                            <UploadOutlined /> Seleccionar archivo
                                            </Button>
                                    </Upload>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <img
                                hidden={fileList.length > 0}
                                height= '100px'
                                src={this.getImage("PROVIDER")}
                                onClick={()=>{openBase64InModal(this.getImage("PROVIDER"))}}
                                alt="PROVIDER"
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="Representante legal del proveedor" style={{ display: "flex", flexDirection: "column" }}>
                                {getFieldDecorator('legalRepresentativeName', {
                                    initialValue: this.state.data.legalRepresentativeName === undefined ? '' : this.state.data.legalRepresentativeName,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el nombre del representante legal del proveedor autorizado por el SAT.'
                                    }],
                                })(
                                    <Input disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Firma del representante proveedor">
                                {getFieldDecorator('legalDictum', {
                                    rules: [{
                                        required: this.props.dictumId === '0' || fileList1.length > 0,
                                        message: 'Por favor carga la firma del representante legal del proveedor autorizado por el SAT.'
                                    }],
                                })(
                                    <Upload listType='picture' onPreview={handlePreview} {...props1} >
                                        <Button disabled={fileList1[0] !== undefined || this.state.isEditable === false} hidden={this.state.data.isEditable===false}>
                                            <UploadOutlined /> Seleccionar archivo
                                            </Button>
                                    </Upload>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <img
                                hidden={fileList1.length > 0}
                                height= '100px'
                                src={this.getImage("LEGAL")}
                                onClick={()=>{openBase64InModal(this.getImage("LEGAL"))}}
                                alt="LEGAL"
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item 
                                label="Folio de dictamen"
                                validateStatus={this.state.dictumFolioValidator.validateStatus}
                                help={this.state.dictumFolioValidator.errorMsg}
                            >
                                {getFieldDecorator('dictumFolio', {
                                    initialValue: this.state.data.dictumFolio === undefined ? '' : this.state.data.dictumFolio,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el folio del dictamen.'
                                    }],
                                })(
                                    <Input onBlur={this.onBlurDictumFolio} disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Fecha de emisión del dictamen">
                                {getFieldDecorator('dictumIssueDate', {
                                    initialValue: this.state.data.dictumIssueDate === undefined ? null : dayjs(this.state.data.dictumIssueDate),
                                    rules: [{
                                        required: true, message: 'Por favor introduce la fecha de emisión del dictamen.'
                                    }],
                                })(
                                    <DatePicker  lang='es' style={{ width: '100%' }} format={dateFormatList} disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Toma de muestras</Divider>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="Fecha en que se tomó la muestra para pruebas" className='text-wrap'>
                                {getFieldDecorator('sampleTakenDate', {
                                    initialValue: this.state.data.sampleTakenDate === undefined ? null : dayjs(this.state.data.sampleTakenDate),
                                    rules: [{
                                        required: true, message: 'Por favor introduce la fecha en que se tomó la muestra para pruebas.'
                                    }],
                                })(
                                    <DatePicker  lang='es' style={{ width: '100%' }} format={dateFormatList} disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Fecha en que se realizaron las pruebas" className='text-wrap'>
                                {getFieldDecorator('testAppliedDate', {
                                    initialValue: this.state.data.testAppliedDate === undefined ? null : dayjs(this.state.data.testAppliedDate),
                                    rules: [{
                                        required: true, message: 'Por favor introduce la fecha en que se realizaron las pruebas.'
                                    }],
                                })(
                                    <DatePicker  lang='es' style={{ width: '100%' }} format={dateFormatList} disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Fecha en que se obtuvieron los resultados de las pruebas" className='text-wrap'>
                                {getFieldDecorator('testResultDate', {
                                    initialValue: this.state.data.testResultDate === undefined ? null : dayjs(this.state.data.testResultDate),
                                    rules: [{
                                        required: true, message: 'Por favor introduce la fecha en que se obtuvieron los resultados de las pruebas.'
                                    }],
                                })(
                                    <DatePicker  lang='es' style={{ width: '100%' }} format={dateFormatList} disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Documentación de hidrocarburo o petrolífero</Divider>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="Lote">
                                {getFieldDecorator('analizedProductBatch', {
                                    initialValue: this.state.data.analizedProductBatch === undefined ? '' : this.state.data.analizedProductBatch,
                                    rules: 
                                    [
                                        {
                                            required: true, message: 'Por favor introduce el lote hidrocarburo o petrolífero.'
                                        },{
                                            min: 1, message: 'Introduce mínimo 1 caracte.'
                                        }, {
                                            max: 50, message: 'No se puede exceder los 50 caracteres.'
                                        }
                                    ],
                                })(
                                    <Input disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Origen del hidrocarburo o petrolífero" className='text-wrap'>
                                {getFieldDecorator('analizedProductOrigin', {
                                    initialValue: this.state.data.analizedProductOrigin === undefined ? '' : this.state.data.analizedProductOrigin,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el origen del hidrocarburo o petrolífero'
                                    }],
                                })(
                                    <Input disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Volumen del hidrocarburo o petrolífero" className='text-wrap'>
                                {getFieldDecorator('analizedProductVolume', {
                                    initialValue: this.state.data.analizedProductVolume === undefined ? '' : this.state.data.analizedProductVolume,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el volumen del hidrocarburo o petrolífero.'
                                    }],
                                })(
                                    <InputNumber disabled={this.state.data.isEditable === false || pagePermissions.edit === false} style={{ width: '100%' }} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} style={{marginBottom: 20}}>
                        <Col span={8}>
                            <Form.Item label="Composición del hidrocarburo o petrolífero" className='text-wrap'>
                                {getFieldDecorator('analizedProductComposition', {
                                    initialValue: this.state.data.analizedProductComposition === undefined ? '' : this.state.data.analizedProductComposition,
                                    rules: [{
                                        required: true, message: 'Por favor introduce la composición hidrocarburo o petrolífero.'
                                    }],
                                })(
                                    <Input disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Características del hidrocarburo o petrolífero" className='text-wrap'>
                                {getFieldDecorator('analizedProductFeatures', {
                                    initialValue: this.state.data.analizedProductFeatures === undefined ? '' : this.state.data.analizedProductFeatures,
                                    rules: [{
                                        required: true, message: 'Por favor introduce las características del hidrocarburo o petrolífero'
                                    }],
                                })(
                                    <Input disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Marca comercial del hidrocarburo o petrolífero" className='text-wrap'>
                                {getFieldDecorator('commercialBrand', {
                                    initialValue: this.state.data.commercialBrand === undefined ? '' : this.state.data.commercialBrand,
                                })(
                                    <Input disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="Marcaje" help="Sustancia utilizada para marcar el hidrocarburo o petrolífero" >
                                {getFieldDecorator('marking', {
                                    initialValue: this.state.data.marking === undefined ? '' : this.state.data.marking,
                                })(
                                    <Input disabled={this.state.data.isEditable === false || pagePermissions.edit === false} onBlur={this.markingExists} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8} hidden={this.state.markingExists === false}>
                            <Form.Item
                                label="Concentración de la sustancia de marcaje"
                                help="Concentración de la sustancia utilizada para marcar, se debe ingresar expresado en ppm"
                                className='text-wrap'
                            >
                                {getFieldDecorator('markingComposition', {
                                    initialValue: this.state.data.markingComposition === undefined ? '' : this.state.data.markingComposition,
                                    rules: [{
                                        required: this.state.markingExists === true, message: 'Por favor introduce la concentración de la sustancia de marcaje (este campo sólo es requerido si existe sustancia de marcaje)'
                                    }],
                                })(
                                    <InputNumber style={{ width: '100%' }} disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Resultados de las muestras</Divider>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="Resultado de las pruebas aplicadas a la muestra" className='text-wrap'>
                                {getFieldDecorator('testResult', {
                                    initialValue: this.state.data.testResult === undefined ? '' : this.state.data.testResult,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el esultado de las pruebas aplicadas a la muestra.'
                                    }],
                                })(
                                    <Input disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Método de las pruebas aplicadas a la muestra">
                                {getFieldDecorator('testMethod', {
                                    initialValue: this.state.data.testMethod === undefined ? '' : this.state.data.testMethod,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el método de las pruebas aplicadas a la muestra.'
                                    }],
                                })(
                                    <Input disabled={this.state.data.isEditable === false || pagePermissions.edit === false} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Detalles</Divider>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="Selecciona el tipo de producto">
                                {getFieldDecorator('productType', {
                                    initialValue: this.state.data.productType === undefined ? '' : this.state.data.productType,
                                    rules: [{
                                        required: true, message: 'Por favor selecciona el tipo de producto.'
                                    }],
                                })(
                                    <Select disabled={this.state.data.isEditable === false || pagePermissions.edit === false}
                                        placeholder="Selecciona un tipo de producto" onChange={(productCode) => this.dropdownProductsChange(productCode)}>
                                        {optionItems(this.state.products)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={16}>
                            <Form.Item label="Selecciona la recepción a la que pertenece este dictámen">
                                {getFieldDecorator('receptionUUID', {
                                    initialValue: this.state.currentReception,
                                    rules: [
                                        {
                                            required: true, message: 'Por favor selecciona la recepción que pertenece a este dictamen.'
                                        },
                                    ],
                                })(
                                    <Select>
                                        {this.getOptionReceptions(this.state.dictumlessReceptions)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} hidden={this.state.selectedProduct !== 'PR12'}>
                        <DictumDetailsPR12 form={this.props.form} selectedProduct={this.state.selectedProduct}
                            pagePermissions={pagePermissions} isEditable={this.state.data.isEditable}
                            data={this.state.data !== undefined ? this.state.data : null} />
                    </Row>
                    <Row gutter={24} hidden={this.state.selectedProduct !== 'PR08'}>
                        <DictumDetailsPR08 form={this.props.form} selectedProduct={this.state.selectedProduct}
                            pagePermissions={pagePermissions} isEditable={this.state.data.isEditable}
                            data={this.state.data !== undefined ? this.state.data : null} />
                    </Row>
                    <Row gutter={24} hidden={this.state.selectedProduct !== 'PR07'}>
                        <DictumDetailsPR07 form={this.props.form} selectedProduct={this.state.selectedProduct}
                            pagePermissions={pagePermissions} isEditable={this.state.data.isEditable}
                            data={this.state.data !== undefined ? this.state.data : null} />
                    </Row>
                    <Row gutter={24} hidden={this.state.selectedProduct !== 'PR03'}>
                        <DictumDetailsPR03 form={this.props.form} selectedProduct={this.state.selectedProduct}
                            pagePermissions={pagePermissions} isEditable={this.state.data.isEditable}
                            data={this.state.data !== undefined ? this.state.data : null} />
                    </Row>
                    <Row gutter={24} hidden={this.state.selectedProduct !== 'PR11'}>
                        <DictumDetailsPR11 form={this.props.form} selectedProduct={this.state.selectedProduct}
                            pagePermissions={pagePermissions} isEditable={this.state.data.isEditable}
                            data={this.state.data !== undefined ? this.state.data : null} />
                    </Row>
                    <Row gutter={24} hidden={this.state.selectedProduct !== 'PR15'}>
                        <DictumDetailsPR15 form={this.props.form} selectedProduct={this.state.selectedProduct}
                            pagePermissions={pagePermissions} isEditable={this.state.data.isEditable}
                            data={this.state.data !== undefined ? this.state.data : null} />
                    </Row>
                    <Row gutter={24} hidden={this.state.selectedProduct !== 'PR09' && this.state.selectedProduct !== 'PR10'}>
                        <DictumDetailsPR09And10 form={this.props.form} selectedProduct={this.state.selectedProduct}
                            pagePermissions={pagePermissions} isEditable={this.state.data.isEditable}
                            data={this.state.data !== undefined ? this.state.data : null} />
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item>
                                {this.state.data.laboratoryRfc === undefined ?
                                    <Button hidden={this.state.data.isEditable === false || pagePermissions.edit === false} className="customized-primary" type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>Agregar dictamen</Button> :
                                    <Button hidden={this.state.data.isEditable === false || pagePermissions.edit === false} className="customized-primary" type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>Editar dictamen</Button>
                                }

                                <Button hidden={this.state.data.isEditable === undefined ? true : this.state.data.isEditable} className="customized-primary"  type="primary" htmlType="submit" style={{ marginLeft: 16 }} onClick={()=>{this.setRedirect(); }} >
                                    Cancelar
                                </Button>

                                <Button hidden={this.state.data.isEditable === false} className="customized-default" style={{ marginLeft: 16 }} onClick={()=>{this.setRedirect(); }} >
                                    Cancelar
                                </Button>


                                <Modal
                                    open={visible}
                                    title="Atención"
                                    onCancel={this.handleCancel}
                                    footer={[
                                        <Button className="back-button" key="back" onClick={this.handleCancel}>Revisar información</Button>,
                                        this.state.data.laboratoryRfc === undefined ?
                                            <Button className="submit-button" key="submit" loading={loading} onClick={this.handleOk}>Confirmar registro</Button> :
                                            <Button className="submit-button" key="submit" loading={loading} onClick={this.handleEdit}>Editar registro</Button>
                                    ]}
                                >
                                    <p>Por favor asegúrese de que la información en el formulario sea la correcta.</p>
                                </Modal>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form >
            </Spin>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DICTUM, permissions),
});
export default connect(mapStateToProps)(Form.create({ name: 'dictumRegistration' })(DictumRegistration));
