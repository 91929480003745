import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import {
    Row,
    Col,
    Button,
    Input,
    Breadcrumb,
    Modal,
    Select,
    DatePicker,
    InputNumber,
    Tooltip,
} from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import TextArea from 'antd/lib/input/TextArea';
import { getSelectedDistributionPoint, getSelectedDistributionPointName } from '../../../common/components/widgets/DistributionPoint/distributionPointStorage';
import {getSubProductInfo} from '../../../common/helpers/productConstants';
import tooltip from './tooltip.json';

const { Option } = Select;

class WareHouseAggregation extends Component {
    _isMount = false;
    constructor(props) {
        super(props);
        this.state = {
            wareHouseIdentificatorVS: {},
            wareHouseCodeExist: false,
            visibleModal: false,
            loading: false,
            existedHoseCodes: [],
            existedwareHouseCodes: [],
            products: [],
            subproducts: [],
            installationKey: {},
            tankKey: [],
            ductKey: [],
            wareHouseTankCodeVS: {},
            wareHouseWellRegKeyVS: {},
            wareHouseWellIdentKeyVS: {},
            wareHouseDuctCodeVS: {},
            tankType: true,
            ductType: false,
            wellType: false,
            observedDensityStatus: {validateStatus: 'success', errorMsg: null,},
            observedTemperatureStatus: {validateStatus: 'success', errorMsg: null,},
            observedPressureStatus: {validateStatus: 'success', errorMsg: null,},
            distributionPoints: [],
            distributionPointId: getSelectedDistributionPoint(),
            distributionPointName: getSelectedDistributionPointName(),
            selectedSubproductId: null,
        };
    }

    componentDidMount() {
        this._isMount = true;
        this.fetchProducts();
        this.fetchInstallationKey();
        this.fetchTankKeys();
        this.fetchDuctKeys();
        this.fetchDistributionPoints();
        window.addEventListener("distributionPointChange",(e) => {
            if(this._isMount){
                this.setState({ distributionPointId: getSelectedDistributionPoint()}, ()=>{
                    this.props.form.setFieldsValue({ 'distributionPoint': this.state.distributionPointId, 'idProduct': '', 'idSubProduct': '' });
                    this.setState({distributionPointName: getSelectedDistributionPointName()});
                    this.fetchProducts();
                });
            }
        });
    }

    componentWillUnmount() {
        this._isMount = false;
    }

    fetchProducts = () => {
        dispensaryServices.get(`distributionPoints/${this.state.distributionPointId}/activeProducts`)
            .then(response => {
                this.setState({
                    products: response
                });
            });
    };

    fetchSubProducts = idx => {
        dispensaryServices.get(`getProductSubProducts/${idx}`)
            .then(response => {
                this.setState({
                    subproducts: response
                });
            });
    };

    fetchInstallationKey = () => {
        this.setState({ loadingFormRegistry: true });
        dispensaryServices.get('company/installationKey')
            .then(response => {
                this.setState({ installationKey: response });
            });
    };

    fetchTankKeys = () => {
        dispensaryServices.get('container/tankKeys')
            .then(response => {
                this.setState({ tankKey: response });
            });
    };

    fetchDuctKeys = () => {
        dispensaryServices.get('container/ductKeys')
            .then(response => {
                this.setState({ ductKey: response });
            });
    };

    fetchDistributionPoints = () => {
        dispensaryServices.get('distributionPoints')
          .then(response => {
            this.setState({distributionPoints: response}, ()=>{
                let dpp  = this.state.distributionPoints.find(item => { return item.id === this.state.distributionPointId});
                if(dpp)
                    this.setState({distributionPointName: dpp.name});
            })
          });
    }

    validateWarehouseKey(equipmentKey) {
        const params = {
            warehouseKey: equipmentKey
        };
        dispensaryServices.get(`distributionPoints/${this.state.distributionPointId}/checkIfWareHouseKeyExist`, params)
        .then(result => {
            if(typeof result === 'boolean' )
            this.setState({ 
                wareHouseCodeExist: result,
                wareHouseIdentificatorVS: {
                    validateStatus: result? 'error': 'success',
                    errorMsg: result? 'La clave de identificación del almacén ya existe.' : null,
                }
             });
        });
    }

    obtainTankCode = (value) => {
        const warehouseKey = `${value}-${this.props.form.getFieldValue(`instalationKey`)}-${this.props.form.getFieldValue(`wareHouseTankKey`)}`;
        this.props.form.setFieldsValue({ warehouseKey });

        const reg = /^[0-9]{4}$/;
        if (reg.test(this.props.form.getFieldValue(`wareHouseTankKey`))) {
            this.setState({ wareHouseTankCodeVS: { ...this.validateWarehouseTankIdentificationKey(true), } });

            if (warehouseKey.length === 12) {
                this.setState({ wareHouseIdentificatorVS: { ...this.validateGeneralWareHouseCode(true), } });
                this.validateWarehouseKey(warehouseKey);
            } else {
                this.setState({ wareHouseIdentificatorVS: { ...this.validateGeneralWareHouseCode(false), } });
            }
        } else {
            this.setState({ wareHouseIdentificatorVS: { ...this.validateGeneralWareHouseCode(false), } });
            this.setState({ wareHouseTankCodeVS: { ...this.validateWarehouseTankIdentificationKey(false), } });
        }
    }

    wareHouseTankCodeChange = event => {
        const warehouseKey = `${this.props.form.getFieldValue(`tankType`)}-${this.props.form.getFieldValue(`instalationKey`)}-${event.target.value}`;
        this.props.form.setFieldsValue({ warehouseKey });

        const reg = /^[0-9]{4}$/;
        if (!reg.test(event.target.value)) {
            this.setState({ wareHouseTankCodeVS: { ...this.validateWarehouseTankIdentificationKey(false), } });
            this.setState({ wareHouseIdentificatorVS: { ...this.validateGeneralWareHouseCode(false), } });
        } else {
            this.setState({ wareHouseTankCodeVS: { ...this.validateWarehouseTankIdentificationKey(true), } });

            if (warehouseKey.length === 12) {
                this.setState({ wareHouseIdentificatorVS: { ...this.validateGeneralWareHouseCode(true), } });
                this.validateWarehouseKey(warehouseKey);
            } else {
                this.setState({ wareHouseIdentificatorVS: { ...this.validateGeneralWareHouseCode(false), } });
            }
        }
    }

    validateWarehouseTankIdentificationKey(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `La clave de identificación no es válida, debe contener 4 números`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    obtainWellRegistryKey = event => {
        const warehouseKey = `POZ-${event.target.value}-${this.props.form.getFieldValue('wellIdentificationKey')}`;
        this.props.form.setFieldsValue({ warehouseKey });

        this.checkWarehouseWellCode(warehouseKey, event.target.value, this.checkWarehouseWellCode(warehouseKey, event.target.value,));
    }

    wellIdentificationKeyChange = event => {
        const warehouseKey = `POZ-${this.props.form.getFieldValue('wellRegisterKey')}-${event.target.value}`;
        this.props.form.setFieldsValue({ warehouseKey });

        if (this.props.form.getFieldValue('wellRegisterKey') !== undefined) {
            this.checkWarehouseWellCode(warehouseKey, this.props.form.getFieldValue('wellRegisterKey'), event.target.value);
        } else {
            this.checkWarehouseWellCode('.', '.', event.target.value);
        }
    }

    checkWarehouseWellCode(warehouseKey, registerKey, identificationKey) {
        const wellReg = /^POZ-[0-9A-Za-z]{1,40}-[0-9]{4}[A-Z]{3}$/;
        if (wellReg.test(warehouseKey)) {
            this.setState({ wareHouseWellRegKeyVS: { ...this.validateWarehouseWellRegisterKey(true), } });
            this.setState({ wareHouseWellIdentKeyVS: { ...this.validateWarehouseWellIdentificationKey(true), } });
            this.setState({ wareHouseIdentificatorVS: { ...this.validateGeneralWareHouseCode(true), } });
            this.validateWarehouseKey(warehouseKey);
        } else {
            this.setState({ wareHouseIdentificatorVS: { ...this.validateGeneralWareHouseCode(false), } });

            const wellRegisterKeyReg = /^[0-9A-Za-z]{1,40}$/;
            if (wellRegisterKeyReg.test(registerKey)) {
                this.setState({ wareHouseWellRegKeyVS: { ...this.validateWarehouseWellRegisterKey(true), } });
            } else {
                this.setState({ wareHouseWellRegKeyVS: { ...this.validateWarehouseWellRegisterKey(false), } });
            }

            const wellIdentKeyReg = /^[0-9]{4}[A-Z]{3}$/;
            if (wellIdentKeyReg.test(identificationKey)) {
                this.setState({ wareHouseWellIdentKeyVS: { ...this.validateWarehouseWellIdentificationKey(true), } });
            } else {
                this.setState({ wareHouseWellIdentKeyVS: { ...this.validateWarehouseWellIdentificationKey(false), } });
            }
        }

    }

    validateWarehouseWellRegisterKey(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `La clave de registro no es válida, debe tener entre 1 a 40 números o letras.`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    validateWarehouseWellIdentificationKey(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `La clave de identificación del pozo no es válida, debe contener 4 números, seguido de 3 letras mayúsculas, con el formato Regex: [0-9]{4}[A-Z]{3}`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    obtainDuctCode = (value) => {
        const warehouseKey = `DUC-${value}-${this.props.form.getFieldValue(`customerDuctKey`)}`;
        this.props.form.setFieldsValue({ warehouseKey });

        const reg = /^[0-9]{4}$/;
        if (reg.test(this.props.form.getFieldValue(`customerDuctKey`))) {
            this.setState({ wareHouseDuctCodeVS: { ...this.validateWarehouseDuctIdentificationKey(true), } });

            if (warehouseKey.length === 12) {
                this.setState({ wareHouseIdentificatorVS: { ...this.validateGeneralWareHouseCode(true), } });
                this.validateWarehouseKey(warehouseKey);
            } else {
                this.setState({ wareHouseIdentificatorVS: { ...this.validateGeneralWareHouseCode(false), } });
            }
        } else {
            this.setState({ wareHouseIdentificatorVS: { ...this.validateGeneralWareHouseCode(false), } });
            this.setState({ wareHouseDuctCodeVS: { ...this.validateWarehouseDuctIdentificationKey(false), } });
        }
    }

    wareHouseDuctCodeChange = event => {
        const warehouseKey = `DUC-${this.props.form.getFieldValue(`ductType`)}-${event.target.value}`;
        this.props.form.setFieldsValue({ warehouseKey });

        const reg = /^[0-9]{4}$/;
        if (!reg.test(event.target.value)) {
            this.setState({ wareHouseDuctCodeVS: { ...this.validateWarehouseDuctIdentificationKey(false), } });
            this.setState({ wareHouseIdentificatorVS: { ...this.validateGeneralWareHouseCode(false), } });
        } else {
            this.setState({ wareHouseDuctCodeVS: { ...this.validateWarehouseDuctIdentificationKey(true), } });

            if (warehouseKey.length === 12) {
                this.setState({ wareHouseIdentificatorVS: { ...this.validateGeneralWareHouseCode(true), } });
                this.validateWarehouseKey(warehouseKey);
            } else {
                this.setState({ wareHouseIdentificatorVS: { ...this.validateGeneralWareHouseCode(false), } });
            }
        }
    }

    validateWarehouseDuctIdentificationKey(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `La clave de identificación no es válida, debe contener 4 números`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    validateGeneralWareHouseCode(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `El formato de clave del almacén no es válido`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    showModal = () => {
        this.setState({
            visibleModal: true,
        });
    }

    handleOk = () => {
        this.setState({ loading: true });
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const warehouseValues = {
                    warehouseKey: values.warehouseKey,
                    description: values.description,
                    calibrationDate: values.calibrationDate.format('YYYY-MM-DD HH:mm:ss'),
                    status: true,
                    idProduct: values.idProduct,
                    idSubProduct: values.idSubProduct,
                    warehouseType: values.warehouseType,
                    observedPressure: values.observedPressure,
                    observedTemperature: values.observedTemperature,
                    observedDensity: values.observedDensity,
                    baseDensity: values.baseDensity,
                    distributionPointId: this.state.distributionPointId,
                }

                const newWarehouseValues = [];
                newWarehouseValues.push(warehouseValues);

                dispensaryServices.post('warehouse/insertwarehouse', newWarehouseValues)
                    .then(response => {
                        setTimeout(() => {
                            this.setState({ visibleModal: false });
                            this.props.onAddWareHouseClick();
                        }, 3000);
                    });
            }
        });
    }

    handleCancel = () => {
        this.setState({ visibleModal: false });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ 
                    observedDensityStatus: {validateStatus: 'success', errorMsg: null,}, 
                    observedPressureStatus: {validateStatus: 'success', errorMsg: null,},
                    observedTemperatureStatus: {validateStatus: 'success', errorMsg: null,},
                });
                this.showModal();
            }else{
                this.setState({ 
                    observedDensityStatus: {validateStatus: 'error', errorMsg: `Por favor ingrese la densidad observada.`,}, 
                    observedPressureStatus: {validateStatus: 'error', errorMsg: `Por favor ingrese la presión observada.`,}, 
                    observedTemperatureStatus: {validateStatus: 'error', errorMsg: `Por favor ingrese la temperatura observada.`,} 
                });
            }
        });
    };

    handleContainerTypeChange = evt => {
        this.props.form.resetFields();
        this.props.form.setFieldsValue({ 'distributionPoint': this.state.distributionPointId });
        if (evt === 'TANQUE') {
            this.setState({ tankType: true, ductType: false, wellType: false, });
        } else if (evt === 'POZO') {
            this.setState({ tankType: false, ductType: false, wellType: true, });
        } else {
            this.setState({ tankType: false, ductType: true, wellType: false, });
        }
    }

    onChangeSubProduct = (e) => {
        this.setState({selectedSubproductId : e});
    }

    filter = (input, option) =>{
		return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
	}

    render() {
        const { getFieldDecorator } = this.props.form;
        const { wareHouseCodeExist, wareHouseIdentificatorVS, visibleModal, loading,
            wareHouseTankCodeVS, wareHouseWellRegKeyVS, wareHouseWellIdentKeyVS, wareHouseDuctCodeVS } = this.state;
        const { onCancelClick } = this.props;
        const dateFormatList = 'DD/MM/YYYY';

        const optionTankDuctKeys = types => types.map(item => (
            <Option key={item.id} value={item.code}>{item.description}</Option>
        ));

        const optionProducts = types => types.map(item => (
            <Option key={item.productCode} value={item.idProduct}>{item.productDescription}</Option>
        ));

        const optionSubproducts = types => types.map(item => (
            <Option key={item.code} value={item.idSubproduct}>{item.description}</Option>
        ));

        const subProduct = getSubProductInfo({idSubproduct: this.state.selectedSubproductId});

        return (
            <div>
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    <Breadcrumb.Item>Almacenes</Breadcrumb.Item>
                    <Breadcrumb.Item>Agregar almacén</Breadcrumb.Item>
                </Breadcrumb>
                <Form layout='vertical' onSubmit={this.handleSubmit}>

                    <div className="hole-instructions">
                        <span>Llena los campos a continuación para agregar un almacén</span>
                    </div>
                    <Row gutter={24}>
                        <Col span={8} hidden>
                            <Tooltip title={tooltip.puntoDeDistribucion}>
                                <Form.Item label="Punto de Distribución" help="Punto de Distribución seleccionado">
                                    {getFieldDecorator(`distributionPoint`, {
                                    })(
                                        <Input className="common-input" disabled />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.puntoDeDistribucion}>
                                <Form.Item label="Punto de Distribución" help="Punto de Distribución seleccionado">
                                    <Input className="common-input" disabled value={this.state.distributionPointName} />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.tipoContenedor}>
                                <Form.Item label="Tipo de contenedor agrupado por el almacén" help="Selecciona el tipo de almacén a agregar">
                                    {getFieldDecorator(`warehouseType`, {
                                        initialValue: 'TANQUE',
                                    })(
                                        <Select onChange={this.handleContainerTypeChange} showSearch filterOption={this.filter}>
                                            <Option key={'tanque'} value={'TANQUE'}>{'Tanque'}</Option>
                                            <Option key={'pozo'} value={'POZO'}>{'Pozo'}</Option>
                                            <Option key={'ducto'} value={'DUCTO'}>{'Ducto'}</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8} /*hidden={this.state.tankType === false}*/>
                            <Tooltip title={tooltip.codigoAlmacen}>
                                <Form.Item
                                    label="Código de almacén"
                                    validateStatus={wareHouseIdentificatorVS.validateStatus}
                                    help={wareHouseIdentificatorVS.errorMsg}
                                >
                                    {getFieldDecorator(`warehouseKey`, {
                                        rules: [{
                                            required: this.state.tankType, message: 'El formato de clave del almacén no es válido.'
                                        }],
                                    })(
                                        <Input className="common-input" disabled />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row gutter={24} hidden={this.state.tankType === false}>
                        <Col span={8}>
                            <Tooltip title={tooltip.tanques.tipoAlmacen}>
                                <Form.Item label="Tipo de almacén">
                                    {getFieldDecorator(`tankType`, {
                                        rules: [{
                                            required: this.state.tankType, message: 'Por favor selecciona el tipo de almacén.'
                                        }]
                                    })(
                                        <Select onChange={(value) => this.obtainTankCode(value)} showSearch filterOption={this.filter}>
                                            {optionTankDuctKeys(this.state.tankKey)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.tanques.claveInstalacion}>
                                <Form.Item label="Clave de instalación">
                                    {getFieldDecorator(`instalationKey`, {
                                        initialValue: this.state.installationKey.code,
                                        rules: [{
                                            required: true, message: 'Por favor selecciona la clave de instalación del almacén.'
                                        }]
                                    })(
                                        <Select disabled={true} showSearch filterOption={this.filter}>
                                            <Option key={this.state.installationKey.id} value={this.state.installationKey.code}>{this.state.installationKey.code} - {this.state.installationKey.description}</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.tanques.codigoNumericoAlmacen}>
                                <Form.Item
                                    label="Código de almacén"
                                    validateStatus={wareHouseTankCodeVS.validateStatus}
                                    help={wareHouseTankCodeVS.errorMsg}
                                >
                                    {getFieldDecorator(`wareHouseTankKey`, {
                                        rules: [{
                                            required: this.state.tankType, message: 'Por favor introduce el código del almacén.'
                                        }]
                                    })(
                                        <Input className="common-input" onBlur={this.wareHouseTankCodeChange} length={4} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row gutter={24} hidden={this.state.wellType === false}>
                        <Col span={8}>
                            <Tooltip title={tooltip.pozos.claveRegistro}>
                                <Form.Item
                                    label="Clave de registro del pozo ante la CNH"
                                    validateStatus={wareHouseWellRegKeyVS.validateStatus}
                                    help={wareHouseWellRegKeyVS.errorMsg}>
                                    {getFieldDecorator('wellRegisterKey', {
                                        rules: [{
                                            required: this.state.wellType, message: 'Por favor escribe la clave de registro del pozo ante la CNH'
                                        }]
                                    })(
                                        <Input className="common-input" maxLength={40} onBlur={this.obtainWellRegistryKey} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={16}>
                            <Tooltip title={tooltip.pozos.claveIdentificacion}>
                                <Form.Item
                                    label="Clave de identificación del pozo"
                                    validateStatus={wareHouseWellIdentKeyVS.validateStatus}
                                    help={wareHouseWellIdentKeyVS.errorMsg}>
                                    {getFieldDecorator('wellIdentificationKey', {
                                        rules: [{
                                            required: this.state.wellType, message: 'Por favor introduce la clave de identificación del pozo.'
                                        }]
                                    })(
                                        <Input className="common-input" maxLength={7} onChange={this.wellIdentificationKeyChange} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row gutter={24} hidden={this.state.ductType === false}>
                        <Col span={8}>
                            <Tooltip title={tooltip.ductos.tipoDucto}>
                                <Form.Item label="Tipo de ducto">
                                    {getFieldDecorator('ductType', {
                                        rules: [{
                                            required: this.state.ductType, message: 'Por favor selecciona el tipo de ducto.'
                                        }]
                                    })(
                                        <Select
                                            showSearch filterOption={this.filter}
                                            onChange={(value) => this.obtainDuctCode(value)}
                                        >
                                            {optionTankDuctKeys(this.state.ductKey)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.ductos.codigoDucto}>
                                <Form.Item
                                    label="Código del ducto"
                                    validateStatus={wareHouseDuctCodeVS.validateStatus}
                                    help={wareHouseDuctCodeVS.errorMsg}
                                >
                                    {getFieldDecorator('customerDuctKey', {
                                        rules: [
                                            { required: this.state.ductType, message: 'Por favor introduce el código del ducto.' },
                                        ]
                                    })(
                                        <Input className="common-input" onBlur={this.wareHouseDuctCodeChange} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Tooltip title={tooltip.descripcion}>
                                <Form.Item label="Descripción">
                                    {getFieldDecorator(`description`, {
                                        rules: [{
                                            required: true, message: 'La descripción del almacén no es válida.'
                                        }]
                                    })(
                                        <TextArea autoSize={{ minRows: 1, maxRows: 6 }} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.fechaCalibracion}>
                                <Form.Item label="Vigencia de calibración">
                                    {getFieldDecorator(`calibrationDate`, {
                                        rules: [{
                                            required: true, message: 'Por favor selecciona la vigencia de calibración.'
                                        }]
                                    })(
                                        <DatePicker  lang='es' style={{ width: '100%' }} format={dateFormatList} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>

                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Tooltip title={tooltip.tipoProducto}>
                                <Form.Item label="Tipo de producto">
                                    {getFieldDecorator(`idProduct`, {
                                        rules: [{ required: true, message: 'Por favor selecciona el tipo de producto.' }]
                                    })(
                                        <Select onChange={(index) => this.fetchSubProducts(index)} showSearch filterOption={this.filter}>
                                            {optionProducts(this.state.products)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={16}>
                            <Tooltip title={tooltip.tipoSubProducto}>
                                <Form.Item label="Tipo de subproducto">
                                    {getFieldDecorator(`idSubProduct`, {
                                        rules: [{ required: this.state.subproducts.length !== 0 ? true : false, message: 'Por favor selecciona el tipo de subproducto.' }]
                                    })(
                                        <Select onChange={this.onChangeSubProduct}
                                            showSearch filterOption={this.filter}
                                        >
                                            {optionSubproducts(this.state.subproducts)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={8}>
                            <Tooltip title={tooltip.densidadObservada}>
                                <Form.Item 
                                    label="Densidad observada"
                                    validateStatus={this.state.observedDensityStatus.validateStatus}
                                    help={this.state.observedDensityStatus.errorMsg}
                                >
                                    {getFieldDecorator(`observedDensity`, {
                                        rules: [{
                                            required: true, message: 'Densidad requerida'
                                        }],
                                    })(
                                        <InputNumber min={0} precision={4} style={{ width: '100%' }} className="common-input" />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.presionObservada}>
                                <Form.Item 
                                label="Presión observada"
                                validateStatus={this.state.observedPressureStatus.validateStatus}
                                help={this.state.observedPressureStatus.errorMsg}
                                >
                                    {getFieldDecorator(`observedPressure`, {
                                        rules: [{
                                            required: true, message: 'Presión requerida'
                                        }],
                                    })(
                                        <InputNumber min={0} precision={2} style={{ width: '100%' }} className="common-input" />
                                    )}

                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.temperaturaObservada}>
                                <Form.Item 
                                label="Temperatura observada"
                                validateStatus={this.state.observedTemperatureStatus.validateStatus}
                                help={this.state.observedTemperatureStatus.errorMsg}
                                >
                                    {getFieldDecorator(`observedTemperature`, {
                                        rules: [{
                                            required: true, message: 'Temperatura requerida'
                                        }],
                                    })(
                                        <InputNumber precision={1} style={{ width: '100%' }} className="common-input" />
                                    )}

                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>

                        <Row gutter={24}>
                            <Col span={8}>
                                <Tooltip title={tooltip.densidadACondifcionesBase}>
                                    <Form.Item label="Densidad a condiciones base (kg/L a 20 °C y 1 atm)">
                                        {getFieldDecorator(`baseDensity`, {
                                            rules: [{
                                                required: true, message: 'Densidad a condiciones base requerida'
                                            }],
                                        })(
                                            <InputNumber placeholder={`Inserta un valor entre ${subProduct.minBaseDensity} y ${subProduct.maxBaseDensity}`} min={subProduct.minBaseDensity} max={subProduct.maxBaseDensity} style={{ width: '100%' }} className="common-input" />
                                        )}
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                        </Row>

                    <Button className="customized-primary" type="primary" htmlType="submit" disabled={wareHouseCodeExist}>Agregar almacén</Button>
                    <Button className="customized-default" style={{ marginLeft: 16 }} onClick={onCancelClick} hidden={this.props.hideCancelButton}>Cancelar</Button>
                </Form>
                <Modal
                    open={visibleModal}
                    title="Atención"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button className="back-button" key="back" onClick={this.handleCancel}>Revisar información</Button>,
                        <Button className="submit-button" key="submit" loading={loading} onClick={this.handleOk}>Agregar almacén</Button>
                    ]}
                >
                    <p>Una vez que presione "Agregar almacén" no se podrá editar </p>
                </Modal>
            </div>
        );
    }
}

export default Form.create({ name: 'wareHouseAggregation' })(WareHouseAggregation);
