import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConfigApplicationAggregation } from '../configurationApplication/components';
import {
    getPagePermissions,
    FIRST_CONFIG_APPLICATION
} from '../../security/permissions';

import './styles.css';

class FirstConfigApplication extends Component {
    render() {
        return (
            <div>
                <ConfigApplicationAggregation isCompanyRegistered={false}/>
            </div>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(FIRST_CONFIG_APPLICATION, permissions)
});
export default connect(mapStateToProps)(FirstConfigApplication);