import { Button, Col, Modal, Row, Table, Tag, Tooltip, Switch, DatePicker } from "antd";
import Search from "antd/lib/input/Search";
import moment from "moment";
import React from "react";

class PreliminarPG extends React.Component {
    constructor(props) {
        super(props);
        const value = props.value || {};
        
        this.state = {
            value,
            isAdvancedMode:false,
            selectedRowsArray: [],
            searchByDate: false
        };
    }

    render(){
        return (
        <Modal
            width="80%"
            icon= "snippets"
            title= "Vinculación de PG Preliminar con Recepciones"
            maskClosable= {true}
            open={this.props.visible}
            onCancel={()=> {this.props.closeModal();} }
            footer={
                <div>
                     <div style={{display: 'flex',justifyContent: 'right'}}>
                            <Button className="back-button" onClick={() => { this.props.closeModal()}}>
                                Cancelar
                            </Button>
                            <Button  
                                className="submit-button" 
                                style={ {width:'auto'}} 
                                onClick={() => { this.props.assignPreliminarPg(this.state.selectedRowsArray[0]) }}
                                disabled={this.state.selectedRowsArray.length===0}
                                >
                                Vincular PG Preliminar
                            </Button>
                     </div>
                </div>
            }
        >
            <div>
                <Row gutter={24}>
                    <Col span={24}>
                        <label>Selecciona un PG Preliminar de la lista a continuación para vincularlo con la recepción elegida.</label>
                        <Tag color='lime' hidden={this.props.preliminarPgList.length !== 1}> Existe <strong>{this.props.preliminarPgList.length}</strong> PG Preliminar seleccionable</Tag>
                        <Tag color='lime'  hidden={this.props.preliminarPgList.length === 1}> Existen <strong>{this.props.preliminarPgList.length}</strong> PGs Preliminares seleccionables</Tag>
                    </Col>
                    
                    <Col span={18}>
                        <Tooltip title="Puedes buscar por Número de Papeleta, Número de PG, Destino o Fecha de Embarque 'DD-MM-AAAA'">
                            <div hidden={this.state.searchByDate}>
                                <Search
                                    hidden={this.state.searchByDate}
                                    placeholder="Puedes buscar por Número de Papeleta, Número de PG o Destino"
                                    onChange={(e)=> {
                                        this.props.onSearchPreliminarPG(e);
                                        this.setState({selectedRowsArray: []});
                                    }}
                                />
                            </div>
                            <div hidden={!this.state.searchByDate}>
                                <DatePicker
                                    style={{ width: '100%' }} 
                                    allowClear={false}
                                    disabled={!this.state.searchByDate} 
                                    format="DD-MM-YYYY"
                                    onChange={(selectedDate, dateString)=>{
                                        let value = {
                                            target: { value: dateString }
                                        };
                                        this.props.onSearchPreliminarPG(value);
                                        this.setState({selectedRowsArray: []});
                                    }}
                                    placeholder="Puedes buscar por Fecha de Embarque 'DD-MM-AAAA'"
                                
                                />
                            </div>
                            
                        </Tooltip>
                    </Col>
                    <Col span={6}>
                        <Switch
                            checkedChildren="Buscar por Fecha de Embarque"
                            unCheckedChildren="Buscar por Fecha de Embarque"
                            onChange={(value)=>{this.setState({searchByDate: value}); }}
                            />
                    </Col>
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'Número de PG:',
                                    dataIndex: 'pgNumber',
                                    render: text => <Tag color='magenta'><strong>Número de PG:</strong>{text}</Tag>,
                                },
                                {
                                    title: 'Número de Papeleta:',
                                    dataIndex: 'remissionName',
                                    render: text => <Tag color='cyan'><strong>Número de Papeleta:</strong>{text}</Tag>,
                                },
                                {
                                    title: 'Destino:',
                                    dataIndex: 'destination',
                                    render: text => <Tag color='purple'><strong>Destino:</strong>{text}</Tag>,
                                },
                                {
                                    title: 'Fecha de embarque:',
                                    dataIndex: 'boardingDate',
                                    render: date => <Tag color='magenta'><strong>Fecha de embarque:</strong>{moment(date).format('DD-MM-YYYY')}</Tag>,
                                },
                            ]} 
                            dataSource={this.props.preliminarPgList}
                            rowKey='uuid'
                            pagination={false}
                            rowSelection={{
                                onChange: (selectedRowKeys, selectedRows) => {
                                    this.setState({selectedRowsArray: selectedRowKeys});
                                },
                                type:'radio',
                                selectedRowKeys: this.state.selectedRowsArray,
                            }}
                            />
                        
                    </Col>
                </Row>
            </div>
        </Modal>)
    }
}
export default PreliminarPG;;