/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-15 11:59:31
 * @Last Modified by: Alejandro Leonor
 * @Last Modified time: 2018-04-17 16:28:30
 */

// DEFINE COMMON REDUCER
const defaultState = {};

export default (state = defaultState, { type, payload }) => { // eslint-disable-line
    switch (type) {
        default:
            return state;
    }
};
