/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 11:18:38
 * @Last Modified by: Alejandro Leonor
 * @Last Modified time: 2018-03-16 11:01:43
 */
const CONTAINER_KEY = 'GENERAL_DATA';

export const FETCH_DATA = `${CONTAINER_KEY}_FETCH_DATA`;
export const FETCH_DATA_FULLFILLED = `${CONTAINER_KEY}_FETCH_DATA_FULLFILLED`;

