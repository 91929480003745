import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';

import { Table, Switch, message, Modal, Tooltip } from 'antd';
import WareHouseShowInfo from './wareHouseShowInfo';
import EditableTable from './editableCell';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import {
    getPagePermissions,
    DISPENSARY_PERMISSION
} from '../../../security/permissions';
import gas_pump from './../../../assets/images/mini_gas_pump.svg';
const { confirm } = Modal;
class WareHouses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleDispensaryEditModal: false,
            visibleHoseEditModal: false,
            visibleDispensaryDeleteModal: false,
            visibleHoseDeleteModal: false,
            visibleHoseAddModal: false,
            visibleHoseUnlinkModal: false,
            visibleActiveContainerModal: false,
            wareHouse: props.wareHouse,
            containers: props.wareHouse.containers,
            loading: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            wareHouse: nextProps.wareHouse
        });
    }

    expandedRowRender = () => <EditableTable wareHouse={this.props.wareHouse} containers={this.props.wareHouse.containers} wareHouseStatus={this.props.wareHouse.status} onDeleteGauges={() => this.props.onUpdateContainers()} />

    data = [{
        warehouseKey: this.props.wareHouse.warehouseKey,
        description: this.props.wareHouse.description,
        idWareHouse: this.props.wareHouse.idWareHouse,
    }];

    columns = (pagePermissions) => {
        return [
            {
                title: 'Código de almacén',
                dataIndex: 'warehouseKey',
                key: 'warehouseKey',
                render: (text) => (
                    <span>
                        <Tooltip title="Clave Auditable del Almacén">
                            <label className="dispensary-id">Clave de almacén: {text}</label>
                        </Tooltip>
                    </span>
                )
            },
            {
                title: 'Descripción',
                dataIndex: 'description',
                key: 'description',
                render: (text, record) => (
                    <span>
                        <Tooltip title="Descripción del almacén">
                            <label className="dispensary-id" >{record.description}</label>
                        </Tooltip>
                    </span>
                )
            },
            {
                title: 'Información',
                dataIndex: 'idWareHouse',
                key: 'idWareHouse',
                render: (text) => (
                    <WareHouseShowInfo wareHouseId={text} onEditWareHouses={() => this.props.onUpdateWareHouses()} />
                )
            },
            pagePermissions.edit ? {
                title: 'Acción',
                key: 'action',
                align: 'right',
                render: () => (
                    <Tooltip title="Almacén Activo">
                        <Switch checked={this.state.wareHouse.status} onChange={this.showInactiveConfirm} />
                    </Tooltip>

                )
            } : {}
        ];
    }

    handleWarehouseStatus = (checked) => {
        dispensaryServices.put(`warehouse/${this.state.wareHouse.idWareHouse}/${checked}`).then(response => {
            const jsonResponse = JSON.parse(JSON.stringify(response));
            if (jsonResponse.hasOwnProperty('response')) {
                message.error(jsonResponse.response.data);
            }
            this.props.onUpdateWareHouses();
        });
    };
    showInactiveConfirm = (checked) => {
        confirm({
            title: `¿Está seguro que desea ${!checked ? 'desactivar' : 'activar'} el almacén ${this.state.wareHouse.warehouseKey}?`,
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            centered: true,
            onOk: () => new Promise((resolve) => {
                setTimeout(() => resolve(this.handleWarehouseStatus(checked)), 2000);
            }).catch(() => console.log('error')),
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    render() {
        const { pagePermissions } = this.props;
        return (
            <div>
                <Table
                    rowKey={record => record.idWareHouse}
                    className="dispensary-table"
                    columns={this.columns(pagePermissions)}
                    expandIcon={() => <img src={gas_pump} alt="Almacen" />}
                    expandedRowRender={this.expandedRowRender}
                    defaultExpandAllRows
                    dataSource={this.data}
                    showHeader={false}
                    size="small"
                    pagination={false}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISPENSARY_PERMISSION, permissions)
});

export default connect(mapStateToProps)(Form.create()(WareHouses));
