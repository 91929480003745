import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Row, Col, Spin } from 'antd';
import { WareHouseAggregation } from './components';

import WareHouses from './components/wareHouses';


import gas_pump from './../../assets/images/gas_pump.svg';

import {
    files as fileServices
} from '../../services';
import {
    getPagePermissions,
    WAREHOUSE_PERMISSIONS
} from '../../security/permissions';

import './styles.css';
import { getSelectedDistributionPoint } from '../../common/components/widgets/DistributionPoint/distributionPointStorage';

class Warehouses extends Component {
    _isMount = false;
    constructor() {
        super();
        this.state = {
            mainView: true,
            company: false,
            addWareHousesForm: false,
            areThereWareHouses: false,
            visible: false,
            wareHouseSaved: [],
            loadingWareHouse: false,
            ParamsWareHousesMoodal: false,
            visibleModal: true,
            distributionPointId: getSelectedDistributionPoint(),
        };
    }

    componentDidMount() {
        this._isMount = true;
        this.fetchWareHouses();
        window.addEventListener("distributionPointChange",(e) => {
            if(this._isMount){
                this.setState({ distributionPointId: getSelectedDistributionPoint()}, ()=>{
                    this.fetchWareHouses();
                });
            }
        });
    }
    componentWillUnmount() {
        this._isMount = false;
    }

    fetchWareHouses = () => {
        this.setState({ loadingWareHouse: true });
        fileServices.get(`distributionPoints/${this.state.distributionPointId}/warehouses`)
            .then(response => {
                if (response.length > 0) {
                    this.setState({
                        areThereWareHouses: true,
                        wareHouseSaved: response,
                        loadingWareHouse: false
                    });
                } else {
                    this.setState({
                        areThereWareHouses: false,
                        loadingWareHouse: false
                    });
                }
            });
    };

    showaddWareHousesForm = () => {
        this.setState({
            mainView: false,
            addWareHousesForm: true
        });
    }

    handleAddWareHouseClick = () => {
        this.setState({
            mainView: true,
            addWareHousesForm: false,
            wareHouseSaved: [],
        });
        this.fetchWareHouses();
    }
    
    handleCancelClick = (form) => {
        this.setState({
            mainView: true,
            [form]: false
        });
    }

    handleUpdateWareHouses = () => {
        this.setState({ wareHouseSaved: [] })
        this.fetchWareHouses();
    }

    render() {
        const { pagePermissions } = this.props;
        const cardItems = this.state.wareHouseSaved.map((value, index) => (
            <Col key={value.warehouseKey} span={24} style={{ marginBottom: '24px' }}>
                <WareHouses wareHouse={value} onUpdateWareHouses={() => this.handleUpdateWareHouses()} />
            </Col>
        ));
        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loadingWareHouse}>
                <div>
                    {this.state.mainView &&
                        <div>
                            {this.state.areThereWareHouses ? (
                                <div>
                                    <div style={{ marginBottom: '24px' }}>
                                        <Row gutter={12}>
                                            <Col span={6}>
                                                {pagePermissions.add && <Button className="customized-primary" type="primary" icon={<PlusOutlined />} onClick={this.showaddWareHousesForm}>Agregar almacén</Button>}
                                            </Col>
                                        </Row>

                                    </div>
                                    <Row gutter={24}>
                                        {cardItems}
                                    </Row>
                                </div>
                            ) : (
                                <div className="center-div">
                                    <img src={gas_pump} alt="Dispensario" />
                                    <div style={{ margin: '8px' }}>
                                        <label>Sin almacenes agregados</label>
                                    </div>
                                    {pagePermissions.add && <Button className="customized-primary" type="primary" icon={<PlusOutlined />} onClick={this.showaddWareHousesForm}>Agregar almacén</Button>}
                                </div>
                            )}
                        </div>
                    }
                    {this.state.addWareHousesForm && <WareHouseAggregation onAddWareHouseClick={() => this.handleAddWareHouseClick()} onCancelClick={() => this.handleCancelClick('addWareHousesForm')} />}
                </div>
            </Spin>
        );

    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(WAREHOUSE_PERMISSIONS, permissions)
});

export default connect(mapStateToProps)(Warehouses);
