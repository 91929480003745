import { isEmpty } from 'lodash';

export const serializeParams = (params) => {
    const reduce = (a, k) => {
        a.push(`${k}=${encodeURIComponent(params[k].toString())}`);
        return a;
    };
    const queryString = isEmpty(params) ? ''
        : `?${Object.keys(params).reduce(reduce, []).join('&')}`;
    return queryString;
};
