const SUBPRODUCTS = [
    { code: 51, minBaseDensity: 0.325022, maxBaseDensity: 0.688039 } //GAS LP.
]

/**
 * Esta función es usada para obtener información extra de los subproductos que puede ser usada para validaciones en formularios.
 * Dicha información no es actualmente almacenada en el backend (talvez en un futuro sea necesario incluirla ahí y utilizarla en la aplicación web con el fin de mantener una sola fuente de información y proteger el backend de información inválida.)
 * @param {*} subProduct 
 * @returns la información del subproducto, en caso de que el subproducto no se encuentre se utilizan valores default.
 */
export const getSubProductInfo = (subProduct) => {
    const defaultSubProduct = { minBaseDensity: 0, maxBaseDensity: 1 }
    if (!subProduct) {
        return defaultSubProduct;
    }
    const validatableSubproduct = SUBPRODUCTS.find(spc => spc.code === subProduct.idSubproduct);
    return Object.assign({}, defaultSubProduct, validatableSubproduct);
}