export const BACKUP_PERMISSION = 'backup';
export const DASHBOARD_PERMISSION = 'dashboard';
export const DISPENSARY_PERMISSION = 'dispensary';
export const EVENTS_PERMISSION = 'events';
export const EVM_PERMISSION = 'evm';
export const INVENTORY_CONTROL_PERMISSION = 'inventoryControl';
export const PRINTER_PERMISSION = 'printer';
export const REGISTER_PERMISSION = 'register';
export const SALE_RESUME_PERMISSION = 'saleResume';
export const SALES_PERMISSION = 'sales';
export const USERS_PERMISSION = 'users';
export const PERMISSIONS = 'permissions';
export const EVM_DETAILS = 'evmDetails';
export const DICTUM = 'dictum';
export const INITIAL_CONTAINER = 'initialContainer';
export const OPERATION_CERTIFICATE = 'operationCertificate';
export const EXPORT_REPORT_PERMISSION = 'exportReport';
export const CONFIG_APPLICATION = 'configApplicationPermissions';
export const FIRST_CONFIG_APPLICATION = 'firstConfigApplicationPermissions';
export const PERMISSION_NUMBER_PERMISSION = 'permisionNumber'
export const CORRUPTED_DATA_VISUALIZER_PERMISSION = 'cdvPermissions';
export const INITIAL_WAREHOUSE_PERMISSIONS = 'initialWareHousePermissions';
export const WAREHOUSE_PERMISSIONS = 'wareHousePermissions';
export const DISTRIBUTION_POINT_PERMISSIONS = 'distributionPointPermissions';

export const getPagePermissions = (pageKey, permissions) => {
    const defaults = { add: false, read: false, edit: false, delete: false };
    return permissions[pageKey] || defaults;
};
