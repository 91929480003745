import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Button, Spin, Progress } from 'antd';
import { ToFirstConfigApplication } from '../../security';
import { register as registerServices } from '../../services';
import { DistributionPointCreatorForm } from '../distributionPoints/components/DistributionPointCreatorForm'

import './styles.css';

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingFormRegistry: false,
            isConfigApplicationRegistered: false,
            isfirstCompanyRegisted: true,
        };
    }

    componentDidMount() {
        this.fetchConfigApplication();
        this.fetch();
    }

    fetch = () => {
        this.setState({ loadingFormRegistry: true });
        registerServices.get('configApplication/getConfig')
            .then(response => {
                console.log('GET', response);
                if (response.authenticationTokenExpirationConfig !== "") {
                    this.setState({ isConfigApplicationRegistered: true});
                }

                this.setState({ loadingFormRegistry: false });
            });
    };

    fetchConfigApplication = () => {
        this.setState({ loadingFormRegistry: true });
        registerServices.get("company").then((response) => {
            if (response.length === 0) {
                this.setState({ isfirstCompanyRegisted: false});
            } else{
                this.setState({ isfirstCompanyRegisted: true});
            }
        }).finally(()=>{
            this.setState({ loadingFormRegistry: false });
        });
	};

    render() {
        const {  loading } = this.state;

        return (
            <div>
                {!this.state.isConfigApplicationRegistered ? (
                    <div className="center-div">
                        <Progress type="circle" percent={70} status="exception" />
                        <div style={{ margin: '8px' }}>
                            <span>Por favor registra los datos de configuración antes de añadir la información de la empresa.</span>
                        </div>
                        <Button className="back-button" key="back" onClick={() => ToFirstConfigApplication()}>Ir a Configuraciones</Button>
                    </div>
                ) : (
                    <div>
                        <Spin size="large" tip="Cargando..." spinning={this.state.loadingFormRegistry}>
                            <DistributionPointCreatorForm
                                loading={loading}
                                isConfigApplicationRegistered={this.state.isConfigApplicationRegistered}
                                allowEdit={!this.state.isfirstCompanyRegisted}
                            />
                        </Spin>
                    </div>
                )}
            </div>
        );
    }
}

export default Form.create({ name: 'register' })(Register);
