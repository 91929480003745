import React from 'react';
import { Form } from '@ant-design/compatible';

import { Modal, Input, Button } from 'antd';

const { TextArea } = Input;

const CauseRegisterForm = Form.create({ name: 'causeRegisterForm_into_modal' })(

    class extends React.Component {
        render() {
            const { visible, onCancel, onRegister, form, inventoryControl } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Modal
                    open={visible}
                    title="Información a capturar sobre la inconsistencia"
                    onCancel={onCancel}
                    onOk={onRegister}
                    footer={[
                        <Button className="back-button" key="back" onClick={onCancel}>Cancelar</Button>,
                        <Button className="submit-button" key="submit" onClick={onRegister}>Guardar</Button>
                    ]}
                >
                    <p>Llena los dos campos a continuación</p>
                    <p>* Esta información permanecerá editable durante el día en curso</p>
                    <Form layout='vertical' >
                        <Form.Item style={{ display: 'none' }} label="UUID del control de existencias">
                            {getFieldDecorator('requestInventoryControlUUID', {
                                initialValue: inventoryControl.inventoryControlDetailsUUID,
                                rules: [{ required: true, message: 'Por favor introduce el UUID del control de existencias' }]
                            })(
                                <Input disabled />
                            )}
                        </Form.Item>
                        <Form.Item label="Posible causa">
                            {getFieldDecorator('requestInventoryControlDifferenceReason', {
                                initialValue: inventoryControl.inventoryControlDetailsVolumeDifferenceReason,
                                rules: [{ required: true, message: 'Por favor introduce la posible causa.' }]
                            })(
                                <TextArea
                                    rows={4}
                                />
                            )}
                        </Form.Item>
                        <Form.Item label="Acciones que se tomarán para su corrección y seguimiento">
                            {getFieldDecorator('requestInventoryControlDifferenceSolution', {
                                initialValue: inventoryControl.inventoryControlDetailsVolumeDifferenceSolution,
                                rules: [{ required: true, message: 'Por favor introduce las acciones a realizar.' }]
                            })(
                                <TextArea
                                    rows={4}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);

export default CauseRegisterForm;
