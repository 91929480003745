import React, { Component } from 'react';
import { SnippetsOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import {
    Row,
    Col,
    Input,
    Button,
    message,
    DatePicker,
    Modal,
    InputNumber,
    Divider,
    Tooltip,
    Spin,
} from 'antd';
import { Link, Redirect } from 'react-router-dom';
import {
    register as registerServices
} from '../../../services';
import dayjs from "dayjs";
import request from '../../../services/request';
import axios from 'axios';
import { serializeParams } from '../../../common/helpers';
import PreliminarPG from './preliminarPG';
import tooltip from './tooltip.json';

const success = () => {
    message.success('PG registrado exitosamente');
};

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}


class PGRegistration extends Component {
    state = {
        loading: false,
        visible: false,
        redirectToRecepcion: false,
        data: {},
        loadingForm: false,
        isPreliminarPGAssignable: (this.props.match.params.id==='0'),
        preliminarPgList: [],
        cancelTokenP: {},
        showPGPreliminar: false,
    };

    componentDidMount() {
        if (this.props.match.params.id !== '0') {
            this.fetch();
        }
        this.getAvailablePreliminarPgs();
    }

    fetch = (params = {}) => {
        registerServices.get(`pgs/${this.props.match.params.id}`)
            .then(response => {
                this.setState({ data: response, });
            });
    };

    setRedirect = () => {
        this.setState({
            redirectToRecepcion: true
        });
    }

    renderRedirect = () => {
        if (this.state.redirectToRecepcion) {
            return <Redirect to="/receptions" />;
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }

    handleCancel = () => {
        this.setState({ visible: false });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.showModal();
            }
        });
    }

    handleOk = () => {
        this.setState({ loading: true });
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const boardingDateValues = values.boardingDate;
                const arrivalDateValues = values.arrivalDate;
                const downloadDateValues = values.downloadDate;

                const filters = {
                    inputRegisterId: values.inputRegisterId,
                    remissionName: values.remissionName,
                    origin: values.origin,
                    destination: values.destination,
                    boardingDate: boardingDateValues.format('DD/MM/YYYY'),
                    arrivalDate: arrivalDateValues.format('DD/MM/YYYY'),
                    downloadDate: downloadDateValues.format('DD/MM/YYYY'),
                    invoiceKgs: values.invoiceKgs,
                    capturedLts: values.capturedLts,
                    arrivalPercentage: values.arrivalPercentage,
                    pressure: values.pressure,
                    temperature: values.temperature,
                    density: values.density,
                    finalPressure: values.finalPressure,
                    grossArrivalWeight: values.grossArrivalWeight,
                    taraWeight: values.taraWeight,
                    netWeight: values.netWeight,
                    weight: values.weight,
                    pgNumber: values.pgNumber,
                    maxPgCapacity: values.maxPgCapacity,
                };
                this.fetchRegisters(filters);
            }
        });
    }

    handleEdit = () => {
        this.setState({ loading: true });
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const filters = {
                    id: this.props.match.params.id,
                    remissionName: values.remissionName,
                    origin: values.origin,
                    destination: values.destination,
                    boardingDate: values.boardingDate.format('DD/MM/YYYY'),
                    arrivalDate: values.arrivalDate.format('DD/MM/YYYY'),
                    downloadDate: values.downloadDate.format('DD/MM/YYYY'),
                    invoiceKgs: values.invoiceKgs,
                    capturedLts: values.capturedLts,
                    arrivalPercentage: values.arrivalPercentage,
                    pressure: values.pressure,
                    temperature: values.temperature,
                    density: values.density,
                    finalPressure: values.finalPressure,
                    grossArrivalWeight: values.grossArrivalWeight,
                    taraWeight: values.taraWeight,
                    netWeight: values.netWeight,
                    weight: values.weight,
                    pgNumber: values.pgNumber,
                    maxPgCapacity: values.maxPgCapacity,
                };
                this.editRegisters(filters);
            }
        });
    }

    fetchRegisters = (values = {}) => {
        registerServices.post('pgs/', values)
            .then(response => {
                setTimeout(() => {
                    this.setState({ loading: false, visible: false });
                    this.setRedirect();
                    success();
                }, 3000);
            });
    }

    editRegisters = (values = {}) => {
        registerServices.put('pgs/', values)
            .then(response => {
                setTimeout(() => {
                    this.setState({ loading: false, visible: false });
                    this.setRedirect();
                }, 3000);
            });
    }

    validateGrossWeightValue = () => (rule, value, callback) => {
        const { form } = this.props;
        const taraWeight = form.getFieldValue(`taraWeight`) !== undefined ? form.getFieldValue(`taraWeight`) : 0;
        if (value < taraWeight) {
            callback('El peso bruto no puede ser menor al peso tara');
        } else {
            callback();
        }
    }

    validateTaraWeightValue = () => (rule, value, callback) => {
        const { form } = this.props;
        const grossArrivalWeight = form.getFieldValue(`grossArrivalWeight`) !== undefined ? form.getFieldValue(`grossArrivalWeight`) : 0;
        if (value > grossArrivalWeight) {
            callback('El peso tara no puede ser mayor al peso bruto');
        } else {
            callback();
        }
    }

    calculateSum = () => {
        const { form } = this.props;
        const num1 = form.getFieldValue(`grossArrivalWeight`) !== null ? parseFloat(form.getFieldValue('grossArrivalWeight'), 10) : 0;
        const num2 = form.getFieldValue(`taraWeight`) !== null ? parseFloat(form.getFieldValue('taraWeight'), 10) : 0;
        const sum = num1 - num2;
        form.setFieldsValue({ netWeight: sum });
    }

    getAvailablePreliminarPgs(searchTerm=undefined){
        return new Promise((resolve)=>{
            const CancelToken = axios.CancelToken;
            let params = {};
            if( searchTerm!==undefined && searchTerm.length > 0)
                params.term = searchTerm.trim();
            request()
                .get(`pgs/preliminar/search${serializeParams(params)}`, {cancelToken:new CancelToken( (c)=> {
                    this.setState({cancelTokenP: c})
                })})
                .then((response)=>{
                    let list = response.data;
                    this.setState({preliminarPgList: list}, ()=>{
                        resolve(list);
                    });
                }).catch((error)=>{});
        });
    }

    assignPreliminarPg(preliminarPgId){
        if(preliminarPgId===undefined){
            message.error("Debes elegir un PG Preliminar para ser vinculado");
            return;
        }
            
        this.setState({loadingForm: true, showPGPreliminar:false}, ()=>{
            let preliminarParams = {
                receptionId: this.props.match.params.inputRegister,
                preliminarPgId: preliminarPgId
            };
            registerServices.post('pg/preliminar/assign', preliminarParams).then((response)=>{
                this.setState({ data: response , isPreliminarPGAssignable: false});
                this.props.match.params.id = response.id;
            }).catch((error)=>{
                console.error(error);
            }).finally(()=>{
                this.setState({loadingForm: false});
            });
        });
    }

     onSearchPreliminarPG = (searchTerm) =>{
        searchTerm = searchTerm.target.value;
        if(this.state.cancelTokenP!==undefined){
           this.state.cancelTokenP();
        }
        this.getAvailablePreliminarPgs(searchTerm);
    }

    openSelectorPGPreliminar = () => {
        this.setState({showPGPreliminar: true});
    }

    getDateOrNull = (dateColumn) => {
        if(this.state.data['dateColumn'] === null || this.state.data['dateColumn'] === undefined)
            return undefined;
        return dayjs(this.state.data.boardingDate)
    }

    render() {
        const { getFieldDecorator, getFieldsError } = this.props.form;
        const dateFormatList = 'DD/MM/YYYY';
        const { visible, loading, loadingForm } = this.state;

        return (
            <Spin spinning={loadingForm}>
                <PreliminarPG 
                    closeModal={()=>{this.setState({showPGPreliminar:false})}}
                    assignPreliminarPg={(uuid) => this.assignPreliminarPg(uuid)}
                    onSearchPreliminarPG={this.onSearchPreliminarPG}
                    preliminarPgList={this.state.preliminarPgList} 
                    visible={this.state.showPGPreliminar}/>
                <Form layout='vertical' onSubmit={this.handleSubmit}>
                    {this.renderRedirect()}
                    <div>
                        <div hidden={!this.state.isPreliminarPGAssignable}>
                            <Tooltip title="¿Quieres vincular esta recepción con un PG Preliminar?">
                                <Button 
                                    icon={<SnippetsOutlined />} 
                                    className="customized-primary" 
                                    type="primary"
                                    onClick={()=>{this.openSelectorPGPreliminar()}}
                                >Vincular con PG Preliminar</Button>
                            </Tooltip>
                            <Divider/>
                        </div>
                        <label>Llena los campos a continuación para registrar un nuevo PG.</label>
                        <Form.Item />
                    </div>
                    <Row gutter={24}>
                        <Col span={8} hidden>
                            <Form.Item label="UUID">
                                {getFieldDecorator('inputRegisterId', {
                                    initialValue: this.props.match.params.inputRegister
                                })(
                                    <Input disabled />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.numeroPapeleta}>
                                <Form.Item label="Número de papeleta">
                                    {getFieldDecorator('remissionName', {
                                        initialValue: this.state.data.remissionName === undefined ? '' : this.state.data.remissionName,
                                        rules: [{
                                            required: true, message: 'Por favor introduce el número de remisión.'
                                        }],
                                    })(
                                        <Input disabled={this.state.data.isEditable === false} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.pgNumber}>
                                <Form.Item label="Número de pg">
                                    {getFieldDecorator('pgNumber', {
                                        initialValue: this.state.data.pgNumber === undefined ? '' : this.state.data.pgNumber,
                                        rules: [{
                                            required: true, message: 'Por favor introduce el número de pg.'
                                        }],
                                    })(
                                        <Input disabled={this.state.data.isEditable === false} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.origen}>
                                <Form.Item label="Origen">
                                    {getFieldDecorator('origin', {
                                        initialValue: this.state.data.origin === undefined ? '' : this.state.data.origin,
                                        rules: [{
                                            required: true, message: 'Por favor introduce el lugar de origen.'
                                        }],
                                    })(
                                        <Input disabled={this.state.data.isEditable === false} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.destino}>
                                <Form.Item label="Destino">
                                    {getFieldDecorator('destination', {
                                        initialValue: this.state.data.destination === undefined ? '' : this.state.data.destination,
                                        rules: [{
                                            required: true, message: 'Por favor introduce el lugar de destino.'
                                        }],
                                    })(
                                        <Input disabled={this.state.data.isEditable === false} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Tooltip title={tooltip.fechaEmbarque}>
                                <Form.Item label="Fecha de embarque">
                                    {getFieldDecorator('boardingDate', {
                                        initialValue: this.getDateOrNull('boardingDate'),
                                        rules: [{
                                            required: true, message: 'Por favor introduce la fecha de embarque.'
                                        }],
                                    })(
                                        <DatePicker  lang='es' style={{ width: '100%' }} format={dateFormatList} disabled={this.state.data.isEditable === false} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.fechaLlegada}>
                                <Form.Item label="Fecha de llegada">
                                    {getFieldDecorator('arrivalDate', {
                                        initialValue: this.getDateOrNull('arrivalDate'),
                                        rules: [{
                                            required: true, message: 'Por favor introduce la fecha de llegada.'
                                        }],
                                    })(
                                        <DatePicker  lang='es' style={{ width: '100%' }} format={dateFormatList} disabled={this.state.data.isEditable === false} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.fechaDescarga}>
                                <Form.Item label="Fecha de descarga">
                                    {getFieldDecorator('downloadDate', {
                                        initialValue: this.getDateOrNull('downloadDate'),
                                        rules: [{
                                            required: true, message: 'Por favor introduce la fecha de descarga.'
                                        }],
                                    })(
                                        <DatePicker  lang='es' style={{ width: '100%' }} format={dateFormatList} disabled={this.state.data.isEditable === false} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Tooltip title={tooltip.kilogramosFactura}>
                                <Form.Item label="Kilogramos en factura (kg)">
                                    {getFieldDecorator('invoiceKgs', {
                                        initialValue: this.state.data.invoiceKgs === undefined ? null : this.state.data.invoiceKgs,
                                        rules: [{
                                            required: true, message: 'Por favor introduce los kilogramos de la factura.'
                                        }],
                                    })(
                                        <InputNumber style={{ width: '100%' }} disabled={this.state.data.isEditable === false} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.litrosDescargados}>
                                <Form.Item label="Litros descargados (L)">
                                    {getFieldDecorator('capturedLts', {
                                        initialValue: this.state.data.capturedLts === undefined ? null : this.state.data.capturedLts,
                                    })(
                                        <InputNumber style={{ width: '100%' }} disabled={this.state.data.isEditable === false} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.temperatura}>
                                <Form.Item label="Temperatura">
                                    {getFieldDecorator('temperature', {
                                        initialValue: this.state.data.temperature === undefined ? null : this.state.data.temperature,
                                    })(
                                        <InputNumber style={{ width: '100%' }} disabled={this.state.data.isEditable === false} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Tooltip title={tooltip.densidad}>
                                <Form.Item label="Densidad (K/L)">
                                        {getFieldDecorator('density', {
                                            initialValue: this.state.data.density === undefined ? null : this.state.data.density,
                                        })(
                                            <InputNumber style={{ width: '100%' }} disabled={this.state.data.isEditable === false} />
                                        )}
                                    </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.porcentajeLlegadaPg}>
                                <Form.Item label="Porcentaje de llegada del pg">
                                    {getFieldDecorator('arrivalPercentage', {
                                        initialValue: this.state.data.arrivalDate === undefined ? null : this.state.data.arrivalPercentage,
                                    })(
                                        <InputNumber style={{ width: '100%' }} disabled={this.state.data.isEditable === false} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.capacidadMaximaPg}>
                                <Form.Item label="Capacidad máxima del pg">
                                    {getFieldDecorator('maxPgCapacity', {
                                        initialValue: this.state.data.maxPgCapacity === undefined ? null : this.state.data.maxPgCapacity,
                                    })(
                                        <InputNumber style={{ width: '100%' }} disabled={this.state.data.isEditable === false} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Tooltip title={tooltip.presiónInicial}>
                                <Form.Item label="Presión Inicial (kg/cm^2)">
                                    {getFieldDecorator('pressure', {
                                        initialValue: this.state.data.pressure === undefined ? null : this.state.data.pressure,
                                    })(
                                        <InputNumber style={{ width: '100%' }} disabled={this.state.data.isEditable === false} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.presiónFinal}>
                                <Form.Item label="Presión final  (kg/cm^2)">
                                    {getFieldDecorator('finalPressure', {
                                        initialValue: this.state.data.finalPressure === undefined ? null : this.state.data.finalPressure,
                                    })(
                                        <InputNumber style={{ width: '100%' }} disabled={this.state.data.isEditable === false} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Tooltip title={tooltip.pesoBruto}>
                                <Form.Item label="Peso bruto (kg)">
                                    {getFieldDecorator('grossArrivalWeight', {
                                        initialValue: this.state.data.grossArrivalWeight === undefined ? null : this.state.data.grossArrivalWeight,
                                        rules: [{
                                            validator: this.validateGrossWeightValue()
                                        }]
                                    })(
                                        <InputNumber style={{ width: '100%' }} disabled={this.state.data.isEditable === false} onBlur={this.calculateSum}/>
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.tara}>
                                <Form.Item label="Tara (kg)">
                                    {getFieldDecorator('taraWeight', {
                                        initialValue: this.state.data.taraWeight === undefined ? null : this.state.data.taraWeight,
                                        rules: [{
                                            validator: this.validateTaraWeightValue()
                                        }]
                                    })(
                                        <InputNumber style={{ width: '100%' }} disabled={this.state.data.isEditable === false} onBlur={this.calculateSum}/>
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.pesoNeto}>
                                <Form.Item label="Peso neto  (kg)">
                                    {getFieldDecorator('netWeight', {
                                        initialValue: this.state.data.netWeight === undefined ? null : this.state.data.netWeight,
                                    })(
                                        <InputNumber style={{ width: '100%' }} disabled/>
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item>
                                {this.state.data.remissionName === undefined ?
                                    <Button hidden={this.state.data.isEditable === false} className="customized-primary" type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>Agregar Papeleta PG</Button> :
                                    <Button hidden={this.state.data.isEditable === false} className="customized-primary" type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>Editar Papeleta PG</Button>
                                }

                                <Link to="/receptions">
                                    <Button hidden={this.state.data.isEditable === undefined ? true : this.state.data.isEditable} className="customized-primary" type="primary" htmlType="submit">Regresar</Button>
                                </Link>

                                <Link to="/receptions">
                                    <Button hidden={this.state.data.isEditable === false} className="customized-default" style={{ marginLeft: 16 }}>Cancelar</Button>
                                </Link>

                                <Modal
                                    open={visible}
                                    title="Atención"
                                    onCancel={this.handleCancel}
                                    footer={[
                                        <Button className="back-button" key="back" onClick={this.handleCancel}>Revisar información</Button>,
                                        this.state.data.remissionName === undefined ?
                                            <Button className="submit-button" key="submit" loading={loading} onClick={this.handleOk}>Confirmar registro</Button> :
                                            <Button className="submit-button" key="submit" loading={loading} onClick={this.handleEdit}>Editar registro</Button>
                                    ]}
                                >
                                    <p>Por favor asegúrese de que la información en el formulario sea la correcta.</p>
                                </Modal>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        );
    }
}

export default Form.create({ name: 'pgRegistration' })(PGRegistration);
