import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';

import { Row, Col, Table, message, Modal, Button, InputNumber, Tooltip, Switch } from 'antd';
import ContainerShowInfo from './containerShowInfo';
import GaugeAggregation from './gaugeAggregation';
import EditableTable from './editableCell';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import {
    getPagePermissions,
    DISPENSARY_PERMISSION
} from '../../../security/permissions';
import gas_pump from './../../../assets/images/mini_gas_pump.svg';
import { getIsCloudModeActive } from '../../../security';
import ContainerInactiveMessage from './ContainerInactiveMessage';
const { confirm } = Modal;
class ContainerGauges extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleDispensaryEditModal: false,
            visibleHoseEditModal: false,
            visibleDispensaryDeleteModal: false,
            visibleHoseDeleteModal: false,
            visibleHoseAddModal: false,
            visibleHoseUnlinkModal: false,
            visibleActiveContainerModal: false,
            container: props.container,
            loading: false,
            gauges: props.container.gauges,
            isCloudMode: getIsCloudModeActive(),
            includeInPreviousInventories: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            container: nextProps.container,
            gauges: nextProps.container.gauges
        });
    }

    showDispensaryEditModal = () => {
        const form = this.dispensaryEditFormRef.props.form;

        this.setState({ visibleDispensaryEditModal: true });
        form.resetFields();
    }

    showHoseEditModal = () => {
        const form = this.hoseEditFormRef.props.form;

        this.setState({ visibleHoseEditModal: true });
        form.resetFields();
    }

    showDispensaryDeleteModal = () => {
        this.setState({ visibleDispensaryDeleteModal: true });
    }

    showHoseDeleteModal = () => {
        this.setState({ visibleHoseDeleteModal: true });
    }

    showHoseAddModal = () => {
        this.setState({ visibleHoseAddModal: true });
    }

    showHoseUnlinkModal = () => {
        this.setState({ visibleHoseUnlinkModal: true });
    }

    updateStatusGauges = () => {
        this.props.onUpdateContainers();
    };

    onGaugeChange = () => {
        this.props.onGaugeChange();
    }

    expandedRowRender = () => <EditableTable onGaugeChange={this.onGaugeChange} container={this.state.container} gauges={this.state.gauges} containerStatus={this.state.container.containerStatus} onSomeGaugeStatusChange={this.updateStatusGauges} onDeleteGauges={() => this.props.onUpdateContainers()} />

    data = [{
        key: this.props.container.containerKey,
        containerCode: this.props.container.containerKey,
        capacity: Intl.NumberFormat('en', { notation: 'standard' }).format(this.props.container.containerTotalCapacity),
        measurementUnit: (this.props.container.product.measurementUnit || {}).measurementDescription,
        idContainer: this.props.container.idContainer,
        warehouseKey: this.props.container.warehouseKey
    }];

    columns = (pagePermissions) => {
        return [
            {
                title: 'Código de contenedor',
                dataIndex: 'containerCode',
                key: 'containerCode',
                render: (text) => (
                    <span>
                        <Tooltip title="Código Identificador del contenedor">
                            <label>{text}</label>
                        </Tooltip>
                    </span>
                )
            },
            {
                title: 'Clave SAT Almacén',
                dataIndex: 'warehouseKey',
                key: 'warehouseKey',
                render: (text) => (
                    <span>
                        <Tooltip title="Clave SAT del almacén">
                            <label style={{color: 'green', fontWeight: 'bold'}}> {text}</label>
                        </Tooltip>
                    </span>
                )
            },
            {
                title: 'Capacidad',
                dataIndex: 'capacity',
                key: 'capacity',
                render: (text, record) => (
                    this.props.container.containerType === 'TANQUE' ?
                        <span>
                            <Tooltip title="Capacidad del Tanque">
                                <label className="dispensary-id" >{record.capacity} {record.measurementUnit}</label>
                            </Tooltip>
                        </span>
                        :
                        <span></span>
                )
            },
            {
                title: 'Información',
                dataIndex: 'idContainer',
                key: 'idContainer',
                render: (text) => (
                    <ContainerShowInfo containerId={text} onDeleteContainers={() => this.props.onUpdateContainers()} />
                )
            },
            pagePermissions.edit ? {
                title: 'Acción',
                key: 'action',
                align: 'right',
                render: (text, record) => (
                    <Tooltip title={(this.state.container.containerStatus) ? 'Desactivar Contenedor' : 'No disponible'}>
                        <Switch checked={this.state.container.containerStatus} disabled={!this.state.container.containerStatus} onChange={(e) => this.showInactiveConfirm(record.idContainer, e)} />
                    </Tooltip>
                )
            } : {}
        ];
    }

    handleContainerStatus = (containerId, checked) => {
        let params = {
            status: checked,
            includeInventoryControl: this.state.includeInPreviousInventories,
        };
        dispensaryServices.put(`container/${containerId}/changeStatus`, params).then(response => {
            const jsonResponse = JSON.parse(JSON.stringify(response));
            if (jsonResponse.hasOwnProperty('response')) {
                message.error(jsonResponse.response.data);
            }
            this.props.onUpdateContainers();
        });
    };
    changeIncludeInPreviousInventories = (checked) => {
        this.setState({ includeInPreviousInventories: checked });
    }
    showInactiveConfirm = (containerId, checked) => {
        confirm({
            title: `¿Está seguro que desea desactivar el contenedor ${this.state.container.containerKey}?`,
            // content: 'No sera tomado para el control de existencias diario',
            content: <ContainerInactiveMessage onChangeIncludeInPreviousInventories={this.changeIncludeInPreviousInventories} />,
            okText: 'Sí',
            okType: 'danger',
            cancelText: 'No',
            width: 750,
            centered: true,
            onOk: () => new Promise((resolve) => {
                setTimeout(() => resolve(this.handleContainerStatus(containerId, checked)), 2000);
            }).catch(() => console.log('error')),
            onCancel() {},
        });
    }

    handleCancel = () => {
        this.setState({ visibleActiveContainerModal: false });
        this.props.form.resetFields();
    }

    showActiveContainermodal = () => {
        this.setState({ visibleActiveContainerModal: true });
    }

    handleActiveContainer = () => {
        this.setState({ loading: true });
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let params = {
                    idContainer: this.props.container.idContainer,
                    statusContainer: true,
                    inventoryFisic: values.currentVolume
                }
                dispensaryServices.put('container/changeStatus/', params).then(response => {
                    setTimeout(() => {
                        const jsonResponse = JSON.parse(JSON.stringify(response));
                        if (jsonResponse.hasOwnProperty('response')) {
                            message.error(jsonResponse.response.data);
                        }
                        this.props.onUpdateContainers();
                        this.props.form.resetFields();
                        this.setState({ visibleActiveContainerModal: false });
                        this.setState({ loading: false });
                    }, 2000);
                });
            } else {
                this.setState({ loading: false });
            }

        });
    }

    render() {
        const { pagePermissions } = this.props;
        return (
            <div>
                <Table
                    className="dispensary-table"
                    columns={this.columns(pagePermissions)}
                    expandIcon={() => <img src={gas_pump} alt="Contenedor" />}
                    expandedRowRender={this.expandedRowRender}
                    defaultExpandAllRows
                    dataSource={this.data}
                    showHeader={false}
                    size="small"
                    pagination={false}
                    loading={this.state.loading}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISPENSARY_PERMISSION, permissions)
});

export default connect(mapStateToProps)(Form.create()(ContainerGauges));
