import moment from 'moment';
import dayjs from "dayjs";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';

import { Row, Col, Select, DatePicker, Table, Pagination, Divider } from 'antd';
import { ExpandIcon } from './components';
import {
    logbook as logbookServices
} from '../../services';
import {
    getPagePermissions,
    CORRUPTED_DATA_VISUALIZER_PERMISSION,
} from '../../security/permissions';
import JSONPretty from 'react-json-pretty';

const Option = Select.Option;
const { RangePicker } = DatePicker;

const options = [];

const registryTypes = [{
    value: null,
    text: 'Todos'
}, {
    value: '0',
    text: 'Estatus de Dispensario'
}, {
    value: '1',
    text: 'Estatus de Recepciones'
}, {
    value: '2',
    text: 'Estatus de Medidores'
}, {
    value: '3',
    text: 'Ventas'
}, {
    value: '4',
    text: 'Recepciones'
}, {
    value: '5',
    text: 'Eventos de Dispensario'
}, {
    value: '6',
    text: 'Eventos de Recepciones'
}, {
    value: '7',
    text: 'Eventos de Medidores'
}];


const filters = {
    corruptedDataDisplayPage: 0,
    corruptedDataDisplayAmount: 10,
    corruptedDataModuleOrigin: null,
    corruptedDataStartDate: moment('00:00:00', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
    corruptedDataEndDate: moment('23:59:59', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
};

class CorruptedDataVisualizer extends Component {
    _expandedRowKeys = new Set();

    _columns = [{
        title: 'Fecha y hora de evento',
        dataIndex: 'corruptedDataCreationDate',
        render: date => moment(date).format('YYYY/MM/DD HH:mm:ss'),
        width: '12%'
    }, {
        title: 'Datos recibidos',
        dataIndex: 'corruptedData',
        width: '30%',
        render: (text, record) => {
            const { corruptedDataId } = record;
            const expanded = this._expandedRowKeys.has(corruptedDataId);
            const maxCharacters = 150;
            const firstCharacters = text.substring(0, maxCharacters);
            const count = text.length;
            return (
                <span>
                    {firstCharacters}
                    {
                        count > maxCharacters &&
                        <span>
                            ...
                            <ExpandIcon
                                count={count}
                                maxCharacters={maxCharacters}
                                expanded={expanded}
                                onClick={() => this.onExpandIconClick(corruptedDataId)}
                            />
                        </span>
                    }
                </span>
            );
        }
    }, {
        title: 'Motivo del Error',
        dataIndex: 'corruptedDataErrorDescription',
        width: '20%'
    }, {
        title: 'Módulo de origen',
        dataIndex: 'corruptedDataOriginModule',
        width: '10%'
    }];

    onExpand = (expanded, record) => {
        this.toggleExpandByEventFolio(record.corruptedDataId);
    };

    onExpandIconClick = (corruptedDataId) => {
        this.toggleExpandByEventFolio(corruptedDataId);
    };

    toggleExpandByEventFolio = (corruptedDataId) => {
        this._expandedRowKeys.has(corruptedDataId)
            ? this._expandedRowKeys.delete(corruptedDataId)
            : this._expandedRowKeys.add(corruptedDataId);

        this.setState({
            expandedRowKeys: Array.from(this._expandedRowKeys.values())
        });
    };

    constructor() {
        super();
        this.state = {
            visibleManualRegistrationModal: false,
            corruptedDataRegistries: [],
            pagination: {},
            eventTypes: [],
            loading: false,
            currentPage: 1,
            options,
            expandedRowKeys: Array.from(this._expandedRowKeys.values())
        };
    }

    handleRegistryTypeChange = (e) => {
        filters.corruptedDataDisplayPage = 0;
        filters.corruptedDataModuleOrigin = e;
        console.log("MI REGISTRO ES: ", e);
        this.fetchCorruptedDataRegistries(filters);
    }

    handlePeriodChange = (value) => {
        filters.corruptedDataDisplayPage = 0;
        filters.corruptedDataStartDate = value[0].format('YYYY-MM-DD HH:mm:ss');
        filters.corruptedDataEndDate = value[1].format('YYYY-MM-DD HH:mm:ss');
        this.fetchCorruptedDataRegistries(filters);
    }

    fetchCorruptedDataRegistries = (params = {}) => {
        console.log('params:', params);
        this.setState({ loading: true });
        logbookServices.post('corruptedDataStorage/getRegisters', params)
            .then(response => {
                console.log('GETDataCorrupted', response);
                const pagination = { ...this.state.pagination };
                pagination.total = response.totalRecords;
                this.setState({
                    loading: false,
                    corruptedDataRegistries: response.details,
                    pagination,
                    currentPage: params.corruptedDataDisplayPage + 1
                });
            });
    }

    componentDidMount() {
        const initialValues = {
            corruptedDataDisplayPage: 0,
            corruptedDataDisplayAmount: 10,
            corruptedDataModuleOrigin: null,
            corruptedDataStartDate: moment('00:00:00', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            corruptedDataEndDate: moment('23:59:59', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
        };

        this.fetchCorruptedDataRegistries(initialValues);
    }

    onPageChange = (page, pageSize) => {
        filters.corruptedDataDisplayPage = page - 1;
        this.fetchCorruptedDataRegistries(filters);
    }

    onShowSizeChange = (current, pageSize) => {
        filters.corruptedDataDisplayPage = 0;
        filters.corruptedDataDisplayAmount = pageSize;
        this.fetchCorruptedDataRegistries(filters);
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const JSONPrettyMon = require('react-json-pretty/dist/monikai');

        const optionRegistryType = types => types.map(item => (
            <Option key={item.value} value={item.value}>{item.text}</Option>
        ));

        return (
            <div>
                <Form layout='vertical' style={{ background: '#F4FBFF', padding: '6px' }}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Tipo de registro"
                            >
                                {getFieldDecorator('registryType', {
                                    initialValue: null
                                })(
                                    <Select
                                        onChange={this.handleRegistryTypeChange}
                                    >
                                        {optionRegistryType(registryTypes)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Seleccionar periodo"
                            >
                                {getFieldDecorator('period', {
                                    initialValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
                                })(
                                    <RangePicker
                                        lang='es'
                                        allowClear={false}
                                        style={{ width: '100%' }}
                                        format="YYYY/MM/DD HH:mm:ss"
                                        showTime={{ defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')] }}
                                        placeholder={['Desde', 'Hasta']}
                                        ranges={{
                                            Hoy: [dayjs().startOf('day'), dayjs().endOf('day')],
                                            'Esta semana': [dayjs().startOf('week'), dayjs().endOf('week')],
                                            'Este mes': [dayjs().startOf('month'), dayjs().endOf('month')],
                                        }}
                                        onOk={this.handlePeriodChange}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Table
                    columns={this._columns}
                    expandIconAsCell={false}
                    expandIcon={() => null}
                    expandedRowKeys={this.state.expandedRowKeys}
                    onExpand={this.onExpand}
                    expandedRowRender={record => <div><b>Datos recibidos:</b><p style={{ margin: 0 }}><JSONPretty id="json-pretty" data={record.corruptedData} theme={JSONPrettyMon}></JSONPretty></p></div>}
                    rowKey={record => record.corruptedDataId}
                    dataSource={this.state.corruptedDataRegistries}
                    pagination={false}
                    loading={this.state.loading}
                />
                <br />
                <Pagination showSizeChanger onChange={this.onPageChange} current={this.state.currentPage}
                 onShowSizeChange={this.onShowSizeChange} total={this.state.pagination.total} pageSizeOptions={['10', '20', '50', '100', '200']} 
                 showTotal={total => `Total ${total} elementos`} />
                <Divider />
            </div>
        );
    }
}

const CorruptedDataVisualizerForm = Form.create({ name: 'corruptedDataVisualizer' })(CorruptedDataVisualizer);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(CORRUPTED_DATA_VISUALIZER_PERMISSION, permissions),
});

export default connect(mapStateToProps)(CorruptedDataVisualizerForm);
