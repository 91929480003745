/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 11:23:04
 * @Last Modified by: Alejandro Leonor
 * @Last Modified time: 2018-04-17 16:54:53
 */
import { combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import { message } from 'antd';

import { actions } from './index';
import { FETCH_DATA } from './types';
import * as generalService from '../../../services/general';
import { parseApiError } from '../../../common/error';

const fetchDataEpic = action$ =>
    action$.ofType(FETCH_DATA)
        .flatMap(action =>
            Observable.concat(
                Observable.of(actions.loading(true)),
                Observable.fromPromise(generalService.fetchData(action.payload))
                    .flatMap(response =>
                        Observable.concat(
                            Observable.of(actions.loading(false)),
                            Observable.of(actions.fetchDataFullfilled(response)),
                        ))
                    .catch(error =>
                        Observable.concat(
                            Observable.of(actions.loading(false)),
                            Observable.of(actions.showMessage(() => {
                                message.error(parseApiError(error).message);
                            })),
                        )),
            ));


export default combineEpics(fetchDataEpic);
