import React from 'react';
import GaugeShowInfo from './gaugeShowInfo';
import { connect } from 'react-redux';
import { Table, Tooltip, message } from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import {
    getPagePermissions,
    DISPENSARY_PERMISSION
} from '../../../security/permissions';
import disconnect from '../../../assets/images/disconnect.svg';
import GaugeUnlinkForm from './gaugeUnlinkForm';

class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.gauges,
            activateAllGauges: props.activateAllGauges,
            containerStatus: props.containerStatus,
            visibleUnlinkModal: false,
            currentGaugeInfo: {},
        };
        this.columns = (pagePermissions) => {
            return [
                {
                    title: 'Desenlazar',
                    key: 'unlink',
                    align: 'left',
                    render: (text, record) => (
                        <span>
                            {(pagePermissions.edit && record.gaugeHash !== '') &&
                                <div>
                                    <Tooltip title="Desenlazar">
                                        <a onClick={() => this.setState({ visibleUnlinkModal: true, currentGaugeInfo: record })}>
                                            <img src={disconnect} alt="Equipo de entrada" />
                                        </a>
                                    </Tooltip>

                                </div>
                            }
                        </span>
                    )
                },
                {
                    title: 'Código',
                    dataIndex: 'gaugeCode',
                    editable: false,
                    render: (text) => (<Tooltip title="Código del Medidor">
                        {text}
                    </Tooltip>)
                },
                {
                    title: 'Información',
                    render: (text, record) => (
                        <GaugeShowInfo gaugeId={record.gaugeId} onDeleteGauges={this.props.onDeleteGauges} />
                    )
                },
            ];
        }
    }

    onGaugeChange = () => {
        this.props.onGaugeChange();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.gauges, activateAllGauges: nextProps.activateAllGauges, containerStatus: nextProps.containerStatus });
    }

    handleGaugeStatus = (idEquipment, checked) => {
        dispensaryServices.put(`equipment/changeGaugeStatus/${idEquipment}/${checked}`)
            .then(response => {
            }).then(() => {
                this.props.onSomeGaugeStatusChange();
            });
    };

    handleCancelUnlinkModal = () => {
        this.setState({ visibleUnlinkModal: false });
    }

    saveUnlinkFormRef = (formRef) => {
        this.unlinkFormRef = formRef;
    }

    handleEquipmentUnlink = () => {
        const form = this.unlinkFormRef.props.form;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            let params = {
                unlinkReason:`${values.unlinkReason}`
            }

            dispensaryServices.put(`equipment/unlinkGauge/${values.gaugeCode}`,params)
                .then(response => {
                    const jsonResponse = JSON.parse(JSON.stringify(response));
                    if (jsonResponse.hasOwnProperty('response')) {
                        message.error(jsonResponse.response.data);
                    } else {
                        message.success("Medidor de nivel desenlazado exitosamente");
                    }
                    this.props.onGaugeChange();
                });
            form.resetFields();
            this.setState({ visibleUnlinkModal: false });
        });
    }

    render() {
        const { pagePermissions } = this.props;
        return (
            <div>
                <Table
                    size="middle"
                    rowKey={record => record.gaugeId}
                    title={() => 'Medidores'}
                    dataSource={this.state.data}
                    columns={this.columns(pagePermissions)}
                    showHeader={false}
                    pagination={false}
                />
                <GaugeUnlinkForm
                    wrappedComponentRef={this.saveUnlinkFormRef}
                    visible={this.state.visibleUnlinkModal}
                    onCancel={this.handleCancelUnlinkModal}
                    onUnlink={this.handleEquipmentUnlink}
                    gauge={this.state.currentGaugeInfo}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISPENSARY_PERMISSION, permissions)
});

export default connect(mapStateToProps)(EditableTable);
