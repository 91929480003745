import React from "react";
import { Form } from '@ant-design/compatible';

import { Row, Col, Input, Select, Button, message, Spin, Modal, Switch } from "antd";
import { users as usersServices } from "../../../services";
import { getIsComanyAdministrator } from "../../../security";
import DistributionPointAssigner from "./distributionPointAssigner";

class UserCreatorComponent extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isNewUser: props.isNewUser,
			userData: props.userData !== undefined ? props.userData : {},
			isCompanyAdministrator: getIsComanyAdministrator(),
			showForm: props.showForm,
			showModalPDD: false,
			isLoading: false,
		};
	}

	processBackendResponse = (response, successMessage) => {
		const jsonResponse = JSON.parse(JSON.stringify(response));
		if (jsonResponse.hasOwnProperty("response")) {
			if (jsonResponse.response.data.hasOwnProperty('message')) {
				message.error(jsonResponse.response.data.message);
			} else {
				message.error(jsonResponse.response.data);
			}
		} else {
			message.success(successMessage);
		}
	}

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				values.firstUser = false;
				if(values.isCompanyAdministrator){
					values.distributionPoints = [];
				}else{
					values.isCompanyAdministrator = false;
					if(values.distributionPoints === undefined || values.distributionPoints.length < 1){
						Modal.error({
							title:'No has asignado puntos de distribución',
							centered: true,
							content: (
								<div>
									<label>Es necesario asignar al menos un punto de distribución al usuario</label>
								</div>
							),
							width: '40%',
							onOk() {},}
						);
						return;
					}

				}
				if (values.distributionPoints === undefined) {
					values.distributionPoints = this.state.userData.distributionPoints !== undefined ? this.state.userData.distributionPoints.map(element => { return element.id }) : [];
				}
				values.distributionPointIds = values.distributionPoints;
				if (this.state.isNewUser) {
					usersServices.post("user", values).then((response) => {
						this.processBackendResponse(response, "Usuario registrado exitosamente");
					}).finally(() => {
						this.props.onCloseModal();
					});

				} else {
					usersServices.put("user", values).then((response) => {
						this.processBackendResponse(response, "Usuario actualizado exitosamente");
					}).finally(() => {
						this.props.onCloseModal();
					});
				}
			}
		});
	};

	render() {
		const { getFieldDecorator, getFieldValue, setFieldsValue } =
			this.props.form;

		return (
			<Modal
				open={this.props.showForm}
				onCancel={this.props.onCloseModal}
				width="50%"
				closable={false}
				footer={[
					<Button
						className="back-button"
						key="cancel"
						style={{ marginLeft: 16 }}
						onClick={this.props.onCloseModal}
					>
						Cancelar
					</Button>,
					<Button
						className="submit-button"
						key="continue"
						onClick={this.handleSubmit}
						htmlType="submit"
					>
						<label hidden={!this.state.isNewUser}>Agregar usuario</label>
						<label hidden={this.state.isNewUser}>Actualizar usuario</label>
					</Button>,
				]}
			>
				<Spin size="large" tip="Cargando..." spinning={this.state.isLoading}>
					<Form layout='vertical' onSubmit={this.handleSubmit} style={{ width: "100%" }}>
						<div>
							<label hidden={!this.state.isNewUser}>
								Llena los campos a continuación para registrar un nuevo usuario.
							</label>
							<label hidden={this.state.isNewUser}>
								Actualiza los campos del usuario.
							</label>
						</div>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label="Nombre">
									{getFieldDecorator("name", {
										rules: [
											{
												required: true,
												message: "Por favor introduce un nombre de usuario.",
											},
										],
										initialValue: this.state.userData.detailUserName,
									})(<Input />)}
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Correo electrónico">
									{getFieldDecorator("email", {
										rules: [
											{
												type: "email",
												message: "Correo electrónico no válido",
											},
											{
												required: true,
												message: "Por favor introduce un correo electrónico.",
											},
										],
										initialValue: this.state.userData.detailUserEmail,

									})(<Input disabled={!this.state.isNewUser} />)}
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={12}>
								<Form.Item label="Asignar perfil">
									{getFieldDecorator("role", {
										rules: [
											{
												required: true,
												message: "Por favor selecciona un perfil.",
											},
										],
										initialValue: this.state.userData.detailUserProfile,
									})(
										<Select
											placeholder="Seleccionar"
											disabled={
												this.state.isNewUser
													? false
													: !this.state.isCompanyAdministrator &&
													this.state.userData.isCompanyAdministrator
											}
											onChange={(value) => {
												if (value !== "ADMINISTRATOR") {
													setFieldsValue({ isCompanyAdministrator: false });
												}
											}}
										>
											<Select.Option value="ADMINISTRATOR">
												Administrador
											</Select.Option>
											<Select.Option value="SUPERVISOR">
												Supervisor
											</Select.Option>
											<Select.Option value="OPERATOR">Operador</Select.Option>
											<Select.Option value="AUDITOR">
												Auditor fiscal
											</Select.Option>
										</Select>
									)}
								</Form.Item>
							</Col>
							<div hidden={!this.state.isCompanyAdministrator}>
								<Col
									span={12}
									hidden={getFieldValue("role") !== "ADMINISTRATOR"}
								>
									<Form.Item label="Administrador Global">
										{getFieldDecorator("isCompanyAdministrator", {
											initialValue: this.state.userData.isCompanyAdministrator,
										})(
											<Switch
												defaultChecked={
													this.state.userData.isCompanyAdministrator
												}
											/>
										)}
									</Form.Item>
								</Col>
							</div>
						</Row>
						<Row gutter={24} hidden={getFieldValue("isCompanyAdministrator")}>
							<Col span={24}>
								<Form.Item>
									{getFieldDecorator("distributionPoints", {
										initialValue: this.state.userData.distributionPoints !== undefined ? this.state.userData.distributionPoints.map(element => { return element.id }) : [],
									})(
										<DistributionPointAssigner
											visible={!getFieldValue("isCompanyAdministrator")}
											actualDistributionPoints={
												this.state.userData === undefined ? [] : this.state.userData.distributionPoints
											}
											onHadleOk={(values) => {
												this.setState({ showModalPDD: false });
												setFieldsValue({ distributionPoints: values });
											}}
											isDistributionPointEditable={this.state.isNewUser}
											availableDistributionPoints={this.props.availableDistributionPoints}
											isNewUser={this.state.isNewUser}
										/>
									)}
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</Spin>
			</Modal>
		);
	}
}

export default Form.create({ name: "userCreatorComponent" })(
	UserCreatorComponent
);
