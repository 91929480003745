import { CrownOutlined } from '@ant-design/icons';
import { Button, Col, Input, List, Modal, Row, Typography } from 'antd';
import React from 'react';
import request from '../../../services/request';

class RedemptionCodeComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            code: "",
            showModal: false,
            responseError: []
        };
    }

    toggleModal = (visible) => {
        this.setState({ showModal: visible });
    }

    redeemCode = () => {
        const { code } = this.state;
        if (code === undefined || code.length < 1) {
            this.setState({ responseError: [{ "code": 409, "message": "El campo no puede ir vacío" }] });
            return;
        }
        request()
            .post("initial-config/redeemCode", code
            )
            .then((resp) => {
                this.toggleModal(false);
            }).catch((err) => {
                this.setState({ responseError: [{ "code": err.response.data.statusCode, "message": err.response.data.message }] })
            });
    }

    footerButtons = () => {
        return [
            <Button key="back" onClick={() => { this.toggleModal(false); }}>
                Cancelar
            </Button>,
            <Button key="submit" type="primary" onClick={this.redeemCode}>
                Canjear
            </Button>,
        ];
    }

    render() {
        window.addEventListener("keydown", (event) => {
            if (event.defaultPrevented) {
                return;
            }
            if (event.ctrlKey && event.shiftKey && (event.key === 'F5' || event.code === 'KeyR')) {
                this.toggleModal(true);
                event.preventDefault();
            }

        }, true);

        return (
            <div>
                <Modal
                    open={this.state.showModal}
                    onCancel={() => { this.toggleModal(false) }}
                    title={
                        (<Row type="flex" justify="space-between">
                            <Col span={4}><CrownOutlined /></Col>
                            <Col span={16}>Canje de Códigos</Col>
                        </Row>)
                    }
                    footer={this.footerButtons()}
                >
                    <Row type="flex" justify="center">
                        <Col span={20}>
                            Para continuar, por favor, ingresa en aquí el código de canjeo generado
                        </Col>
                        <Col span={20}>
                            <Input.Password
                                placeholder="Código generado por ventas usando el SubscriptionManager"
                                onChange={(value) => { this.setState({ code: value.target.value }); }}
                                value={this.state.code} />
                        </Col>
                        <Col span={20}>
                            <List>
                                {this.state.responseError.map((error) =>
                                (<List.Item key={error.code}>
                                    <Typography.Text mark>{error.code}</Typography.Text>
                                    : {error.message}
                                </List.Item>))
                                }
                            </List>
                        </Col>
                    </Row>
                </Modal>
                {this.props.children}
            </div>
        );
    };

}

export default RedemptionCodeComponent;