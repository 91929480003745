import React, { Component } from 'react';
import { MinusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import {
    Card,
    Row,
    Col,
    Button,
    Input,
    Divider,
    Breadcrumb,
    Modal,
    Select,
    DatePicker,
    InputNumber,
    Checkbox,
    message,
    Spin,
    Tooltip,
} from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import TextArea from 'antd/lib/input/TextArea';
import { getSelectedDistributionPoint } from '../../../common/components/widgets/DistributionPoint/distributionPointStorage';
import tooltipWarehouse from '../../wareHouse/components/tooltip.json';
import tooltip from './tooltip.json';
import { logOut, ToFirstContainerRegistry } from '../../../security';
const { Option } = Select;

let id = 1;

const removeEmptyElements = array => (
    array.filter(element => element != null)
);

const removeGaugeInfo = array => array.map(item => (
    {
        gaugeCode: item.gaugeCode,
        gaugeDescription: item.gaugeDescription,
        gaugeCalibrationDate: item.gaugeCalibrationDate.format('YYYY-MM-DD HH:mm:ss'),
        gaugeUncertaintyMeasurement: item.gaugeUncertaintyMeasurement,
    }
));

let hoseCodes = [''];
function hasDuplicatedValues(array) {
    return (new Set(array)).size !== array.length;
}

class ContainerAggregation extends Component {
    _isMount = false;
    constructor(props) {
        super(props);
        this.state = {
            containerCodeVS: {},
            integrationCodeVS: {},
            containerCodeInputVS: {},
            containerCodeExist: true,
            integrationCodeExist: true,
            containerCodeInputExist: true,
            hoseIdentificatorVS: {},
            gaugeIdentificatorVS: {},
            hoseCodeExist: false,
            visibleModal: false,
            loading: false,
            existedHoseCodes: [],
            installationKey: {},
            tankKey: [],
            ductKey: [],
            warehouses: [],
            tankType: true,
            ductType: false,
            wellType: false,
            heelGasType: false,
            wellIdentificationKeyVS: {},
            tankAndDuctIdentificationKeyVS: {},
            distributionPointId: getSelectedDistributionPoint(),
        };
    }

    componentDidMount() {
        this._isMount = true;
        this.fetchWarehouses('TANQUE');
        this.fetchInstallationKey();
        this.fetchTankKeys();
        this.fetchDuctKeys();
        window.addEventListener("distributionPointChange",(e) => {
            if(this._isMount){
                this.setState({ distributionPointId: getSelectedDistributionPoint()}, ()=>{
                    this.props.form.setFieldsValue({ 'idWareHouse': undefined });
                    let warehouseType = 
                        (this.state.tankType)? "TANQUE" :
                        (this.state.wellType)? "POZO" :
                        (this.state.ductType)? "DUCTO" : "UNKNOWN";
                    this.fetchWarehouses(warehouseType);
                });
            }
        });
    }

    componentWillUnmount() {
        this._isMount = false;
    }

    fetchWarehouses = (warehouseType) => {
        const params = {
            warehouseType: warehouseType
        };
        dispensaryServices.get(`distributionPoints/${this.state.distributionPointId}/warehouseTypes`, params)
        .then(response => {
            this.setState({ warehouses: response });
        });
    };

    fetchInstallationKey = () => {
        dispensaryServices.get('company/installationKey')
            .then(response => {
                this.setState({ installationKey: response });
            });
    };

    fetchTankKeys = () => {
        dispensaryServices.get('container/tankKeys')
            .then(response => {
                this.setState({ tankKey: response });
            });
    };

    fetchDuctKeys = () => {
        dispensaryServices.get('container/ductKeys')
            .then(response => {
                this.setState({ ductKey: response });
            });
    };

    validateContainerCode = (containerCode) => ({
        validateStatus: 'error',
        errorMsg: `La clave de tanque ${containerCode} ya existe actualmente.`,
    })
    validateIntegrationCode = (integrationCode) => ({
        validateStatus: 'error',
        errorMsg: `El código de integración ${integrationCode} ya existe actualmente.`,
    })

    validateLocalHoseCode(response) {
        if (response) {
            this.setState({ hoseCodeExist: true });
            return {
                validateStatus: 'error',
                errorMsg: 'Cada identificador de medidor tiene que ser único.',
            };
        }

        this.setState({ hoseCodeExist: false });
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    validateGaugeCode = (gaugeCode) => ({
        validateStatus: 'error',
        errorMsg: `El identificador de medidor ${gaugeCode} ya existe actualmente.`,
    })

    validateGeneralGaugeCode(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `El formato de clave del medidor no es válido`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    handleContainerCodeChange = (containerCode) => {
        this.setState({ containerCodeVS: {} });

        let warehouseId = this.props.form.getFieldValue('idWareHouse');
        const params = {
            containerKey: containerCode
        };
        dispensaryServices.get(`warehouse/${warehouseId}/checkIfContainerKeyExist`, params)
            .then(response => {
                if (response === true) {
                    this.setState({
                        containerCodeVS: {
                            ...this.validateContainerCode(containerCode),

                        }
                    });
                }
                this.setState({ containerCodeExist: response });
            });
    }

    handleAddHoseCode = () => {
        hoseCodes = hoseCodes.concat(['']);
    };

    handleRemoveHoseCode = idx => {
        let hcs = hoseCodes;
        hcs = hcs.filter((s, sidx) => idx !== sidx);
        hoseCodes = hcs;

        this.reviewArchivedHoseCodes();
    };

    reviewArchivedHoseCodes() {
        const arr1 = hoseCodes;
        const arr2 = this.state.existedHoseCodes;
        const found = arr1.some(r => arr2.includes(r));

        if (found) {
            this.setState({
                hoseCodeExist: true,
                hoseIdentificatorVS: {
                    validateStatus: 'error',
                    errorMsg: 'Identificador de manguera existente.',
                }
            });
        } else {
            this.setState({
                hoseCodeExist: false,
                hoseIdentificatorVS: {
                    validateStatus: 'success',
                    errorMsg: null,
                }
            });
        }
    }

    handleHoseCodeBlur = () => {
        this.reviewArchivedHoseCodes();
    }

    reviewLocalHoseCodes(idx, equipmentKey) {
        const newHoseCodes = hoseCodes.map((hose, sidx) => {
            if (idx !== sidx) return hose;
            return equipmentKey;
        });

        hoseCodes = newHoseCodes;
        this.setState({
            hoseIdentificatorVS: {
                ...this.validateLocalHoseCode(hasDuplicatedValues(newHoseCodes))
            }
        });
    }

    cacheExistedHoseCodes(equipmentKey) {
        let newHoseCodes = this.state.existedHoseCodes;

        newHoseCodes = newHoseCodes.concat([equipmentKey]);
        this.setState({ existedHoseCodes: newHoseCodes });
    }

    handleGaugeFlowChange = idx => evt => {
        this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(true), } });

        let auditableEquipmentKey = undefined;

        const reg = /^[0-9]{4}$/;
        if (!reg.test(`${this.props.form.getFieldValue(`gauges[${idx}].customerGaugeKey`)}`)) {
            this.setState({ gaugeIdentificatorVS: { ...this.validateGaugeIdentificationKey(false), } });
            this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(false), } });

        } else {
            this.setState({ gaugeIdentificatorVS: { ...this.validateGaugeIdentificationKey(true), } });

            if (this.state.tankType === true) {
                this.props.form.validateFieldsAndScroll(['tankType', 'instalationKey'], (err) => {
                    if (err) {
                        this.setState({ gaugeIdentificatorVS: { ...this.validateGaugeTankIdentificationKey(false), } });
                        this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(false), } });
                    } else {
                        this.setState({ gaugeIdentificatorVS: { ...this.validateGaugeTankIdentificationKey(true), } });

                        auditableEquipmentKey = `SMD-${evt}-${this.props.form.getFieldValue('tankType')}-${this.props.form.getFieldValue('instalationKey')}-${this.props.form.getFieldValue(`gauges[${idx}].customerGaugeKey`)}`;
                    }
                });
            }
        }

        if (auditableEquipmentKey !== undefined) {
            this.checkAuditableKey(auditableEquipmentKey, idx);
        }
    }

    checkAuditableKey(auditableEquipmentKey, idx){
        const params = {
            auditableEquipmentKey: auditableEquipmentKey
        };
        dispensaryServices.get(`distributionPoints/${this.state.distributionPointId}/checkIfAuditableEquipmentKeyExist`, params)
                .then(response => {
                    if (response === true) {
                        this.cacheExistedHoseCodes(auditableEquipmentKey);
                        this.setState({ hoseCodeExist: true });
                        this.setState({
                            hoseIdentificatorVS: {
                                ...this.validateGaugeCode(auditableEquipmentKey),
                            }
                        });
                    }
                });

            this.reviewLocalHoseCodes(idx, auditableEquipmentKey);
    }

    handleGaugeCodeChange = idx => evt => {
        this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(true), } });

        let auditableEquipmentKey = undefined;

        if (this.state.wellType === true) {
            const reg = /^[0-9]{4}[A-Z]{3}$/;
            if (!reg.test(evt.target.value)) {
                this.setState({ gaugeIdentificatorVS: { ...this.validateWellIdentificationKey(false), } });
                this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(false), } });
            } else {
                this.props.form.validateFieldsAndScroll(['ductType', 'customerDuctKey'], (err) => {
                    if (err) {
                        this.setState({ gaugeIdentificatorVS: { ...this.validateGaugeTankIdentificationKey(false), } });
                        this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(false), } });
                    } else {
                        auditableEquipmentKey = `SMD-POZ-${this.props.form.getFieldValue('ductType')}-${evt.target.value}`;
                        this.setState({ gaugeIdentificatorVS: { ...this.validateGaugeTankIdentificationKey(true), } });
                        this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(true), } });
                    }
                });
            }
        } else {
            const reg = /^[0-9]{4}$/;
            if (!reg.test(evt.target.value)) {
                this.setState({ gaugeIdentificatorVS: { ...this.validateGaugeIdentificationKey(false), } });
                this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(false), } });

            } else {
                this.setState({ gaugeIdentificatorVS: { ...this.validateGaugeIdentificationKey(true), } });

                if (this.state.tankType === true) {
                    this.props.form.validateFieldsAndScroll(['tankType', 'instalationKey'], (err) => {
                        if (err) {
                            this.setState({ gaugeIdentificatorVS: { ...this.validateGaugeTankIdentificationKey(false), } });
                            this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(false), } });
                        } else {
                            this.setState({ gaugeIdentificatorVS: { ...this.validateGaugeTankIdentificationKey(true), } });

                            if (`${this.props.form.getFieldValue(`gauges[${idx}].tankGaugeType`)}` === 'true') {
                                auditableEquipmentKey = `SMD-${this.props.form.getFieldValue(`gauges[${idx}].gaugeFlow`)}-${this.props.form.getFieldValue('tankType')}-${this.props.form.getFieldValue('instalationKey')}-${evt.target.value}`;
                            } else {
                                auditableEquipmentKey = `SME-${this.props.form.getFieldValue('tankType')}-${this.props.form.getFieldValue('instalationKey')}-${evt.target.value}`;
                            }
                        }
                    });

                } else if (this.state.wellType === true) {
                    this.props.form.validateFieldsAndScroll(['wellRegisterKey', 'wellIdentificationKey'], (err) => {
                        if (err) {
                            this.setState({ gaugeIdentificatorVS: { ...this.validateGaugeTankIdentificationKey(false), } });
                            this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(false), } });
                        } else {
                            this.setState({ gaugeIdentificatorVS: { ...this.validateGaugeTankIdentificationKey(true), } });

                            auditableEquipmentKey = `SMD-DUC-${this.props.form.getFieldValue('wellRegisterKey')}-${evt.target.value}`;
                        }
                    });

                }
            }

        }

        if (auditableEquipmentKey !== undefined) {
            this.checkAuditableKey(auditableEquipmentKey, idx);
        }
    }

    validateGaugeIdentificationKey(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `La clave del medidor no es válida, debe contener 4 números`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    validateGaugeTankIdentificationKey(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `Por favor registre la clave del tanque primero.`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    remove = (idx, k) => {
        this.handleRemoveHoseCode(idx);
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        // We need at least one passenger
        if (keys.length === 1) {
            return;
        }

        // can use data-binding to set
        form.setFieldsValue({
            keys: keys.filter(key => key !== k)
        });
    };

    add = () => {
        this.handleAddHoseCode();
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(id++);
        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
            keys: nextKeys
        });
    };

    showModal = () => {
        this.setState({
            visibleModal: true,
        });
    }

    handleOk = () => {
        this.setState({ loading: true });
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let newContainer;

                if (this.state.tankType) {
                    newContainer = {
                        idContainer: null,
                        containerKey: values.containerKey,
                        containerType: 'TANQUE',
                        containerOperationalCapacity: values.containerOperationalCapacity,
                        containerUsefulCapacity: values.containerUsefulCapacity,
                        containerTotalCapacity: values.containerTotalCapacity,
                        containerHeelGasCapacity: values.containerHeelGasCapacity,
                        containerFundingCapacity: values.containerFundingCapacity,
                        containerStatus: true,
                        containerMinOperationalCapacity: values.containerMinOperationalCapacity,
                        containerDescription: values.containerDescription,
                        containerCalibrationDate: values.containerCalibrationDate.format('YYYY-MM-DD HH:mm:ss'),
                        gauges: removeGaugeInfo(removeEmptyElements(values.gauges)),
                        idWareHouse: values.idWareHouse,
                        integrationCode: values.integrationCode,
                        containerInitialVolume: values.containerInitialVolume,
                    };
                } else if (this.state.wellType) {
                    newContainer = {
                        containerKey: values.wellKey,
                        containerType: 'POZO',
                        containerStatus: true,
                        containerDescription: values.wellDescription,
                        gauges: removeGaugeInfo(removeEmptyElements(values.gauges)),
                        idWareHouse: values.idWareHouse,
                        integrationCode: values.integrationCode,
                    };
                } else {
                    newContainer = {
                        containerKey: values.ductKey,
                        containerType: 'DUCTO',
                        containerHeelGasCapacity: values.ductHeelGasCapacity,
                        containerStatus: true,
                        containerDuctDiameter: values.ductDiameter,
                        containerDescription: values.ductDescription,
                        gauges: removeGaugeInfo(removeEmptyElements(values.gauges)),
                        idWareHouse: values.idWareHouse,
                        integrationCode: values.integrationCode,
                    };
                }

                dispensaryServices.post('container', newContainer)
                    .then(response => {
                        const jsonResponse = JSON.parse(JSON.stringify(response));
                        if (jsonResponse.hasOwnProperty('response')) {
                            
                            if (jsonResponse.response.data.hasOwnProperty('message')) {
                                message.error(jsonResponse.response.data.message);
                            }else{
                                message.error(jsonResponse.response.data);
                            }
                        }else{
                            message.success("Se agregó correctamente el contenedor");
                        }
                        this.finalAction();
                    });
            }
        });
    }

    finalAction(){
        this.setState({ loading: false, visibleModal: false });
        if(this.props.isInitialConfiguration){
            Modal.confirm({
                title: '¿Quieres agregar más contenedores?',
                content: (
                    <div>
                        En caso de requerir seguir agregando mas información, por favor da click en 'Continuar', en caso contrario da click sobre 'Finalizar configuración inicial'
                    </div>
                ),
                okText: 'Finalizar configuración inicial',
                cancelText: 'Continuar',
                onCancel: ()=>{
                    ToFirstContainerRegistry();
                },
                onOk: ()=>{
                    logOut();
                }
            });
        }else{
            this.props.onAddContainerClick();
        }
        
    }

    handleCancel = () => {
        this.setState({ visibleModal: false });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err) => {
            if (!err) {
                this.showModal();
            }
        });
    };

    obtainTankCode = () => {
        this.props.form.validateFieldsAndScroll(['tankType', 'instalationKey', 'customerTankKey'], (err) => {
            if (!err) {
                const containerKey = `${this.props.form.getFieldValue('tankType')}-${this.props.form.getFieldValue('instalationKey')}-${this.props.form.getFieldValue('customerTankKey')}`;
                this.props.form.setFieldsValue({ containerKey });
                this.handleContainerCodeChange(containerKey);
                this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(true), } });
            } else
                this.props.form.setFieldsValue({ containerKey: '' });
            this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(false), } });
        });
    }

    obtainWellCode = () => {
        this.props.form.validateFieldsAndScroll(['wellRegisterKey', 'wellIdentificationKey'], (err) => {
            if (!err) {
                const wellKey = `POZ-${this.props.form.getFieldValue('wellRegisterKey')}-${this.props.form.getFieldValue('wellIdentificationKey')}`;
                this.props.form.setFieldsValue({ wellKey: wellKey });
                this.handleContainerCodeChange(wellKey);
                this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(true), } });
            } else {
                this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(false), } });
            }
        });
    }

    obtainDuctCode = () => {
        this.props.form.validateFieldsAndScroll(['ductType', 'customerDuctKey'], (err) => {
            if (!err) {
                const ductKey = `DUC-${this.props.form.getFieldValue('ductType')}-${this.props.form.getFieldValue('customerDuctKey')}`;
                this.props.form.setFieldsValue({ ductKey: ductKey });
                this.handleContainerCodeChange(ductKey);
                this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(true), } });
            } else
                this.props.form.setFieldsValue({ ductKey: '' });
            this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(false), } });
        });
    }

    handleContainerTypeChange = evt => {
        this.props.form.resetFields();
        if (evt === 'tanque') {
            this.setState({ tankType: true, ductType: false, wellType: false, });
            this.fetchWarehouses('TANQUE');
        } else if (evt === 'pozo') {
            this.setState({ tankType: false, ductType: false, wellType: true, });
            this.fetchWarehouses('POZO');
        } else {
            this.setState({ tankType: false, ductType: true, wellType: false, });
            this.fetchWarehouses('DUCTO');
        }
    }

    wellIdentificationKeyChange = event => {
        const reg = /^[0-9]{4}[A-Z]{3}$/;
        if (!reg.test(event.target.value)) {
            this.setState({
                wellIdentificationKeyVS: {
                    ...this.validateWellIdentificationKey(false),
                }
            });
            this.props.form.setFieldsValue({ wellKey: '' });
            this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(false), } });
        } else {
            this.setState({
                wellIdentificationKeyVS: {
                    ...this.validateWellIdentificationKey(true),
                }
            });
            this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(true), } });
            this.obtainWellCode();
        }
    }

    validateWellIdentificationKey(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `La clave de identificación del pozo no es válida, debe contener 4 números, seguido de 3 letras mayúsculas, con el formato Regex: [0-9]{4}[A-Z]{3}`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    tankAndDuctIdentificationKeyChange = event => {
        const reg = /^[0-9]{4}$/;
        if (!reg.test(event.target.value)) {
            this.setState({
                tankAndDuctIdentificationKeyVS: { ...this.validateTankAndDuctIdentificationKey(false), }
            });
            this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(false), } });
            if (this.state.tankType === true ? this.props.form.setFieldsValue({ containerKey: '' }) : this.props.form.setFieldsValue({ ductKey: '' }));
        } else {
            this.setState({ tankAndDuctIdentificationKeyVS: { ...this.validateTankAndDuctIdentificationKey(true), } });
            this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(true), } });
            if (this.state.tankType === true ? this.obtainTankCode() : this.obtainDuctCode());
        }
    }

    verifyIntegrationCode = event => {
        const integrationCode = event.target.value;
        this.setState({ integrationCodeVS: {} });
        const params = {
            integrationCode
        };
        dispensaryServices.get('container/integration-code/exists', params)
            .then(response => {
                if (response === true) {
                    this.setState({
                        integrationCodeVS: {
                            ...this.validateIntegrationCode(integrationCode),
                        }
                    });
                }
                this.setState({ integrationCodeExist: response });
            });
    }

    validateTankAndDuctIdentificationKey(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `La clave de identificación no es válida, debe contener 4 números`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    formatGaugeCode = idx => {
        let gaugeCode = undefined

        if (this.state.tankType === true) {
            if (`${this.props.form.getFieldValue(`gauges[${idx}].tankGaugeType`)}` === "true") {
                gaugeCode = `SMD-${this.props.form.getFieldValue(`gauges[${idx}].gaugeFlow`)}-${this.props.form.getFieldValue('tankType')}-${this.props.form.getFieldValue('instalationKey')}-${this.props.form.getFieldValue(`gauges[${idx}].customerGaugeKey`)}`;
            } else {
                gaugeCode = `SME-${this.props.form.getFieldValue('tankType')}-${this.props.form.getFieldValue('instalationKey')}-${this.props.form.getFieldValue(`gauges[${idx}].customerGaugeKey`)}`;
            }

        } else if (this.state.wellType === true) {
            gaugeCode = `SMD-POZ-${this.props.form.getFieldValue('wellRegisterKey')}-${this.props.form.getFieldValue(`gauges[${idx}].customerGaugeKey`)}`;
        } else {
            gaugeCode = `SMD-DUC-${this.props.form.getFieldValue('ductType')}-${this.props.form.getFieldValue(`gauges[${idx}].customerGaugeKey`)}`;
        }

        return gaugeCode;
    }
    preSetFields(warehouseId){
        let warehouse = this.state.warehouses.filter(w => w.idWareHouse===warehouseId);
        if(warehouse!==undefined){
            warehouse = warehouse[0];
            let wareHouseKey = warehouse.warehouseKey;
            let splittedKey = wareHouseKey.split('-');
            if(this.state.tankType){
                this.props.form.setFieldsValue({tankType: splittedKey[0], instalationKey: splittedKey[1]});
            }
            if(this.state.wellType){
                this.props.form.setFieldsValue({wellRegisterKey:splittedKey[1]});
            }
            if(this.state.ductType){
                this.props.form.setFieldsValue({ductType:splittedKey[1]});
            }
        }else{
            console.error("No warehouse!!!!");
        }
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { hoseIdentificatorVS, visibleModal, loading, wellIdentificationKeyVS,
            tankAndDuctIdentificationKeyVS, gaugeIdentificatorVS, containerCodeVS, integrationCodeVS } = this.state;
        const { onCancelClick } = this.props;
        const dateFormatList = 'DD/MM/YYYY';
        const isWarehouseSelected = getFieldValue('idWareHouse')!==undefined;

        const optionContainerKeys = types => types.map(item => (
            <Option key={item.id} value={item.code}>{item.description}</Option>
        ));

        const optionWarehouses = types => types.map(item => (
            <Option key={item.idWareHouse} value={item.idWareHouse}>{item.warehouseKey} - {item.product.productDescription} - {item.subproduct.description}</Option>
        ));

        getFieldDecorator('keys', { initialValue: [0] });
        const keys = getFieldValue('keys');
        const formItems = keys.map((k, index) => (
            <Row key={k}>
                <Col span={18}>
                    <Card>
                        <Row gutter={24}>
                            <Tooltip title={tooltip.medidores.medidorDinamico}>
                                <Col span={8} hidden={this.state.tankType === false}>
                                    <Form.Item label="Tipo de medidor para tanque">
                                        {getFieldDecorator(`gauges[${k}].tankGaugeType`, {
                                            initialValue: false,
                                        })(
                                            <Checkbox key={index}>{'Medidor dinámico'}</Checkbox>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Tooltip>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8} >
                                <Tooltip title={tooltip.medidores.claveIdentificacion}>
                                    <Form.Item
                                        label="Clave de identificación del medidor"
                                        validateStatus={hoseIdentificatorVS.validateStatus}
                                        help={hoseIdentificatorVS.errorMsg}
                                    >
                                        {getFieldDecorator(`gauges[${k}].gaugeCode`, {
                                            initialValue: this.formatGaugeCode(k),
                                            rules: [
                                                { required: true, message: 'Por favor ingresa el código del medidor.' },
                                            ]
                                        })(
                                            <Input className="common-input" disabled />
                                        )}
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8} hidden={`${this.props.form.getFieldValue(`gauges[${index}].tankGaugeType`)}` === "false"}>
                                <Tooltip title={tooltip.medidores.flujoMedidor}>
                                    <Form.Item
                                        label="Flujo del medidor"
                                        validateStatus={gaugeIdentificatorVS.validateStatus}
                                        help={gaugeIdentificatorVS.errorMsg}
                                    >
                                        {getFieldDecorator(`gauges[${k}].gaugeFlow`, {
                                            initialValue: 'ETA',
                                            rules: [
                                                { required: true, message: 'Por favor selecciona el flujo del medidor.' },
                                            ]
                                        })(
                                            <Select onChange={this.handleGaugeFlowChange(k)}>
                                                <Option key={'ETA'} value={'ETA'}>{'Para entrada a medios de almacenamiento '}</Option>
                                                <Option key={'SDA'} value={'SDA'}>{'Para salida a medios de almacenamiento'}</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col span={8}>
                                <Tooltip title={tooltip.medidores.codigo}>
                                    <Form.Item
                                        label="Código del medidor"
                                        validateStatus={gaugeIdentificatorVS.validateStatus}
                                        help={gaugeIdentificatorVS.errorMsg}
                                    >
                                        {getFieldDecorator(`gauges[${k}].customerGaugeKey`, {
                                            rules: [
                                                { required: true, message: 'Por favor introduce el código del medidor.' },
                                            ]
                                        })(
                                            <Input className="common-input" onChange={this.handleGaugeCodeChange(k)} />
                                        )}
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Tooltip title={tooltip.medidores.descripcion}>
                                    <Form.Item label="Descripción">
                                        {getFieldDecorator(`gauges[${k}].gaugeDescription`, {
                                            rules: [
                                                {required: true, message: 'Por favor introduce la descripción del medidor.'},
                                                { min: 2, message: 'Introduce mínimo 2 caracteres.' },
                                                { max: 250, message: 'Introduce máximo 250 caracteres.' },
                                            ]
                                        })(
                                            <Input className="common-input" />
                                        )}
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col span={8}>
                                <Tooltip title={tooltip.medidores.fechaCalibracion}>
                                    <Form.Item label="Fecha de calibración">
                                        {getFieldDecorator(`gauges[${k}].gaugeCalibrationDate`, {
                                            rules: [{
                                                required: true, message: 'Por favor introduce la fecha de calibración del medidor.'
                                            }]
                                        })(
                                            <DatePicker  lang='es' lang='es' style={{ width: '100%' }} format={dateFormatList} />
                                        )}
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col span={8}>
                                <Tooltip title={tooltip.medidores.incertidumbre}>
                                    <Form.Item label="Incertidumbre de medición">
                                        {getFieldDecorator(`gauges[${k}].gaugeUncertaintyMeasurement`, {
                                            rules: [{
                                                required: true, message: 'Por favor introduce la incertidumbre de medición del medidor.'
                                            }]
                                        })(
                                            <InputNumber className="common-input" />
                                        )}
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col className="container-minus-button" span={2} offset={1} >
                    <div className="vertical-center">
                        {k > -0 ? (
                            <Button shape="circle" icon={<MinusOutlined />} onClick={() => this.remove(index, k)} />
                        ) : null}
                    </div>
                </Col>
            </Row>
        ));

        return (
            <div>
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    <Breadcrumb.Item>Contenedores</Breadcrumb.Item>
                    <Breadcrumb.Item>Agregar contenedor</Breadcrumb.Item>
                </Breadcrumb>
                <Form layout='vertical' onSubmit={this.handleSubmit}>
                    <div className="hole-instructions">
                        <Divider orientation="left">Contenedor</Divider>
                        <span>Llena los campos a continuación para agregar un contenedor y sus medidores</span>
                    </div>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Tooltip title={tooltip.tipoContenedor}>
                                <Form.Item label="Tipo de contenedor" help="Selecciona el tipo de contenedor a agregar">
                                    {getFieldDecorator('containerType', {
                                        initialValue: 'tanque',
                                    })(
                                        <Select onChange={this.handleContainerTypeChange}>
                                            <Option key={'tanque'} value={'tanque'}>{'Tanque'}</Option>
                                            <Option key={'pozo'} value={'pozo'}>{'Pozo'}</Option>
                                            <Option key={'ducto'} value={'ducto'}>{'Ducto'}</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.almacen}>
                                <Form.Item label="Almacén">
                                    {getFieldDecorator(`idWareHouse`, {
                                        rules: [{ required: true, message: 'Por favor selecciona el almacén al que pertenece el contenedor' }]
                                    })(
                                        <Select onChange={(warehouse)=>{
                                            this.props.form.setFieldsValue({customerTankKey: undefined});
                                            this.preSetFields(warehouse);
                                        }}>
                                            {optionWarehouses(this.state.warehouses)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col span={8}>
                            <Tooltip title={tooltip.codigoIntegracion}>
                                <Form.Item
                                    label="Código de Integración"
                                           validateStatus={integrationCodeVS.validateStatus}
                                           help={integrationCodeVS.errorMsg}
                                >
                                    {getFieldDecorator('integrationCode', {})(
                                        <Input className="common-input" style={{ width: '100%' }} disabled={!isWarehouseSelected} onBlur={this.verifyIntegrationCode} />
                                    )}
                                </Form.Item>
                            </Tooltip>
                        </Col>
                    </Row>
                     <Spin spinning={!isWarehouseSelected} tip="Esperando a que se seleccione el Almácen" size='large'>
                        <Row span={24} hidden={this.state.tankType === false}>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Tooltip title={tooltipWarehouse.codigoAlmacen}>
                                        <Form.Item
                                            label="Código auditable del tanque (almacén)"
                                            validateStatus={containerCodeVS.validateStatus}
                                            help={containerCodeVS.errorMsg}
                                        >
                                            {getFieldDecorator('containerKey', {
                                                rules: [{
                                                    required: this.state.tankType, message: 'El formato de clave de tanque no es válido.'
                                                }]
                                            })(
                                                <Input className="common-input" disabled />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Tooltip title={tooltip.tanques.tipo}>
                                        <Form.Item label="Tipo de tanque  (almacén)">
                                            {getFieldDecorator('tankType', {
                                                rules: [{
                                                    required: this.state.tankType, message: 'Por favor selecciona el tipo de tanque.'
                                                }]
                                            })(
                                                <Select
                                                    disabled
                                                    onBlur={this.obtainTankCode}
                                                >
                                                    {optionContainerKeys(this.state.tankKey)}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={8}>
                                    <Tooltip title={tooltip.tanques.claveInstalacion}>
                                        <Form.Item label="Clave de instalación  (almacén)">
                                            {getFieldDecorator('instalationKey', {
                                                initialValue: this.state.installationKey.code,
                                                rules: [{
                                                    required: this.state.tankType, message: 'Por favor selecciona la clave de instalación del tanque.'
                                                }]
                                            })(
                                                <Select
                                                    onBlur={this.obtainTankCode}
                                                    disabled={true}
                                                >
                                                    <Option key={this.state.installationKey.id} value={this.state.installationKey.code}>{this.state.installationKey.code} - {this.state.installationKey.description}</Option>
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={8}>
                                    <Tooltip title={tooltip.tanques.codigoTanque}>
                                        <Form.Item
                                            label="Código del tanque"
                                            validateStatus={tankAndDuctIdentificationKeyVS.validateStatus}
                                            help={tankAndDuctIdentificationKeyVS.errorMsg}
                                            >
                                            {getFieldDecorator('customerTankKey', {
                                                rules: [
                                                    { required: this.state.tankType, message: 'Por favor introduce el código del tanque.' },
                                                ]
                                            })(
                                                <Input className="common-input" length={4} onBlur={this.tankAndDuctIdentificationKeyChange}/>
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Tooltip title={tooltip.tanques.descripcion}>
                                        <Form.Item label="Localización y descripción">
                                            {getFieldDecorator('containerDescription', {
                                                rules: [
                                                    {required: this.state.tankType, message: 'Por favor introduce la descripción del medidor.'},
                                                    { min: 2, message: 'Introduce mínimo 2 caracteres.' },
                                                    { max: 250, message: 'Introduce máximo 250 caracteres.' },
                                                ]
                                            })(
                                                <TextArea autoSize={{ minRows: 1, maxRows: 6 }} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={8}>
                                    <Tooltip title={tooltip.tanques.calibracion}>
                                        <Form.Item label="Vigencia de calibración">
                                            {getFieldDecorator('containerCalibrationDate', {
                                                rules: [{
                                                    required: this.state.tankType, message: 'Por favor selecciona la vigencia de calibración.'
                                                }]
                                            })(
                                                <DatePicker  lang='es' lang='es' style={{ width: '100%' }} format={dateFormatList} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={8}>
                                    <Tooltip title={tooltip.tanques.capacidadTotal}>
                                        <Form.Item label="Capacidad total">
                                            {getFieldDecorator('containerTotalCapacity', {
                                                rules: [{
                                                    required: this.state.tankType, message: 'Por favor introduce la capacidad total del contenedor.'
                                                }]
                                            })(
                                                <InputNumber className="common-input" precision={3} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{ width: '100%' }} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Tooltip title={tooltip.tanques.capacidadOperativa}>
                                        <Form.Item label="Capacidad operativa">
                                            {getFieldDecorator('containerOperationalCapacity', {
                                                rules: [{
                                                    required: this.state.tankType, message: 'Por favor introduce la capacidad operativa del contenedor.'
                                                }]
                                            })(
                                                <InputNumber className="common-input" precision={3} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{ width: '100%' }} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={8}>
                                    <Tooltip title={tooltip.tanques.capacidadUtil}>
                                        <Form.Item label="Capacidad útil">
                                            {getFieldDecorator('containerUsefulCapacity', {
                                                rules: [{
                                                    required: this.state.tankType, message: 'Por favor introduce la capacidad útil del contenedor.'
                                                }]
                                            })(
                                                <InputNumber className="common-input" precision={3} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{ width: '100%' }} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={8}>
                                    <Tooltip title={tooltip.tanques.capacidadFondaje}>
                                        <Form.Item label="Capacidad fondaje">
                                            {getFieldDecorator('containerFundingCapacity', {
                                                rules: [{
                                                    required: this.state.tankType, message: 'Por favor ingresa la capacidad de fondaje del contenedor.'
                                                }]
                                            })(
                                                <InputNumber className="common-input" precision={3} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{ width: '100%' }} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8} style={{whiteSpace: 'pre-wrap'}}>
                                    <Tooltip title={tooltip.nivelMinimoLlenado}>
                                        <Form.Item label="Nivel mínimo operativo de llenado (Sólo para producto gas natural)" style={{ display: "flex", flexDirection: "column" }}>
                                            {getFieldDecorator('containerHeelGasCapacity', {
                                                rules: [{
                                                    required: this.state.tankType && this.state.heelGasType, message: 'Por favor introduce la capacidad gas talón del contenedor.'
                                                }]
                                            })(
                                                <InputNumber className="common-input" precision={3} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{ width: '100%' }} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={8}>
                                    <Tooltip title={tooltip.tanques.nivelMinimoOperativo}>
                                        <Form.Item label="Volumen mínimo de operación">
                                            {getFieldDecorator('containerMinOperationalCapacity', {
                                                rules: [{
                                                    required: this.state.tankType, message: 'Por favor introduce el volumen mínimo de operación del contenedor.'
                                                }]
                                            })(
                                                <InputNumber className="common-input" precision={3} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{ width: '100%' }} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={8}>
                                    <Tooltip title={tooltip.tanques.initialVolume}>
                                        <Form.Item label="Volumen inicial ">
                                            {getFieldDecorator('containerInitialVolume', {
                                                rules: [{
                                                    required: this.state.tankType, message: 'Por favor introduce el volumen inicial del contenedor.'
                                                }]
                                            })(
                                                <InputNumber className="common-input" precision={3} min={0} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{ width: '100%' }} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Row>
                        <Row span={24} hidden={this.state.wellType === false}>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Tooltip title={tooltip.pozo.claveInstalacion}>
                                        <Form.Item
                                            label="Clave de identificación del pozo"
                                            validateStatus={containerCodeVS.validateStatus}
                                            help={containerCodeVS.errorMsg}
                                        >
                                            {getFieldDecorator('wellKey', {
                                                rules: [{
                                                    required: this.state.wellType, message: 'El formato de clave del pozo no es válido.'
                                                }]
                                            })(
                                                <Input className="common-input" disabled />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Tooltip title={tooltip.pozo.registro}>
                                        <Form.Item label="Clave de registro del pozo ante la CNH">
                                            {getFieldDecorator('wellRegisterKey', {
                                                rules: [{
                                                    required: this.state.wellType, message: 'Por favor escribe la clave de registro del pozo ante la CNH'
                                                }]
                                            })(
                                                <Input className="common-input" maxLength={40} onBlur={this.obtainWellCode} disabled/>
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={16}>
                                    <Tooltip title={tooltip.pozo.claveNumerica}>
                                        <Form.Item
                                            label="Clave de identificación del pozo"
                                            validateStatus={wellIdentificationKeyVS.validateStatus}
                                            help={wellIdentificationKeyVS.errorMsg}>
                                            {getFieldDecorator('wellIdentificationKey', {
                                                rules: [{
                                                    required: this.state.wellType, message: 'Por favor introduce la clave de identificación del pozo.'
                                                }]
                                            })(
                                                <Input className="common-input" maxLength={7} onBlur={this.wellIdentificationKeyChange} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Tooltip title={tooltip.pozo.descripcion}>
                                        <Form.Item label="Descripción del pozo">
                                            {getFieldDecorator('wellDescription', {
                                                rules: [{
                                                    required: this.state.wellType, message: 'Por favor selecciona la descripción del pozo.'
                                                }]
                                            })(
                                                <TextArea autoSize={{ minRows: 1, maxRows: 6 }} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Row>
                        <Row span={24} hidden={this.state.ductType === false}>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Tooltip title={tooltip.ductos.claveInstalacion}>
                                        <Form.Item
                                            label="Clave de identificación del ducto"
                                            validateStatus={containerCodeVS.validateStatus}
                                            help={containerCodeVS.errorMsg}
                                        >
                                            {getFieldDecorator('ductKey', {
                                                rules: [{
                                                    required: this.state.ductType, message: 'El formato de clave de ducto no es válido.'
                                                }]
                                            })(
                                                <Input className="common-input" disabled />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Tooltip title={tooltip.ductos.tipo}>
                                        <Form.Item label="Tipo de ducto">
                                            {getFieldDecorator('ductType', {
                                                rules: [{
                                                    required: this.state.ductType, message: 'Por favor selecciona el tipo de ducto.'
                                                }]
                                            })(
                                                <Select
                                                    onBlur={this.obtainDuctCode}
                                                    disabled
                                                >
                                                    {optionContainerKeys(this.state.ductKey)}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={8}>
                                    <Tooltip title={tooltip.ductos.codigo}>
                                        <Form.Item
                                            label="Código del ducto"
                                            validateStatus={tankAndDuctIdentificationKeyVS.validateStatus}
                                            help={tankAndDuctIdentificationKeyVS.errorMsg}
                                        >
                                            {getFieldDecorator('customerDuctKey', {
                                                rules: [
                                                    { required: this.state.ductType, message: 'Por favor introduce el código del ducto.' },
                                                ]
                                            })(
                                                <Input className="common-input" onBlur={this.tankAndDuctIdentificationKeyChange} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={8}>
                                    <Tooltip title={tooltip.ductos.diametro}>
                                        <Form.Item label="Diámetro del ducto (pulgadas)">
                                            {getFieldDecorator('ductDiameter', {
                                                rules: [{
                                                    required: this.state.ductType, message: 'Por favor introduce el diámetro del ducto.'
                                                }]
                                            })(
                                                <InputNumber className="common-input" precision={1} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{ width: '100%' }} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Tooltip title={tooltip.ductos.nivelMinimoLlenado}>
                                        <Form.Item label="Nivel mínimo operativo de llenado (Sólo para producto tipo gas natural)" style={{ display: "flex", flexDirection: "column" }}>
                                            {getFieldDecorator('ductHeelGasCapacity', {
                                                rules: [{
                                                    required: this.state.tankType && this.state.heelGasType, message: 'Por favor introduce el nivel mínimo operativo de llenado del tanque.'
                                                }]
                                            })(
                                                <InputNumber className="common-input" precision={3} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{ width: '100%' }} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={16}>
                                    <Tooltip title={tooltip.ductos.descripcion}>
                                        <Form.Item label="Descripción del ducto">
                                            {getFieldDecorator('ductDescription', {
                                                rules: [{
                                                    required: this.state.ductType, message: 'Por favor escribe la descripción del ducto.'
                                                }]
                                            })(
                                                <TextArea autoSize={{ minRows: 1, maxRows: 6 }} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Row>
                        <div className="hole-instructions">
                            <Divider orientation="left">Medidores</Divider>
                            <label>Agrega los medidores que utilizará tu contenedor</label>
                        </div>
                        {formItems}
                    </Spin>
                    
                    
                    {/*<Row>
                        <Col span={18} style={{ paddingTop: 16 }}>
                            <Button className="add-hose-button" type="dashed" onClick={this.add} icon="plus" disabled={hoseCodeExist}>Agregar medidor</Button>
                        </Col>
                    </Row>*/}
                    <Button className="customized-primary" type="primary" htmlType="submit" disabled={this.state.containerCodeExist}>Agregar contenedor</Button>
                    <Button className="customized-default" style={{ marginLeft: 16 }} onClick={onCancelClick}>Cancelar</Button>
                </Form>
                <Modal
                    open={visibleModal}
                    title="Atención"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button className="back-button" key="back" onClick={this.handleCancel}>Revisar información</Button>,
                        <Button className="submit-button" key="submit" loading={loading} onClick={this.handleOk}>Agregar contenedor</Button>
                    ]}
                >
                    <p>
                        Revisa a detalle que todos los campos ingresados sean correctos, 
                        pues una vez que presione "Agregar contenedor" la edición de estos se vera limitada.
                    </p>
                </Modal>
            </div>
        );
    }
}

export default Form.create({ name: 'containerAggregation' })(ContainerAggregation);
