import React from 'react';
import moment from 'moment';
import dayjs from "dayjs";
import axios from 'axios';
import { DownloadOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import {
    Table,
    Row,
    Col,
    DatePicker,
    Button,
    Popover,
    Pagination,
    Divider,
    Spin,
    message,
} from 'antd';
import DictumElimination from './components/dictumElimination';
import {
    dictum as dictumServices
} from '../../services';
import { connect } from 'react-redux';
import {
    getPagePermissions,
    DICTUM,
    EXPORT_REPORT_PERMISSION
} from '../../security/permissions';
import { getSelectedDistributionPoint } from '../../common/components/widgets/DistributionPoint/distributionPointStorage';
import DictumRegistration from './components/dictumRegistration';
import { saveJsonToFile } from '../stockControl/components/jsonViewer';
import { getRemoteIp } from '../../common/helpers/remoteIp';
import { getToken } from '../../security';
import { apiUrl } from 'app.config';


const { RangePicker } = DatePicker;

let displayPage = 0;
let displayAmount = 10;

class Dictums extends React.Component {
    _isMount = false;
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            mainView: true,
            addDictumForm: false,
            dictumId: '0',
            pagination: {},
            currentPage: 1,
            loadingDictums: false,
            selectedDistributionPoint: getSelectedDistributionPoint(),
            distributionPointId: getSelectedDistributionPoint(),
            hideDictumRegister: true,
        };

        this.columns = (pagePermissions) => {
            return [
                {
                    title: 'Folio del dictamen',
                    dataIndex: 'dictumFolioDetail',
                }, {
                    title: 'Fecha de emisión',
                    dataIndex: 'dictumIssueDateDetail',
                    align: 'center',
                    render: date => moment(date).format('YYYY/MM/DD')
                }, {
                    title: 'Fecha de la muestra',
                    dataIndex: 'dictumSampleTakenDetail',
                    align: 'center',
                    render: date => moment(date).format('YYYY/MM/DD')
                },
                {
                    title: 'Fecha de la prueba',
                    dataIndex: 'dictumTestAppliedDate',
                    align: 'center',
                    render: date => moment(date).format('YYYY/MM/DD')
                },
                {
                    title: 'Fecha del resultado',
                    dataIndex: 'dictumTestResultDate',
                    align: 'center',
                    render: date => moment(date).format('YYYY/MM/DD')
                },
                {
                    title: 'Método de la prueba',
                    dataIndex: 'dictumTestMethod',
                },
                {
                    title: 'Resultado de las muestra',
                    dataIndex: 'dictumTestResult',
                },
                {
                    title: 'Firmas',
                    render: (text, record) =>
                    (<div style={{ display: 'inline-block' }}>
                        <Button.Group>
                            <Popover content="Proveedor autorizado">
                                <Button onClick={() => this.onClickDownloadSignatureProvider(record.dictumId)} shape="circle" icon={<DownloadOutlined />} />
                            </Popover>
                            <Popover content="Representante legal">
                                <Button onClick={() => this.onClickDownloadSignatureLegalRepresentative(record.dictumId)} shape="circle" icon={<DownloadOutlined />} />
                            </Popover>
                        </Button.Group>
                    </div>)
                },
                {
                    title: 'Acciones',
                    render: (text, record) =>
                        record.isEditable && pagePermissions.edit === true ?
                            <div style={{ display: 'inline-block' }}>
                                <Button.Group>
                                    <Popover content="Editar dictamen">
                                        <Button shape="circle" icon={<EditOutlined />} onClick={()=>{
                                            this.setState({hideDictumRegister: false, dictumId: record.dictumId}, ()=>{
                                                this.fechSingleDictum();
                                            });
                                        }}/>
                                    </Popover>
                                    <DictumElimination dictumId={record.dictumId} handleSubmit={this.getDictumInfo} />
                                </Button.Group>

                            </div>
                            :
                            <div>
                                 <Popover content="Ver dictamen"><Button icon={<EyeOutlined />} onClick={()=>{
                                    this.setState({hideDictumRegister: false, dictumId: record.dictumId}, ()=>{
                                        this.fechSingleDictum();
                                    });
                                 }} /></Popover>
                            </div>
                },
            ];
        }
    }

    componentDidMount() {
        this._isMount = true;
        displayPage = 0;
        displayAmount = 10;
        this.getDictumInfo();
        window.addEventListener("distributionPointChange",(e) => {
            if(this._isMount){
                if (this.state.selectedDistributionPoint === getSelectedDistributionPoint()) {
                    return;
                }
                this.setState({ distributionPointId: getSelectedDistributionPoint()}, ()=>{
                    this.getDictumInfo();
                });
            }
        });
    }
    componentWillUnmount() {
        this._isMount = false;
    }

    onPageChange = (page, pageSize) => {
        displayPage = page - 1;
        this.getDictumInfo();
    }

    onShowSizeChange = (current, pageSize) => {
        displayPage = 0;
        displayAmount = pageSize;
        this.getDictumInfo();
    }

    getDictumInfo = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const rangeTimeValue = fieldsValue.period;
                const filters = {
                    dictumStartDate: rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss'),
                    dictumEndDate: rangeTimeValue[1].format('YYYY-MM-DD HH:mm:ss'),
                    dictumDisplayPage: displayPage,
                    dictumDisplayAmount: displayAmount,
                    distributionPointId: this.state.distributionPointId,
                };
                this.fetchRegisters(filters);
            }
        });
    }

    handlePeriodChange = e => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                fieldsValue.period = e;
                const rangeTimeValue = fieldsValue.period;
                const filters = {
                    dictumStartDate: rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss'),
                    dictumEndDate: rangeTimeValue[1].format('YYYY-MM-DD HH:mm:ss'),
                    dictumDisplayPage: displayPage,
                    dictumDisplayAmount: displayAmount,
                    distributionPointId: this.state.distributionPointId,
                };
                this.fetchRegisters(filters);
            }
        });
    }

    fetchRegisters = (params = {}) => {
        this.setState({ dataSource: [], loadingDictums: true });
        dictumServices.post('dictum/getDictums', params)
            .then(response => {
                const pagination = { ...this.state.pagination };
                pagination.total = response.dictumsTotalRecords;
                this.setState({
                    dataSource: response.details,
                    pagination,
                    currentPage: displayPage + 1
                });
                this.setState({ loadingDictums: false });
            });
    }

    downloadSignature = (uuid, type) =>{
        getRemoteIp().then(ip =>{
            axios({
                url: `${apiUrl}dictum/${uuid}/signature/${type}`,
                method: 'GET',
                headers: {
                    Authorization: getToken(),
                    originIp: ip
                },
                responseType: 'blob',
            }).then((response) => {
                saveJsonToFile(response.data,response.headers['content-type'], response.headers['x-filename']);
            }).catch((error)=>{
                message.error("No fue posible obtener el documento");
            }).finally(()=>{
                this.setState({isLoading: false});
            });
        });
    }

    onClickDownloadSignatureProvider = (uuid) => {
        this.downloadSignature(uuid, 'PROVIDER');
    }

    onClickDownloadSignatureLegalRepresentative = (uuid) => {
        this.downloadSignature(uuid, 'LEGAL');
    }

    closeDictumRegister = () =>{
        this.setState({hideDictumRegister: true, dictumId:'0'}, ()=>{
            this.getDictumInfo();
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { pagePermissions } = this.props;

        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loadingDictums}>
                <div hidden={!this.state.hideDictumRegister}>
                    {pagePermissions.read &&
                        <div>
                            {this.state.mainView &&
                                <div>
                                    <Form layout='vertical' >
                                        <Row gutter={24}>
                                            <Col span={12} >
                                                <Form.Item
                                                    label="Consultar período por fecha de dictamen"
                                                >
                                                    {getFieldDecorator('period', {
                                                        rules: [{ required: true, message: 'Por favor selecciona un periodo de tiempo.' }],
                                                        initialValue: [dayjs().startOf('week'), dayjs().endOf('week')]
                                                    })(
                                                        <RangePicker
                                                            lang='es'
                                                            format="YYYY/MM/DD"
                                                            placeholder={['Desde', 'Hasta']}
                                                            onChange={this.handlePeriodChange}
                                                        />
                                                    )}
                                                </Form.Item>
                                            </Col>
                                            <Col span={6} offset={3} hidden={pagePermissions.add === false}>
                                                <Form.Item className="transparent-label" label=" ">
                                                    <Button type="primary" icon={<PlusOutlined />} onClick={()=>{
                                                        this.setState({hideDictumRegister: false, dictumId: '0'}, ()=>{
                                                            this.fechSingleDictum();
                                                        });}}>
                                                            Agregar dictamen
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Table
                                        dataSource={this.state.dataSource}
                                        scroll={{ x: true }}
                                        columns={this.columns(pagePermissions)}
                                        pagination={false}
                                        rowKey={record => record.dictumFolioDetail}
                                    />
                                    <br />
                                    <Pagination
                                        showSizeChanger
                                        onChange={this.onPageChange}
                                        current={this.state.currentPage}
                                        onShowSizeChange={this.onShowSizeChange}
                                        total={this.state.pagination.total}
                                        pageSizeOptions={['10', '20', '30']}
                                        // locale={{ items_per_page: '/ página' }} 
                                        showTotal={total => `Total ${total} elementos`}
                                    />
                                    <Divider />
                                </div>
                            }
                        </div>}
                </div>
                <div>
                    <DictumRegistration 
                    dictumId={this.state.dictumId} 
                    hideDictumRegister={this.state.hideDictumRegister} 
                    onReturn={()=>{this.closeDictumRegister();}} 
                    fetchDictum={click => this.fechSingleDictum = click} />
                </div>
            </Spin>
        );
    }
}

const DictumTable = Form.create({ name: 'dictumTable' })(Dictums);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DICTUM, permissions),
    exportReportsPermissions: getPagePermissions(EXPORT_REPORT_PERMISSION, permissions)
});

export default connect(mapStateToProps)(DictumTable);
