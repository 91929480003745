import React from 'react';
import { Form } from '@ant-design/compatible';

import { Modal, Input, Button, Row, Col } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

const HoseUnlinkForm = Form.create({ name: 'hoseUnlinkForm_into_modal' })(

    class extends React.Component {
        render() {
            const {
                visible, onCancel, onUnlink, form, dispensary
            } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Modal
                    open={visible}
                    title={`Desenlazar dispensario ${dispensary.dispensaryCode}`}
                    onCancel={onCancel}
                    onOk={onUnlink}
                    footer={[
                        <Button className="back-button" key="back" onClick={onCancel}>Cancelar</Button>,
                        <Button className="submit-button" key="submit" onClick={onUnlink}>Desenlazar</Button>
                    ]}
                >
                    <p>{`¿Estás seguro que quieres desenlazar el dispensario ${dispensary.dispensaryCode}?`}</p>
                    <Form layout='vertical' >
                        <Row gutter={24}>
                            <Form.Item label="Código de dispensario" hidden>
                                {getFieldDecorator('dispensaryCode', {
                                    initialValue: dispensary.dispensaryCode,
                                    rules: [{ required: true, message: 'Por favor introduce el código de dispensario' }]
                                })(
                                    <Input disabled/>
                                )}
                            </Form.Item>
                            <Col span={24}>
                                    <Form.Item label="Motivo de desenlace">
                                        {getFieldDecorator('unlinkReason', {
                                            rules: [{
                                                required: true, message: 'Por favor ingresa el motivo del desenlace.'
                                            }]
                                        })(
                                            <TextArea autoSize={{ minRows: 2, maxRows: 6 }} maxLength={60} placeholder="Ingrese motivo del desenlace."/>
                                        )}
                                    </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            );
        }
    }

);

export default HoseUnlinkForm;
