import React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';

import { Modal, Input, Button, Row, Col, Popover, message, Spin } from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import { connect } from 'react-redux';
import {
    getPagePermissions,
    DISPENSARY_PERMISSION
} from '../../../security/permissions';

const { confirm } = Modal;

const EquipmentShowInfo = Form.create({ name: 'containerShowForm_into_modal' })(

    class extends React.Component {
        state = {
            visible: false,
            loading: false,
        };

        showModal = () => {
            this.setState({
                visible: true,
            });
        }

        handleReturn = () => {
            this.setState({
                visible: false,
            });
            this.props.form.resetFields();
        };

        showLinkConfirm = (gaugeCode, linkCode) => {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    let serialNumber = values.equipmentSerialNumber;
                    confirm({
                        title: `¿Está seguro que desea enlazar el registro de entrada: ${gaugeCode}?`,
                        content: 'Confirme que el número de serie sea el correcto.',
                        okText: 'Sí',
                        okType: 'ok',
                        cancelText: 'No',
                        centered: true,
                        onOk: () => new Promise((resolve) => {
                            setTimeout(() => resolve(this.linkVolumeMeasurer(gaugeCode, linkCode, serialNumber)), 2000);
                        }).catch(() => console.log('error')),
                        onCancel() {
                            console.log('Cancel');
                        },
                    });
                }
            });
        }

        linkVolumeMeasurer = (gaugeCode, linkCode, serialNumber) => {
            console.log("values: ", gaugeCode, linkCode, serialNumber);
            this.setState({ loading: true });
            dispensaryServices.put(`equipment/linkEquipment/${gaugeCode}/${linkCode}/${serialNumber}`)
                .then(response => {
                    const jsonResponse = JSON.parse(JSON.stringify(response));
                    if (jsonResponse.hasOwnProperty('response')) {
                        message.error(jsonResponse.response.data);
                    } else {
                        message.success(jsonResponse);
                    }
                    this.props.onEquipmentLink();
                    this.setState({ loading: false });
                });
            this.setState({
                visible: false,
            });
        }


        render() {
            const { gaugeCode, linkCode, pagePermissions } = this.props;
            const { getFieldDecorator } = this.props.form;
            return (
                <Spin size="large" tip="Cargando..." spinning={this.state.loading}>

                    <div style={{ display: 'inline-block' }} >
                        <Popover content={"Enlazar registro de entrada"}>
                            <a type="link" shape="circle" onClick={() => this.showModal()}>
                                <LegacyIcon type={"tool"} theme="twoTone" style={{ fontSize: '20px' }} />
                            </a>
                        </Popover>
                        <Modal
                            open={this.state.visible}
                            title="Información del registro de entrada"
                            onCancel={this.handleReturn}
                            footer={pagePermissions.edit ? [
                                <Button key={0} type="danger" onClick={() => this.showLinkConfirm(gaugeCode, linkCode)}>Enlazar</Button>
                            ] : []}
                        >
                            <p>Ingrese el número de serie del equipo a enlazar</p>
                            <Form layout='vertical' >
                                <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item label="Número de serie del equipo">
                                            {getFieldDecorator('equipmentSerialNumber', {
                                                rules: [{
                                                    min: 32, max: 32, length: 32, required: true, message: 'Por favor introduce los 32 caracteres del número de serie del equipo'
                                                }],
                                            })(
                                                <Input className="common-input" min={32} max={32} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Modal>
                    </div >
                </Spin>
            );
        }
    }
);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISPENSARY_PERMISSION, permissions)
});

export default connect(mapStateToProps)(EquipmentShowInfo);
