export const timeZones = [{
    value: 'America/Tijuana',
    text: 'Tijuana UTC-8'
}, {
    value: 'America/Chihuahua',
    text: 'Chihuahua UTC-7'
}, {
    value: 'America/Hermosillo',
    text: 'Hermosillo UTC-7'
}, {
    value: 'America/Mazatlan',
    text: 'Mazatlan UTC-7'
}, {
    value: 'America/Ojinaga',
    text: 'Ojinaga UTC-7'
}, {
    value: 'America/Bahia_Banderas',
    text: 'Bahía Banderas UTC-6'
}, {
    value: 'America/Mexico_City',
    text: 'Ciudad de México UTC-6'
}, {
    value: 'America/Matamoros',
    text: 'Matamoros UTC-6'
}, {
    value: 'America/Merida',
    text: 'Mérida UTC-6'
}, {
    value: 'America/Monterrey',
    text: 'Monterrey UTC-6'
}, {
    value: 'America/Cancun',
    text: 'Cancun UTC-5'
}];

export const regulatoryCharacter = [{
    value: 'CONTRATISTA',
    text: 'Contratista'
}, {
    value: 'ASIGNATARIO',
    text: 'Asignatario'
}, {
    value: 'PERMISIONARIO',
    text: 'Permisionario'
}, {
    value: 'USUARIO',
    text: 'Usuario'
}];
