import React from 'react';
import { CheckCircleFilled, MailOutlined } from '@ant-design/icons';
import { Badge } from 'antd';

const RestoredPassword = () => (
    <div style={{ textAlign: 'center' }}>
        <Badge count={<CheckCircleFilled style={{ fontSize: '19px', color: '#31CB5D' }} />}><MailOutlined style={{ fontSize: '80px', color: '#31CB5D' }} /></Badge>
        <h3 style={{ marginTop: 40.4 }}>Correo enviado</h3>
        <p>En tu bandeja de entrada encontrarás un correo que te permitirá restablecer tu contraseña</p>
    </div>
);

export default RestoredPassword;
