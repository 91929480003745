import request from './request';
import { serializeParams } from '../common/helpers';

export const get = (url = '', params = {}) => request().get(`${url}${serializeParams(params)}`)
    .then(response => response.data)
    .catch(error => error);

export const post = (url = '', params = {}, options = {}) => request().post(url, params, options)
    .then(response => response)
    .catch(error => error);
