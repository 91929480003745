import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Row, Col, Input, InputNumber } from 'antd';
import {
    register as registerServices
} from '../../../services';

class DictumDetailsPR12 extends Component {

    calculateSum = () => {
        const num1 = parseFloat(this.props.form.getFieldValue('gasPropanoRequestDictumDetailValue'), 10);
        const num2 = parseFloat(this.props.form.getFieldValue('gasButanoRequestDictumDetailValue'), 10);
        const sum = 100 - (num1 + num2);
        this.props.form.setFieldsValue({ gasOtrosRequestDictumDetailValue: sum });
        this.props.form.validateFieldsAndScroll(['gasPropanoRequestDictumDetailValue', 'gasButanoRequestDictumDetailValue'], (err, values) => {
            if (!err) {
                const normalize = {
                    requestPropanePercentage: values.gasPropanoRequestDictumDetailValue,
                    requestButanePercentage: values.gasButanoRequestDictumDetailValue,
                };
                this.normalized(normalize);
            }
        });
    }

    normalized = (normalize) => {
        this.props.form.validateFieldsAndScroll(['gasOtrosRequestDictumDetailValue'], (err, values) => {
            if (!err) {
                registerServices.post('dictum/calculateNormalizedGasValues', normalize)
                    .then(response => {
                        this.props.form.setFieldsValue({ gasPropanoNormalizadoRequestDictumDetailValue: response.responsePropanePercentage });
                        this.props.form.setFieldsValue({ gasButanoNormalizadoRequestDictumDetailValue: response.responseButanePercentage });
                    });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { pagePermissions } = this.props;

        return (
            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label="Porcentaje de gas propano">
                        {getFieldDecorator('gasPropanoRequestDictumDetailValue', {
                            initialValue: this.props.data.dictumDetails !== null && this.props.data.productType === 'PR12'
                                ? this.props.data.dictumDetails[0].responseDictumDetailValue : '',
                            rules: [{
                                required: this.props.selectedProduct === 'PR12', message: 'Por favor introduce el porcentaje de gas propano.'
                            }],
                        })(
                            <InputNumber
                                style={{ width: '100%' }}
                                onBlur={this.calculateSum}
                                disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                min={0.01} max={99.99}
                                precision={2}
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label="Porcentaje de gas butano">
                        {getFieldDecorator('gasButanoRequestDictumDetailValue', {
                            initialValue: this.props.data.dictumDetails !== null && this.props.data.productType === 'PR12'
                                ? this.props.data.dictumDetails[1].responseDictumDetailValue : '',
                            rules: [{
                                required: this.props.selectedProduct === 'PR12', message: 'Por favor introduce el porcentaje de gas butano.'
                            }],
                        })(
                            <InputNumber
                                style={{ width: '100%' }}
                                onBlur={this.calculateSum}
                                disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                min={0.01} max={99.99}
                                precision={2}
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label="Porcentaje de otros gases">
                        {getFieldDecorator('gasOtrosRequestDictumDetailValue', {
                            // initialValue: this.state.data.dictumDetails === undefined ? '' : this.state.data.dictumDetails[2].responseDictumDetailValue,
                            rules: [{
                                required: this.props.selectedProduct === 'PR12', type: 'number', min: 0, message: 'La suma de los porcentajes anteriores debe ser menor o igual a 99.99%.'
                            }],
                        })(
                            <InputNumber
                                style={{ width: '100%' }}
                                disabled
                                precision={3}
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label="Porcentaje de gas propano normalizado">
                        {getFieldDecorator('gasPropanoNormalizadoRequestDictumDetailValue', {
                            // initialValue: this.state.data.dictumDetails === undefined ? '' : this.state.data.dictumDetails[3].responseDictumDetailValue,
                            // rules: [{
                            //     required: true, message: 'Por favor introduce el porcentaje de gas propano normalizado.'
                            // }],
                        })(
                            <Input disabled />
                        )}
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label="Porcentaje de gas butano normalizado">
                        {getFieldDecorator('gasButanoNormalizadoRequestDictumDetailValue', {
                            // initialValue: this.state.data.dictumDetails === undefined ? '' : this.state.data.dictumDetails[4].responseDictumDetailValue,
                            // rules: [{
                            //     required: true, message: 'Por favor introduce el porcentaje de gas propano.'
                            // }],
                        })(
                            <Input disabled />
                        )}
                    </Form.Item>
                </Col>
            </Row>
        );
    }
}

export default Form.create({ name: 'dictumDetailsPR12' })(DictumDetailsPR12);
