import React, { Component } from 'react';

class Printer extends Component {
    render() {
        return (
            <h1>Impresora</h1>
        );
    }
}

export default Printer;
