import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Progress, Modal } from 'antd';
import { /* FirstContainerAggregation,  */FirstListContainersSaved } from './components';
import { ToFirstWarehouseRegistry, } from '../../security';
import {
    files as fileServices
} from '../../services';
import {
    getPagePermissions,
    INITIAL_CONTAINER
} from '../../security/permissions';

import './styles.css';
import ContainerAggregation from '../containers/components/containerAggregation';

class FirstContainers extends Component {
    constructor() {
        super();
        this.state = {
            warehouse: false,
            areThereContainers: false,
            visible: false,

        };
    }

    componentDidMount() {
        this.fetchWarehouses();
    }

    fetchWarehouses = (params = {}) => {
        fileServices.get('warehouse')
            .then(response => {
                if (response.length > 0) {
                    this.setState({ warehouse: true });
                    this.fetchContainers();
                    this.info();
                }
            });
    };

    fetchContainers = () => {
        fileServices.get('container')
            .then(response => {
                if (response.length > 0) {
                    this.setState({
                        areThereContainers: true,
                        containersSaved: response
                    });
                } else {
                    this.setState({
                        areThereContainers: false,
                    });
                }
            });
    };

    info = () => {
        Modal.info({
            title: 'Atención',
            content: (
                <div>
                    <br />
                    <br />
                    <p style={{ fontSize: '20px' }}>La información agregada al registrar contenedores no puede ser editada, por lo cual es de suma importancia ingresar y verificar la información cuidadosamente ya que este registro queda a responsabilidad del usuario.</p>
                    <br />
                </div>
            ),
            width: 1000,
            onOk() { },
        });
    }

    render() {
        return (
            <div>
                {this.state.warehouse ? (
                    <div>
                        {this.state.containersSaved ? (
                            <div>
                                <FirstListContainersSaved
                                    containersSaved={this.state.containersSaved}
                                />
                            </div>

                        ) : (
                                <div>
                                    <span style={{ fontSize: '24px' }}>Sin contenedores agregados</span>
                                </div>

                            )}
                        <div>
                            <br />
                            <br />
                            <ContainerAggregation hideCancel={true} isInitialConfiguration={true}/>
                        </div>
                    </div>

                ) : (
                        <div className="center-div">
                            <Progress type="circle" percent={70} status="exception" />
                            <div style={{ margin: '8px' }}>
                                <span>Por favor registra algún almacén antes de añadir de añadir contenedores</span>
                            </div>
                            <Button className="back-button" key="back" onClick={() => ToFirstWarehouseRegistry()}>Registrar almacén</Button>
                        </div>
                    )}
            </div>

        );

    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(INITIAL_CONTAINER, permissions)
});

export default connect(mapStateToProps)(FirstContainers);