import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Button, Divider, Row, Col, Input, Select, Pagination, Spin } from 'antd';
import { EditableUserTable } from './components';
import {
    users as usersServices
} from '../../services';
import {
    getPagePermissions,
    USERS_PERMISSION
} from '../../security/permissions';

import './styles.css';
import UserCreatorComponent from './components/userCreatorComponent';
import request from '../../services/request';

const { Option } = Select;

const initialValues = {
    userDisplayPage: 0,
    userDisplayAmount: 10,
    userVerified: true,
    userActive: true,
    userEmail: null,
    userName: null,
    userRole: null
};

const filters = {
    userDisplayPage: 0,
    userDisplayAmount: 10,
    userVerified: true,
    userActive: true,
    userEmail: null,
    userName: null,
    userRole: null
};

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pagination: {},
            loading: false,
            registerForm: false,
            currentPage: 1,
            availableDistributionPoints: []
        };
    }

    componentDidMount() {
        this.fetch(initialValues);
        this.fetchDistributionPoints();
    }

    fetchDistributionPoints() {
		request()
			.get("distributionPoints")
			.then((resp) => {
				this.setState({
					availableDistributionPoints: resp.data,
				});
			});
	}

    fetch = (params = {}) => {
        console.log('params:', params);
        this.setState({ loading: true });
        usersServices.post('user/getUsers', params)
            .then(response => {
                console.log('FETCH', response);
                const pagination = { ...this.state.pagination };
                pagination.total = response.userTotalRecords;
                this.setState({
                    data: response.userDetail,
                    pagination,
                    currentPage: params.userDisplayPage + 1,
                    loading: false
                });
            });
    };

    handleUserStatusChange = () => {
        if (this.state.data.length === 1 && filters.userDisplayPage > 0) {
            filters.userDisplayPage -= 1;
        }
        this.fetch(filters);
    }

    handleUserEdition = () => {
        this.fetch(filters);
    }

    handleUserDeletion = () => {
        if (this.state.data.length === 1 && filters.userDisplayPage > 0) {
            filters.userDisplayPage -= 1;
        }
        this.fetch(filters);
    }

    handleCancelClick = () => {
        this.fetch(filters);
        this.setState({
            registerForm: false
        });
    }

    handleShowChange = (e) => {
        filters.userDisplayPage = 0;

        switch (e) {
            case '-1':
                filters.userVerified = false;
                filters.userActive = true;
                break;

            case '0':
                filters.userActive = false;
                filters.userVerified = true;
                break;

            case '1':
                filters.userActive = true;
                filters.userVerified = true;
                break;

            default:
                break;
        }

        this.fetch(filters);
    }

    handleUserNameEnter = (e) => {
        filters.userDisplayPage = 0;
        filters.userName = e.target.value;
        this.fetch(filters);
    }

    handleUserEmailEnter = (e) => {
        filters.userDisplayPage = 0;
        filters.userEmail = e.target.value;
        this.fetch(filters);
    }

    handleUserRoleChange = (e) => {
        filters.userDisplayPage = 0;
        filters.userRole = e;
        this.fetch(filters);
    }

    showRegisterForm = () => {
        this.setState({
            registerForm: true
        });
    }

    onPageChange = (page, pageSize) => {
        filters.userDisplayPage = page - 1;
        this.fetch(filters);
    }

    onShowSizeChange = (current, pageSize) => {
        filters.userDisplayPage = 0;
        filters.userDisplayAmount = pageSize;
        this.fetch(filters);
    }

    render() {
        const { pagePermissions } = this.props;
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                {
                    <Spin size="large" tip="Cargando..." spinning={this.state.loading}>
                        <Form layout='vertical' >
                            <Row>
                                <Col span={24} style={{ textAlign: 'left' }}>
                                    <Form.Item>
                                        {pagePermissions.add && <Button className="customized-primary" type="primary" icon={<PlusOutlined />} onClick={this.showRegisterForm}>Agregar usuario</Button>}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={6}>
                                    <Form.Item label="Mostrar">
                                        {getFieldDecorator('show', {
                                            initialValue: '1',
                                            rules: [{

                                            }],
                                        })(
                                            <Select
                                                onChange={this.handleShowChange}
                                            >
                                                <Option value="1">Activos</Option>
                                                <Option value="0">Inactivos</Option>
                                                <Option value="-1">Verificación pendiente</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Nombre">
                                        {getFieldDecorator('userName', {
                                            initialValue: null,
                                            rules: [{

                                            }],
                                        })(
                                            <Input onPressEnter={this.handleUserNameEnter} placeholder="Buscar nombre" suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Correo electrónico">
                                        {getFieldDecorator('userEmail', {
                                            initialValue: null,
                                            rules: [{

                                            }],
                                        })(
                                            <Input onPressEnter={this.handleUserEmailEnter} placeholder="Buscar correo" suffix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item label="Perfil">
                                        {getFieldDecorator('userRole', {
                                            rules: [{

                                            }],
                                        })(
                                            <Select
                                                onChange={this.handleUserRoleChange}
                                                placeholder="Seleccionar perfil"
                                            >
                                                <Option value="ADMINISTRATOR">Administrador</Option>
                                                <Option value="SUPERVISOR">Supervisor</Option>
                                                <Option value="OPERATOR">Operador</Option>
                                                <Option value="AUDITOR">Auditor fiscal</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>

                        <EditableUserTable
                            data={this.state.data}
                            onEditUser={this.handleUserEdition}
                            onChangeUserStatus={this.handleUserStatusChange}
                            onDeleteUser={this.handleUserDeletion}
                            availableDistributionPoints={this.state.availableDistributionPoints}
                        />
                        <br />
                        <Pagination showSizeChanger onChange={this.onPageChange} current={this.state.currentPage} onShowSizeChange={this.onShowSizeChange} total={this.state.pagination.total} pageSizeOptions={['10', '20', '50', '100', '200']} showTotal={total => `Total ${total} elementos`} />
                        <Divider />
                    </Spin>
                }
                {
                    <UserCreatorComponent
                        showForm={this.state.registerForm}
                        onCloseModal={() => this.handleCancelClick()}
                        isNewUser
                        availableDistributionPoints={this.state.availableDistributionPoints}
                    />
                }
            </div>
        );
    }
}

const UsersForm = Form.create({ name: 'users' })(Users);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(USERS_PERMISSION, permissions)
});

export default connect(mapStateToProps)(UsersForm);
