/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 11:35:46
 * @Last Modified by: Alejandro Leonor
 * @Last Modified time: 2018-04-17 16:56:50
 */
import { createReducer } from 'aleonor-redux-helpers';
import epics from './epics';
import { FETCH_DATA, FETCH_DATA_FULLFILLED } from './types';

const defaultState = {
    reducerKey: 'home',
    currentData: null,
    someprop: '',
    username: '',
};

// Actions
const fetchData = payload => ({ type: FETCH_DATA, payload });
const fetchDataFullfilled = payload => ({ type: FETCH_DATA_FULLFILLED, payload });

// Reducer handling action types
const { reducer, defaultActions } = createReducer(defaultState, {
    [FETCH_DATA_FULLFILLED]: (state, payload) => ({ ...state, currentData: payload }),
});

const actions = { ...defaultActions, fetchData, fetchDataFullfilled };

export {
    epics,
    reducer,
    actions,
};
