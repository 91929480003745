import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    PlusOutlined,
    PlusSquareOutlined,
} from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Table, Input, DatePicker, Button, Popover, Modal, message } from 'antd';
import PGElimination from './pgElimination';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import {
    getPagePermissions,
    EVM_DETAILS
} from '../../../security/permissions';

import { Link } from 'react-router-dom';

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const receptionMovementDetails = array => array.map(item => (
    {
        key: item.rmFolio,
        container: item.rmContainerCode,
        volume: item.rmVolume,
        mass: item.rmMass,
        folio: item.rmFolio,
        initialDate: item.rmStartDate,
        endDate: item.rmEndDate,
    }
));

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
    state = {
        editing: false,
    };

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    saveDate = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.id]) {
                return;
            }
            values.inputRegisterPolicyReceptionDate = moment(e._d).format('YYYY-MM-DD HH:mm:ss');
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    }

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title } = this.props;
        const { editing } = this.state;
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `${title} es requerido.`,
                        },
                    ],
                    initialValue: dataIndex !== 'inputRegisterPolicyReceptionDate' ?
                        record[dataIndex] :
                        (record[dataIndex] !== null ? moment(record.inputRegisterPolicyReceptionDate, 'YYYY-MM-DD') : null)
                })(
                    dataIndex !== 'inputRegisterPolicyReceptionDate' ?
                        <Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />
                        :
                        <DatePicker
                            style={{ width: '100%' }}
                            ref={node => (this.input = node)}
                            onChange={this.saveDate}
                            format="YYYY/MM/DD"
                            placeholder="Seleccionar fecha"
                            allowClear={false}
                        />
                )}
            </Form.Item>
        ) : (
                <div
                    className="editable-cell-value-wrap"
                    onClick={this.toggleEdit}
                >
                    {children}
                </div>
            );
    };

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable && record.isEditAllowed ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                        children
                    )}
            </td>
        );
    }
}

class EditableTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: this.props.dataSource,
            count: 2,
            loading: false,
            visible: false,
            infoToDelete: {},
        };

        this.columns = (handleAddComplement) => [
            {
                title: 'Fecha de inicio',
                dataIndex: 'inputRegisterReceptionDate',
                render: date => moment(date).format('YYYY/MM/DD HH:mm:ss')
            },{
                title: 'Fecha de fin',
                dataIndex: 'inputRegisterEndReceptionDate',
                render: date => moment(date).format('YYYY/MM/DD HH:mm:ss')
            },{
                title: 'Equipo',
                dataIndex: 'inputRegisterEquipmentCode',    
            },{
                title: 'Origen',
                dataIndex: 'pgVehicle',    
            } ,{
                title: 'Número de registro',
                dataIndex: 'inputRegisterFolio',
            }, {
                title: 'Volumen',
                dataIndex: 'inputRegisterVolume',
                render: inputRegisterVolume => Intl.NumberFormat('en', { notation: 'standard' }).format(inputRegisterVolume)
            }, {
                title: 'Masa',
                dataIndex: 'inputRegisterMass',
                render: inputRegisterMass => Intl.NumberFormat('en', { notation: 'standard' }).format(inputRegisterMass)
            },
            {
                title: 'Almacén',
                dataIndex: 'inputRegisterWarehouseKey'
            },
            {
                title: 'PGs',
                align: 'center',
                render: (text, record) =>
                    (record.isEditAllowed && (record.inputRegisterAssociatedPg !== null)) ?
                        <div style={{ display: 'inline-block' }}>
                            <Button.Group>
                                {this.props.pagePermissions.edit &&
                                    <Link
                                        key="2"
                                        to={{ pathname: `/receptions/components/pgRegistration/${record.inputRegisterAssociatedPg.id}/${record.inputRegisterUUID}` }}
                                    >
                                        <Popover content="Editar PG">
                                            <Button shape="circle" icon={<EditOutlined />} />
                                        </Popover>
                                    </Link>}
                                {this.props.pagePermissions.delete && <PGElimination inputRegisterAssociatedPg={record.inputRegisterAssociatedPg} handleSubmit={this.props.handleSubmit} />}
                            </Button.Group>
                        </div>
                        :
                        (record.isEditAllowed && (record.inputRegisterAssociatedPg === null)) ?
                            <div>
                                {this.props.pagePermissions.add &&
                                    <Link
                                        key="1"
                                        to={{ pathname: `/receptions/components/pgRegistration/0/${record.inputRegisterUUID}` }}
                                    >
                                        <Popover content="Agregar PG">
                                            <Button type="primary" icon={<PlusOutlined />} />
                                        </Popover>
                                    </Link>}
                            </div>
                            :
                            ((record.isEditAllowed === false) && (record.inputRegisterAssociatedPg !== null)) ?
                                <div>
                                    {this.props.pagePermissions.read &&
                                        <Link
                                            key="3"
                                            to={{ pathname: `/receptions/components/pgRegistration/${record.inputRegisterAssociatedPg.id}/${record.inputRegisterUUID}` }}
                                        >
                                            <Popover content="Ver PG"><Button icon={<EyeOutlined />} /></Popover>
                                        </Link>}
                                </div>
                                :
                                <div>{this.props.pagePermissions.read &&
                                    <span>Sin PG asociado</span>}
                                </div>
            },
            {
                title: 'Complementos',
                align: 'center',
                render: (text, record) =>                    
                    (record.isEditAllowed && (record.cfdiComplementInfo === null) && (record.storageComplementInfo === null) && (record.transportComplementInfo === null) && (record.internationalTransactionComplement === null) && record.clarifications === null) ?
                        <Popover content="Agregar información para complemento">
                            <Button icon={<PlusOutlined />} onClick={() => 
                                handleAddComplement({
                                    mainView:false,
                                    addComplementForm:true,
                                    currentCfdiComplement: record.cfdiComplementInfo,
                                    currentTransportComplement: record.transportComplementInfo,
                                    currentStorageComplement: record.storageComplementInfo,
                                    currentInternationalComplement: record.internationalTransactionComplement,
                                    isCurrentEditable: record.isEditAllowed,
                                    inputRegisterUUID: record.inputRegisterUUID,
                                    clarifications: record.clarifications,
                                })}>
                            </Button>
                        </Popover>
                    :   
                        (record.isEditAllowed && (record.cfdiComplementInfo !== null || record.storageComplementInfo !== null || record.transportComplementInfo !== null || record.internationalTransactionComplement !== null || record.clarifications !== null)) ?
                            <div>
                                <Popover content="Editar información de complemento">
                                    <Button icon={<EditOutlined />} onClick={() => 
                                        handleAddComplement({
                                            mainView:false,
                                            addComplementForm:true,
                                            currentCfdiComplement: record.cfdiComplementInfo,
                                            currentTransportComplement: record.transportComplementInfo,
                                            currentStorageComplement: record.storageComplementInfo,
                                            currentInternationalComplement: record.internationalTransactionComplement,
                                            isCurrentEditable: record.isEditAllowed,
                                            inputRegisterUUID: record.inputRegisterUUID,
                                            clarifications: record.clarifications,
                                        })}>
                                    </Button>
                                </Popover>
                                <Popover content="Eliminar información de complemento">
                                    <Button icon={<DeleteOutlined />} onClick={() => 
                                        this.handleDelete({
                                            inputRegisterUUID: record.inputRegisterUUID,
                                            currentCfdiComplement: record.cfdiComplementInfo,
                                            currentTransportComplement: record.transportComplementInfo,
                                            currentStorageComplement: record.storageComplementInfo,
                                            currentInternationalComplement: record.internationalTransactionComplement,
                                            cfdiComplementRegisterType: record.cfdiComplementRegisterType,
                                            clarifications: record.clarifications,
                                            delete: true,
                                        })} type={'danger'}></Button>
                                </Popover>
                            </div>
                        :
                            (!record.isEditAllowed && (record.cfdiComplementInfo !== null || record.storageComplementInfo !== null || record.transportComplementInfo !== null || record.internationalTransactionComplement !== null)) ?
                                <Popover content="Ver información de complemento">
                                    <Button icon={<EyeOutlined />} onClick={() => 
                                        handleAddComplement({
                                            mainView:false,
                                            addComplementForm:true,
                                            currentCfdiComplement: record.cfdiComplementInfo,
                                            currentTransportComplement: record.transportComplementInfo,
                                            currentStorageComplement: record.storageComplementInfo,
                                            currentInternationalComplement: record.internationalTransactionComplement,
                                            isCurrentEditable: record.isEditAllowed,
                                            inputRegisterUUID: record.inputRegisterUUID,
                                            clarifications: record.clarifications,
                                        })}>
                                    </Button>
                                </Popover>
                            :
                                <div>
                                    <span>Sin complemento asociado</span>
                                </div>
                }
            
        ];
    }

    handleCancel = () => {
        this.setState({ visible: false });
    }

    handleOk = () => {
        this.setState({ loading: true });

        dispensaryServices.put('complements/editComplement', this.state.infoToDelete)
            .then(response => {
                const jsonResponse = JSON.parse(JSON.stringify(response));
                if (jsonResponse.hasOwnProperty('response')) {
                    message.error(jsonResponse.response.data);
                } else {
                    this.setState({ loading: false, visible: false });
                    message.success("Complemento eliminado");
                    // window.location.reload();
                    this.props.onloadRegister();
                }
            });
    }

    handleDelete = (params) => {
        this.setState({visible:true});

        const filters = {
            //General info
            receptionUUID: params.inputRegisterUUID,
            transactionType: this.props.transactionType,

            cfdiComplementRegisterType: params.cfdiComplementRegisterType,
            
            //CFDI Complement Data
            isCfdiComplement: false,
            currentcfdiComplementUUID: params.currentCfdiComplement !== null ? params.currentCfdiComplement.id : null,
            
            //Transport Complement Data
            isTransportComplement: false,
            transportComplementUUID: params.currentTransportComplement !== null ? params.currentTransportComplement.id : null,
            
            //Storage Complement Data
            isStorageComplement: false,
            storageComplementUUID: params.currentStorageComplement !== null ? params.currentStorageComplement.id : null,
            
            //International Complement Data
            isInternationalComplement: false,
            internationalComplementUUID: params.currentInternationalComplement !== null ? params.currentInternationalComplement.id : null,

            delete: (params.delete !== undefined) ? params.delete : false,
        };
        this.setState({infoToDelete: filters});
    }

    handleSave = (row) => {
        const newData = {
            registerUUID: row.inputRegisterUUID,
            policyDate: row.inputRegisterPolicyReceptionDate,
            policyFolio: row.inputRegisterPolicyFolio,
            documentedVolume: row.inputRegisterProviderDocumentedVolume
        };
        dispensaryServices.put('inputEquipment/editRegisters', newData)
            .then(response => {
                console.log('PUT', response);
                this.props.onEditReception();
            });
    };

    titleTableReceptions = () => <span style={{ marginLeft: '10px' }} >Presione <PlusSquareOutlined /> para ver movimientos de la recepción</span>

    columnsNestedTable = [
        {
            title: 'Volumen',
            dataIndex: 'volume',
            width: '20%',
            render: (text) => `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },{
            title: 'Masa',
            dataIndex: 'mass',
            width: '20%',
            render: (text) => `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }, {
            title: 'Folio del Movimiento',
            dataIndex: 'folio',
            width: '20%',
        }, {
            title: 'Fecha de inicio del movimiento',
            dataIndex: 'initialDate',
            width: '20%',
            render: (date) => moment(date).format('YYYY/MM/DD HH:mm:ss')
        }, {
            title: 'Fecha de fin del movimiento',
            dataIndex: 'endDate',
            width: '20%',
            render: (date) => moment(date).format('YYYY/MM/DD HH:mm:ss')
        }
    ];

    render() {
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };

        return (
            <div>
                <Table
                    title={this.titleTableReceptions}
                    components={components}
                    rowClassName={() => 'editable-row'}
                    dataSource={this.props.dataSource}
                    columns={this.columns(this.props.handleAddComplement)}
                    rowKey={record => record.inputRegisterUUID}
                    expandedRowRender={record => <Table className="nested-table" columns={this.columnsNestedTable} dataSource={receptionMovementDetails(record.inputRegisterMovements)} pagination={false} />}
                    pagination={false}
                    scroll={{ x: true }}
                />
                <Modal
                    open={this.state.visible}
                    title="Atención"
                    onCancel={this.handleCancel}
                    footer={[
                        <Button className="back-button" key="cancel-delete" onClick={this.handleCancel}>Cancelar</Button>,
                        <Button type="danger" key="delete" loading={this.state.loading} onClick={this.handleOk}>Confirmar</Button>
                    ]}
                >
                    <p>¿Está seguro que desea eliminar este complemento? Una vez realizada la acción, la información no podrá ser recuperada.</p>
                </Modal>
            </div>
        );
    }
}

const EditableRecepcionTableForm = Form.create({ name: 'editableReceptionsTable' })(EditableTable);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(EVM_DETAILS, permissions)
});

export default connect(mapStateToProps)(EditableRecepcionTableForm);
