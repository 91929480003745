import React from 'react';
import { Form } from '@ant-design/compatible';

import { Modal, Input, Button, Select } from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import TextArea from 'antd/lib/input/TextArea';
import { getSelectedDistributionPoint } from '../../../common/components/widgets/DistributionPoint/distributionPointStorage';

const { Option } = Select;

const HoseEditForm = Form.create({ name: 'hoseEditForm_into_modal' })(

    class extends React.Component {
        _isMount = false;
        state = {
            hoseIdentificatorVS: {},
            auditableHoseIdentificatorVS: {},
            hoseCodeExist: false,
            distributionPointId: getSelectedDistributionPoint(),
        };

        componentDidMount() {
            this._isMount = true;
            window.addEventListener("distributionPointChange", (e) => {
                if (this._isMount) {
                    this.setState({ distributionPointId: getSelectedDistributionPoint() }, () => {
                    });
                }
            });
        }
        componentWillUnmount() {
            this._isMount = false;
        }

        validateHoseIdentificator(hoseCode) {
            return {
                validateStatus: 'error',
                errorMsg: `La clave de manguera ${hoseCode} ya existe.`,
            };
        }

        validateAuditableHoseIdentificator(hoseCode) {
            return {
                validateStatus: 'error',
                errorMsg: `La clave auditable de manguera ${hoseCode} ya existe.`,
            };
        }

        validateAuditableHoseLength(hoseCode) {
            return {
                validateStatus: 'error',
                errorMsg: `La clave auditable de manguera debe contener 8 caracteres con el siguiente formato: MGA- seguido de 4 números.`,
            };
        }

        handleOnChange = (event) => {
            this.setState({ hoseIdentificatorVS: {} });
            const hoseCode = event.target.value.toUpperCase();

            if (hoseCode.length === 2) {
                dispensaryServices.get(`hose/checkIfHoseKeyExist/${hoseCode}`)
                    .then(response => {
                        if (response === true) {
                            this.setState({
                                hoseIdentificatorVS: {
                                    ...this.validateHoseIdentificator(hoseCode),
                                }
                            });
                        }
                        this.setState({ hoseCodeExist: response });
                    });
            }
        }

        handleOnAuditableChange = (event) => {
            this.setState({ auditableHoseIdentificatorVS: {} });
            const auditableHoseCode = event.target.value;
            const completeAuditableHoseCode = this.props.hose.auditableDispensaryCode.concat("-").concat(event.target.value);
            const reg = /^(DISP-)[0-9]{4}(-MGA-)[0-9]{4}$/;

            if (completeAuditableHoseCode.length === 18) {
                if (reg.test(completeAuditableHoseCode) || completeAuditableHoseCode === '') {
                    dispensaryServices.get(`hose/checkIfAuditableHoseKeyExist/${completeAuditableHoseCode}`)
                        .then(response => {
                            if (response === true) {
                                this.setState({
                                    auditableHoseIdentificatorVS: {
                                        ...this.validateAuditableHoseIdentificator(auditableHoseCode),
                                    }
                                });
                            }
                            this.setState({ hoseCodeExist: response });
                        });
                } else {
                    this.setState({ hoseCodeExist: true });
                    this.setState({
                        auditableHoseIdentificatorVS: {
                            ...this.validateAuditableHoseLength(auditableHoseCode),
                        }
                    });
                }
            } else {
                this.setState({ hoseCodeExist: true });
                this.setState({
                    auditableHoseIdentificatorVS: {
                        ...this.validateAuditableHoseLength(completeAuditableHoseCode),
                    }
                });
            }
        }

        render() {
            const {
                visible, onCancel, onEdit, form, hose, dispensary
            } = this.props;
            const { getFieldDecorator } = form;
            const { hoseIdentificatorVS, auditableHoseIdentificatorVS, hoseCodeExist } = this.state;
            const optionWarehouseDropdown = types => {
                if (types === undefined || types === null)
                    return;
                return types.map(item => (
                    <Option key={item.idWareHouse} value={item.idWareHouse}>{
                        item.subproducts !== null ?
                            `${item.warehouseKey} - ${item.products.productDescription} - ${item.subproducts.description}` :
                            `${item.warehouseKey} - ${item.products.productDescription}`}
                    </Option>
                ))
            };

            return (
                <Modal
                    open={visible}
                    title={`Editar manguera ${hose.hoseCode}`}
                    onCancel={onCancel}
                    onOk={onEdit}
                    footer={[
                        <Button className="back-button" key="back" onClick={onCancel}>Cancelar</Button>,
                        <Button className="submit-button" key="submit" onClick={onEdit} disabled={hoseCodeExist}>Guardar</Button>
                    ]}
                >
                    <p>Introduce un valor a 2 caracteres para editar la manguera, puedes usar sólo números (01), sólo letras (AB) o una combinación (1A).</p>
                    <Form layout='vertical' >
                        <Form.Item style={{ display: 'none' }} label="UUID de la manguera">
                            {getFieldDecorator('idHose', {
                                initialValue: hose.hoseUUID,
                                rules: [{ required: true, message: 'Por favor introduce el UUID de la manguera' }]
                            })(
                                <Input disabled />
                            )}
                        </Form.Item>
                        <Form.Item style={{ display: 'none' }} label="Código auditable del dispensario">
                            {getFieldDecorator('auditableDispensaryCode', {
                                initialValue: dispensary.auditableDispensaryCode,
                                rules: [{ required: true, message: 'Por favor introduce el código auditable del dispensario' }]
                            })(
                                <Input disabled />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Clave de la manguera"
                            validateStatus={hoseIdentificatorVS.validateStatus}
                            help={hoseIdentificatorVS.errorMsg}
                        >
                            {getFieldDecorator('equipmentKey', {
                                initialValue: hose.hoseCode,
                                rules: [{ required: true, message: 'Por favor introduce la clave de la manguera.' }]
                            })(
                                <Input maxLength={2} onChange={this.handleOnChange} disabled />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Clave auditable de la manguera"
                            validateStatus={auditableHoseIdentificatorVS.validateStatus}
                            help={auditableHoseIdentificatorVS.errorMsg}
                        >
                            {getFieldDecorator('auditableEquipmentkey', {
                                initialValue: hose.auditableEquipmentKey,
                                rules: [{ required: true, message: 'Por favor introduce la clave auditable de la manguera.' }]
                            })(
                                <Input maxLength={8} onChange={this.handleOnAuditableChange} disabled />
                            )}
                        </Form.Item>
                        <Form.Item label="Almacén">
                            {getFieldDecorator('idWarehouse', {
                                initialValue: hose.containerId,
                                rules: [{ required: true, message: 'Por favor selecciona el almacén.' }]
                            })(
                                <Select
                                    placeholder="Selecciona un almacén"
                                >
                                    {optionWarehouseDropdown(this.props.warehouses)}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Descripción">
                            {getFieldDecorator('description', {
                                initialValue: hose.description,
                                rules: [{ required: true, message: 'Por favor selecciona la descripción dela manguera.' }]
                            })(
                                <TextArea onChange={this.handleOnChange} onBlur={this.handleOnBlur} autoSize={{ minRows: 2, maxRows: 6 }} />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);

export default HoseEditForm;
