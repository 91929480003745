import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Button, Col, Row, Spin } from 'antd';
import { ContainerAggregation } from './components';
import ContainerGauges from './components/containerGauges';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { apiUrl } from '../../app.config';
import {
    dispensaries as dispensaryServices
} from '../../services';
import {
    getPagePermissions,
    DISPENSARY_PERMISSION
} from '../../security/permissions';

import gas_pump from './../../assets/images/gas_pump.svg';
import './styles.css';
import { getSelectedDistributionPoint } from '../../common/components/widgets/DistributionPoint/distributionPointStorage';

class Containers extends Component {
    _isMount = false;
    constructor() {
        super();
        this.state = {
            mainView: true,
            addContainerForm: false,
            areThereContainers: false,
            containers: [],
            loadingContainers: false,
            distributionPointId: getSelectedDistributionPoint(),
        };
    }

    componentDidMount() {
        this._isMount = true;
        this.fetchContainers();
        this.setupWebSocket();
        window.addEventListener("distributionPointChange",(e) => {
            if(this._isMount){
                this.setState({ distributionPointId: getSelectedDistributionPoint()}, ()=>{
                    this.fetchContainers();
                });
            }
        });
    }
    componentWillUnmount() {
        this._isMount = false;
    }

    fetchContainers = () => {
        this.setState({ loadingContainers: true });
        dispensaryServices.get(`distributionPoints/${this.state.distributionPointId}/containers`)
            .then(response => {
                if (response.length > 0) {
                    this.setState({
                        areThereContainers: true,
                        containers: response
                    });
                } else {
                    this.setState({
                        areThereContainers: false,
                        containers: []
                    });
                }
                this.setState({ loadingContainers: false });
            });
    }

    setupWebSocket = () => {
        const socket = SockJS(`${apiUrl}volumetric-control`);
        this.stompClient = Stomp.over(socket);
        this.stompClient.connect({}, this.connect);
    };

    stompClient = null;

    connect = () => {
        this.stompClient.subscribe('/topic/gauges', (messageOutput) => {
            const data = JSON.parse(messageOutput.body);
            if (data.length > 0) {
                this.setState({
                    areThereContainers: true,
                    containers: data
                });
            } else {
                this.setState({
                    areThereContainers: false,
                });
            }
        });
    };

    showAddContainerForm = () => {
        this.setState({
            mainView: false,
            addContainerForm: true
        });
    }

    handleAddContainerClick = () => {
        this.fetchContainers();
        this.setState({
            mainView: true,
            addContainerForm: false
        });
    }

    handleCancelClick = (form) => {
        this.setState({
            mainView: true,
            [form]: false
        });
    }

    handleUpdateContainers = () => {
        this.fetchContainers();
    }

    onGaugeChange = () => {
        this.setState({ containers: [] });
        this.fetchContainers();
    }


    render() {
        const { pagePermissions } = this.props;

        const cardItems = this.state.containers.map((value, index) => (
            <Col key={value.containerKey} span={12} style={{ marginBottom: '24px' }}>
                <ContainerGauges onGaugeChange={this.onGaugeChange} container={value} onUpdateContainers={() => this.handleUpdateContainers()} />
            </Col>
        ));

        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loadingContainers}>
                {this.state.mainView &&
                    <Form layout='vertical' >
                        {this.state.areThereContainers ? (
                            <Col>
                                <Row style={{ marginBottom: '24px' }}>
                                    {pagePermissions.add && <Button className="customized-primary" type="primary" icon={<PlusOutlined />} onClick={this.showAddContainerForm}>Agregar contenedor</Button>}
                                </Row>
                                <Row gutter={24}>
                                    {cardItems}
                                </Row>
                            </Col>
                        ) : (
                            <div className="center-div">
                                <img src={gas_pump} alt="Dispensario" />
                                <div style={{ margin: '8px' }}>
                                    <label>Sin contenedores agregados</label>
                                </div>
                                {pagePermissions.add && <Button className="customized-primary" type="primary" icon={<PlusOutlined />} onClick={this.showAddContainerForm}>Agregar contenedor</Button>}
                            </div>
                        )}
                    </Form>
                }
                {this.state.addContainerForm && <ContainerAggregation onAddContainerClick={() => this.handleAddContainerClick()} onCancelClick={() => this.handleCancelClick('addContainerForm')} />}
            </Spin>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISPENSARY_PERMISSION, permissions)
});

export default connect(mapStateToProps)(Containers);
