import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { PlusOutlined, WarningOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Row, Col, Button, Spin, Divider, Typography, Tooltip, Modal, List } from 'antd';
import gasIcon from '../../assets/images/gas_pump.svg';
import { DISTRIBUTION_POINT_PERMISSIONS, getPagePermissions } from '../../security/permissions';
import { DistributionPointCreatorForm } from './components/DistributionPointCreatorForm';
import { CV_CONTEXT_PATH, getIsComanyAdministrator } from '../../security';
import request from '../../services/request';
import { serializeParams } from '../../common/helpers';
import DistributionPointTable from './components/DistributionPointTable';
import IconInfinite from '../../assets/images/infinite';


class DistributionPoints extends Component {
    constructor() {
        super();
        this.state = {
            distributionPoints: [],
            displayMainView: true,
            loadingSpinner: false,
            spinnerSubscription: false,
            newDistributionPointForm: false,
            isCompanyAdministrator: getIsComanyAdministrator(),
            displayPage: 0,
            displayAmount: 10,
            pagination: {},
            currentPage: 0,
            subscription: {},
            sort: "id,DESC",
            filters: "",
        };
    }

    componentDidMount() {
        this.fetchAllDistributionPoints();
        this.fetchSubscription();
    }

    onNewDistPointFormCancel = () => {
        this.fetchAllDistributionPoints();
        this.setState({
            displayMainView: true,
            newDistributionPointForm: false,
        });
    }

    showNewDistributionPointForm = () => {
        this.setState({ spinnerSubscription: true });
        request().get('subscriptions/entitlement/register').then(response => {
            this.setState({ spinnerSubscription: false }, () => {
                const subscription = response.data;
                if (subscription.entitlement) {
                    this.setState({
                        displayMainView: false,
                        newDistributionPointForm: true,
                    });
                } else {
                    Modal.error({
                        title: 'Suscripción: Excepción al registrar punto de distribución',
                        icon: <WarningOutlined />,
                        content: (<List>
                            {subscription.subscriptionDetails.subscriptionMessageList.map(error => {
                                return (<List.Item key={error}>
                                    {error}
                                </List.Item>)
                            })}
                        </List>),
                        onOk: () => {
                            window.location.replace(`${CV_CONTEXT_PATH}/subscription`);
                        }
                    })
                }
            });
        }).finally(() => { this.setState({ spinnerSubscription: false }); });
    }

    fetchSubscription = () => {
        request().get('subscription/subscriptionDetail').then(response => {
            this.setState({
                subscription: response.data
            });
        });
    }
    isSubscriptionOnFreeMode() {
        return (this.state.subscription.subscriptionMessages &&
            this.state.subscription.subscriptionMessages.find((x) => x === "SUBSCRIPTIONS_ON_FREE_MODE"));
    }

    fetchAllDistributionPoints = () => {
        this.setState({ loadingSpinner: true }, () => {
            let params = {
                page: this.state.displayPage,
                size: this.state.displayAmount,
                sort: this.state.sort,
                ...this.state.filters,
            };
            request().get(`distributionPointsPage${serializeParams(params)}`, params).then((response) => {
                const pagination = { ...this.state.pagination };
                pagination.total = response.data.totalElements;
                this.setState({
                    distributionPoints: response.data.content,
                    pagination,
                    currentPage: this.state.displayPage + 1,
                });
            }).finally(() => {
                this.setState({ loadingSpinner: false });
            });
        });
    }

    mapDBNames = (dtoName) => {
        if (dtoName === undefined)
            return "id"
        if (dtoName && dtoName.toLowerCase() === "subsidiary") {
            return "name";
        }
        return dtoName;
    }

    mapSortKeys = (field, order) => {
        let key = "id";
        if (field) {
            key = field;
            if (field.toLowerCase() === "subsidiary")
                key = "name";
        }
        const sort = `${key},${order && order.includes("asc") ? 'ASC' : 'DESC'}`;
        return sort;
    }

    handleTableChange = (pagination, filters, sorter) => {
        const pager = { ...this.state.pagination };
        pager.current = pagination.current;

        let textFilter = { ...this.state.filters };
        let filterCopy = { ...filters };

        if (textFilter.textFilter !== null && textFilter.textFilter !== undefined) {
            filterCopy.textFilter = textFilter.textFilter;
        }

        if (filterCopy.entitled && typeof filterCopy.entitled === "object" && filterCopy.entitled.length === 1) {
            filterCopy.entitled = filterCopy.entitled[0];
        } else {
            delete filterCopy.entitled;
        }

        this.setState({
            pagination: pager,
            displayPage: pagination.current - 1,
            sort: this.mapSortKeys(sorter.field, sorter.order),
            filters: filterCopy,
        }, () => {
            this.fetchAllDistributionPoints();
        });
    };


    handleSearchChange = (value) => {
        let filterCopy = { ...this.state.filters };
        filterCopy.textFilter = value;
        this.setState({
            filters: filterCopy,
            displayPage: 0,
            displayAmount: 10,
            pagination: {
                current: 1
            },
            currentPage: 0,
        }, () => {
            this.fetchAllDistributionPoints();
        });

    }

    render() {
        return (
            <Fragment>
                <Spin size="large" tip="Cargando..." spinning={this.state.loadingSpinner}>
                    <div>
                        <div>
                            {this.state.displayMainView &&
                                <div>
                                    {this.state.distributionPoints !== undefined ? (
                                        <div>
                                            <div style={{ marginBottom: '24px' }}>
                                                <Row>
                                                    <Col lg={12} xs={24}>
                                                        <Button
                                                            hidden={!this.state.isCompanyAdministrator}
                                                            className="customized-primary"
                                                            type="primary"
                                                            icon={<PlusOutlined />}
                                                            loading={this.state.spinnerSubscription}
                                                            onClick={this.showNewDistributionPointForm}>
                                                            Agregar Punto de Distribución
                                                        </Button>
                                                    </Col>
                                                    <Col lg={12} xs={24} style={{ display: 'flex', placeContent: 'flex-end' }}>
                                                        <Tooltip title="Suscripción">

                                                            <Typography.Title code level={3}>
                                                                <Typography.Text style={{ fontSize: '17px' }}>
                                                                    Puntos de Distribución &nbsp;
                                                                </Typography.Text>
                                                                {this.state.subscription.activeDistributionPoints}/{this.isSubscriptionOnFreeMode() ? <IconInfinite /> : this.state.subscription.equipmentsNumber}
                                                            </Typography.Title>
                                                        </Tooltip>

                                                    </Col>
                                                </Row>
                                            </div>
                                            <Row gutter={24}>
                                                <DistributionPointTable
                                                    distributionPointList={this.state.distributionPoints}
                                                    refreshOnUpdate={() => { this.fetchAllDistributionPoints(); this.fetchSubscription(); }}
                                                    pagination={this.state.pagination}
                                                    handleTableChange={this.handleTableChange}
                                                    handleSearchChange={this.handleSearchChange}
                                                />
                                            </Row>
                                        </div>
                                    ) :
                                        (
                                            <div className="center-div">
                                                <img src={gasIcon} alt="Punto de Distribución" />
                                                <div style={{ margin: '8px' }}>
                                                    <label>Sin Puntos de distribución agregados</label>
                                                </div>
                                                <Button
                                                    hidden={!this.state.isCompanyAdministrator}
                                                    className="customized-primary"
                                                    type="primary"
                                                    icon={<PlusOutlined />}
                                                    onClick={this.showNewDistributionPointForm}>
                                                    Agregar Punto de Distribución
                                                </Button>
                                            </div>
                                        )}
                                </div>
                            }
                            {this.state.newDistributionPointForm && <DistributionPointCreatorForm onCancel={this.onNewDistPointFormCancel}
                                loading={this.state.loadingSpinner}
                                isConfigApplicationRegistered={false}
                                showReturnButton={true}
                                isDistributionPoint
                            />}
                        </div>
                    </div>
                    <Divider />
                </Spin>
            </Fragment>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISTRIBUTION_POINT_PERMISSIONS, permissions),
    form: Form.create()(DistributionPointCreatorForm),
});

export default connect(mapStateToProps)(DistributionPoints);
