import { CopyOutlined, FileTextOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Button, Col, List, Modal, Row, Typography } from 'antd';
import React from 'react';
import { dispensaries as dispensaryServices } from '../../../services';


import { getSelectedDistributionPoint } from '../../../common/components/widgets/DistributionPoint/distributionPointStorage';


export const formatErrors = (errores) => {
    let result = (
        <div style={{ overflow: 'auto', height: '500px' }}>
            <List
                header="Se detectaron algunas inconsistencias en el reporte Json generado"
                dataSource={errores}
                renderItem={item => {
                    let isToFormat = item.startsWith('El campo');
                    let listString = isToFormat ? item.split(' ') : [];

                    return (
                        <div>
                            {!isToFormat && <List.Item>
                                <Typography.Text type="danger"> {item}</Typography.Text>
                            </List.Item>}
                            {isToFormat && <List.Item>
                                <List.Item.Meta
                                    title={(<div><Typography.Text strong ellipsis>{listString[2]}</Typography.Text></div>)}
                                    description={(
                                        <div>
                                            {listString.slice(0, 2).join(' ').concat(' ')}
                                            <Typography.Text mark>{listString[2].concat(' ')}</Typography.Text>
                                            {listString.slice(3).join(' ')}
                                        </div>)}
                                />
                            </List.Item>}
                        </div>
                    )
                }}
            />
        </div>
    );
    return result;
}

export const saveJsonToFile = (jsonFile, fileType, fileName) => {
    const a = document.createElement('a');
    if (fileType === 'application/zip' || fileType === 'text/csv') {
        a.href = URL.createObjectURL(new Blob([jsonFile]));
    } else if (fileType === 'application/json') {
        a.href = URL.createObjectURL(new Blob([JSON.stringify(jsonFile, undefined, 2)], { type: fileType }));
    } else {
        let binaryData = [];
        binaryData.push(jsonFile);
        a.href = URL.createObjectURL(new Blob(binaryData), {type: fileType});
    }
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

export const downloadReport = (reportName) => {
    dispensaryServices.post('inventoryControl/jsonReport/download', { reportName, distributionPointId: getSelectedDistributionPoint() })
        .then(response => {
            saveJsonToFile(response, 'application/json', reportName);
        }).finally(() => {
    });
};

export const openJsonInModal = (response, action, title = 'Json Report') => {
    const { message, json, errors, reportName } = response;
    let showErrors = false;
    let jsonObject;
    if (json === undefined || json === null) {
        jsonObject = message;
    } else {
        try {
            jsonObject = JSON.stringify(JSON.parse(json), undefined, 2);
        } catch (e) {
            jsonObject = JSON.stringify(json, undefined, 2);
        }
    }
    switch (action) {
        case 'showJson':
            break;
        case 'showErrors':
            showErrors = errors !== null && errors !== undefined;
            break;
        default:
            showErrors = errors !== null && errors !== undefined;
    }


    Modal.confirm({
        icon: <FileTextOutlined />,
        title: title,
        content: (
            <div>
                {(showErrors && <div>
                    <Form layout='vertical' >
                        <Row gutter={24}>
                            <Col span={12}>
                                <pre style={{ height: '500px' }}>
                                    <code>{jsonObject}</code>
                                </pre>
                            </Col>
                            <Col span={12} hidden={ errors.length === 0}>
                                {formatErrors(errors)}
                            </Col>
                            <Col span={12} hidden={ errors.length !== 0}>
                                <span>
                                    La validación del reporte Json no encontró problemas.
                                </span>
                            </Col>
                        </Row>
                    </Form>
                </div>)}
                {(!showErrors && <div>
                    <pre style={{ height: '500px' }}>
                        <code>{jsonObject}</code>
                    </pre>
                </div>)}
                <div>
                    <Button
                        type="primary"
                        icon={<CopyOutlined />}
                        style={{ width: '100%' }}
                        onClick={() => { navigator.clipboard.writeText(jsonObject); message.success("Copiado") }}>
                        Copiar al Portapapeles
                    </Button>
                </div>
            </div>),
        width: '60%',
        centered: true,
        okText: 'Descargar Reporte Json',
        cancelText: 'Cerrar',
        onOk: () => {
            downloadReport(reportName);
        },
    });
}