import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Modal, Button, Input } from 'antd';

const { TextArea } = Input;

const ManualRegistrationForm = Form.create({ name: 'manualRegistrationForm_into_modal' })(

    class extends Component {
        constructor() {
            super();
            this.state = {
                counter: 0
            };
        }

        handleTextChange = event => {
            this.setState({
                counter: event.target.value.length
            });
        }

        render() {
            const { visible, onCancel, onRegister, form } = this.props;
            const { getFieldDecorator } = form;

            return (
                <Modal
                    open={visible}
                    title="Agregar registro manual"
                    onCancel={onCancel}
                    footer={[
                        <Button className="back-button" key="back" onClick={onCancel}>Cancelar</Button>,
                        <Button className="submit-button" key="submit" onClick={onRegister}>Agregar</Button>
                    ]}
                >
                    <p>Describe brevemente el motivo del registro.</p>
                    <Form layout='vertical' >
                        <Form.Item label="Descripción">
                            {getFieldDecorator('eventDescription', {
                                rules: [{ required: true, message: 'Por favor introduce una descripción' }]
                            })(
                                <TextArea maxLength={150} placeholder="Escribe aquí" autosize={{ minRows: 4, maxRows: 5 }} onChange={this.handleTextChange} />
                            )}
                        </Form.Item>
                    </Form>
                    <div style={{ textAlign: 'right', color: '#A3A3A3' }}>{`${this.state.counter} / 150`}</div>
                </Modal>
            );
        }
    }
);

export default ManualRegistrationForm;
