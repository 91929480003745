import React, { Component } from 'react';
import { connect } from 'react-redux';
import { VolumeMeasurerEdit } from './components';

import { Form } from '@ant-design/compatible';


import { Row } from 'antd';

import {
    getPagePermissions,
    CONFIG_APPLICATION
} from '../../security/permissions';

class VolumeMeasurerConfig extends Component {

    render() {
        return (
            <Row>
                <VolumeMeasurerEdit />
            </Row>
        );
    }

}

const VolumeMeasurerConfigForm = Form.create({ name: 'volumeMeasurerConfig' })(VolumeMeasurerConfig);


const mapStateToProps = ({ userData, userData: { permissions } }) => {
    return {
        userData,
        exportReportsPermissions: getPagePermissions(CONFIG_APPLICATION, permissions)
    };
};

export default connect(mapStateToProps)(VolumeMeasurerConfigForm);
