import React from 'react';
import { Form } from '@ant-design/compatible';

import { Modal, Input, Button } from 'antd';

const HoseDeleteForm = Form.create({ name: 'hoseDeleteForm_into_modal' })(

    class extends React.Component {
        render() {
            const {
                visible, onCancel, onDelete, form, hose
            } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Modal
                    open={visible}
                    title={`Eliminar manguera ${hose.hoseCode}`}
                    onCancel={onCancel}
                    onOk={onDelete}
                    footer={[
                        <Button className="back-button" key="back" onClick={onCancel}>Cancelar</Button>,
                        <Button className="submit-button" key="submit" onClick={onDelete}>Eliminar</Button>
                    ]}
                >
                    <p>{`¿Estás seguro que quieres eliminar la manguera ${hose.hoseCode}?`}</p>
                    <Form layout='vertical' hidden>
                        <Form.Item label="Id de la manguera">
                            {getFieldDecorator('idHose', {
                                initialValue: hose.hoseUUID,
                                rules: [{ required: true, message: 'Por favor introduce el id de la manguera' }]
                            })(
                                <Input disabled />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);

export default HoseDeleteForm;
