import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Row, Col, Input, Select, Button, message, Spin } from 'antd';
import {
    users as usersServices
} from '../../../services';

const { Option } = Select;
const success = () => {
    message.success('Usuario registrado exitosamente');
};

function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class UserRegistration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadingUsers: false,
        }
    };

    componentDidMount() {
        this.props.form.validateFields();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                values.firstUser = false;
                console.log('Received values of form: ', values);
                this.setState({ loadingUsers: true });
                usersServices.post('user', values)
                    .then(response => {
                        const jsonResponse = JSON.parse(JSON.stringify(response));
                        if (jsonResponse.hasOwnProperty('response')) {
                            message.error(jsonResponse.response.data);
                        } else {
                            success();
                            this.props.onAddUserClick();
                        }
                        this.setState({ loadingUsers: false });
                    });
            }
        });
    }

    render() {
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;
        const { onCancelClick } = this.props;

        // Only show error after a field is touched.
        const userNameError = isFieldTouched('name') && getFieldError('name');
        const emailError = isFieldTouched('email') && getFieldError('email');
        const roleError = isFieldTouched('role') && getFieldError('role');

        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loadingUsers}>

                <Form layout='vertical' onSubmit={this.handleSubmit}>
                    <div>
                        <label>Llena los campos a continuación para registrar un nuevo usuario.</label>
                    </div>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Nombre"
                                validateStatus={userNameError ? 'error' : ''}
                                help={userNameError || ''}
                            >
                                {getFieldDecorator('name', {
                                    rules: [{
                                        required: true, message: 'Por favor introduce un nombre de usuario.'
                                    }],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Correo electrónico"
                                validateStatus={emailError ? 'error' : ''}
                                help={emailError || ''}
                            >
                                {getFieldDecorator('email', {
                                    rules: [{
                                        type: 'email', message: 'Correo electrónico no válido'
                                    }, {
                                        required: true, message: 'Por favor introduce un correo electrónico.'
                                    }],
                                })(
                                    <Input />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Asignar perfil"
                                validateStatus={roleError ? 'error' : ''}
                                help={roleError || ''}
                            >
                                {getFieldDecorator('role', {
                                    rules: [{
                                        required: true, message: 'Por favor selecciona un perfil.'
                                    }],
                                })(
                                    <Select
                                        placeholder="Seleccionar"
                                    >
                                        <Option value="ADMINISTRATOR">Administrador</Option>
                                        <Option value="SUPERVISOR">Supervisor</Option>
                                        <Option value="OPERATOR">Operador</Option>
                                        <Option value="AUDITOR">Auditor fiscal</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item>
                                <Button className="customized-primary" type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>Agregar usuario</Button>
                                <Button className="customized-default" style={{ marginLeft: 16 }} onClick={onCancelClick}>Cancelar</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        );
    }
}

export default Form.create({ name: 'userRegistration' })(UserRegistration);
