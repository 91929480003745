import React, { Component } from 'react';
import moment from 'moment';
import dayjs from "dayjs";

import {
    CheckCircleOutlined,
    DownloadOutlined,
    EyeOutlined,
    PlusOutlined,
    ProfileOutlined,
    ReadOutlined,
    WarningOutlined,
} from '@ant-design/icons';

import { Form, Icon as LegacyIcon } from '@ant-design/compatible';

import {
    Divider,
    Tabs,
    DatePicker,
    Row,
    Col,
    Button,
    Table,
    Spin,
    Tooltip,
    Modal,
    message,
    Select,
    Switch,
} from 'antd';
import { getSelectedDistributionPoint, getSelectedDistributionPointName } from '../../common/components/widgets/DistributionPoint/distributionPointStorage';
import { dispensaries as dispensaryServices } from '../../services';
import { openJsonInModal, saveJsonToFile } from '../stockControl/components/jsonViewer';
import { getRemoteIp } from '../../common/helpers/remoteIp';
import { getToken } from '../../security';
import axios from 'axios';
import request from '../../services/request';
import { apiUrl } from 'app.config';

const { MonthPicker } = DatePicker;
class JsonReport extends Component {
    _isMount = false;
    constructor() {
        super();

        this.JSON_REPORT_SIZE = process.env.JSON_REPORT_SIZE || 10;
        this.state = {
            distributionPointId: getSelectedDistributionPoint(),
            distributionPointName: getSelectedDistributionPointName(),
            selectedDate: dayjs().set('date', 1),
            isOpenYearDatepicker: false,
            isDailySelected: true,
            isLoading: false,
            reportsInfo: [],
            selectedReports: [],
        };
    }
    componentDidMount() {
        this._isMount = true;
        this.searchReports();
        window.addEventListener('distributionPointChange', (e) => {
            if (this._isMount) {
                if (this.state.distributionPointId === getSelectedDistributionPoint())
                    return;
                this.setState({ distributionPointId: getSelectedDistributionPoint(), distributionPointName: getSelectedDistributionPointName(), selectedReports: [] }, () => {
                    this.searchReports();
                });
            }
        });
    }

    componentWillUnmount() {
        this._isMount = false;
    }

    searchReports = () => {
        this.setState({ isOpenYearDatepicker: false, isLoading: true });

        const filters = {
            startDate: (this.state.isDailySelected)
                ? this.state.selectedDate.format('YYYY-MM-DD')
                : this.state.selectedDate.startOf('year').format('YYYY-MM-DD'),
            endDate: (this.state.isDailySelected)
                ? this.state.selectedDate.endOf('month').format('YYYY-MM-DD')
                : this.state.selectedDate.endOf('year').format('YYYY-MM-DD'),
            reportType: this.state.isDailySelected ? 'DIARIO' : 'MENSUAL',
            distributionPointId: this.state.distributionPointId
        };

        dispensaryServices.post('inventoryControl/getJsonReportList', filters)
            .then(response => {
                this.setState({
                    reportsInfo: response,
                });
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        if (this.state.isDailySelected) {
            this.columns.pop();
        } else {
            this.columns.push({
                title: 'Enviado al SAT',
                dataIndex: 'alreadySent',
                width: '12%',
                render: (value, record, index) =>
                    (<Switch
                        disabled={value || record.reportType === 'DIARIO'}
                        checked={value}
                        onChange={(selection) => {
                            this.changeValidation(record, selection);
                        }}
                    />),
            });
        }
    };

    newJson = (jsonFilters, type = 'DIARIO') => {
        if (jsonFilters == null) {
            jsonFilters = {
                startDate: moment().format('YYYY-MM-DD'),
                reportType: type,
                dateToCreateJson: dayjs().subtract(1, 'day')
            };
        }
        jsonFilters.distributionPointId = this.state.distributionPointId;
        Modal.destroyAll();
        Modal.confirm({
            title: 'Generación de Reporte Json',
            icon: <PlusOutlined />,
            content: (
                <div>
                    <div>
                        <Form layout='vertical' >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label="Tipo de reporte">
                                        <Select
                                            onChange={(value) => {
                                                jsonFilters.reportDate = (value === 'DIARIO')
                                                    ? [moment().set('date', 1), moment().endOf('month')]
                                                    : [moment().startOf('year'), moment().endOf('year')];
                                                jsonFilters.reportType = value;
                                                this.newJson(jsonFilters, value);
                                            }}
                                            defaultValue={jsonFilters.reportType}
                                        >
                                            <Select.Option key={'DIARIO'} value={'DIARIO'}>{'Reporte JSON diario'}</Select.Option>
                                            <Select.Option key={'MENSUAL'} value={'MENSUAL'}>{'Reporte JSON mensual'}</Select.Option>
                                        </Select>

                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item label="Fecha del reporte a construir">
                                        <div hidden={jsonFilters.reportType === 'MENSUAL'}>
                                            <DatePicker
                                                onChange={(day) => {
                                                    jsonFilters.dateToCreateJson = dayjs(day);
                                                }}
                                                disabledDate={(current) => current > dayjs().subtract(1, 'days')}
                                                format={'Do MMMM YYYY'}
                                                mode={'date'}
                                                defaultValue={jsonFilters.dateToCreateJson}
                                                style={{ width: '100%' }}
                                                allowClear={false}
                                            />
                                        </div>
                                        <div hidden={jsonFilters.reportType === 'DIARIO'}>
                                            <MonthPicker
                                                onChange={(month) => {
                                                    jsonFilters.dateToCreateJson = dayjs(month).startOf('month');
                                                }}
                                                disabledDate={(current) => current > dayjs().startOf('month')}
                                                format="MMMM YYYY"
                                                defaultValue={jsonFilters.dateToCreateJson}
                                                mode={'month'}
                                                style={{ width: '100%' }}
                                                allowClear={false}
                                            />
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            ),
            width: '40%',
            centered: true,
            okText: 'Generar Reporte Json',
            cancelText: 'Cerrar',
            onOk: () => {
                this.setState({ isLoading: true });
                if (jsonFilters.reportType === 'MENSUAL') {
                    jsonFilters.dateToCreateJson = dayjs(jsonFilters.dateToCreateJson).startOf('month');
                }
                jsonFilters.startDate = jsonFilters.dateToCreateJson.format('YYYY-MM-DD');
                dispensaryServices.post('inventoryControl/jsonReport/generate', jsonFilters)
                    .then(response => {
                        openJsonInModal(response, undefined, `Json ${jsonFilters.reportType.toLowerCase()} - ${jsonFilters.startDate}`);
                    }).catch((error) => {
                        message.error(error.response.data.message);
                    }).finally(() => {
                        this.setState({ isLoading: false });
                        this.searchReports();
                    });
            },
        });
    }

    downloadReport = (urlComplement, reportName, download, action) => {
        this.setState({ isOpenYearDatepicker: false, isLoading: true });
        dispensaryServices.post(`inventoryControl/jsonReport/${urlComplement}`, { reportName, distributionPointId: this.props.distributionPointId })
            .then(response => {
                (download)
                    ? saveJsonToFile(response, 'application/json', reportName)
                    : openJsonInModal(response, action, `Json Report - ${reportName}`);
            }).finally(() => {
                this.setState({ isLoading: false });
            });
    };

    downloadSelection = () => {
        this.setState({ isOpenYearDatepicker: false, isLoading: true });
        getRemoteIp().then(ip => {
            axios({
                url: `${apiUrl}inventoryControl/downloadJsonReportZip`,
                method: 'POST',
                headers: {
                    Authorization: getToken(),
                    originIp: ip
                },
                data: {
                    reportNames: this.state.selectedReports.map((rp) => rp.reportName),
                    distributionPointId: this.props.distributionPointId
                },
                responseType: 'blob',
            }).then((response) => {
                const fileName = 'Json-Reports-'.concat(moment().format('YYYY-MM-DD').concat('.zip'));
                saveJsonToFile(response.data, 'application/zip', fileName);
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        });
    }

    changeValidation = (jsonReportRecord, selection) => {
        const verifiedString = selection ? 'Enviado al SAT' : 'No enviado al SAT';
        Modal.confirm({
            title: '¿Quieres marcar como '.concat(verifiedString).concat(' este reporte Json?'),
            width: '65%',
            centered: true,
            icon: <WarningOutlined />,
            maskClosable: true,
            content: (
                <div>
                    <p>
                        ¿Estas seguro de querer marcar este reporte Json como {verifiedString}?
                    </p>
                    <p hidden={!selection}>
                        Una vez confirmado este cambio, muchas funciones respecto al control de existencias no estarán disponibles
                        para este {(jsonReportRecord.reportType === 'DIARIO')
                            ? `día: ${moment(jsonReportRecord.creationDate).format('DD MMMM YYYY')}`
                            : `mes: ${moment(jsonReportRecord.creationDate).format('MMMM YYYY')}`
                        }

                    </p>
                    <div>
                        <ul>
                            <li>
                                El proceso para re-calcular existencias no estará disponible
                            </li><li>
                                El proceso de regeneración de reportes Json para el dia en cuestión no estará disponible
                            </li>
                        </ul>
                    </div>
                </div>),
            okText: 'Estoy seguro de querer cambiar el estatus del reporte Json',
            cancelText: 'Cancelar',
            onOk: () => {
                request().post(`inventoryControl/jsonReport/${jsonReportRecord.idReport}/verified/${selection}`).then((response) => {
                    message.info(response.data);
                }).catch((error) => {
                    console.debug(error.response.data);
                    const array = error.response.data.message.split(',');
                    openJsonInModal(array, 'No es posible marcar este reporte como '.concat(verifiedString));
                })
                    .finally(() => {
                        this.searchReports();
                    });
            }
        });
    }

    columns = [
        {
            title: 'Fecha del reporte',
            dataIndex: 'creationDate',
            width: '10%',
            render: (date) => moment(date).format('YYYY/MM/DD')
        }, {
            title: 'Nombre del Reporte',
            dataIndex: 'reportName',
            width: '30%',
            render: (text) => text
        }, {
            title: 'Tipo de reporte',
            dataIndex: 'reportType',
            render: (text) => text
        }, {
            title: 'Punto de Distribución',
            render: () => this.state.distributionPointName,
        }, {
            title: 'Descargar',
            dataIndex: 'download',
            render: (text, record) => <Button icon={<DownloadOutlined/>} onClick={() => this.downloadReport('download', record.reportName, true)} />
        }, {
            title: 'Preview',
            dataIndex: 'preview',
            render: (text, record) => (<Tooltip title={record.sizeJsonMB > window._env_.JSON_REPORT_SIZE ? 'Archivo Json sobre pasa el limite para visualizarlo, Disponible solo DESCARGA' : ''}>
                <Button icon={<EyeOutlined/>} onClick={() => this.downloadReport('preview', record.reportName, false, 'showJson')} disabled={record.sizeJsonMB > window._env_.JSON_REPORT_SIZE} />
            </Tooltip>)
        }, {
            title: <Tooltip title="Indica si al momento de construir el reporte Json, hubo inconsistencias contra el schema report">Warnings</Tooltip>,
            dataIndex: 'errors',
            render: (text, record) => (<Tooltip title={text === '[]' ? 'Sin errores' : 'Ver inconsistencias en el reporte'}>
                <Button
                    icon={text === '[]' ? <CheckCircleOutlined /> : <WarningOutlined />}
                    style={text === '[]' ? { color: 'green', fontSize: '20px' } : { color: 'orange', fontSize: '20px' }}
                    onClick={() => {
                        if (text !== '[]') {
                            this.downloadReport('preview', record.reportName, false, 'showErrors');
                        }
                    }}
                />
            </Tooltip>)
        }, {
            title: 'Enviado al SAT',
            dataIndex: 'alreadySent',
            width: '10%',
            render: (value, record, index) =>
                (<Switch
                    disabled={value || record.reportType === 'DIARIO'}
                    checked={value}
                    onChange={(selection) => {
                        this.changeValidation(record, selection);
                    }}
                />),
        }
    ];

    render() {
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({ selectedReports: selectedRows, isOpenYearDatepicker: false }, () => { });
            }
        };
        return (
            <div>
                <div>
                    Por favor selecciona el tipo de reporte a consultar
                </div>
                <div>
                    <Form layout='vertical' >
                        <Row gutter={24}>
                            <Col span={18}>
                                <Tabs
                                    defaultActiveKey="1"
                                    onChange={(activeKey) => {
                                        this.setState({ isDailySelected: activeKey === '1', selectedReports: [] }, () => {
                                            this.searchReports();
                                        });
                                    }}
                                >
                                    <Tabs.TabPane
                                        tab={
                                            <span>
                                                <ProfileOutlined />
                                                {" "}
                                                Json Diarios
                                            </span>
                                        }
                                        key="1"
                                    >
                                        <Form.Item label="Consultar periodo">
                                            <MonthPicker
                                                onChange={(month) => {
                                                    this.setState({ selectedDate: month }, () => {
                                                        this.searchReports();
                                                    });
                                                }}
                                                disabledDate={(current) => current > dayjs()}
                                                format="MMMM YYYY"
                                                value={this.state.selectedDate}
                                                mode={'month'}
                                                allowClear={false}
                                            />
                                        </Form.Item>
                                    </Tabs.TabPane>
                                    <Tabs.TabPane
                                        tab={
                                            <span>
                                                <ReadOutlined />
                                                {' '}
                                                Json Mensuales
                                            </span>
                                        }
                                        key="2"
                                    >
                                        <Form.Item
                                            label="Consultar periodo"
                                            onClick={() => {
                                                this.setState({ isOpenYearDatepicker: !this.state.isOpenYearDatepicker });
                                            }}
                                        >
                                            <DatePicker
                                                onPanelChange={(year) => {
                                                    this.setState({ selectedDate: year, isOpenYearDatepicker: false, selectedReports: [] }, () => {
                                                        this.searchReports();
                                                    });
                                                }}
                                                open={this.state.isOpenYearDatepicker}
                                                disabledDate={(current) => current > moment()}
                                                value={this.state.selectedDate}
                                                format="YYYY"
                                                mode={'year'}
                                                shouldCloseOnSelect
                                                allowClear={false}
                                                onOk={() => { this.setState({ isOpenYearDatepicker: false }); }}
                                            />
                                        </Form.Item>

                                    </Tabs.TabPane>
                                </Tabs>
                            </Col>
                            <Col span={6}>
                                <Button type="primary" icon={<PlusOutlined />} onClick={(e) => this.newJson(null, this.state.isDailySelected ? 'DIARIO' : 'MENSUAL')}>Generar JSON</Button>
                            </Col>

                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Button type="primary" icon={<DownloadOutlined />} hidden={this.state.selectedReports.length === 0} onClick={(e) => this.downloadSelection(e)}>Descargar Seleccionados</Button>
                            </Col>
                            <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Reportes Consultados</Divider>
                            <Spin spinning={this.state.isLoading}>
                                <div style={{ height: '450px' }}>
                                    <Table
                                        style={{ MaxHeight: '450px' }}
                                        className="general-table"
                                        rowSelection={{
                                            type: 'checkbox',
                                            ...rowSelection,
                                        }}
                                        columns={this.columns}
                                        dataSource={this.state.reportsInfo}
                                        rowKey={record => record.idReport}
                                        pagination={false}
                                        scroll={{ y: 400 }}
                                    />
                                </div>
                            </Spin>
                        </Row>
                    </Form>

                </div>

            </div>
        );
    }
}

export default JsonReport;
