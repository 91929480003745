import React from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';

import {
    Table,
    Input,
    Select,
    Popconfirm,
    Divider,
    message,
    Spin,
    Tooltip,
    Button,
    Modal,
} from 'antd';
import gasIcon from '../../../assets/images/gas_pump.svg';
import { getCurrentUser, getIsComanyAdministrator, getToken, logOut } from './../../../security';
import {
    users as usersServices
} from '../../../services';
import {
    getPagePermissions,
    USERS_PERMISSION
} from '../../../security/permissions';
import UserCreatorComponent from './userCreatorComponent';
import DistributionPointAssigner from './distributionPointAssigner';

const FormItem = Form.Item;
const { Option } = Select;

const success = (userName) => {
    message.success(`El usuario ${userName} ha sido eliminado`);
};

const error = (stringMessage) => {
    message.error(stringMessage);
};

const EditableContext = React.createContext();
class EditableCell extends React.Component {
    getInput = () => {
        if (this.props.inputType === 'list') {
            return (
                <Select style={{ width: '100%' }}>
                    <Option value="ADMINISTRATOR">Administrador</Option>
                    <Option value="SUPERVISOR">Supervisor</Option>
                    <Option value="OPERATOR">Operador</Option>
                    <Option value="AUDITOR">Auditor fiscal</Option>
                </Select>
            );
        }
        return <Input disabled={this.props.dataIndex === 'detailUserEmail'} />;
    };

    render() {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            ...restProps
        } = this.props;
        return (
            <EditableContext.Consumer>
                {(form) => {
                    const { getFieldDecorator } = form;
                    return (
                        <td {...restProps}>
                            {editing ? (
                                <FormItem style={{ margin: 0 }}>
                                    {getFieldDecorator(dataIndex, {
                                        rules: [{
                                            required: true,
                                            message: `Please Input ${title}!`,
                                        }],
                                        initialValue: record[dataIndex],
                                    })(this.getInput())}
                                </FormItem>
                            ) : restProps.children}
                        </td>
                    );
                }}
            </EditableContext.Consumer>
        );
    }
}

class EditableTable extends React.Component {    


    constructor(props) {
        super(props);
        this.state = { data: props.data, editingKey: '', loadingUsers: false, userData : getCurrentUser(), isCompanyAdministrator: getIsComanyAdministrator() };

        this.columns = [{
            title: 'Nombre',
            dataIndex: 'detailUserName',
            width: '25%',
            sorter: false,
            editable: true,
        }, {
            title: 'Perfil',
            dataIndex: 'detailUserProfile',
            width: '25%',
            editable: true,
            render: text => {
                switch (text) {
                    case 'ADMINISTRATOR':
                        return 'Administrador';

                    case 'SUPERVISOR':
                        return 'Supervisor';

                    case 'OPERATOR':
                        return 'Operador';

                    case 'AUDITOR':
                        return 'Auditor fiscal';

                    default:
                        break;
                }
            }
        }, {
            title: 'Correo electrónico',
            dataIndex: 'detailUserEmail',
            width: '20%',
            editable: true,
        }, {
            title: 'Puntos de distribución',
            dataIndex: 'pdd',
            width: '10%',
            editable: false,
            render: (text, record) => {
                return (
                    <div>
                        <div>
                            <Tooltip title={(record.isCompanyAdministrator)? "" :"Ver Puntos de Distribución"}>
                                <Button 
                                    disabled={record.isCompanyAdministrator}
                                    style={{border:'none', background:'none'}}
                                    onClick={()=>{
                                        Modal.info({
                                            title:'Puntos de distribución del usuario',
                                            content: (
                                                <DistributionPointAssigner
                                                    visible
                                                    actualDistributionPoints={record.distributionPoints}
                                                    isDistributionPointEditable={false}
                                                    noAllowEdit={true}
                                                    onHadleOk={(values) => {
                                                    }}
                                                    availableDistributionPoints={this.props.availableDistributionPoints}
                                                    isNewUser={false}
                                                />
                                            ),
                                            width: '60%',
                                            onOk() {},
                                        });
                                }}>
                                    <img src={gasIcon} alt='PDD' style={{height:'100%', filter: (record.isCompanyAdministrator)? 'blur(2px)' : 'blur(0px)' }}/>
                                </Button>
                            </Tooltip>
                            
                        </div>
                    </div>
                );
            }
        },{
            title: 'Acciones',
            dataIndex: 'actions',
            width: '20%',
            render: (text, record) => {
                const { editingKey } = this.state;
                const editable = this.isEditing(record);
                return (
                    <div>
                        {record.detailActive && record.detailVerified ? (
                            editable ? (
                                <span>
                                    <EditableContext.Consumer>
                                        {form => (
                                            <div>
                                                <UserCreatorComponent
                                                    showForm={editable}
                                                    onCloseModal={() => this.cancel(record.key)}
                                                    userData={record}
                                                    availableDistributionPoints={this.props.availableDistributionPoints}
                                            />
                                            </div>
                                        )}
                                    </EditableContext.Consumer>
                                </span>
                            ) : (
                                <span>
                                    {this.props.pagePermissions.edit && <a disabled={(editingKey !== '')||(record.isCompanyAdministrator && !this.state.isCompanyAdministrator)} onClick={() => this.edit(record.detailUserEmail)}>Editar</a>}
                                    <Divider type="vertical" />
                                    {this.props.pagePermissions.edit && <a disabled={editingKey !== ''||(record.isCompanyAdministrator && !this.state.isCompanyAdministrator)} onClick={() => this.handleUserStatus(record.detailUserEmail, false)}>Desactivar</a>}
                                </span>
                            )
                        ) :
                            !record.detailActive && record.detailVerified ? (
                                <span>
                                    {this.props.pagePermissions.edit && <a onClick={() => this.handleUserStatus(record.detailUserEmail, true)}>Activar</a>}
                                </span>
                            ) :
                                editable ? (
                                    <span>
                                        <EditableContext.Consumer>
                                            {form => (
                                                <div>
                                                    <UserCreatorComponent
                                                        showForm={editable}
                                                        onCloseModal={() => this.cancel(record.key)}
                                                        userData={record}
                                                        availableDistributionPoints={this.props.availableDistributionPoints}
                                                />
                                                </div>
                                            )}
                                        </EditableContext.Consumer>
                                    </span>
                                ) : (
                                    <span>
                                        {this.props.pagePermissions.edit && <a disabled={editingKey !== ''} onClick={() => this.edit(record.detailUserEmail)}>Editar</a>}
                                        <Divider type="vertical" />
                                        <Popconfirm
                                            title={`¿Seguro que quieres eliminar a ${record.detailUserName}?`}
                                            cancelText="Regresar"
                                            okText="Sí, eliminar"
                                            onConfirm={() => this.handleDelete(record)}
                                        >
                                            {this.props.pagePermissions.delete && <a disabled={editingKey !== ''}>Eliminar</a>}
                                        </Popconfirm>
                                    </span>
                                )
                        }
                    </div>
                );
            },
        }];
    }

    isEditing = record => record.detailUserEmail === this.state.editingKey;

    cancel = () => {
        this.setState({ editingKey: '' });
        this.props.onEditUser();
    };

    edit(key) {
        this.setState({ editingKey: key });
    }

    handleDelete = (user) => {
        const params = {
            email: user.detailUserEmail,
            Authorization: getToken()
        };
        this.setState({ loadingUsers: true });
        usersServices.put('user/delete', params)
            .then(response => {
                if (response === true) {
                    success(user.detailUserName);
                }
                this.props.onDeleteUser();
                this.setState({ loadingUsers: false });
            });
    }

    handleUserStatus = (email, newStatus) => {
        const params = {
            active: newStatus,
            email,
            Authorization: getToken()
        };
        this.setState({ loadingUsers: true });
        usersServices.put('user/changeUserStatus', params)
            .then(response => {
                if (response.id) {
                    if (params.active !== true && response.email === this.state.userData.userEmail ) {
                        logOut();
                    }
                    this.props.onChangeUserStatus();
                } else {
                    const errorResponse = response.response;
                    error(errorResponse.data);
                }
                this.setState({ loadingUsers: false });
            });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.data });
    }

    render() {
        const components = {
            body: {
                cell: EditableCell,
            },
        };

        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: col.dataIndex === 'detailUserProfile' ? 'list' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loadingUsers}>

                <EditableContext.Provider value={this.props.form}>
                    <Table
                        rowKey={record => record.detailUserEmail}
                        components={components}
                        dataSource={this.state.data}
                        columns={columns}
                        pagination={false}
                    />
                </EditableContext.Provider>
            </Spin>
        );
    }
}

const EditableUserTable = Form.create()(EditableTable);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(USERS_PERMISSION, permissions)
});

export default connect(mapStateToProps)(EditableUserTable);
