import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Row, Col, InputNumber, Select } from 'antd';
import {
    register as registerServices
} from '../../../services';

const Option = Select.Option;

class DictumDetailsPR08 extends Component {
    state = {
        subproducts: [],
    };

    componentDidMount() {
        this.fetchSubproducts();
    }

    fetchSubproducts = () => {
        registerServices.get('getProductSubProducts/3')
            .then(response => {
                this.setState({
                    subproducts: response
                });
            });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { pagePermissions } = this.props;

        const optionItems = types => types.map(item => (
            <Option key={item.idSubproduct} value={item.code}>{item.description} ({item.code})</Option>
        ));

        return (

            <Row gutter={24}>
                <Col span={8}>
                    <Form.Item label="Subproducto del petróleo">
                        {getFieldDecorator('petroleumSubproductType', {
                            initialValue: this.props.data.dictumDetails !== null && this.props.data.productType === 'PR08'
                                ? this.props.data.dictumDetails[0].responseDictumDetailValue : '',
                            rules: [{
                                required: this.props.selectedProduct === 'PR08', message: 'Por favor selecciona un subproducto del petróleo.'
                            }],
                        })(
                            <Select placeholder="Selecciona un subproducto" disabled={this.props.isEditable === false || pagePermissions.edit === false}>
                                {optionItems(this.state.subproducts)}
                            </Select>
                        )}
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label="Densidad de la muestra (En grados API)">
                        {getFieldDecorator('petroleumDensity', {
                            initialValue: this.props.data.dictumDetails !== null && this.props.data.productType === 'PR08'
                                ? this.props.data.dictumDetails[1].responseDictumDetailValue : '',
                            rules: [{
                                required: this.props.selectedProduct === 'PR08', message: 'Por favor introduce la densidad del petróleo en la muestra.'
                            }],
                        })(
                            <InputNumber
                                style={{ width: '100%' }}
                                disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                min={0.1}
                                max={80}
                                precision={1}
                            />
                        )}
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label="Composición de azufre en la muestra (Expresado en porcentaje)">
                        {getFieldDecorator('petroleumSulphurCompose', {
                            initialValue: this.props.data.dictumDetails !== null && this.props.data.productType === 'PR08'
                                ? this.props.data.dictumDetails[2].responseDictumDetailValue : '',
                            rules: [{
                                required: this.props.selectedProduct === 'PR08', type: 'number', message: 'Introduce el porcentaje de composición del azufre en la muestra.'
                            }],
                        })(
                            <InputNumber
                                style={{ width: '100%' }}
                                disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                min={0.1}
                                max={10}
                                precision={1}
                            />
                        )}
                    </Form.Item>
                </Col>

            </Row>

        );
    }
}

export default Form.create({ name: 'dictumDetailsPR08' })(DictumDetailsPR08);
