import React from "react";
import { PlusCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import {
    Modal,
    Button,
    Row,
    Col,
    Select,
    message,
    InputNumber,
    Divider,
    AutoComplete,
    Tooltip,
} from "antd";
import { isValidEmail } from "../../../services/utils/rfcValidator";
import _ from 'lodash';

const EmailsModal = Form.create({ name: "emails_modal" })(
	class extends React.Component {

		constructor(props) {
			super(props);
			this.state = {
				newEmail: "",
				errors: ""
			};
		}

		onWriteEmail = (value) => {
			this.setState({ newEmail: value });
		};

		onWriteMaxDifferencePorcentage = (value) => {
			this.props.updateMaxDifferencePorcentage(value);
			this.setState({errors: ""});
			if(isNaN(value)){
				this.setState({errors: "Introduce una diferencia númerica máxima válida."});
				return;
			}
			let number = parseFloat(value);
			if(number < 0.1 ){
				this.setState({errors: "El valor porcentaje de diferencia debe ser de cuando menos 0.1"});
				return;
			}
			if(number > 10 ){
				this.setState({errors: "El valor porcentaje de diferencia debe ser menor a 10"});
				return;
			}
			
		}

		onAddEmail = (emailsList) => {
			if(this.state.newEmail === undefined){
				message.error('El email no es valido');
				return;
			}
			let newEmail = this.state.newEmail.toLowerCase();
			
			if (!isValidEmail(newEmail)) {
				message.error('El email ' + newEmail + ' no es valido');
				return;
			}
			if (emailsList.includes(newEmail)) {
				message.error('El email ' + newEmail + ' ya esta registrado en este punto de distribución');
				return;
			}
			emailsList.push(newEmail);
			this.props.onUpdateEmailList(emailsList);
			this.setState({ newEmail: "" });
		};

		onAcceptConfig = () =>{
			let maxDifference = this.props.maxDifferencePorcentage;
			if(this.state.errors !== ""){
				maxDifference = null;
				message.warning(this.state.errors.concat(" | Se ignorará esta configuración"));
			}
			let configReturn = {
				emailsList: this.props.originalEmailist,
				maxDifferencePorcentage: maxDifference,
			};
			this.props.onAccept(configReturn);
		}

		render() {
			const originalEmailist = (this.props.originalEmailist !== undefined)? this.props.originalEmailist : [];
			return (
                <Modal
					open={this.props.visible}
					title={"Configuración de diferencia máxima"}
					onCancel={this.props.onCancel}
					onAccept={this.onAcceptConfig}
					footer={[
						<Button className="back-button" key="back" onClick={this.onAcceptConfig}>
							Aceptar
						</Button>,
					]}
				>
					<Form layout='vertical' >
						<Row gutter={24}>
							<Col span={24}>
								<p>Agregar un Email a la lista</p>
							</Col>
							<Row gutter={24}>
								<Col span={20}>
									<Tooltip title="Escribe aquí el correo electrónico que deseas registrar">
										<Form.Item placeholder="Email" >
											<AutoComplete
												style={{minWidth: 300}}
												placeholder="Escribe un email para agregarlo a la lista"
												options={(this.props.availableEmails === undefined)? [] : _.difference(this.props.availableEmails, this.props.originalEmailist).map(e=>({value: e})) }
												onChange={this.onWriteEmail} 
												value={this.state.newEmail}
												filterOption={(inputValue, option) => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1 }
												allowClear
											/>
										</Form.Item>
									</Tooltip>
								</Col>
								<Col span={4}>
									<Tooltip title="Da clic aquí para validar el correo y agregarlo a la lista. (No olvides dar click en el botón Aceptar mas abajo)">
										<Button size="large" type="primary" onClick={() => { this.onAddEmail(originalEmailist); }}>
											<PlusCircleOutlined />
										</Button>
									</Tooltip>
								</Col>
							</Row>
						</Row>
						<Row gutter={24}>
							<h3>
								Lista de los emails a notificar cuando la diferencia permitida sea superada
							</h3>
							<Tooltip title="Estos son las direcciones de correo a las cuales se alertará si se encuentra una diferencia, puedes quitar alguno si asi lo deseas dando click en la pequeña [x] al final de la dirección">
								<Select
									style={{ width: 300 }}
									value={originalEmailist}
									options={originalEmailist.map(e => ({value: e}))}
									open={false}
									placeholder="Lista de Emails"
									mode="multiple"
									onChange={(value) => {
										this.props.onUpdateEmailList(value);
									}}
								></Select>
							</Tooltip>
						</Row>
						<Divider/>
						<Row gutter={24} hidden={!this.props.showMaxDifferencePorcentage}>
							<Col span={24}>
								<p>Introduce la diferencia máxima permitida en el control de existencias</p>
							</Col>
							<Col span={24}>
								<Tooltip title="Define aquí la diferencia maxima que deseas revise este punto de distribución, deberá ser mayor a 0.1 y menor a 10">
									<Form.Item 
									label="Diferencia Máxima Permitida"
									help={this.state.errors}
									>
										<InputNumber
											className="common-input"
											style={{ width: "100%" }}
											precision={7}
											value={this.props.maxDifferencePorcentage}
											onChange={this.onWriteMaxDifferencePorcentage}
											placeholder="Introduce la diferencia máxima permitida en el control de existencias"
										/>
									</Form.Item>
								</Tooltip>
							</Col>
						</Row>
					</Form>
				</Modal>
            );
		}
	}
);

export default EmailsModal;
