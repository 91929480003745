import { Select, Button, Divider, Tooltip } from "antd";
import React from "react";
import { getIsComanyAdministrator } from "../../../security";
import _ from 'lodash';

class DistributionPointAssigner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: props.visible,
			actualDistributionPoints: props.actualDistributionPoints,
			originalDistributionPoints : props.actualDistributionPoints,
			selectedDPP: props.actualDistributionPoints !== undefined ? props.actualDistributionPoints.map(element => { return element.id }) : [],
			isDistributionPointEditable: props.isDistributionPointEditable,
			noAllowEdit: props.noAllowEdit,
			isCompanyAdministrator: getIsComanyAdministrator(),
		};
	}

	distributionPointsToReturn = (selectedDistributionPoints) => {
		let originalDistributionPoints = this.state.originalDistributionPoints;

		
		let availableDistributionPoints = this.props.availableDistributionPoints.map(element => {
			return {name: element.name, id: element.id};
		});

		if(this.props.isNewUser){
			this.setState({ selectedDPP: selectedDistributionPoints }, () => {
				this.props.onHadleOk(this.state.selectedDPP);
			});
			return;
		}


		if(this.state.isCompanyAdministrator){
			this.setState({ selectedDPP: selectedDistributionPoints }, () => {
				this.props.onHadleOk(this.state.selectedDPP);
			});
		}else{
			const allowedToAssignID = availableDistributionPoints.map(item => item.id);
			const previousUserDistributionPointsID = originalDistributionPoints.map(item => item.id);
			const selectedDistributionPointIds = selectedDistributionPoints;
			const resultDistributionPointsIds = _.difference(previousUserDistributionPointsID, allowedToAssignID);
			const losQueQuedan = [...new Set([...resultDistributionPointsIds, ...selectedDistributionPointIds])];

			this.setState({ selectedDPP: losQueQuedan }, () => {
				this.props.onHadleOk(this.state.selectedDPP);
			});
		}
	}

	getDefaultPDD = (previousUserDistributionPoints, allowedToAssign) => {
		const allowedToAssignID = allowedToAssign.map(item => item.id);
		const previousUserDistributionPointsID = previousUserDistributionPoints.map(item => item.id);
		const resultDistributionPointsIds = _.intersection(previousUserDistributionPointsID, allowedToAssignID);
		return resultDistributionPointsIds;
	}

	render() {
		const listPDD = (this.state.actualDistributionPoints!==undefined)?
			 this.getDefaultPDD(this.state.actualDistributionPoints,  this.props.availableDistributionPoints)
			 : [];
		return (
			<div hidden={!this.props.visible}>
				<Divider />
				<label hidden={this.state.isNewUser || this.state.noAllowEdit}>
					Puntos de distribución del usuario.
				</label>

				<div hidden={this.state.noAllowEdit}>
					<div style={{ textAlignLast: "center" }} hidden={this.state.isDistributionPointEditable}>
						<Tooltip
							title="Asignar Puntos de Distribución"
							placement="top"
						>
							<Button
								onClick={() => {
									this.setState({ isDistributionPointEditable: true });
								}}
							>
								Editar Puntos de Distribución
							</Button>
						</Tooltip>
					</div>
					<div hidden={!this.state.isDistributionPointEditable}>
						<Select
							mode="multiple"
							allowClear
							style={{ width: "100%" }}
							placeholder="Selecciona los Puntos de Distribución a Asignar"
							defaultValue={listPDD}
							onChange={(value) => {
								this.distributionPointsToReturn(value);
							}}
						>
							{this.props.availableDistributionPoints.map((dp) => (
								<Select.Option key={dp.id}>{dp.name}</Select.Option>
							))}
						</Select>
					</div>

				</div>

				<div hidden={this.state.selectedDPP.map.length === 0} >
					<ul style={{ maxHeight: '300px', overflowY: 'auto' }}>
						{this.props.availableDistributionPoints.length > 0 && this.state.selectedDPP.map((item) => (
							<li key={item}>
								{
									(this.props.availableDistributionPoints.find((element) => {return element.id === item}) !== undefined)? 
										this.props.availableDistributionPoints.find((obj) => {
											return obj.id === item;
										}).name
									: "No autorizado para ver esta información"
								}
							</li>
						))}
					</ul>
				</div>
				<Divider />
			</div>
		);
	}
}

export default DistributionPointAssigner;
