export const SELECTED_DISTRIBUTION_POINT_ID = 'selectedDistributionPoint';
export const SELECTED_DISTRIBUTION_POINT_NAME = 'selectedDistributionPointName';

export const saveSelectedDistributionPoint = (selectedDistributionPointId, selectedDistributionPointName) => {
    localStorage.setItem(SELECTED_DISTRIBUTION_POINT_ID, selectedDistributionPointId);
    localStorage.setItem(SELECTED_DISTRIBUTION_POINT_NAME, selectedDistributionPointName);
};

export const removeSelectedDistributionPoint = (selectedDistributionPoint) => {
    localStorage.removeItem(SELECTED_DISTRIBUTION_POINT_ID, selectedDistributionPoint);
};

export const getSelectedDistributionPoint = () => localStorage.getItem(SELECTED_DISTRIBUTION_POINT_ID);

export const getSelectedDistributionPointName = () => localStorage.getItem(SELECTED_DISTRIBUTION_POINT_NAME);