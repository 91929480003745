import React, { Component } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import {
    Row,
    Col,
    Button,
    Input,
    Divider,
    Popover,
    Anchor,
    message,
    Spin,
    InputNumber,
    Switch,
} from 'antd';
import { dispensaries as dispensaryServices } from '../../../services';
import RfcProveedoresModal from './rfcProveedoresModal';
import { getIsCloudModeActive, returnToRegister } from '../../../security';
import HumanCronExpressionInput from './humanCronExpressionInput';

class ConfigApplicationAggregation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            configApplication: {},
            loadingForm: false,
            visibleRfcProveedoresModal: false,
            isCloudEnable: getIsCloudModeActive()
        };
    }

    componentDidMount() {
        this.getConfigApplication();
    }

    onChangeAllowManualWarehouseLevel = (checked) => {
        this.props.form.setFieldsValue({ allowManualWarehouseLevel: `${checked}` });
    }

    getConfigApplication = () => {
        this.setState({ loadingForm: true });
        dispensaryServices.get('configApplication/getConfig')
            .then(response => {
                this.setState({
                    configApplication: response,
                });
                this.props.form.setFieldsValue({ allowManualWarehouseLevel: response.allowManualWarehouseLevel === 'true' });
                this.setState({ loadingForm: false });
            });
    }


    showRfcProveedoresModal = () =>{
        this.setState({visibleRfcProveedoresModal:true});
    }
    hideRfcProveedoresModal = () =>{
        this.setState({visibleRfcProveedoresModal:false});
    }
    updateRfcProveedoresList = (value) =>{
        let allconfig = this.state.configApplication;
        allconfig.rfcProveedores = value;
        this.setState({configApplication: allconfig});
    }

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true }, ()=>{
                    values.authenticationTokenExpirationConfig = values.authenticationTokenExpirationConfig * 1000 * 60;
                    if(!this.props.isCompanyRegistered && this.state.isCloudEnable){
                        let NEVER_WILL_BE_EXECUTED = "0 0 0 1 1 *";
                        values.temporalUserRecoveredPwCron = NEVER_WILL_BE_EXECUTED;
                        values.patternSystemTestInternetConnectionCron = NEVER_WILL_BE_EXECUTED;
                        values.checkExternalEventsCron = NEVER_WILL_BE_EXECUTED;
                        values.checkExternalEventsCron = NEVER_WILL_BE_EXECUTED;
                    }

                    let configApplicationURL = "configApplication".concat(this.props.isCompanyRegistered ? "/changeConfigApplication": "");

                    dispensaryServices.post(configApplicationURL, values)
                        .then(response => {
                            const jsonResponse = JSON.parse(JSON.stringify(response));
                            if (jsonResponse.hasOwnProperty('response')) {
                                message.error(jsonResponse.response.data);
                            } else {
                                message.success("Configuración actualizada correctamente");
                                this.setState({configApplication: response});
                                if(!this.props.isCompanyRegistered){
                                    returnToRegister();
                                }
                            }
                        });
                });
            }
        });
    };


    render() {
        const { configApplication } = this.state;
        const allowManualWarehouseLevel = configApplication.allowManualWarehouseLevel === 'true';
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const { Link } = Anchor;
        const cronPopOverContent = (
            <Anchor><Link title="Generador de crons" target="https://www.freeformatter.com/cron-expression-generator-quartz.html" /></Anchor>
        );

        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loadingForm}>
                <Form layout='vertical' >
                    <Row>
                        <span className="instructions">Llena los campos a continuación para configuración del sistema.</span>
                        <Form.Item extra="* Campos obligatorios" />
                    </Row>
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Datos de configuración</Divider>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Vencimiento de token de autenticación (en minutos)"
                            >
                                {getFieldDecorator('authenticationTokenExpirationConfig', {
                                    initialValue: configApplication.authenticationTokenExpirationConfig? configApplication.authenticationTokenExpirationConfig/60/1000 : "",
                                    rules: [{
                                        required: true, message: 'Por favor introduce el tiempo (en minutos) de Vencimiento de token de autenticación.'
                                    }]
                                })(
                                    <InputNumber style={{width:'100%'}} min={10} step={1} precision={0}/>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12} hidden={this.state.isCloudEnable}>
                            <Form.Item
                                label="Cron de contraseña temporal expirada de un usuario"
                            >
                                {getFieldDecorator('temporalUserRecoveredPwCron', {
                                    initialValue: configApplication.temporalUserRecoveredPwCron,
                                    rules: [{
                                        required: !this.state.isCloudEnable, message: 'Por favor introduce un cron de contraseña temporal expirada de un usuario.'
                                    }]
                                })(
                                    <Input className="common-input"
                                           suffix={
                                               <Popover content={cronPopOverContent} title="Consulta la página de generación de crons aquí:">
                                                   <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                               </Popover>
                                           } />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12} hidden={this.state.isCloudEnable}>
                            <Form.Item

                                label="Cron prueba de sistema de conexión a internet"
                            >
                                {getFieldDecorator('patternSystemTestInternetConnectionCron', {
                                    initialValue: configApplication.patternSystemTestInternetConnectionCron,
                                    rules: [{
                                        required: !this.state.isCloudEnable, message: 'Por favor introduce un cron prueba de sistema de conexión a internet.'
                                    }]
                                })(
                                    <Input className="common-input"
                                           suffix={
                                               <Popover content={cronPopOverContent} title="Consulta la página de generación de crons aquí:">
                                                   <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                               </Popover>
                                           } />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12} hidden={this.state.isCloudEnable}>
                            <Form.Item
                                label="Cron verificar eventos externos"
                            >
                                {getFieldDecorator('checkExternalEventsCron', {
                                    initialValue: configApplication.checkExternalEventsCron,
                                    rules: [{
                                        required: !this.state.isCloudEnable, message: 'Por favor introduce un cron verificar eventos externos.'
                                    }]
                                })(
                                    <Input className="common-input"
                                           suffix={
                                               <Popover content={cronPopOverContent} title="Consulta la página de generación de crons aquí:">
                                                   <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                               </Popover>
                                           } />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Cron calculo de los valores de volumen diario"
                            >
                                {getFieldDecorator('calculateDailyVolumeValuesCron', {
                                    initialValue: configApplication.calculateDailyVolumeValuesCron || "0 00 05 */1 * *",
                                    rules: [{
                                        required: true, message: 'Por favor introduce un cron calculo de los valores de volumen diario.'
                                    }]
                                })(
                                    <HumanCronExpressionInput availablePeriods={[{key: "Diariamente", value: "*/1"}]} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24} hidden={this.state.isCloudEnable}>
                        <Col span={12}>
                            <Form.Item
                                label="Cron de diagnostico de comunicación de manguera"
                            >
                                {getFieldDecorator('patternHoseDiagnosticCommunicationCron', {
                                    initialValue: configApplication.patternHoseDiagnosticCommunicationCron,
                                    rules: [{
                                        required: !this.state.isCloudEnable, message: 'Por favor introduce un cron de diagnostico de comunicación de manguera.'
                                    }]
                                })(
                                    <Input className="common-input"
                                           suffix={
                                               <Popover content={cronPopOverContent} title="Consulta la página de generación de crons aquí:">
                                                   <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                               </Popover>
                                           } />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Cron de diagnostico de variables de manguera"
                            >
                                {getFieldDecorator('patternHoseDiagnosticVariablesCron', {
                                    initialValue: configApplication.patternHoseDiagnosticVariablesCron,
                                    rules: [{
                                        required: !this.state.isCloudEnable, message: 'Por favor introduce un cron de diagnostico de variables de manguera.'
                                    }]
                                })(
                                    <Input className="common-input"
                                           suffix={
                                               <Popover content={cronPopOverContent} title="Consulta la página de generación de crons aquí:">
                                                   <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                               </Popover>
                                           } />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12} hidden={this.state.isCloudEnable}>
                            <Form.Item
                                label="Cron de diagnostico de voltaje de manguera"
                            >
                                {getFieldDecorator('patternHoseDiagnosticVoltageCron', {
                                    initialValue: configApplication.patternHoseDiagnosticVoltageCron,
                                    rules: [{
                                        required: !this.state.isCloudEnable, message: 'Por favor introduce un cron de diagnóstico de voltaje de manguera.'
                                    }]
                                })(
                                    <Input className="common-input"
                                           suffix={
                                               <Popover content={cronPopOverContent} title="Consulta la página de generación de crons aquí:">
                                                   <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                               </Popover>
                                           } />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="RFC's de Proveedores" help="Ingresa los RFC de los proveedores que deseas que aparezcan en el reporte JSON">
                                {getFieldDecorator(`rfcProveedores`, {
                                    initialValue: configApplication.rfcProveedores,
                                })(
                                    <Input className="common-input" onClick={this.showRfcProveedoresModal}/>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Permitir ingreso de medición de nivel manual por tanque">
                                {getFieldDecorator(`allowManualWarehouseLevel`, {
                                    valuePropName: 'checked',
                                    initialValue: allowManualWarehouseLevel,
                                })(
                                    <Switch onChange={this.onChangeAllowManualWarehouseLevel} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Configuración de Importación de CFDI</Divider>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Cron de consumo de CFDI del Conector"
                            >
                                {getFieldDecorator('cfdiImportConnectorCron', {
                                    initialValue: configApplication.cfdiImportConnectorCron || "0 00 05 */1 * *",
                                    rules: [{
                                        required: true, message: 'Por favor introduce un cron de consulta de cfdi.'
                                    }]
                                })(
                                    <HumanCronExpressionInput availablePeriods={[{key: "Diariamente", value: "*/1"}]}/>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="URL del servidor de facturación" help="Ingresa la URL del servidor de facturación">
                                {getFieldDecorator(`invoiceAppUrl`, {
                                    initialValue: configApplication.invoiceAppUrl,
                                    rules: [{
                                        required: true, message: 'Por favor ingresa la URL'
                                    }]
                                })(
                                    <Input className="common-input"/>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={this.state.loadingForm}
                            onClick={this.handleSubmit}
                        >
                            {this.state.loadingForm ? 'Guardando Configuraciones' : 'Guardar Configuraciones'}
                        </Button>
                    </Form.Item>
                    <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
                </Form>
                <RfcProveedoresModal
                    visible={this.state.visibleRfcProveedoresModal}
                    onAccept={this.hideRfcProveedoresModal}
                    onUpdateRfcList={this.updateRfcProveedoresList}
                    originalRfcList={this.state.configApplication.rfcProveedores}
                />
            </Spin>
        );
    }
}
export default Form.create({ name: 'configApplicationAggregation' })(ConfigApplicationAggregation);
