/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 11:08:43
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2024-05-08 10:44:08
 */
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import { omit } from 'lodash';
import { apiUrl } from '../app.config';

export const TOKEN_KEY = 'cv-token';
export const IS_TEMPORAL_PASSWORD_KEY = 'cv-is-temporal-password';
export const REFRESH_TOKEN_KEY = 'x-refresh-token';
export const IP_ORIGIN = 'cv-ip';
export const CV_CONTEXT_PATH = '/cv';

export const isAuthenticated = () => {
    try {
        jwtDecode(getToken());
    } catch (err) {
        return false;
    }

    return true;
};

export const getUserData = () => getTokenData().user || null;

export const getTokenData = () => {
    try {
        return jwtDecode(getToken());
    } catch (error) {
        return {};
    }
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const getIsComanyAdministrator = () => {
    let tokenData = jwtDecode(getToken());
    return tokenData.isCompanyAdministrator;
};

export const getIsCloudModeActive = () => {
    let token = getToken();
    if(token===null) return false;
    let tokenData = jwtDecode(token);
    return tokenData.isCloudModeActive;
};
export const getAllowedDistributionPoints = new Promise((resolve, reject) =>{
    if(isAuthenticated()){
        let tokenData = jwtDecode(getToken());
        resolve(  tokenData.allowedDistributionPoints );
    }
    else{
        // reject("No Authenticated");
    }   
});

export const getIsTemporalPasswordFromClaims = (token) =>{
    return new Promise((resolve) =>{
        let tokenData = jwtDecode(token);
        resolve(tokenData.isTemporalPassword);
    });
} 

export const getIsTemporalPassword = () => localStorage.getItem(IS_TEMPORAL_PASSWORD_KEY);

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

export const saveToken = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const saveIsTemporalPassword = (isTemporalPassword) => {
    localStorage.setItem(IS_TEMPORAL_PASSWORD_KEY, isTemporalPassword);
};

export const saveRefreshToken = (refreshToken) => {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

export const removeToken = (token) => {
    localStorage.removeItem(TOKEN_KEY, token);
};

export const removeRefreshToken = (refreshToken) => {
    localStorage.removeItem(REFRESH_TOKEN_KEY, refreshToken);
};

export const removeIsTemporalPassword = (isTemporalPassword) => {
    localStorage.removeItem(IS_TEMPORAL_PASSWORD_KEY, isTemporalPassword);
    window.location.replace(CV_CONTEXT_PATH);
};

export const logOut = () => {
    removeToken();
    window.location.replace(CV_CONTEXT_PATH);
};

export const onSuccess = () => {
    let configClaims = getCurrentUser();
    if (configClaims.isConfigApplication){
        if (configClaims.isCompanyRegistered) {
            window.location.reload();
        }else{
           window.location.replace(`${CV_CONTEXT_PATH}/register`);
        }
    } else {
       window.location.replace(`${CV_CONTEXT_PATH}/firstConfigApplication`);
    }
}


export const returnToRegister = () => {
    window.location.replace(`${CV_CONTEXT_PATH}/register`);
};

export const ToFirstWarehouseRegistry = () => {
    window.location.replace(`${CV_CONTEXT_PATH}/firstWareHouseRegistry`);
}

export const ToFirstContainerRegistry = () => {
    window.location.replace(`${CV_CONTEXT_PATH}/firstContainerRegistry`);
};

export const ToFirstBillingInfoRegistry = () => {
    window.location.replace(`${CV_CONTEXT_PATH}/billing`);
};

export const ToFirstConfigApplication = () => {
    window.location.replace(`${CV_CONTEXT_PATH}/firstConfigApplication`);
}

/**
 * Logs in the user
 * @param {string} email
 * @param {string} password
 * @param {string} originIp
 */
export const login = (username, password, originIp) => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}auth/authenticate`, { username, password, originIp })
            .then(response => {
                if(response.response !== undefined){
                    reject(response.response.data);
                }else{
                    resolve(response.data);
                }
            });
        });
};

export const getCurrentUser = () => {
    const claims = jwtDecode(getToken());
    const cleanClaims = omit(claims, ['exp', 'created']);
    return {
        ...cleanClaims,
    };
};
