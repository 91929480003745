import { apiUrl } from '../../app.config';
import React from 'react';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { Form } from '@ant-design/compatible';

import { Row, Col, Card, Statistic, Select } from 'antd';
import Hose from './hose';
import {
    dashboard as dashboardServices
} from '../../services';

import input_equipment from './../../assets/images/input_equipment.svg';
import gas_pump from './../../assets/images/gas_pump.svg';

const { Option } = Select;

class WebSocket extends React.Component {
    stompClient = null;

    column = [{
        title: 'Entrada',
        dataIndex: 'lsInputCode',
        render: (text, row) => (<span>
            <div>
                {`${text} ${row.lsInputStatus.toLowerCase()}`}
            </div>
            <img src={input_equipment} alt="Equipo de entrada" />
        </span>)
    }];

    columns = [{
        title: 'Dispensarios',
        dataIndex: 'lsDispensaryCode',
        render: (text) => (<span>
            {text}
            <img src={gas_pump} alt="Dispensario" />
        </span>)
    }, {
        title: 'Mangueras',
        render: (text, row) => (
            row.lsHoseDetail.map(hose =>
            (<span key={hose.lsHoseCode}>
                {hose.lsHoseCode}
                <Hose id={hose.lsHoseCode} hoseStatus={hose.lsHoseStatus} />
            </span>)
            )
        )
    }];

    constructor() {
        super();
        this.state = {
            generalIndicators: {},
            currentVolumePercentage: 0,
            dispensaries: [],
            inputs: [],
            products: [],
            subproducts: [],
            loadedProducts: false
        };
    }

    componentDidMount() {
        this.setupWebSocket();
    }

    setupWebSocket = () => {
        const socket = SockJS(`${apiUrl}volumetric-control`);
        this.stompClient = Stomp.over(socket);
        this.stompClient.connect({}, this.connect);
    };

    connect = () => {
        this.setState({
            loadedProducts: false
        });

        this.stompClient.subscribe('/topic/liveStatusReply', (messageOutput) => {
            const data = JSON.parse(messageOutput.body);
            console.log("RESPUESTA WEBSOCKET: ", data.lsProducts)
            this.setState({
                products: data.lsProducts
            });
            this.setState({
                loadedProducts: true
            });
        });
    };

    componentWillUnmount() {
        this.stompClient.disconnect();
    }

    dropdownProductsChange = idProduct => {

        let a = this.state.products.filter(product => product.lsIdProduct === idProduct);
        this.setState({
            subproducts: a[0].lsSubProducts
        });
    };

    dropdownSubproductsChange = idSubproduct => {
        this.props.onUpdateId(idSubproduct);

        let infoSubproduct = this.state.subproducts.filter(subProduct => subProduct.lsIdSubproduct === idSubproduct);

        this.setState({
            generalIndicators: {
                initialVolume: infoSubproduct[0].lsCurrentInitialVolume,
                inputs: infoSubproduct[0].lsCurrentInputs,
                sales: infoSubproduct[0].lsCurrentSales,
                currentVolume: infoSubproduct[0].lsCurrentVolume,
                currentVolumePercentageDifference: infoSubproduct[0].lsCurrentVolumePercentageDifference
            },
            dispensaries: infoSubproduct[0].lsDispensaryDetail,
            inputs: infoSubproduct[0].lsRegistryDetail
        });

        let params = {
            startDashboardDate: this.props.startDate,
            endDashboardDate: this.props.endDate,
            idSubproduct: idSubproduct
        }

        dashboardServices.post('dashboard/linearGraphInfoWS', params);
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { subproducts } = this.state;
        const getActiveProductsDropdown = types => types.map(item => (
            <Option key={item.lsIdProduct} value={item.lsIdProduct}>{item.lsProductDescription}</Option>
        ));

        const getActiveSubproductsDropdown = types => types.map(item => (
            <Option key={item.lsIdSubproduct} value={item.lsIdSubproduct}>{item.lsSubproductDescription}</Option>
        ));
        return (
            <div>
                <Form layout='vertical' >
                    <Row gutter={24}>
                        {this.state.loadedProducts && <Col span={8}>
                            <Form.Item label="Producto">
                                {getFieldDecorator('productCode', {
                                    rules: [{
                                        required: true, message: 'Por favor selecciona un producto.'
                                    }]
                                })(
                                    <Select
                                        onChange={(lsIdProduct) => this.dropdownProductsChange(lsIdProduct)}
                                    >
                                        {getActiveProductsDropdown(this.state.products)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>}
                        {this.state.loadedProducts && <Col span={16}>
                            <Form.Item label="Subproducto">
                                {getFieldDecorator('idSubproduct', {
                                    rules: [{
                                        required: true, message: 'Por favor selecciona un subproducto.'
                                    }]
                                })(
                                    <Select
                                        onChange={(lsIdSubproduct) => this.dropdownSubproductsChange(lsIdSubproduct)}
                                    >
                                        {getActiveSubproductsDropdown(subproducts)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>}
                    </Row>
                </Form>
                <Row gutter={24}>
                    <Col span={12}>
                        <Card>
                            <Statistic
                                title="Volumen inicial"
                                value={this.state.generalIndicators.initialVolume}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card>
                            <Statistic
                                title="Entradas"
                                value={this.state.generalIndicators.inputs}
                            />
                        </Card>
                    </Col>
                </Row>
                <br />
                <Row gutter={24}>
                    <Col span={12}>
                        <Card>
                            <Statistic
                                title="Ventas"
                                value={this.state.generalIndicators.sales}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card>
                            <Statistic
                                title="Volumen actual"
                                value={this.state.generalIndicators.currentVolume}
                            />
                        </Card>
                    </Col>
                </Row>
                <br />
                <Row gutter={24}>
                    <Col span={24}>
                        <Card>
                            <Statistic
                                style={{ textAlign: 'center' }}
                                title="Diferencia en existencias de hoy"
                                value={
                                    this.state.generalIndicators.currentVolumePercentageDifference === null
                                        || typeof this.state.generalIndicators.currentVolumePercentageDifference === 'undefined'
                                        ? 'Sin Registro'
                                        : this.state.generalIndicators.currentVolumePercentageDifference + "%"
                                }
                                valueStyle={{
                                    color:
                                        (this.state.generalIndicators.currentVolumePercentageDifference < -1 ||
                                            this.state.generalIndicators.currentVolumePercentageDifference > 1) && 'red'
                                }}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Form.create()(WebSocket);