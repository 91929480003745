import React from 'react';
import { Form } from '@ant-design/compatible';

import { Modal, Input, Button } from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';

const DispensaryEditForm = Form.create({ name: 'dispensaryEditForm_into_modal' })(

    class extends React.Component {
        state = {
            dispensaryCodeVS: {},
            auditableDispensaryCodeVS: {},
            dispensaryCodeExist: true,
            containers: []
        };

        handleOnChange = (event) => {
            this.setState({ dispensaryCodeVS: {} });
            const dispensaryCode = event.target.value.toUpperCase();

            if (dispensaryCode.length === 2) {
                dispensaryServices.get(`equipment/checkIfEquipmentKeyExist/${dispensaryCode}`)
                    .then(response => {
                        console.log('GET', response);
                        if (response === true) {
                            this.setState({
                                dispensaryCodeVS: {
                                    ...this.validateDispensaryCode(dispensaryCode),
                                }
                            });
                        }
                        this.setState({ dispensaryCodeExist: response });
                    });
            } else {
                this.setState({
                    dispensaryCodeVS: {
                        ...this.validateDispensaryCodeLength(dispensaryCode),
                    }
                });
            }
        }

        handleOnAuditableChange = (event) => {
            this.setState({ auditableDispensaryCodeVS: {} });
            const auditableDispensaryCode = event.target.value;

            if (auditableDispensaryCode.length === 9) {
                const reg = /^(DISP-)[0-9]{4}$/;
                if (reg.test(auditableDispensaryCode) || auditableDispensaryCode === '') {
                    dispensaryServices.get(`equipment/checkIfAuditableEquipmentKeyExist/${auditableDispensaryCode}`)
                        .then(response => {
                            console.log('GET', response);
                            if (response === true) {
                                this.setState({
                                    auditableDispensaryCodeVS: {
                                        ...this.validateAuditableDispensaryCode(auditableDispensaryCode),
                                    }
                                });
                            }
                            this.setState({ dispensaryCodeExist: response });
                        });
                } else {
                    this.setState({ dispensaryCodeExist: true });
                    this.setState({
                        auditableDispensaryCodeVS: {
                            ...this.validateAuditableDispensaryCodeLength(auditableDispensaryCode),
                        }
                    });
                }
            } else {
                this.setState({ dispensaryCodeExist: true });
                this.setState({
                    auditableDispensaryCodeVS: {
                        ...this.validateAuditableDispensaryCodeLength(auditableDispensaryCode),
                    }
                });
            }
        }

        validateDispensaryCode(dispensaryCode) {
            return {
                validateStatus: 'error',
                errorMsg: `El código de dispensario ${dispensaryCode} ya existe actualmente.`,
            };
        }

        validateDispensaryCodeLength(dispensaryCode) {
            return {
                validateStatus: 'error',
                errorMsg: `El código de dispensario debe ser de exactamente dos caracteres.`,
            };
        }

        validateAuditableDispensaryCode(dispensaryCode) {
            return {
                validateStatus: 'error',
                errorMsg: `El código auditable de dispensario ${dispensaryCode} ya existe actualmente.`,
            };
        }

        validateAuditableDispensaryCodeLength(dispensaryCode) {
            return {
                validateStatus: 'error',
                errorMsg: `El código auditable de dispensario debe contener 9 caracteres con el siguiente formato: DISP- seguido de 4 números.`,
            };
        }

        render() {
            const {
                visible, onCancel, onEdit, form, dispensary
            } = this.props;
            const { getFieldDecorator } = form;
            const { dispensaryCodeVS, auditableDispensaryCodeVS, dispensaryCodeExist } = this.state;

            return (
                <Modal
                    open={visible}
                    title={`Editar dispensario ${dispensary.dispensaryCode}`}
                    onCancel={onCancel}
                    onOk={onEdit}
                    footer={[
                        <Button className="back-button" key="back" onClick={onCancel}>Cancelar</Button>,
                        <Button className="submit-button" key="submit" onClick={onEdit} disabled={dispensaryCodeExist}>Guardar</Button>
                    ]}
                >
                    <Form layout='vertical' >
                        <Form.Item style={{ display: 'none' }} label="Código de dispensario">
                            {getFieldDecorator('currentDispensaryCode', {
                                initialValue: dispensary.dispensaryCode,
                                rules: [{ required: true, message: 'Por favor introduce el código de dispensario' }]
                            })(
                                <Input maxLength={2} disabled />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Código de dispensario"
                            validateStatus={dispensaryCodeVS.validateStatus}
                            help={dispensaryCodeVS.errorMsg}
                        >
                            {getFieldDecorator('newDispensaryCode', {
                                initialValue: dispensary.dispensaryCode,
                                rules: [{ required: true, message: 'Por favor introduce el número de dispensario' }]
                            })(
                                <Input maxLength={2} onChange={this.handleOnChange} />
                            )}
                        </Form.Item>
                        <Form.Item style={{ display: 'none' }} label="Código auditable de dispensario">
                            {getFieldDecorator('currentAuditableDispensaryCode', {
                                initialValue: dispensary.auditableDispensaryCode,
                                rules: [{ required: true, message: 'Por favor introduce el código auditable de dispensario' }]
                            })(
                                <Input maxLength={2} disabled />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Código auditable de dispensario"
                            validateStatus={auditableDispensaryCodeVS.validateStatus}
                            help={auditableDispensaryCodeVS.errorMsg}
                        >
                            {getFieldDecorator('newAuditableDispensarycode', {
                                initialValue: dispensary.auditableDispensaryCode,
                                rules: [{ required: true, message: 'Por favor introduce el código auditable de dispensario' }]
                            })(
                                <Input maxLength={9} onChange={this.handleOnAuditableChange} />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);

export default DispensaryEditForm;
