import React, { Component } from 'react';

class Alerts extends Component {
    render() {
        return (
            <h1>Alertas</h1>
        );
    }
}

export default Alerts;
