import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';
import { Table, Divider, Switch, Menu, Dropdown, message, Tooltip } from 'antd';
import EditableTable from './../components/editableCell';
import DispensaryEditForm from './dispensaryEditForm';
import HoseEditForm from './hoseEditForm';
import DispensaryDeleteForm from './dispensaryDeleteForm';
import HoseDeleteForm from './hoseDeleteForm';
import HoseAddForm from './hoseAddForm';
import HoseUnlinkForm from './hoseUnlinkForm';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import {
    getPagePermissions,
    DISPENSARY_PERMISSION
} from '../../../security/permissions';

import gas_pump from './../../../assets/images/mini_gas_pump.svg';
import EquipmentShowInfo from './equipmentShowInfo';
import moment from 'moment';
import { getIsCloudModeActive } from '../../../security';

const { SubMenu } = Menu;

const getDispensaryStatus = hoses => (
    hoses.some(element => element.status === true)
);

class DispensaryHoses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleDispensaryEditModal: false,
            visibleHoseEditModal: false,
            visibleDispensaryDeleteModal: false,
            visibleHoseDeleteModal: false,
            visibleHoseAddModal: false,
            visibleHoseUnlinkModal: false,
            hashValue: false,
            dispensary: props.dispensary,
            hoses: props.dispensary.hoses,
            dispensaryStatus: getDispensaryStatus(props.dispensary.hoses),
            hose: {
                hoseUUID: '',
                hoseCode: '',
                productId: '',
                description: ''
            },
            activateAllHoses: -1
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ dispensary: nextProps.dispensary, hoses: nextProps.dispensary.hoses });
    }

    handleMenuClick = (e) => {
        const { key } = e;

        switch (key) {
            case 'editDispensary':
                this.showDispensaryEditModal();
                break;

            case 'editHose':
                this.showHoseEditModal();
                break;

            case 'deleteDispensary':
                this.showDispensaryDeleteModal();
                break;

            case 'deleteHose':
                this.showHoseDeleteModal();
                break;

            case 'addHose':
                this.showHoseAddModal();
                break;

            case 'unlinkDispensary':
                this.showHoseUnlinkModal();
                break;

            default:
                break;
        }
    }

    showDispensaryEditModal = () => {
        const form = this.dispensaryEditFormRef.props.form;

        this.setState({ visibleDispensaryEditModal: true });
        form.resetFields();
    }

    showHoseEditModal = () => {
        const form = this.hoseEditFormRef.props.form;

        this.setState({ visibleHoseEditModal: true });
        form.resetFields();
    }

    showDispensaryDeleteModal = () => {
        this.setState({ visibleDispensaryDeleteModal: true });
    }

    showHoseDeleteModal = () => {
        this.setState({ visibleHoseDeleteModal: true });
    }

    showHoseAddModal = () => {
        this.setState({ visibleHoseAddModal: true });
    }

    showHoseUnlinkModal = () => {
        this.setState({ visibleHoseUnlinkModal: true });
    }

    handleCancelDispensaryEditModal = () => {
        this.setState({ visibleDispensaryEditModal: false });
    }

    handleCancelHoseEditModal = () => {
        this.setState({ visibleHoseEditModal: false });
    }

    handleCancelDispensaryDeleteModal = () => {
        this.setState({ visibleDispensaryDeleteModal: false });
    }

    handleCancelHoseDeleteModal = () => {
        this.setState({ visibleHoseDeleteModal: false });
    }

    handleCancelHoseAddModal = () => {
        const form = this.hoseAddFormRef.props.form;

        this.setState({ visibleHoseAddModal: false });
        form.resetFields();
    }

    handleCancelHoseUnlinkModal = () => {
        this.setState({ visibleHoseUnlinkModal: false });
    }

    handleDispensaryEdit = () => {
        const form = this.dispensaryEditFormRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            dispensaryServices.put(`equipment/${values.currentDispensaryCode}/${values.newDispensaryCode}/${values.currentAuditableDispensaryCode}/${values.newAuditableDispensarycode}`, values)
                .then(response => {
                    this.props.onUpdateDispensaries();
                });
            form.resetFields();
            this.setState({ visibleDispensaryEditModal: false });
        });
    }

    handleHoseEdit = () => {
        const form = this.hoseEditFormRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            const editHose = {
                idEquipment: values.idHose,
                equipmentKey: values.equipmentKey,
                idWarehouse: values.idWarehouse,
                description: values.description,
                auditableEquipmentkey: values.auditableDispensaryCode + "-" + values.auditableEquipmentkey,

            };
            dispensaryServices.put('hose/updateHose', editHose)
                .then(response => {
                    message.success("Manguera actualizada correctamente");
                    this.props.onUpdateDispensaries();
                });
            form.resetFields();
            this.setState({ visibleHoseEditModal: false });
        });
    }

    handleDispensaryDelete = () => {
        const form = this.dispensaryDeleteFormRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            dispensaryServices.Delete(`equipment/${values.idEquipment}`)
                .then(response => {
                    this.props.onUpdateDispensaries();
                });
            form.resetFields();
            this.setState({ visibleDispensaryDeleteModal: false });
        });
    }

    handleHoseDelete = () => {
        const form = this.hoseDeleteFormRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            dispensaryServices.Delete(`hose/${values.idHose}`)
                .then(response => {
                    this.props.onUpdateDispensaries();
                });
            form.resetFields();
            this.setState({ visibleHoseDeleteModal: false });
        });
    }

    handleHoseAdd = () => {
        const form = this.hoseAddFormRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            const newHoseToDispensary = {
                equipmentKey: values.equipmentKey,
                auditableEquipmentKey: values.auditableEquipmentKey,
                hoses: [{
                    hoseKey: values.hoseKey,
                    auditableHoseKey: values.auditableEquipmentKey + "-MGA-" + values.auditableHoseKey,
                    description: values.description,
                    idWarehouse: values.idWarehouse,
                    price: values.price,
                }],
            };
            dispensaryServices.post('dispensary', newHoseToDispensary)
                .then(response => {
                    const jsonResponse = JSON.parse(JSON.stringify(response));
                    if (jsonResponse.hasOwnProperty('response')) {
                        message.error(jsonResponse.response.data);
                    } else {
                        this.props.onUpdateDispensaries();
                    }
                });

            form.resetFields();
            this.setState({ visibleHoseAddModal: false });
        });
    }

    handleDispensaryUnlink = () => {
        const form = this.hoseUnlinkFormRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            let params = {
                unlinkReason:`${values.unlinkReason}`
            }
            
            dispensaryServices.put(`equipment/unlinkDispensary/${values.dispensaryCode}`,params)
                .then(response => {
                    const jsonResponse = JSON.parse(JSON.stringify(response));
                    if (jsonResponse.hasOwnProperty('response')) {
                        message.error(jsonResponse.response.data);
                    } else {
                        message.success("Dispensario desenlazado exitosamente");
                    }
                    this.props.onUpdateDispensaries();
                });
            form.resetFields();
            this.setState({ visibleHoseUnlinkModal: false });
        });
    }

    saveDispensaryEditFormRef = (formRef) => {
        this.dispensaryEditFormRef = formRef;
    }

    saveHoseEditFormRef = (formRef) => {
        this.hoseEditFormRef = formRef;
    }

    saveDispensaryDeleteFormRef = (formRef) => {
        this.dispensaryDeleteFormRef = formRef;
    }

    saveHoseDeleteFormRef = (formRef) => {
        this.hoseDeleteFormRef = formRef;
    }

    saveHoseAddFormRef = (formRef) => {
        this.hoseAddFormRef = formRef;
    }

    saveHoseUnlinkFormRef = (formRef) => {
        this.hoseUnlinkFormRef = formRef;
    }
    
    updateStatusHoses = () => {
        this.props.onUpdateDispensaries();
    };

    expandedRowRender = () => <EditableTable dispensary={this.state.dispensary} hoses={this.state.hoses} activateAllHoses={this.state.activateAllHoses} dispensaryStatus={this.state.dispensaryStatus} onSomeHoseStatusChange={this.updateStatusHoses} />

    menu = () => {
        const isCloudModeEnabled = getIsCloudModeActive();
        return (
        <Menu onClick={this.handleMenuClick}>
            {!this.props.dispensary.dispensaryHasSales ?
                (<SubMenu key={this.props.dispensary.dispensaryCode} title={`Dispensario ${this.props.dispensary.dispensaryCode}`}>
                    {this.props.pagePermissions.edit &&
                        <Menu.Item key="editDispensary">
                            Editar
                        </Menu.Item>
                    }
                    {this.props.pagePermissions.delete &&
                        <Menu.Item key="deleteDispensary">
                            Eliminar
                        </Menu.Item>
                    }
                    {!isCloudModeEnabled && 
                        <Menu.Item key="unlinkDispensary">
                            <a>Desenlazar</a>
                        </Menu.Item>
                    }
                </SubMenu>) :
                ((this.props.dispensary.hash !== ''  && !isCloudModeEnabled ) &&
                    <SubMenu key={this.props.dispensary.dispensaryCode} title={`Dispensario ${this.props.dispensary.dispensaryCode}`}>
                        <Menu.Item key="unlinkDispensary">
                            <a>Desenlazar</a>
                        </Menu.Item>
                    </SubMenu>)
            }
            {this.state.dispensary.hoses.map((value, index) => (
                !this.props.dispensary.dispensaryHasSales ? (
                    <SubMenu key={value.hoseUUID} title={`Manguera ${value.responseHoseCode}`}>
                        {this.props.pagePermissions.edit &&
                            <Menu.Item key="editHose">
                                <a onClick={() => this.setState({
                                    hose: {
                                        hoseUUID: value.hoseUUID, hoseCode: value.responseHoseCode, containerId: value.containerUUID, description: value.equipmentDescription,
                                        auditableEquipmentKey: value.auditableEquipmentKey.substring(10), auditableDispensaryCode: value.auditableDispensaryCode
                                    }
                                })}>Editar</a>
                            </Menu.Item>
                        }
                        {this.props.pagePermissions.delete &&
                            <Menu.Item key="deleteHose">
                                <a onClick={() => this.setState({ hose: { hoseUUID: value.hoseUUID, hoseCode: value.responseHoseCode } })}>Eliminar</a>
                            </Menu.Item>
                        }
                    </SubMenu>
                ) : (
                        <div></div>
                    )
            ))}
            {this.props.pagePermissions.add && <Menu.Divider />}
            {!isCloudModeEnabled && this.props.pagePermissions.add && this.props.dispensary.hoseCount < 10 &&
                <Menu.Item key="addHose">
                    Agregar manguera
                </Menu.Item>
            }
        </Menu>
    )
};

    columns = (pagePermissions) => [
        {
            title: 'Número de dispensario',
            dataIndex: 'dispensaryCode',
            key: 'dispensaryCode',
            render: (text) => (
                <span>
                    <Tooltip title="Número de dispensario">
                        <label className="dispensary-id">{text}</label>
                    </Tooltip>
                </span>
            )
        },
        {
            title: 'Clave de Enlace',
            dataIndex: 'synchronizationKey',
            key: 'synchronizationKey',
            render: (text, record) => (
                <div style={{ height: '19px' }}>
                    {record.hash === "" 
                        ?
                        <EquipmentShowInfo gaugeCode={record.auditableDispensaryCode} linkCode={text} 
                        pagePermissions={pagePermissions} onGaugeChange={this.onUpdateDispensaries}/>
                        :
                        ""}
                </div>
            )
        },
        {
            title: 'hash',
            dataIndex: 'hash',
            key: 'hash',
            render: (text, record) => {
                const isCloudModeEnabled = getIsCloudModeActive();
                const isUnlinkedEquipment = isCloudModeEnabled ? record.status === 'PENDIENTE_ENLACE' : text === "";
                return (<div style={{ height: '19px' }}>
                    <Tooltip title="Status del equipo">
                        <label style={{ color: isUnlinkedEquipment ? 'red' : 'green' }}>
                            {isUnlinkedEquipment
                            ? "Dispensario no enlazado" 
                            : 
                            record.lastCommunicationDate === "Sin comunicación" ? "Enlazado - " + record.lastCommunicationDate : "Última comunicación: " + moment(record.lastCommunicationDate).format('YYYY/MM/DD HH:mm:ss')}
                        </label>
                    </Tooltip>
                </div>)
            }
        },
        {
            title: 'Acción',
            key: 'action',
            align: 'right',
            render: () => {
                const isCloudEnable= getIsCloudModeActive();
                const allowedToEnableDisable = this.props.pagePermissions.edit && this.props.dispensary.status !== 'PENDIENTE_ENLACE';
                return (
                    <span>
                        {(this.props.pagePermissions.edit ||
                            this.props.pagePermissions.delete ||
                            this.props.pagePermissions.add) &&
                            <div hidden={isCloudEnable}>
                                <Tooltip title="Configuración del dispensario">
                                    <Dropdown overlay={this.menu} trigger={['click']}>
                                        <a>
                                            Opciones <DownOutlined />
                                        </a>
                                    </Dropdown>
                                </Tooltip>
                                <Divider type="vertical"/>
                            </div>
                        }
                        {allowedToEnableDisable  &&
                            <Tooltip title={this.state.dispensary.dispensaryStatus? "Desactivar dispensario" : "Activar dispensario"}>
                                <Switch 
                                    checked={this.state.dispensary.dispensaryStatus} 
                                    onChange={this.handleChangeDispensaryStatus} />
                            </Tooltip>
                        }
                    </span>
                );
            }
        }
    ];

    onUpdateDispensaries = () => {
        this.props.onUpdateDispensaries();
    }

    handleChangeDispensaryStatus = (checked) => {
        this.props.onDispensaryActiveStatusChange(this.props.dispensary.dispensaryCode, checked);
    };

    render() {
        return (
            <div>
                <Table
                    className="dispensary-table"
                    columns={this.columns(this.props.pagePermissions)}
                    expandIcon={() => <img src={gas_pump} alt="Dispensario" />}
                    expandedRowRender={this.expandedRowRender}
                    defaultExpandAllRows
                    dataSource={[
                        {
                            key: this.props.dispensary.dispensaryCode,
                            dispensaryCode: this.props.dispensary.auditableDispensaryCode + ' (' + this.props.dispensary.dispensaryCode + ')',
                            synchronizationKey: this.props.dispensary.synchronizationKey,
                            hash: this.props.dispensary.hash,
                            serialNumber: this.props.dispensary.serialNumber,
                            lastCommunicationDate: this.props.dispensary.lastCommunicationDate,
                            status: this.props.dispensary.status,
                        }
                    ]}
                    showHeader={false}
                    size="small"
                    pagination={false}
                />
                <DispensaryEditForm
                    wrappedComponentRef={this.saveDispensaryEditFormRef}
                    visible={this.state.visibleDispensaryEditModal}
                    onCancel={this.handleCancelDispensaryEditModal}
                    onEdit={this.handleDispensaryEdit}
                    dispensary={this.props.dispensary}
                />
                <HoseEditForm
                    wrappedComponentRef={this.saveHoseEditFormRef}
                    visible={this.state.visibleHoseEditModal}
                    onCancel={this.handleCancelHoseEditModal}
                    onEdit={this.handleHoseEdit}
                    hose={this.state.hose}
                    dispensary={this.props.dispensary}
                    warehouses={this.props.warehouses}
                />
                <DispensaryDeleteForm
                    wrappedComponentRef={this.saveDispensaryDeleteFormRef}
                    visible={this.state.visibleDispensaryDeleteModal}
                    onCancel={this.handleCancelDispensaryDeleteModal}
                    onDelete={this.handleDispensaryDelete}
                    dispensary={this.props.dispensary}
                />
                <HoseDeleteForm
                    wrappedComponentRef={this.saveHoseDeleteFormRef}
                    visible={this.state.visibleHoseDeleteModal}
                    onCancel={this.handleCancelHoseDeleteModal}
                    onDelete={this.handleHoseDelete}
                    hose={this.state.hose}
                />
                <HoseAddForm
                    wrappedComponentRef={this.saveHoseAddFormRef}
                    visible={this.state.visibleHoseAddModal}
                    onCancel={this.handleCancelHoseAddModal}
                    onAdd={this.handleHoseAdd}
                    dispensary={this.props.dispensary}
                    warehouses={this.props.warehouses}
                />
                <HoseUnlinkForm
                    wrappedComponentRef={this.saveHoseUnlinkFormRef}
                    visible={this.state.visibleHoseUnlinkModal}
                    onCancel={this.handleCancelHoseUnlinkModal}
                    onUnlink={this.handleDispensaryUnlink}
                    dispensary={this.props.dispensary}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISPENSARY_PERMISSION, permissions)
});

export default connect(mapStateToProps)(DispensaryHoses);
