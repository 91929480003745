import React, { useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { Modal, Button } from 'antd';
import { logOut } from '../../../../security';
import {
    stockControl as stockServices
} from '../../../../services';

function IdleTimerContainer() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const idleTimeRef = useRef(null);
    const sessionTimeoutRef = useRef(null);

    const onIdle = () => {
        setModalIsOpen(true);
        sessionTimeoutRef.current = setTimeout(closeSession, 1 * 60 * 1000);
    }

    const stayActive = () => {
        setModalIsOpen(false);
        clearTimeout(sessionTimeoutRef.current);
    }

    const closeSession = () => {
        clearTimeout(sessionTimeoutRef.current);
        stockServices.get('events/inactivityLogout').then(response => {
            console.log('Cierre de Sesión por Inactividad', response);
        }).finally(()=>{
            logOut();
        });
    }

    return (
        <div>
            <Modal
                open={modalIsOpen}
                title={`Usuario Inactivo`}
                footer={[
                    <Button className="back-button" key="logout" onClick={closeSession}>Cerrar sesión</Button>,
                    <Button className="submit-button" key="continue" onClick={stayActive}>Continuar</Button>
                ]}
            >
                <p>Ha estado inactivo por 10 minutos, ¿Desea cerrar la sesión?</p>
                <p>La sesión se cerrará automáticamente en 60 segundos.</p>
            </Modal>

            <IdleTimer
                ref={idleTimeRef}
                timeout={10 * 60 * 1000}
                onIdle={onIdle}
            ></IdleTimer>
        </div>
    )
}

export default IdleTimerContainer;