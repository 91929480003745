import request from './request';
import { serializeParams } from '../common/helpers';

export const get = (url = '', params = {}) => request().get(`${url}${serializeParams(params)}`)
    .then(response => response.data)
    .catch(error => error);

export const post = (url = '', params = {}) => request().post(url, params)
    .then(response => response.data)
    .catch(error => error);

export const put = (url = '', params = {}) => request().put(url, params)
    .then(response => response.data)
    .catch(error => error);
