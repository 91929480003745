import React, { Component } from 'react';
import { MinusOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import {
    Row,
    Col,
    Input,
    Button,
    message,
    DatePicker,
    Modal,
    Divider,
    Select,
    Upload,
    Spin,
} from 'antd';
import { Redirect } from 'react-router-dom';
import {
    operationCertificate as operationCertificateServices
} from '../../../services';
import {
    getPagePermissions,
    OPERATION_CERTIFICATE
} from '../../../security/permissions';
import dayjs from "dayjs";
import { connect } from 'react-redux';
import EditableTable from './editableCellCertificates';
import { getSelectedDistributionPoint } from '../../../common/components/widgets/DistributionPoint/distributionPointStorage'
import { handlePreview, openBase64InModal } from '../../../services/utils/generalUtils';
import request from '../../../services/request';

const { Option } = Select;
const { TextArea } = Input;
let id = 0;

const success = () => {
    message.success('Certiicado registrado exitosamente');
};

const removeEmptyElementsOnOperationCertificateEquipments = array => (
    array.filter(element => element != null)
);

const volumetricControlUCC = [
    {
        equipmentKey: 'UCC',
        dispensaryCode: ''
    },
    {
        equipmentKey: 'Control volumétrico',
        dispensaryCode: ''
    },
];

let equipmentSelected = [];

class OperationCertificateRegistration extends Component {
    _isMount = false;
    constructor(props) {
        super(props);
        this.fetch = this.fetch.bind(this);
    }
    state = {
        loading: false,
        visible: false,
        redirectToCertificates: false,
        data: {},
        company: {},
        rocOperationCertificateDetails: {},
        isEditable: true,
        equipments: [],
        fileList: [],
        fileList1: [],
        loadingForm: false,
        emptyTable: false,
        signatures: [],
    };

    fetch() {
        this.props.form.resetFields();
        this.setState({
            data: {},
            isEditable: true,
            rocOperationCertificateDetails: {},
            equipments: [],
        });
        id = 0;
        equipmentSelected = [];
        this.getComponyInformationOnOpertationCertificate();
        this.getEquipmentOnOpertationCertificate();
        if (this.props.certificateId !== undefined && this.props.certificateId !== 0) {
            this.getOperationCertificatesDetails(this.props.certificateId);
        }
    }

    componentDidMount() {
        this.props.fetchCertificate(this.fetch);
        this._isMount = true;
        window.addEventListener("distributionPointChange", (e) => {
            if (this._isMount) {
                if (this.props.distributionPointId === getSelectedDistributionPoint()) {
                    return;
                }
                this.redirectToCertificatesTable();
            }
        });
    }

    componentWillUnmount() {
        equipmentSelected = [];
        id = 0;
        this._isMount = false;
    }

    getEquipmentOnOpertationCertificate = () => {
        request().get(`distributionPoints/${this.props.distributionPointId}/equipments`)
            .then(response => {
                this.setState({
                    equipments: response.data.concat(volumetricControlUCC)
                }, () => {
                    equipmentSelected = [];
                });
            });
    };

    getComponyInformationOnOpertationCertificate = () => {
        request().get('distributionPoints/' + this.props.distributionPointId)
            .then(response => {
                this.setState({
                    company: response.data
                });
            });
    };

    getImage(signatureType) {
        if (this.state.signatures !== undefined && this.state.signatures.length > 0) {
            let imageFile = this.state.signatures.find(e => e.signatureType === signatureType);
            if (imageFile !== undefined) {
                return "data:".concat(imageFile.contentType).concat(";base64,").concat(imageFile.signatureFile);
            }
        }
    }

    getOperationCertificatesDetails = (idOperationCertificate) => {
        this.setState({ loadingForm: true });
        request().get(`operationCertificate/getRegisterByID/${idOperationCertificate}`)
            .then(response => {
                this.setState({ data: response.data.operationCertificate, isEditable: response.data.isEditable, loadingForm: false }, () => {
                    if (response.data.signatures.length > 0) {
                        this.setState({ signatures: response.data.signatures });
                    }
                    this.removeOptionSelected(this.state.data);
                });
            });
    };

    removeOptionSelected = (operationCertificate) => {
        operationCertificate.operationCertificateEquipments.forEach(item => {
            equipmentSelected.push(item.relatedEquipmentKey);
            this.addOperationCertificateEquipments();
        }
        );
        if (this.props.pagePermissions.edit === false) {
            this.setState({ isEditable: false });
        }
    }

    receiveDataFromTableDetails = (dataDetails) => {
        this.setState({ rocOperationCertificateDetails: dataDetails });
    }

    receiveIfDataFromTableIsEmpty = (isEmpty) => {
        this.setState({ emptyTable: isEmpty });
    }

    redirectToCertificatesTable = () => {
        this.clearDataOnNewCertificate();
        this.props.onReturn();
    }

    showModalOnOperationCertificate = () => {
        this.setState({
            visible: true,
        });
    }

    handleCancelOnOperationCertificate = () => {
        this.setState({ visible: false });
    }

    handleSubmitOnOperationCertificate = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.showModalOnOperationCertificate();
            }
        });
    }

    handleOkOnOperationCertificate = () => {
        this.setState({ loading: true });
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const filters = {
                    rocProviderBusinessName: values.rocProviderBusinessName,
                    rocProviderRfc: values.rocProviderRfc,
                    rocStreet: values.rocStreet,
                    rocNumber: values.rocNumber,
                    rocColony: values.rocColony,
                    rocPostalCode: values.rocPostalCode,
                    rocState: values.rocState,
                    rocMunicipality: values.rocMunicipality,
                    rocProviderName: values.rocProviderName,
                    rocLegalRepresentativeName: values.rocLegalRepresentativeName,
                    rocCertificateFolio: values.rocCertificateFolio,
                    rocCertificateIssuedDate: values.rocCertificateIssuedDate.format('YYYY-MM-DD'),
                    rocCertificateStartDate: values.rocCertificateStartDate.format('YYYY-MM-DD HH:mm:ss'),
                    rocCertificateEndDate: values.rocCertificateEndDate.format('YYYY-MM-DD HH:mm:ss'),
                    rocNormativity: values.rocNormativity,
                    rocCertificateResult: values.rocCertificateResult,
                    rocOperationCertificateDetails: this.state.rocOperationCertificateDetails,
                    rocOperationCertificateEquipments: removeEmptyElementsOnOperationCertificateEquipments(values.rocOperationCertificateEquipments)
                };
                this.addOperationCertificate(filters);
            }
        });
    }

    handleEditOnOperationCertificate = () => {
        this.setState({ loading: true });
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const filters = {
                    rocIdOperationCertificate: this.props.certificateId,
                    rocProviderBusinessName: values.rocProviderBusinessName,
                    rocProviderRfc: values.rocProviderRfc,
                    rocStreet: values.rocStreet,
                    rocNumber: values.rocNumber,
                    rocColony: values.rocColony,
                    rocPostalCode: values.rocPostalCode,
                    rocState: values.rocState,
                    rocMunicipality: values.rocMunicipality,
                    rocProviderName: values.rocProviderName,
                    rocLegalRepresentativeName: values.rocLegalRepresentativeName,
                    rocCertificateFolio: values.rocCertificateFolio,
                    rocCertificateIssuedDate: values.rocCertificateIssuedDate.format('YYYY-MM-DD'),
                    rocCertificateStartDate: values.rocCertificateStartDate.format('YYYY-MM-DD HH:mm:ss'),
                    rocCertificateEndDate: values.rocCertificateEndDate.format('YYYY-MM-DD HH:mm:ss'),
                    rocNormativity: values.rocNormativity,
                    rocCertificateResult: values.rocCertificateResult,
                    rocOperationCertificateDetails: this.state.rocOperationCertificateDetails,
                    rocOperationCertificateEquipments: removeEmptyElementsOnOperationCertificateEquipments(values.rocOperationCertificateEquipments)
                };
                this.editOperationCertificate(filters);
            }
        });
    }

    addOperationCertificate = (values = {}) => {
        values.distributionPointId = this.props.distributionPointId;
        request().post('operationCertificate', values)
            .then(response => {
                this.handleUpload(response.data);
            });
    }

    handleUpload = URL => {
        const { fileList, fileList1 } = this.state;
        const formData = new FormData();

        if (fileList.length === 0 && fileList1.length === 0) {
            this.setState({ loading: false, visible: false });
            this.redirectToCertificatesTable();
            success();
        } else {
            formData.append('providerSignature', fileList[0]);
            formData.append('legalRepresentativeSignature', fileList1[0]);

            operationCertificateServices.post(`operationCertificate/signature/${URL}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                const jsonResponse = JSON.parse(JSON.stringify(response));
                if (jsonResponse.hasOwnProperty('response')) {
                    message.error(
                        (jsonResponse.response.data.hasOwnProperty('message'))
                            ? jsonResponse.response.data.message
                            : jsonResponse.response.data
                    );
                }
            }).finally(() => {
                this.setState({ loading: false, visible: false, fileList: [], fileList1: [] }, () => {
                    this.redirectToCertificatesTable();
                    success();
                });
            });
        }
    };

    editOperationCertificate = (values = {}) => {
        values.distributionPointId = this.props.distributionPointId;
        request().put('operationCertificate', values)
            .then(response => {
                this.handleUpload(values.rocIdOperationCertificate);
            });
    }

    removeOperationCertificateEquipments = (idx, k) => {
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');

        // We need at least one passenger
        if (keys.length === 1) {
            return;
        }

        // can use data-binding to set
        form.setFieldsValue({
            keys: keys.filter(key => key !== k)
        });

        equipmentSelected.splice(idx, 1);
    };

    addOperationCertificateEquipments = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(id++);
        form.setFieldsValue({
            keys: nextKeys
        });
    };

    changeDropdownEquipmentsValue = idx => e => {
        equipmentSelected.splice(idx, 1, e);
    }

    renderRedirectToOPerationCertificate = () => {
        if (this.state.redirectToCertificates) {
            return <Redirect to="/operationCertificates" />;
        }
    }

    onChangeFile = (file) => {
        if (file.file.size > 10000000) {
            message.error("El fichero no puede ser mayor a 10 MB");
        }
    }

    generateEquipmentName = (item) => {
        let text = "";
        if (item.applicationType !== null & item.applicationType !== undefined)
            text = text.concat(item.applicationType).concat("-");
        if (item.auditableEquipmentKey !== null & item.auditableEquipmentKey !== undefined)
            text = text.concat(item.auditableEquipmentKey).concat("-");
        return text.concat(item.equipmentKey);
    }

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const dateFormatList = 'DD/MM/YYYY';
        const { visible, loading, company, fileList, fileList1 } = this.state;

        const equipmentToSelectFilter = this.state.equipments.filter(itemEquipment => !equipmentSelected.some(itemSelected => (itemSelected === itemEquipment.equipmentKey)));

        const optionEquipments = types => types.map(item => (
            <Option key={item.equipmentKey} value={item.equipmentKey}>
                {this.generateEquipmentName(item)}
            </Option>
        ));

        const props = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList
                    };
                });
                this.props.form.resetFields(['legalSignature']);
            },
            onChange: this.onChangeFile,
            beforeUpload: file => {
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }));
                return false;
            },
            accept: `image/*,.pdf`,
        };

        const props1 = {
            onRemove: file => {
                this.setState(state => {
                    const index = state.fileList1.indexOf(file);
                    const newFileList = state.fileList1.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList1: newFileList
                    };
                });
                this.props.form.resetFields(['providerSignature']);
            },
            onChange: this.onChangeFile,
            beforeUpload: file => {
                this.setState(state => ({
                    fileList1: [...state.fileList1, file]
                }));
                return false;
            },
            accept: `image/*,.pdf`,

        };

        getFieldDecorator('keys', { initialValue: [] });
        const keys = getFieldValue('keys');
        const operationCertificateEquipments = keys.map((k, index) => (
            <Row key={k} gutter={24}>
                <Col span={8}>
                    <Form.Item
                        label="Equipo verificado"
                    >
                        {getFieldDecorator(`rocOperationCertificateEquipments[${k}].roceEquipmentKey`, {
                            // eslint-disable-next-line no-nested-ternary
                            initialValue: this.state.data.operationCertificateEquipments === undefined ? null : this.state.data.operationCertificateEquipments[k] === undefined ? null : this.state.data.operationCertificateEquipments[k].relatedEquipmentKey,
                            rules: [{
                                required: true, message: 'Por favor selecciona el equipo verificado.'
                            }]
                        })(
                            <Select
                                onChange={this.changeDropdownEquipmentsValue(index)}
                                disabled={this.state.isEditable === false}
                            >
                                {optionEquipments(equipmentToSelectFilter)}
                            </Select>
                        )}
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="Observaciones anteriores">
                        {getFieldDecorator(`rocOperationCertificateEquipments[${k}].observations`, {
                            // eslint-disable-next-line no-nested-ternary
                            initialValue: this.state.data.operationCertificateEquipments === undefined
                                ? ''
                                : this.state.data.operationCertificateEquipments[k] === undefined
                                    ? ''
                                    : this.state.data.operationCertificateEquipments[k].lastCertificationObservations,
                            rules: [{
                                required: true, message: 'Por favor ingresa las observaciones.'
                            }]
                        })(
                            <Input className="common-input" disabled={this.state.isEditable === false} />
                        )}
                    </Form.Item>
                </Col>
                <Col className="container-minus-button" span={2} offset={1} >
                    <div className="vertical-center">
                        {keys.length > 1 ? (
                            <Button shape="circle" icon={<MinusOutlined />} onClick={() => this.removeOperationCertificateEquipments(index, k)} hidden={this.state.isEditable === false} />
                        ) : null}
                    </div>
                </Col>
            </Row>
        ));

        return (
            <Spin spinning={this.state.loadingForm} hidden={this.props.hideCertificateRegister}>
                <Form layout='vertical' onSubmit={this.handleSubmitOnOperationCertificate} >
                    {this.renderRedirectToOPerationCertificate()}
                    <div >
                        <span style={{ fontSize: '15px', color: '#606676' }}>Llena los campos a continuación para registrar un nuevo certificado de operación.</span>
                        <Form.Item />
                    </div>
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Datos del proveedor que emite el certificado de verificación de la correcta operación y funcionamiento de los equipos y programas informáticos</Divider>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="Punto de Distribución" help="Punto de Distribución seleccionado">
                                <Input style={{ 'fontSize': 'small' }} className="common-input" disabled value={this.props.distributionPointName} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Nombre o razón social">
                                {getFieldDecorator('rocProviderBusinessName', {
                                    initialValue: this.state.data.providerBusinessName === undefined ? '' : this.state.data.providerBusinessName,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el nombre o razón social del proveedor autorizado por el SAT que emite el certificado.'
                                    }],
                                })(
                                    <Input disabled={this.state.isEditable === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="RFC">
                                {getFieldDecorator('rocProviderRfc', {
                                    initialValue: this.state.data.providerRfc === undefined ? '' : this.state.data.providerRfc,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el RFC del proveedor autorizado por el SAT que emite el certificado.'
                                    },
                                    { min: 12, message: 'Introduce mínimo 12 caracteres.' },
                                    { max: 13, message: 'Introduce máximo 13 caracteres.' },
                                    ],
                                })(
                                    <Input disabled={this.state.isEditable === false} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Domicilio en el que se prestó el servicio de verificación</Divider>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="Calle">
                                {getFieldDecorator('rocStreet', {
                                    initialValue: this.state.data.street === undefined ? company.street : this.state.data.street,
                                    rules: [{
                                        required: true, message: 'Por favor introduce la calle en donde se prestó el servicio de verificación.'
                                    }],
                                })(
                                    <Input disabled={this.state.isEditable === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Número">
                                {getFieldDecorator('rocNumber', {
                                    initialValue: this.state.data.number === undefined ? company.number : this.state.data.number,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el número de la calle en donde se prestó el servicio de verificación.'
                                    }],
                                })(
                                    <Input disabled={this.state.isEditable === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Colonia">
                                {getFieldDecorator('rocColony', {
                                    initialValue: this.state.data.colony === undefined ? company.colony : this.state.data.colony,
                                    rules: [{
                                        required: true, message: 'Por favor introduce la colonia en donde se prestó el servicio de verificación.'
                                    }],
                                })(
                                    <Input disabled={this.state.isEditable === false} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="Código postal">
                                {getFieldDecorator('rocPostalCode', {
                                    initialValue: this.state.data.postalCode === undefined ? company.postalCode : this.state.data.postalCode,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el código postal en donde se prestó el servicio de verificación.'
                                    }],
                                })(
                                    <Input disabled={this.state.isEditable === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Estado">
                                {getFieldDecorator('rocState', {
                                    initialValue: this.state.data.state === undefined ? company.state : this.state.data.state,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el estado en donde se prestó el servicio de verificación.'
                                    }],
                                })(
                                    <Input disabled={this.state.isEditable === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Municipio">
                                {getFieldDecorator('rocMunicipality', {
                                    initialValue: this.state.data.municipality === undefined ? company.municipality : this.state.data.municipality,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el municipio en donde se prestó el servicio de verificación.'
                                    }],
                                })(
                                    <Input disabled={this.state.isEditable === false} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Información del certificado emitido</Divider>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="Folio">
                                {getFieldDecorator('rocCertificateFolio', {
                                    initialValue: this.state.data.certificateFolio === undefined ? '' : this.state.data.certificateFolio,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el folio del certificado emitido.'
                                    }],
                                })(
                                    <Input disabled={this.state.isEditable === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Normatividad aplicada">
                                {getFieldDecorator('rocNormativity', {
                                    initialValue: this.state.data.normativity === undefined ? '' : this.state.data.normativity,
                                    rules: [{
                                        required: true, message: 'Por favor introduce la normatividad aplicada'
                                    }],
                                })(
                                    <Input disabled={this.state.isEditable === false} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="Fecha de emisión del certificado">
                                {getFieldDecorator('rocCertificateIssuedDate', {
                                    initialValue: this.state.data.certificateIssuedDate === undefined ? null : dayjs(this.state.data.certificateIssuedDate),
                                    rules: [{
                                        required: true, message: 'Por favor introduce la fecha de emisión del certificado.'
                                    }],
                                })(
                                    <DatePicker  lang='es' style={{ width: '100%' }} format={dateFormatList} disabled={this.state.isEditable === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Inicio de la verificación">
                                {getFieldDecorator('rocCertificateStartDate', {
                                    initialValue: this.state.data.certificateStartDate === undefined ? null : dayjs(this.state.data.certificateStartDate),
                                    rules: [{
                                        required: true, message: 'Por favor introduce la fecha de inicio de la verificación.'
                                    }],
                                })(
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        format="YYYY-MM-DD HH:mm:ss"
                                        disabled={this.state.isEditable === false}
                                        showTime
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Fin de la verificación">
                                {getFieldDecorator('rocCertificateEndDate', {
                                    initialValue: this.state.data.certificateEndDate === undefined ? null : dayjs(this.state.data.certificateEndDate),
                                    rules: [{
                                        required: true, message: 'Por favor introduce la fecha en que terminó la verificación.'
                                    }],
                                })(
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        format="YYYY-MM-DD HH:mm:ss"
                                        disabled={this.state.isEditable === false}
                                        showTime
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Equipos y programas verificados</Divider>
                    {operationCertificateEquipments}
                    <Row>
                        <Col span={18} style={{ paddingTop: 16 }}>
                            <Button hidden={equipmentToSelectFilter.length < 1 || this.state.isEditable === false} className="add-hose-button" type="dashed" onClick={this.addOperationCertificateEquipments} icon={<PlusOutlined />} >Agregar equipo</Button>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Tabla de cumplimiento de los requisitos verificados</Divider>
                    <EditableTable
                        isDataEmpty={this.receiveIfDataFromTableIsEmpty}
                        dataDetailsTable={this.receiveDataFromTableDetails}
                        operationCertificateDetails={this.state.data.operationCertificateDetails}
                        isEditable={this.state.isEditable}
                        clearData={click => this.clearDataOnNewCertificate = click}
                    />
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Resultado de la verificación</Divider>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item label="Resultado de la verificación">
                                {getFieldDecorator('rocCertificateResult', {
                                    initialValue: this.state.data.certificateResult === undefined ? '' : this.state.data.certificateResult,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el resultado del certificado emitido.'
                                    }],
                                })(
                                    <TextArea autosize={{ minRows: 1, maxRows: 6 }} disabled={this.state.isEditable === false} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Información del personal del proveedor autorizado que emite el certificado</Divider>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="Nombre">
                                {getFieldDecorator('rocProviderName', {
                                    initialValue: this.state.data.providerName === undefined ? '' : this.state.data.providerName,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el nombre del personal del proveedor autorizado que emite el certificado.'
                                    }],
                                })(
                                    <Input disabled={this.state.isEditable === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Firma">
                                {getFieldDecorator('providerSignature', {
                                    rules: [{
                                        required: this.props.certificateId === '0' || fileList.length > 0,
                                        message: 'Por favor carga la firma del personal del proveedor autorizado que emite el certificado.'
                                    }],
                                })(
                                    <Upload listType='picture' onPreview={handlePreview} {...props}>
                                        <Button disabled={fileList[0] !== undefined || this.state.isEditable === false} style={{ width: '100%' }} hidden={this.state.isEditable === false}>
                                            <UploadOutlined /> Seleccionar archivo
                                        </Button>
                                    </Upload>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <img
                                hidden={fileList.length > 0}
                                height='100px'
                                src={this.getImage("PROVIDER")}
                                onClick={() => { openBase64InModal(this.getImage("PROVIDER")) }}
                                alt="PROVIDER"
                            />
                        </Col>
                    </Row>
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Información del representante legal del proveedor autorizado por el SAT</Divider>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item label="Nombre">
                                {getFieldDecorator('rocLegalRepresentativeName', {
                                    initialValue: this.state.data.legalRepresentativeName === undefined ? '' : this.state.data.legalRepresentativeName,
                                    rules: [{
                                        required: true, message: 'Por favor introduce el nombre del representante legal del proveedor autorizado por el SAT.'
                                    }],
                                })(
                                    <Input disabled={this.state.isEditable === false} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Firma">
                                {getFieldDecorator('legalSignature', {
                                    rules: [{
                                        required: this.props.certificateId === '0' || fileList1.length > 0,
                                        message: 'Por favor carga la firma del representante legal del proveedor autorizado por el SAT.'
                                    }],
                                })(
                                    <Upload listType='picture' onPreview={handlePreview} {...props1} >
                                        <Button disabled={fileList1[0] !== undefined || this.state.isEditable === false} hidden={this.state.isEditable === false}>
                                            <UploadOutlined /> Seleccionar archivo
                                        </Button>
                                    </Upload>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <img
                                hidden={fileList1.length > 0}
                                height='100px'
                                src={this.getImage("LEGAL")}
                                onClick={() => { openBase64InModal(this.getImage("LEGAL")) }}
                                alt="LEGAL"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item>
                                {this.state.data.providerBusinessName === undefined ?
                                    <Button hidden={this.state.isEditable === false} className="customized-primary" type="primary" htmlType="submit" disabled={this.state.emptyTable}>Agregar certificado</Button> :
                                    <Button hidden={this.state.isEditable === false} className="customized-primary" type="primary" htmlType="submit" disabled={this.state.emptyTable}>Editar certificado</Button>
                                }


                                <Button hidden={this.state.isEditable === undefined ? true : this.state.isEditable} className="customized-primary" type="primary" onClick={() => { this.redirectToCertificatesTable(); }}>Regresar</Button>

                                <Button hidden={this.state.isEditable === false} className="customized-default" style={{ marginLeft: 16 }} onClick={() => { this.redirectToCertificatesTable(); }}>Cancelar</Button>


                                <Modal
                                    open={visible}
                                    title="Atención"
                                    onCancel={this.handleCancelOnOperationCertificate}
                                    footer={[
                                        <Button className="back-button" key="back" onClick={this.handleCancelOnOperationCertificate}>Revisar información</Button>,
                                        this.state.data.providerBusinessName === undefined ?
                                            <Button className="submit-button" key="submit" loading={loading} onClick={this.handleOkOnOperationCertificate}>Confirmar registro</Button> :
                                            <Button className="submit-button" key="submit" loading={loading} onClick={this.handleEditOnOperationCertificate}>Editar registro</Button>
                                    ]}
                                >
                                    <p>Por favor asegúrese de que la información en el formulario sea la correcta.</p>
                                </Modal>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form >
            </Spin>
        );
    }
}

const OperationCertificatesForm = Form.create({ name: 'operationCertificateRegistration' })(OperationCertificateRegistration);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(OPERATION_CERTIFICATE, permissions)
});

export default connect(mapStateToProps)(OperationCertificatesForm);

