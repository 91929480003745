import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import dayjs from "dayjs";
import { FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { DatePicker, Input, Row, Col, Statistic, Pagination, Divider, Button, Spin } from 'antd';
import { EditableTable } from './components';
import {
    dispensaries as dispensaryServices
} from '../../services';
import {
    getPagePermissions,
    EVM_PERMISSION,
    EXPORT_REPORT_PERMISSION
} from '../../security/permissions';
import { getSelectedDistributionPoint } from '../../common/components/widgets/DistributionPoint/distributionPointStorage';
import { saveJsonToFile } from '../stockControl/components/jsonViewer';
import request from '../../services/request';
import { serializeParams } from '../../common/helpers';
import CreateTraspaso from './components/createTraspaso';
import {ComplementsComponent} from '../index';

const Search = Input.Search;
const { RangePicker } = DatePicker;

let displayPage = 0;
let displayAmount = 10;

class Traspasos extends Component {

    _isMount = false;

    constructor() {
        super();
        this.state = {
            hideTraspasosCreator: true,
            inputRegisterDetails: [],
            data: {},
            traspaso: undefined,
            pagination: {},
            loading: false,
            selectedDistributionPoint: getSelectedDistributionPoint(),
            filters: {
                inputRegisterStartDate: moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss'),
                inputRegisterEndDate: moment().format('YYYY-MM-DD HH:mm:ss'),
                inputRegisterFolio: null,
                inputRegisterDisplayPage: 0,
                inputRegisterDisplayAmount: 10,
                distributionPointId: getSelectedDistributionPoint(),
            },
            mainView: true,
            addComplementForm: false,
            currentComplementInfo: {},
            distributionPoint: {},
        };
    }

    handleSubmit = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.setState({ loading: true });
                const rangeTimeValue = fieldsValue.period;
                const inputRegisterFolio = fieldsValue.inputRegisterFolio;
                let newFilters = this.state.filters;
                newFilters.inputRegisterStartDate = rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss');
                newFilters.inputRegisterEndDate = rangeTimeValue[1].format('YYYY-MM-DD HH:mm:ss');
                newFilters.inputRegisterFolio = (inputRegisterFolio === undefined || inputRegisterFolio.trim() === '') ? null : inputRegisterFolio.trim();
                this.setState({ filters: newFilters }, () => {
                    this.fetchRegisters(newFilters);
                });
            }
        });
    }

    handlePeriodChange = e => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                fieldsValue.period = e;
                const rangeTimeValue = fieldsValue.period;
                let newFilters = this.state.filters;
                newFilters.inputRegisterStartDate = rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss');
                newFilters.inputRegisterEndDate = rangeTimeValue[1].format('YYYY-MM-DD HH:mm:ss');
                this.setState({ filters: newFilters }, () => {
                    this.fetchRegisters(newFilters);
                });
            }
        });
    };

    onPageChange = (page, pageSize) => {
        displayPage = page - 1;
        let newFilters = this.state.filters;
        newFilters.inputRegisterDisplayPage = page - 1;
        this.setState({ filters: newFilters }, () => {
            this.fetchRegisters(newFilters);
        });
    }

    onShowSizeChange = (current, pageSize) => {
        displayPage = 0;
        displayAmount = pageSize;
        let newFilters = this.state.filters;
        newFilters.inputRegisterDisplayPage = 0;
        newFilters.inputRegisterDisplayAmount = pageSize;
        this.setState({ filters: newFilters }, () => {
            this.fetchRegisters(newFilters);
        });
    }

    fetchRegisters = (params = {}) => {
        if(!this.fetchingRegisters){
            this.fetchingRegisters = true
            let queryParams = {
                page: displayPage,
                size: displayAmount,
                sort: "folio,DESC",
            };
            request().post(`distributionPoints/${this.state.selectedDistributionPoint}/traspasos${serializeParams(queryParams)}`, params).then((response) => {
                const pagination = { ...this.state.pagination };
                pagination.total = response.data.totalElements;
                this.setState({
                    data: response.data.content,
                    pagination,
                    inputRegisterDetails: response.data.content,
                    currentPage: displayPage + 1
                });
            }).finally(() => {
                this.fetchingRegisters = false
                this.setState({ loading: false });
            });
        }

    }

    handleRefreshView = () => {
        this.handleSubmit();
    }

    toggleTraspasosCreatorModa = (close = undefined) => {
        let hide = (close === undefined)
            ? !this.state.hideTraspasosCreator
            : close;
        this.setState({
            hideTraspasosCreator: hide,
            traspaso: hide ? undefined : this.state.traspaso,
        });
    }

    componentDidMount() {
        this._isMount = true;
        displayPage = 0;
        displayAmount = 10;
        this.handleSubmit();
        window.addEventListener("distributionPointChange", (e) => {
            if (this._isMount) {
                if (this.state.selectedDistributionPoint === getSelectedDistributionPoint()) {
                    return;
                }
                this.setState({ selectedDistributionPoint: getSelectedDistributionPoint(), loading: true }, () => {
                    let newFilters = this.state.filters;
                    newFilters.distributionPointId = this.state.selectedDistributionPoint;
                    newFilters.inputRegisterFolio = null;
                    newFilters.inputRegisterDisplayPage = 0;
                    newFilters.inputRegisterDisplayAmount = 10;
                    displayPage = 0;
                    displayAmount = 10;
                    this.setState({ filters: newFilters }, () => {
                        this.fetchRegisters(newFilters);
                    });
                });
            }
        });
        dispensaryServices.get(`distributionPoints/${this.state.selectedDistributionPoint}`)
            .then(response => {
                this.setState({
                    distributionPoint: response,
                });
            });
    }

    componentWillUnmount() {
        this._isMount = false;
    }

    handlePrintReceptions = (type) => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.setState({ loading: true });
                const rangeTimeValue = fieldsValue['period'];
                const inputRegisterFolio = fieldsValue['inputRegisterFolio'];
                const filters = {
                    inputRegisterStartDate: rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss'),
                    inputRegisterEndDate: rangeTimeValue[1].format('YYYY-MM-DD HH:mm:ss'),
                    inputRegisterFolio: inputRegisterFolio === undefined ? null : inputRegisterFolio,
                    inputRegisterDisplayPage: displayPage,
                    inputRegisterDisplayAmount: displayAmount,
                    distributionPointId: this.state.selectedDistributionPoint,
                    reportType: type,
                }
                this.printReceptions(filters);
            }
        });
    }

    editTraspaso = (traspaso) => {
        this.setState({ traspaso: traspaso }, () => {
            this.toggleTraspasosCreatorModa(false);
        })
    }
    changeToRegisterForm = (params) => {
        let complement = {
            currentCfdiComplement: params.currentCfdiComplement,
            isCfdiComplement: params.currentCfdiComplement === null ? false : true,

            currentTransportComplement: params.currentTransportComplement,
            isTransportComplement: params.currentTransportComplement === null ? false : true,

            currentStorageComplement: params.currentStorageComplement,
            isStorageComplement: params.currentStorageComplement === null ? false : true,

            currentInternationalComplement: params.currentInternationalComplement,
            isInternationalComplement: params.currentInternationalComplement === null ? false : true,

            isCurrentEditAllowed: params.isCurrentEditable,

            currentCFDIRegisterType: "RECEPCION",
            currentCFDIType: "EGRESO",

            inputRegisterUUID: params.inputRegisterUUID,
            clarifications: params.clarifications,
        };

        this.setState({
            currentComplementInfo: complement,
            mainView: params.mainView,
            addComplementForm: params.addComplementForm,
        });
    }

    printReceptions = (params = {}) => {
        if (params.reportType === 'csv') {
            dispensaryServices.post(`traspasos/reports/${params.reportType}`, params)
                .then(response => {
                    saveJsonToFile(response, "text/csv", "traspasos.csv");
                }).finally(() => {
                    this.setState({ loading: false })
                });
        }
    }

    changeToMainTable = () => {
        this.setState({
            mainView: true,
            addComplementForm: false,
            currentComplementInfo: {},
        });
        this.fetchRegisters(this.state.filters);
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { exportReportsPermissions } = this.props;
        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loading}>
                {this.state.mainView && <div>
                    <Form layout='vertical' >
                        <Row gutter={24}>
                            <Col span={10}>
                                <Form.Item
                                    label="Consultar periodo por fechas de inicio a fin de traspaso"
                                >
                                    {getFieldDecorator('period', {
                                        rules: [{ required: true, message: 'Por favor selecciona un periodo de tiempo.' }],
                                        initialValue: [dayjs().subtract(7, 'days').startOf('day'), dayjs().endOf('day')]
                                    })(
                                        <RangePicker
                                            lang='es'
                                            format="YYYY/MM/DD"
                                            placeholder={['Desde', 'Hasta']}
                                            onChange={this.handlePeriodChange}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    className="transparent-label"
                                    label=" "
                                >
                                    {getFieldDecorator('inputRegisterFolio', {
                                    })(
                                        <Search
                                            placeholder="Buscar número de registro"
                                            onSearch={this.handleSubmit}
                                            enterButton
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6} offset={10}>
                                {exportReportsPermissions.read &&
                                    <Button type="primary" icon={<PlusOutlined />} onClick={() => { this.toggleTraspasosCreatorModa(false); }}>
                                        Registrar un traspaso
                                    </Button>
                                }
                            </Col>
                            <Col span={4}>
                                {exportReportsPermissions.read &&
                                    <Button type="primary" icon={<FileExcelOutlined />} onClick={() => { this.handlePrintReceptions("csv"); }}>
                                        Descargar CSV
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                    <div style={{ background: '#E5E5E5', padding: '30px' }} hidden>
                        <Row>
                            <Col span={8}>
                                <h3 style={{ color: '#1B95CA' }}>TOTAL</h3>
                            </Col>
                            <Col span={8}>
                                <Statistic title="Litros recibidos" value={this.state.data.inputRegisterTotalVolume} />
                            </Col>
                        </Row>
                    </div>
                    <div style={{ margin: 16 }}>
                        <span style={{ color: '#1B95CA' }}>Todos los Traspasos</span>
                    </div>
                    <EditableTable
                        dataSource={this.state.inputRegisterDetails}
                        editTraspaso={(traspaso) => { this.editTraspaso(traspaso)}}
                        handleAddComplement={this.changeToRegisterForm}
                        transactionType={'traspaso'}
                        onloadRegister={this.changeToMainTable}
                    />
                    <br />
                    <Pagination showSizeChanger onChange={this.onPageChange} current={this.state.currentPage} onShowSizeChange={this.onShowSizeChange} total={this.state.pagination.total} pageSizeOptions={['10', '20', '50', '100', '200']} showTotal={total => `Total ${total} elementos`} pageSize={displayAmount} />
                    <Divider />
                </div>}
                {this.state.addComplementForm &&
                    <ComplementsComponent transactionType={'traspaso'} complementType={this.state.distributionPoint.companyActivityTypeDescription} currentComplementInfo={this.state.currentComplementInfo} handleReturnToTable={this.changeToMainTable} />
                }
                <div>
                    <CreateTraspaso
                        hidden={this.state.hideTraspasosCreator}
                        closeModal={() => { this.toggleTraspasosCreatorModa(true); }}
                        distributionPointId={this.state.selectedDistributionPoint}
                        refreshView={() => { this.handleRefreshView() }}
                        traspaso={this.state.traspaso}
                    />
                </div>
            </Spin>
        );
    }
}


const TraspasosForm = Form.create({ name: 'traspasos' })(Traspasos);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(EVM_PERMISSION, permissions),
    exportReportsPermissions: getPagePermissions(EXPORT_REPORT_PERMISSION, permissions)
});

export default connect(mapStateToProps)(TraspasosForm);
