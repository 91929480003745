import React from 'react';
import { Table, Tooltip } from 'antd';

export default function DistributionPointWarehousesDetail(props) {

    const columns = [
        {
            title: 'Código',
            dataIndex: 'key',
            editable: false,
            width: '30%',
            render: (text) => (<Tooltip title="Código SAT Auditable del Almacén">{text}</Tooltip>)
        },
        {
            title: 'Descripción',
            dataIndex: 'description',
            align: 'center',
            key: 'description',
            width: '70%',
            render: (text) => (<Tooltip title="Descripción del Almacén">{text}</Tooltip>)
        },
    ];

    return (
        <div>
            <Table
                size="middle"
                rowKey={record => record.id}
                dataSource={props.warehouses}
                columns={columns}
                showHeader={false}
                pagination={false}
            />
        </div>
    );
}