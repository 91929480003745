import React from 'react';
import moment from 'moment';
import { Form } from '@ant-design/compatible';

import { Modal } from 'antd';

class CauseView extends React.Component {
    render() {
        const { visible, onCancel, inventoryControl } = this.props;
        return (
            <Modal
                open={visible}
                title={`Información inconsistencia ${moment(inventoryControl.inventoryControlDetailsRegisteredDate).format('DD MMM YYYY')}`}
                onCancel={onCancel}
                footer={null}
            >
                <Form.Item
                    label="Posible causa"
                >
                    <p>{inventoryControl.inventoryControlDetailsVolumeDifferenceReason}</p>
                </Form.Item>
                <Form.Item
                    label="Acciones que se tomarán para su corrección y seguimiento"
                >
                    <p>{inventoryControl.inventoryControlDetailsVolumeDifferenceSolution}</p>
                </Form.Item>

            </Modal>
        );
    }
}

export default CauseView;
