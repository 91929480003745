import React from 'react';
import { Form } from '@ant-design/compatible';

import { Modal, Input, Button, Select, InputNumber } from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';

const { Option, } = Select;

const PermissionNumberEditForm = Form.create({ name: 'permissionNumberEditForm_into_modal' })(

    class extends React.Component {
        state = {
            companyInfo: {},
            permissionInfo: [],
            hoses: [],
        };

        componentDidMount() {
            this.getCompanyInfo();
        }

        componentWillReceiveProps(nextProps) {
            this.setState({ permissionInfo: nextProps.permissionInfo, });
            console.log("PRUEBA: ", nextProps.permissionInfo.key);
            this.getHoses(nextProps.permissionInfo.key);
        }

        getCompanyInfo = () => {
            dispensaryServices.get('company')
                .then(response => {
                    this.setState({ companyInfo: response[0] });
                });
        }

        getHoses = (key) => {
            dispensaryServices.get(`serviceNumber/getUnavialableEquipments/${key}`)
                .then(response => {
                    console.log("WEB SERVICE: ", response);
                    this.setState({ hoses: response });
                });
        }

        checkCFDISerial = e => {
            const serie = e.target.value;
            console.log(serie);
            if (serie.length === 2) {
                dispensaryServices.get('serviceNumber/getFolioValues')
                    .then(response => {
                        const indexOfSerie = response.findIndex(i => i.serie === serie);
                        console.log('GET', response, indexOfSerie);
                        if (indexOfSerie === -1) {
                            this.setState({ initialFolio: 0 });
                        } else {
                            this.setState({ initialFolio: response[indexOfSerie].lastFolio });
                        }
                    });
            }
        }

        render() {
            const { visible, onCancel, onAdd, form, } = this.props;
            const { getFieldDecorator } = form;

            const optionItems = hoses => this.state.hoses.map(hose => (
                <Option key={hose.hoseUUID} value={hose.responseHoseCode}>{`${hose.responseHoseCode}`}</Option>
            ));

            return (
                <Modal
                    open={visible}
                    title='Agregar Número de Permiso'
                    onCancel={onCancel}
                    onOk={onAdd}
                    footer={[
                        <Button className="back-button" key="back" onClick={onCancel}>Cancelar</Button>,
                        <Button className="submit-button" key="submit" onClick={onAdd}>Agregar</Button>
                    ]}
                >
                    <Form layout='vertical' >
                        <Form.Item style={{ display: 'none' }} label="UUID de la compañía">
                            {getFieldDecorator('idCompanyInfo', {
                                initialValue: this.state.companyInfo.idCompanyInfo,
                                rules: [{ required: true, message: 'Por favor introduce el UUID de la compañía.' }]
                            })(
                                <Input disabled />
                            )}
                        </Form.Item>
                        <Form.Item style={{ display: 'none' }} label="Id del permiso">
                            {getFieldDecorator('idPermission', {
                                initialValue: this.state.permissionInfo.key,
                                rules: [{ required: true, message: 'Id del permiso' }]
                            })(
                                <Input disabled />
                            )}
                        </Form.Item>
                        <Form.Item label="Número de permiso">
                            {getFieldDecorator('numberPermission', {
                                initialValue: this.state.permissionInfo.permissionNumber,
                                rules: [{ required: true, message: 'Por favor introduce el número de permiso.' }]
                            })(
                                <Input disabled />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Serie de facturación"
                            extra="Introduce un valor a 2 caracteres, puedes usar sólo números (01), sólo letras (AB) o una combinación (1A)"
                        >
                            {getFieldDecorator('CFDISerial', {
                                initialValue: this.state.permissionInfo.serie,
                                rules: [{
                                    required: true, message: 'Por favor introduce la serie de facturación.'
                                }, {
                                    min: 2, message: 'Introduce un valor a 2 caracteres.'
                                }]
                            })(
                                <Input
                                    maxLength={2}
                                    className="common-input"
                                    placeholder="Introduce la serie de facturación"
                                    disabled
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Folio de inicio de facturación"
                            extra={`Introduce un folio con un máximo de 4 caracteres, puedes elegir folios a partir del número ${this.state.initialFolio}`}
                        >
                            {getFieldDecorator('CFDIStartFolio', {
                                initialValue: this.state.permissionInfo.startFolio,
                                rules: [{
                                    required: true, message: 'Por favor introduce el folio de facturación.'
                                }, {
                                    type: 'integer',
                                    asyncValidator: (rule, value) => new Promise((resolve, reject) => {
                                        if (value <= this.state.initialFolio) {
                                            reject(new Error(`Por favor introduce un folio válido, no se pueden utilizar folios menores a ${this.state.initialFolio + 1}`)); // reject with error message
                                        } else if (this.props.form.getFieldValue('CFDISerial') === undefined || this.props.form.getFieldValue('CFDISerial').length < 2) {
                                            reject(new Error('Es necesario capturar primero la serie de facturación'));
                                        } else {
                                            resolve();
                                        }
                                    })
                                }]
                            })(
                                <InputNumber
                                    className="common-input"
                                    maxLength={4}
                                    style={{ width: '100%' }}
                                    placeholder="Introduce folio de facturación"
                                    disabled
                                />
                            )}
                        </Form.Item>

                        <Form.Item label="Folio de fin de facturación">
                            {getFieldDecorator('CFDIEndFolio', {
                                initialValue: this.state.permissionInfo.endFolio,
                                rules: [
                                    { required: true, message: 'Por favor introduce el folio de facturación.' },
                                    { type: 'integer', }
                                ]
                            })(
                                <InputNumber
                                    className="common-input"
                                    maxLength={4}
                                    style={{ width: '100%' }}
                                    placeholder="Introduce folio de facturación"
                                    disabled
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Seleccionar mangueras a asociar"
                        >
                            {getFieldDecorator('hose', {
                                initialValue: this.state.permissionInfo.equipments,
                                rules: [{ required: true, message: 'Por favor selecciona al menos una manguera.' }],
                            })(
                                <Select
                                    mode="multiple"
                                    placeholder="Selecciona una o más mangueras"
                                >
                                    {optionItems(this.state.hoses)}
                                </Select>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);

export default PermissionNumberEditForm;
