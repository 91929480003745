import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';

import { Divider } from 'antd';
import { WarehouseLevelChart } from './components';

class Dashboard extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        return (
            <div>
                <Divider />
                <WarehouseLevelChart />
            </div>
        );
    }
}

const DashboardForm = Form.create({ name: 'dashboard' })(Dashboard);

const mapStateToProps = ({ userData, userData: { iss } }) => ({
    userData,
    iss
});

export default connect(mapStateToProps, null)(DashboardForm);
