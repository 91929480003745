import React from 'react';
import { Form } from '@ant-design/compatible';

import { Table, Input, Checkbox } from 'antd';
import './styles.css';


const { TextArea } = Input;

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
    state = {
        editing: false,
    };

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record } = this.props;
        const { editing } = this.state;
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    // rules: [
                    //   {
                    //     required: true,
                    //     message: `${title} es un campo obligatorio.`,
                    //   },
                    // ],
                    initialValue: record[dataIndex],
                })(<TextArea autosize={{ minRows: 1, maxRows: 10 }} ref={node => (this.input = node)} placeholder="Detalle de opinión" onPressEnter={this.save} onBlur={this.save} />)}
            </Form.Item>
        ) : (
                <div
                    className='editable-cell-value-wrap'
                    style={{ paddingRight: 24, whiteSpace: 'normal', wordBreak: 'break-word' }}
                    onClick={this.toggleEdit}
                >
                    {children}
                </div>
            );
    };

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                        children
                    )}
            </td>
        );
    }
}

// eslint-disable-next-line react/no-multi-comp
class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        this.initializeState = this.initializeState.bind(this);
        this.columns = [
            {
                title: 'Especificación o requerimiento',
                dataIndex: 'request',
                width: '50%',
                render: (text, row, index) => {
                    if (index === 0 || index === 5) {
                        return {
                            children: text,
                            props: {
                                colSpan: 5,
                                style: { backgroundColor: '#fafafa', textAlign: 'center', fontWeight: 'bold', whiteSpace: 'pre-line' }
                            },
                        };
                    }
                    return text;
                }
            },
            {
                title: 'Opinion de cumplimiento',
                dataIndex: 'opinion',
                width: '15%',
                render: (record, row, index) => {
                    if (index === 0 || index === 5) {
                        return {
                            props: {
                                colSpan: 0
                            },
                        };
                    }
                    return <Checkbox key={index} disabled={this.props.isEditable === false} checked={record} onChange={this.handleChange(index)}>{record ? 'Sí cumple' : 'No cumple'}</Checkbox>;
                }
            },
            {
                title: 'Detalle de opinión',
                dataIndex: 'details',
                editable: true,
                render: (text, row, index) => {
                    if (index === 0 || index === 5) {
                        return {
                            props: {
                                colSpan: 0
                            },
                        };
                    }
                    return text;
                }
            }
        ];

        this.state = {
            dataSource: [
                {
                    key: '0',
                    request: 'Sistemas de medición',
                },
                {
                    key: '1',
                    request: 'Requerimientos generales del sistema de medición',
                    opinion: true,
                    details: '',
                },
                {
                    key: '2',
                    request: 'Requerimientos de los sistemas de medición estática',
                    opinion: true,
                    details: '',
                },
                {
                    key: '3',
                    request: 'Requerimientos de los sistemas de medición dinámica en ductos',
                    opinion: true,
                    details: '',
                },
                {
                    key: '4',
                    request: 'Requerimientos de los sistemas de medición dinámica en estaciones de servicio',
                    opinion: true,
                    details: '',
                },
                {
                    key: '5',
                    request: 'Programas informáticos',
                },
                {
                    key: '6',
                    request: 'Requerimientos generales',
                    opinion: true,
                    details: '',
                },
                {
                    key: '7',
                    request: 'Requerimientos de la información a recopilar:  \n a) Datos generales \n b) Registros del volumen \n c) Tipo de hidrocarburo o petrolífero \n d) Información fiscal',
                    opinion: true,
                    details: '',
                },
                {
                    key: '8',
                    request: 'Requerimientos del almacenamiento de información',
                    opinion: true,
                    details: '',
                },
                {
                    key: '9',
                    request: 'Requerimientos del procesamiento de la información y de la generación de reportes',
                    opinion: true,
                    details: '',
                },
                {
                    key: '10',
                    request: 'Requerimientos de seguridad',
                    opinion: true,
                    details: '',
                },
            ],
            idOperationCeritifcateDetail: null,
            emptyDataTable: true,
        };
    }

    initializeState() {
        this.setState({
            dataSource: [
                {
                    key: '0',
                    request: 'Sistemas de medición',
                },
                {
                    key: '1',
                    request: 'Requerimientos generales del sistema de medición',
                    opinion: true,
                    details: '',
                },
                {
                    key: '2',
                    request: 'Requerimientos de los sistemas de medición estática',
                    opinion: true,
                    details: '',
                },
                {
                    key: '3',
                    request: 'Requerimientos de los sistemas de medición dinámica en ductos',
                    opinion: true,
                    details: '',
                },
                {
                    key: '4',
                    request: 'Requerimientos de los sistemas de medición dinámica en estaciones de servicio',
                    opinion: true,
                    details: '',
                },
                {
                    key: '5',
                    request: 'Programas informáticos',
                },
                {
                    key: '6',
                    request: 'Requerimientos generales',
                    opinion: true,
                    details: '',
                },
                {
                    key: '7',
                    request: 'Requerimientos de la información a recopilar:  \n a) Datos generales \n b) Registros del volumen \n c) Tipo de hidrocarburo o petrolífero \n d) Información fiscal',
                    opinion: true,
                    details: '',
                },
                {
                    key: '8',
                    request: 'Requerimientos del almacenamiento de información',
                    opinion: true,
                    details: '',
                },
                {
                    key: '9',
                    request: 'Requerimientos del procesamiento de la información y de la generación de reportes',
                    opinion: true,
                    details: '',
                },
                {
                    key: '10',
                    request: 'Requerimientos de seguridad',
                    opinion: true,
                    details: '',
                },
            ],
            idOperationCeritifcateDetail: null,
            emptyDataTable: true,
        });
    }

    componentDidMount() {
        this.props.clearData(this.initializeState);
        this.sendDataToOperationCertificateRegistration();
    }

    componentWillReceiveProps(nextProps) {
        if ((nextProps.operationCertificateDetails !== undefined) && (nextProps.operationCertificateDetails.idOperationCeritifcateDetail !== (this.props.operationCertificateDetails || {}).idOperationCeritifcateDetail)) {
            this.setState({
                dataSource: [
                    {
                        key: '0',
                        request: 'Sistemas de medición',
                    },
                    {
                        key: '1',
                        request: 'Requerimientos generales del sistema de medición',
                        opinion: nextProps.operationCertificateDetails.measuringSystemGeneralsOpinion,
                        details: nextProps.operationCertificateDetails.measuringSystemGeneralsDetail,
                    },
                    {
                        key: '2',
                        request: 'Requerimientos de los sistemas de medición estática',
                        opinion: nextProps.operationCertificateDetails.measuringSystemStaticOpinion,
                        details: nextProps.operationCertificateDetails.measuringSystemStaticDetail,
                    },
                    {
                        key: '3',
                        request: 'Requerimientos de los sistemas de medición dinámica en ductos',
                        opinion: nextProps.operationCertificateDetails.measuringSystemDynamicDuctOpinion,
                        details: nextProps.operationCertificateDetails.measuringSystemDynamicDuctDetail,
                    },
                    {
                        key: '4',
                        request: 'Requerimientos de los sistemas de medición dinámica en estaciones de servicio',
                        opinion: nextProps.operationCertificateDetails.measuringSystemDynamicServiceStationOpinion,
                        details: nextProps.operationCertificateDetails.measuringSystemDynamicServiceStationDetail,
                    },
                    {
                        key: '5',
                        request: 'Programas informáticos',
                    },
                    {
                        key: '6',
                        request: 'Requerimientos generales',
                        opinion: nextProps.operationCertificateDetails.softwareGeneralOpinion,
                        details: nextProps.operationCertificateDetails.softwareGeneralDetail,
                    },
                    {
                        key: '7',
                        request: 'Requerimientos de la información a recopilar:  \n a) Datos generales \n b) Registros del volumen \n c) Tipo de hidrocarburo o petrolífero \n d) Información fiscal',
                        opinion: nextProps.operationCertificateDetails.softwareRequiredInformationOpinion,
                        details: nextProps.operationCertificateDetails.softwareRequiredInformationDetail,
                    },
                    {
                        key: '8',
                        request: 'Requerimientos del almacenamiento de información',
                        opinion: nextProps.operationCertificateDetails.softwareStoredInformationOpinion,
                        details: nextProps.operationCertificateDetails.softwareStoredInformationDetail,
                    },
                    {
                        key: '9',
                        request: 'Requerimientos del procesamiento de la información y de la generación de reportes',
                        opinion: nextProps.operationCertificateDetails.softwareInformationProcessingOpinion,
                        details: nextProps.operationCertificateDetails.softwareInformationProcessingDetail,
                    },
                    {
                        key: '10',
                        request: 'Requerimientos de seguridad',
                        opinion: nextProps.operationCertificateDetails.softwareSecurityOpinion,
                        details: nextProps.operationCertificateDetails.softwareSecurityDetail,
                    },
                ],
                idOperationCeritifcateDetail: nextProps.operationCertificateDetails.idOperationCeritifcateDetail
            }, () => this.sendDataToOperationCertificateRegistration());
        }
    }

    handleChange = (index) => (e) => {
        const dataTable = this.state.dataSource;
        dataTable[index].opinion = e.target.checked;
        this.setState({ dataSource: dataTable }, () => { this.sendDataToOperationCertificateRegistration(); });
    };

    handleSave = row => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item1 => row.key === item1.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData }, () => { this.sendDataToOperationCertificateRegistration(); });
    };

    sendDataToOperationCertificateRegistration = () => {
        const { dataSource, idOperationCeritifcateDetail } = this.state;

        const dataFromTable = {
            rocdIdOperationCeritifcateDetail: idOperationCeritifcateDetail,
            rocdMeasuringSystemGeneralsOpinion: dataSource[1].opinion,
            rocdMeasuringSystemGeneralsDetail: dataSource[1].details,
            rocdMeasuringSystemStaticOpinion: dataSource[2].opinion,
            rocdMeasuringSystemStaticDetail: dataSource[2].details,
            rocdMeasuringSystemDynamicDuctOpinion: dataSource[3].opinion,
            rocdMeasuringSystemDynamicDuctDetail: dataSource[3].details,
            rocdMeasuringSystemDynamicServiceStationOpinion: dataSource[4].opinion,
            rocdMeasuringSystemDynamicServiceStationDetail: dataSource[4].details,
            rocdSoftwareGeneralOpinion: dataSource[6].opinion,
            rocdSoftwareGeneralDetail: dataSource[6].details,
            rocdSoftwareRequiredInformationOpinion: dataSource[7].opinion,
            rocdSoftwareRequiredInformationDetail: dataSource[7].details,
            rocdSoftwareStoredInformationOpinion: dataSource[8].opinion,
            rocdSoftwareStoredInformationDetail: dataSource[8].details,
            rocdSoftwareInformationProcessingOpinion: dataSource[9].opinion,
            rocdSoftwareInformationProcessingDetail: dataSource[9].details,
            rocdSoftwareSecurityOpinion: dataSource[10].opinion,
            rocdSoftwareSecurityDetail: dataSource[10].details
        };

        this.emptyDataTable(dataFromTable);
        this.props.dataDetailsTable(dataFromTable);
    }

    emptyDataTable = (dataFromTable) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in dataFromTable) {
            if (dataFromTable[key] === '') {
                this.setState({ emptyDataTable: true }, () => this.props.isDataEmpty(this.state.emptyDataTable));
                break;
            } else {
                this.setState({ emptyDataTable: false }, () => this.props.isDataEmpty(this.state.emptyDataTable));
            }
        }
    }

    render() {
        const { dataSource } = this.state;

        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };

        const columns = this.columns.map(col => {
            if (!this.props.isEditable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });

        return (
            <div>
                <Table
                    style={{ whiteSpace: 'pre-line' }}
                    className="general-table"
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                />
                <br />
                {this.state.emptyDataTable && <span style={{ fontSize: '15px', color: '#f43034' }} >Existen campos vacíos en los detalles del cumpimiento, por favor llenalos para poder envíar guardar el formulario.</span>}
            </div>
        );
    }
}

export default EditableTable;
