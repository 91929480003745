import { apiUrl } from '../../../app.config';
import React, { Component } from 'react';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import dayjs from "dayjs";
import { Form } from '@ant-design/compatible';

import { DatePicker, message, Row, Col } from 'antd';
import WebSocket from '../webSocket';
import {
    dashboard as dashboardServices
} from '../../../services';

const { RangePicker } = DatePicker;

const info = () => {
    message.info('El rango de fechas debe ser mínimo 7 días.');
};

class WarehouseLevelChart extends Component {
    stompClient = null;

    constructor() {
        super();
        this.state = {
            chartData: {},
            chartOptions: {
                title: {
                    display: true,
                    text: 'Nivel de almacén',
                    fontSize: 25
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                legend: {
                    display: false,
                    position: 'top'
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            max: 10000
                        }
                    }]
                }
            },
            startDate: moment().subtract(6, 'days').format('YYYY-MM-DD 00:00:00'),
            endDate: moment().format('YYYY-MM-DD 23:59:59'),
            idSubproduct: "",
        };
    }

    disabledDate = (current) =>
        // Can not select days after today
        current && current > moment();

    handlePeriodChange = e => {
        const startDate = moment(e[0].format());
        const endDate = moment(e[1].format());
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err && endDate.diff(startDate, 'days') >= 6) {
                fieldsValue.period = e;
                console.log('Received values of form: ', fieldsValue);
                const rangeTimeValues = fieldsValue.period;
                let params = {
                    startDashboardDate: rangeTimeValues[0].format('YYYY-MM-DD 00:00:00'),
                    endDashboardDate: rangeTimeValues[1].format('YYYY-MM-DD 23:59:59'),
                    idSubproduct: this.state.idSubproduct
                }

                dashboardServices.post('dashboard/linearGraphInfoWS', params);

                this.setState({
                    startDate: rangeTimeValues[0].format('YYYY-MM-DD 00:00:00'),
                    endDate: rangeTimeValues[1].format('YYYY-MM-DD 23:59:59'),
                });
            } else {
                info();
            }
        });
    };

    componentDidMount() {
        this.setupWebSocket();
    }

    setupWebSocket = () => {
        const socket = SockJS(`${apiUrl}volumetric-control`);
        this.stompClient = Stomp.over(socket);
        this.stompClient.connect({}, this.connect);
    };

    connect = () => {
        this.stompClient.subscribe('/topic/linearGraphReply', (messageOutput) => {
            const data = JSON.parse(messageOutput.body);
            console.log('graficaaaa:', data);
            this.setState({
                chartData: {
                    labels: data.details.map(item => item.linearGraphDate),
                    datasets: [
                        {
                            label: 'Volumen',
                            fill: true,
                            borderColor: 'blue',
                            data: data.details.map(item => item.linearGraphDailyVolume)
                        }
                    ]
                },
                chartOptions: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                max: data.totalStorageVolume
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Litros'
                            }
                        }]
                    }
                }
            });
        });
        const form = this.props.form;
        form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                const rangeTimeValues = values.period;
                let params = {
                    startDashboardDate: rangeTimeValues[0].format('YYYY-MM-DD 00:00:00'),
                    endDashboardDate: rangeTimeValues[1].format('YYYY-MM-DD 23:59:59'),
                    idSubproduct: this.state.idSubproduct
                }

                dashboardServices.post('dashboard/linearGraphInfoWS', params);
            }
        });
    };

    componentWillUnmount() {
        this.stompClient.disconnect();
    }

    onUpdateId = (idSubproduct) => {
        this.setState({
            idSubproduct: idSubproduct,
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <Row gutter={24}>
                    <Col span={8}>
                        <WebSocket startDate={this.state.startDate} endDate={this.state.endDate} onUpdateId={this.onUpdateId} />
                    </Col>
                    <Col span={16}>
                        <Form layout='vertical' >
                            <Line
                                data={this.state.chartData}
                                options={this.state.chartOptions}
                            />
                            <Form.Item
                                label="Consultar periodo"
                            >
                                {getFieldDecorator('period', {
                                    initialValue: [dayjs().subtract(6, 'days'), dayjs()]
                                })(
                                    <RangePicker
                                        lang='es'
                                        disabledDate={this.disabledDate}
                                        format="YYYY/MM/DD"
                                        placeholder={['Desde', 'Hasta']}
                                        onChange={this.handlePeriodChange}
                                    />
                                )}
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Form.create()(WarehouseLevelChart);
