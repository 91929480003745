import React, { Component } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Breadcrumb, Input, Row, Col, Card, Tooltip, Button, Spin, Select } from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import { getSelectedDistributionPoint } from '../../../common/components/widgets/DistributionPoint/distributionPointStorage';

const { Option } = Select;

class EquipmentAggregation extends Component {
    _isMount = false;
    constructor() {
        super();
        this.state = {
            hoseCodeExist: true,
            hoseCodeVS: {},
            warehouses: [],
            loadingEntryVolumeMeasurer: false,
            distributionPointId: getSelectedDistributionPoint(),
        };
    }

    componentDidMount() {
        this._isMount = true;
        this.getWarehouseOnDispensaries();
        window.addEventListener("distributionPointChange",(e) => {
            if(this._isMount){
                this.setState({ distributionPointId: getSelectedDistributionPoint()}, ()=>{
                    this.getWarehouseOnDispensaries();
                    this.props.form.setFieldsValue({ 'idWarehouse': undefined });
                });
            }
        });
    }
    componentWillUnmount() {
        this._isMount = false;
    }

    getWarehouseOnDispensaries = () => {
        dispensaryServices.get(`distributionPoints/${this.state.distributionPointId}/warehouses`)
            .then(response => {
                console.log("RESPONSE WAREHOUSES: ",response);
                this.setState({
                    warehouses: response
                });
            });
    }

    validateHoseCode(alreadyExist, equipmentKey) {
        this.setState({ hoseCodeExist: alreadyExist });

        if (alreadyExist) {
            return {
                validateStatus: 'error',
                errorMsg: `El identificador del equipo de entrada ${equipmentKey} ya existe actualmente.`
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null
        };
    }

    handleHoseCodeChange = e => {
        const newHoseCode = e.target.value.toUpperCase();

        this.setState({ hoseCodeVS: {} });
        this.setState({ loadingEntryVolumeMeasurer: true });

        if (newHoseCode.length === 2) {
            dispensaryServices.get(`equipment/checkIfEquipmentKeyExist/${newHoseCode}`)
                .then(response => {
                    console.log('GET', response);
                    if (response === true || response === false) {
                        this.setState({
                            hoseCodeVS: {
                                ...this.validateHoseCode(response, newHoseCode)
                            }
                        });
                    }
                    this.setState({ loadingEntryVolumeMeasurer: false });
                });
        }
    }

    handleSubmit = e => {
        e.preventDefault();
        this.setState({ loadingEntryVolumeMeasurer: true });
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                const newEquipment = {
                    equipmentKey: values.equipmentKey,
                    idWarehouse: values.idWarehouse,
                };
                console.log('Sending values:', newEquipment);

                dispensaryServices.post('equipment/insertInputEquipment', newEquipment)
                    .then(response => {
                        console.log('SUBMIT', response);
                        this.props.onAdd();
                        this.setState({ loadingEntryVolumeMeasurer: false });
                    });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { hoseCodeExist, hoseCodeVS } = this.state;
        const { onCancel } = this.props;

        const optionWarehouseDropdown = types => types.map(item => (
            <Option key={item.idWareHouse} value={item.idWareHouse}>{
                item.subproducts !== null ?
                    `${item.warehouseKey} - ${item.products.productDescription} - ${item.subproducts.description}` :
                    `${item.warehouseKey} - ${item.products.productDescription}`}
            </Option>
        ));

        const newEquipment = (
            <Spin size="large" tip="Cargando..." spinning={this.state.loadingEntryVolumeMeasurer}>

                <Row>
                    <Col span={8}>
                        <Card>
                            <Form.Item
                                label="Identificador del equipo de entrada"
                                validateStatus={hoseCodeVS.validateStatus}
                                help={hoseCodeVS.errorMsg}
                            >
                                {getFieldDecorator('equipmentKey', {
                                    rules: [{
                                        required: true, message: 'Por favor introduce el identificador del equipo de entrada.'
                                    }]
                                })(
                                    <Input
                                        minLength={2}
                                        maxLength={2}
                                        suffix={
                                            <Tooltip title="Introduce un valor a 2 caracteres, puedes usar sólo números (01), sólo letras (AB) o una combinación (1A)">
                                                <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                            </Tooltip>
                                        }
                                        onChange={this.handleHoseCodeChange}
                                    />
                                )}
                            </Form.Item>
                            <Form.Item
                                label="Almacén"
                            >
                                {getFieldDecorator('idWarehouse', {
                                    rules: [{
                                        required: true, message: 'Por favor selecciona un almacén.'
                                    }],
                                })(
                                    <Select
                                        placeholder="Selecciona un almacén"
                                    >
                                        {optionWarehouseDropdown(this.state.warehouses)}
                                    </Select>
                                )}
                            </Form.Item>
                            <Form.Item>
                                {getFieldDecorator('description', {
                                    initialValue: '',
                                    rules: [{
                                        message: 'Por favor introduce el identificador del equipo de entrada.'
                                    }]
                                })(
                                    <Input hidden />
                                )}
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
            </Spin>
        );

        return (
            <div>
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    <Breadcrumb.Item>Equipos de entrada</Breadcrumb.Item>
                    <Breadcrumb.Item>Agregar equipo de entrada</Breadcrumb.Item>
                </Breadcrumb>
                <Form layout='vertical' onSubmit={this.handleSubmit}>
                    <div className="hole-instructions">
                        <label>Define un identificador para el equipo de entrada</label>
                    </div>
                    <Form.Item>
                        {newEquipment}
                    </Form.Item>
                    <Row>
                        <Col span={8}>
                            <Row>
                                <Col span={12}>
                                    <Button className="customized-default" onClick={onCancel}>Cancelar</Button>
                                </Col>
                                <Col span={12}>
                                    <Button className="customized-primary" type="primary" htmlType="submit" disabled={hoseCodeExist}>Agregar equipo de entrada</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default Form.create({ name: 'equipmentAggregation' })(EquipmentAggregation);
