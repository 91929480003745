import React, { Component } from 'react';
import { MinusOutlined, PlusCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import {
    Row,
    Col,
    Button,
    Input,
    Divider,
    Modal,
    Select,
    DatePicker,
    Popover,
    Checkbox,
    Tooltip,
} from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import tooltip from '../../containers/components/tooltip.json';

const { TextArea } = Input;
const { confirm } = Modal;

const Option = Select.Option;

const removeEmptyElements = array => (
    array.filter(element => element !== null)
);

const removeGaugeInfo = array => array.map(item => (
    {
        gaugeCode: item.gaugeCode,
        gaugeDescription: item.gaugeDescription,
        gaugeCalibrationDate: item.gaugeCalibrationDate.format('YYYY-MM-DD HH:mm:ss'),
        gaugeUncertaintyMeasurement: item.gaugeUncertaintyMeasurement
    }
));

let hoseCodes = [''];

class GaugeAggregation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            containerCodeVS: {},
            gaugeIdentificatorVS: {},
            containerCodeExist: true,
            hoseIdentificatorVS: {},
            hoseCodeExist: false,
            visibleModal: false,
            loading: false,
            existedHoseCodes: [],
            gaugecode: [],
            isDinamicGauge: false,
        };
    }

    validateGaugeCode(equipmentKey) {
        return {
            validateStatus: 'error',
            errorMsg: `El identificador de medidor ${equipmentKey} ya existe actualmente.`,
        };
    }

    handleGaugeFlowChange = idx => evt => {
        let containerCode = this.props.containerCode;
        this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(true), } });
        this.setState({ gaugeIdentificatorVS: { ...this.validateGaugeIdentificationKey(true), } });

        let auditableEquipmentKey = undefined;

        auditableEquipmentKey = `SMD-${evt}-${containerCode.substring(0, 7)}-${this.props.form.getFieldValue(`gauges[${idx}].customerGaugeKey`)}`;

        if (auditableEquipmentKey !== undefined) {
            dispensaryServices.get(`equipment/checkIfAuditableEquipmentKeyExist/${auditableEquipmentKey}`)
                .then(response => {
                    if (response === true) {
                        this.cacheExistedHoseCodes(auditableEquipmentKey);
                        this.setState({ hoseCodeExist: true });
                        this.setState({
                            hoseIdentificatorVS: {
                                ...this.validateGaugeCode(auditableEquipmentKey),
                            }
                        });
                    }
                });
        }
    }

    handleGaugeCodeChange = k => evt => {
        let containerCode = this.props.containerCode;
        this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(true), } });

        let auditableEquipmentKey = undefined;

        if (this.props.containerType === 'POZO') {
            const reg = /^[0-9]{4}[A-Z]{3}$/;
            if (!reg.test(evt.target.value)) {
                this.setState({ gaugeIdentificatorVS: { ...this.validateWellIdentificationKey(false), } });
                this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(false), } });
            } else {
                auditableEquipmentKey = `SMD-${containerCode.substring(0, containerCode.length - 8)}-${evt.target.value}`;
                this.setState({ gaugeIdentificatorVS: { ...this.validateWellIdentificationKey(true), } });
                this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(true), } });
            }
        } else {
            const reg = /^[0-9]{4}$/;
            if (!reg.test(evt.target.value)) {
                this.setState({ gaugeIdentificatorVS: { ...this.validateGaugeIdentificationKey(false), } });
                this.setState({ hoseIdentificatorVS: { ...this.validateGeneralGaugeCode(false), } });

            } else {
                this.setState({ gaugeIdentificatorVS: { ...this.validateGaugeIdentificationKey(true), } });

                if (this.props.containerType === 'TANQUE') {

                    if (`${this.props.form.getFieldValue(`gauges[${k}].tankGaugeType`)}` === 'true') {
                        auditableEquipmentKey = `SMD-${this.props.form.getFieldValue(`gauges[${k}].gaugeFlow`)}-${containerCode.substring(0, 7)}-${evt.target.value}`;
                    } else {
                        auditableEquipmentKey = `SME-${containerCode.substring(0, 7)}-${evt.target.value}`;
                    }

                } else if (this.props.containerType === 'DUCTO') {

                    auditableEquipmentKey = `SMD-${containerCode.substring(0, 7)}-${evt.target.value}`;
                }
            }

        }

        if (auditableEquipmentKey !== undefined) {
            dispensaryServices.get(`equipment/checkIfAuditableEquipmentKeyExist/${auditableEquipmentKey}`)
                .then(response => {
                    if (response === true) {
                        this.setState({ hoseCodeExist: true });
                        this.setState({ hoseIdentificatorVS: { ...this.validateGaugeCode(auditableEquipmentKey), } });
                    }
                    this.setState({ hoseCodeExist: response });
                });
        }
    }

    validateGaugeIdentificationKey(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `La clave del medidor no es válida, debe contener 4 números`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    validateWellIdentificationKey(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `La clave de identificación del pozo no es válida, debe contener 4 números, seguido de 3 letras mayúsculas, con el formato Regex: [0-9]{4}[A-Z]{3}`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    validateGeneralGaugeCode(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `El formato de clave del medidor no es válido`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    handleAddHoseCode = () => {
        hoseCodes = hoseCodes.concat(['']);
    };

    handleRemoveHoseCode = idx => {
        let hcs = hoseCodes;
        hcs = hcs.filter((s, sidx) => idx !== sidx);
        hoseCodes = hcs;

        this.reviewArchivedHoseCodes();
    };

    reviewArchivedHoseCodes() {
        const arr1 = hoseCodes;
        const arr2 = this.state.existedHoseCodes;
        const found = arr1.some(r => arr2.includes(r));

        if (found) {
            this.setState({
                hoseCodeExist: true,
                hoseIdentificatorVS: {
                    validateStatus: 'error',
                    errorMsg: 'Identificador de manguera existente.',
                }
            });
        } else {
            this.setState({
                hoseCodeExist: false,
                hoseIdentificatorVS: {
                    validateStatus: 'success',
                    errorMsg: null,
                }
            });
        }
    }

    remove = (idx, k) => {
        this.handleRemoveHoseCode(idx);
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        // We need at least one passenger
        if (keys.length === 1) {
            return;
        }

        // can use data-binding to set
        form.setFieldsValue({
            keys: keys.filter(key => key !== k)
        });
    };

    showModal = () => {
        this.setState({
            visibleModal: true,
        });
    }

    handleOk = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const newGauge = {
                    idContainer: this.props.containerId,
                    gauges: removeGaugeInfo(removeEmptyElements(values.gauges)),
                };

                dispensaryServices.post('container/insertContainerGauges', newGauge)
                    .then(response => {
                        this.props.onAddGauges();
                    });
                this.setState({
                    visibleModal: false,
                });
                this.props.form.resetFields();
            }
        });
    }

    handleCancel = () => {
        this.setState({ isDinamicGauge: false, visibleModal: false, hoseIdentificatorVS: {} });
        this.props.form.resetFields();
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                removeGaugeInfo(removeEmptyElements(values.gauges));
                confirm({
                    title: '¿Está seguro que desea agregar un nuevo medidor?',
                    okText: 'Sí',
                    okType: 'danger',
                    cancelText: 'No',
                    centered: true,
                    onOk: () => new Promise((resolve) => {
                        setTimeout(() => resolve(this.handleOk()), 2000);
                    }).catch(() => console.log('error')),
                    onCancel() {},
                });
            }
        });
    };

    validateHoseIdentificator(hoseCode) {
        const { form } = this.props;
        const hoses = form.getFieldValue('data');

        if (hoses.filter(hose => hose.hoseCode === hoseCode).length === 0) {
            this.setState({ hoseCodeExist: false });
            return {
                validateStatus: 'success',
                errorMsg: null,
            };
        }
        this.setState({ hoseCodeExist: true });
        return {
            validateStatus: 'error',
            errorMsg: 'Cada identificador de manguera tiene que ser único.',
        };
    }

    handleHoseChange = event => {
        this.setState({
            hoseIdentificatorVS: {
                ...this.validateHoseIdentificator(event.target.value),
            }
        });
    }

    formatGaugeCode = idx => {
        let containerCode = this.props.containerCode;
        let gaugeCode = undefined

        if (this.props.containerType === 'TANQUE') {
            if (`${this.props.form.getFieldValue(`gauges[${idx}].tankGaugeType`)}` === "true") {
                gaugeCode = `SMD-${this.props.form.getFieldValue(`gauges[${idx}].gaugeFlow`)}-${containerCode.substring(0, 7)}-${this.props.form.getFieldValue(`gauges[${idx}].customerGaugeKey`)}`;
            } else {
                gaugeCode = `SME-${containerCode.substring(0, 7)}-${this.props.form.getFieldValue(`gauges[${idx}].customerGaugeKey`)}`;
            }

        } else if (this.props.containerType === 'POZO') {
            gaugeCode = `SMD-${containerCode.substring(0, containerCode.length - 8)}-${this.props.form.getFieldValue(`gauges[${idx}].customerGaugeKey`)}`;
        } else {
            gaugeCode = `SMD-${containerCode.substring(0, 7)}-${this.props.form.getFieldValue(`gauges[${idx}].customerGaugeKey`)}`;
        }

        return gaugeCode;
    }

    handleDinamicGaugeChange = (index) => (e) => {
        this.setState({ isDinamicGauge: e.target.checked });
    };

    render() {
        const { hoseIdentificatorVS, hoseCodeExist, gaugeIdentificatorVS } = this.state;
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const dateFormatList = 'DD/MM/YYYY';

        getFieldDecorator('keys', { initialValue: [0] });
        const keys = getFieldValue('keys');
        const formItems = keys.map((k, index) => (
            <div key={k}>
                <Row gutter={24}>
                    <Col span={8} hidden={this.props.containerType !== 'TANQUE'}>
                        <Tooltip title={tooltip.medidores.medidorDinamico}>
                            <Form.Item label="Tipo de medidor para tanque">
                                {getFieldDecorator(`gauges[${k}].tankGaugeType`, {
                                    valuePropName: 'checked',
                                    initialValue: false,
                                })(
                                    <Checkbox key={index} onChange={this.handleDinamicGaugeChange(index)}>{'Medidor dinámico'}</Checkbox>
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Tooltip title={tooltip.medidores.claveIdentificacion}>
                            <Form.Item
                                label="Clave de identificación del medidor"
                                validateStatus={hoseIdentificatorVS.validateStatus}
                                help={hoseIdentificatorVS.errorMsg}
                            >
                                {getFieldDecorator(`gauges[${k}].gaugeCode`, {
                                    initialValue: this.formatGaugeCode(k),
                                })(
                                    <Input className="common-input" disabled />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12} hidden={this.state.isDinamicGauge === false}>
                        <Tooltip title={tooltip.medidores.flujoMedidor}>
                            <Form.Item label="Flujo del medidor">
                                {getFieldDecorator(`gauges[${k}].gaugeFlow`, {
                                    initialValue: 'ETA',
                                    rules: [
                                        { required: true, message: 'Por favor selecciona el flujo del medidor.' },
                                    ]
                                })(
                                    <Select onChange={this.handleGaugeFlowChange(k)}>
                                        <Option key={'ETA'} value={'ETA'}>{'Para entrada a medios de almacenamiento '}</Option>
                                        <Option key={'SDA'} value={'SDA'}>{'Para salida a medios de almacenamiento'}</Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={12}>
                        <Tooltip title={tooltip.medidores.codigo}>
                            <Form.Item
                                label="Código del medidor"
                                validateStatus={gaugeIdentificatorVS.validateStatus}
                                help={gaugeIdentificatorVS.errorMsg}
                            >
                                {getFieldDecorator(`gauges[${k}].customerGaugeKey`, {
                                    rules: [
                                        { required: true, message: 'Por favor introduce el código del medidor.' },
                                    ]
                                })(
                                    <Input className="common-input" onChange={this.handleGaugeCodeChange(k)} />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Tooltip title={tooltip.medidores.fechaCalibracion}>
                            <Form.Item label="Fecha de calibración">
                                {getFieldDecorator(`gauges[${k}].gaugeCalibrationDate`, {
                                    rules: [{
                                        required: true, message: 'Por favor introduce la fecha de calibración del medidor.'
                                    }]
                                })(
                                    <DatePicker  lang='es' style={{ width: '100%' }} format={dateFormatList} />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col span={12}>
                        <Tooltip title={tooltip.medidores.incertidumbre}>
                            <Form.Item label="Incertidumbre de medición">
                                {getFieldDecorator(`gauges[${k}].gaugeUncertaintyMeasurement`, {
                                    rules: [{
                                        required: true, message: 'Por favor introduce la incertidumbre de medición del medidor.'
                                    }]
                                })(
                                    <Input className="common-input" />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Tooltip title={tooltip.medidores.descripcion}>
                            <Form.Item label="Descripción">
                                {getFieldDecorator(`gauges[${k}].gaugeDescription`, {
                                    rules: [{
                                        required: true, message: 'Por favor introduce la descripción del medidor.'
                                    }]
                                })(
                                    <TextArea className="common-input" autoSize={{ minRows: 1, maxRows: 6 }} />
                                )}
                            </Form.Item>
                        </Tooltip>
                    </Col>
                </Row>
                <Row hidden={k < 1} gutter={24} className="container-minus-button" >
                    <Col span={12} offset={6} align="center">
                        {k > -0 ? (
                            <Popover content="Eliminar medidor">
                                <Button shape="circle" icon={<MinusOutlined />} onClick={() => this.remove(index, k)} />
                            </Popover>
                        ) : null}
                    </Col>
                    <br />
                    <Divider />
                </Row>
            </div>
        ));

        return (
            <div style={{ display: 'inline-block' }}>
                <Popover content="Agregar medidores">
                    <a type="link" shape="circle" onClick={() => this.showModal()}>
                        <PlusCircleTwoTone style={{ fontSize: '20px' }} />
                    </a>
                </Popover>
                <Modal
                    width={900}
                    open={this.state.visibleModal}
                    title="Agregar medidores"
                    onCancel={this.handleCancel}
                    footer={[
                        <Button className="back-button" onClick={this.handleCancel} key={0}>Cancelar</Button>,
                        <Button className="submit-button" onClick={this.handleSubmit} disabled={hoseCodeExist} key={1}>Agregar</Button>
                    ]}
                >
                    <Form layout='vertical' onSubmit={this.handleSubmit}>
                        <div className="hole-instructions">
                            <label>Llena los campos a continuación para agregar medidores al contenedor {this.props.containerCode}</label>
                        </div>
                        {formItems}
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default Form.create({ name: 'gaugeAggregation' })(GaugeAggregation);