import request from './request';
import { serializeParams } from '../common/helpers';

export const get = (url = '', params = {}) => request().get(`${url}${serializeParams(params)}`)
    .then(response => response.data)
    .catch(error => error);

export const put = (url = '', params = {}) => {
    return new Promise((resolve, reject) =>{
        request().put(url, params)
        .then(response => resolve(response.data))
        .catch(error => { reject(error.response.data)});
    })
} 

export const Delete = (url = '', params = {}) => request().delete(url, params)
    .then(response => response.data)
    .catch(error => error);

export const post  = (url = '', params = {}) => {
    return new Promise((resolve, reject) => {
        request().post(url, params)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
        });
}
