import React from 'react';
import {Checkbox, Divider, Typography} from 'antd';

function ContainerInactiveMessage( { onChangeIncludeInPreviousInventories  }) {

    const onChange = (e) => {
        const checked = e.target.checked;
        onChangeIncludeInPreviousInventories(checked);
    }
    return (
        <div>
            <Checkbox onChange={onChange}>Desea incluir el contenedor en inventarios previos al deshabilitarlo</Checkbox>
            <Divider />
            <Typography.Text type="secondary">El Contenedor será descartado del control de existencias diario una vez inactivo</Typography.Text>
        </div>
    );
}

export default ContainerInactiveMessage;