import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Progress, Modal } from 'antd';
import { FirstListWareHousesSaved } from './components';
import { returnToRegister, ToFirstContainerRegistry, } from '../../security';
import {
    files as fileServices
} from '../../services';
import {
    getPagePermissions,
    INITIAL_WAREHOUSE_PERMISSIONS
} from '../../security/permissions';

import './styles.css';
import WareHouseAggregation from '../wareHouse/components/wareHouseAggregation';

class FirstWarehouses extends Component {
    constructor() {
        super();
        this.state = {
            company: false,
            areThereWareHouses: false,
            visible: false,

        };
        this.mounted = false
    }

    componentDidMount() {
        if(!this.mounted){
            this.mounted = true
            this.fetchCompany();
        }
    }

    fetchCompany = (params = {}) => {
        fileServices.get('company')
            .then(response => {
                if (response.length > 0) {
                    this.setState({ company: true });
                    this.fetchWareHouses();
                    this.info();
                }
            });
    };

    fetchWareHouses = () => {
        fileServices.get('warehouse')
            .then(response => {
                if (response.length > 0) {
                    this.setState({
                        areThereWareHouses: true,
                        wareHouseSaved: response
                    });
                } else {
                    this.setState({
                        areThereWareHouses: false,
                    });
                }
            });
    };

    info = () => {
        Modal.info({
            title: 'Atención',
            content: (
                <div>
                    <br />
                    <br />
                    <p style={{ fontSize: '20px' }}>La información agregada al registrar los almacenes no puede ser editada, por lo cual es de suma importancia ingresar y verificar la información cuidadosamente ya que este registro queda a responsabilidad del usuario.</p>
                    <br />
                </div>
            ),
            width: 1000,
            onOk() { },
        });
    }

    handleAddWareHouseClick(){
        setTimeout(() => {
            this.setState({ visibleModal: false });
            ToFirstContainerRegistry();
        }, 3000);
    }

    render() {
        return (
            <div>
                {this.state.company ? (
                    <div>
                        {this.state.wareHouseSaved ? (
                            <div>
                                <FirstListWareHousesSaved
                                    wareHouseSaved={this.state.wareHouseSaved}
                                />
                            </div>

                        ) : (
                                <div>
                                    <span style={{ fontSize: '24px' }}>Sin almacenes registrados</span>
                                </div>

                            )}
                        <div>
                            <br />
                            <br />
                            <WareHouseAggregation onAddWareHouseClick={() => this.handleAddWareHouseClick()} hideCancelButton={true}/>
                        </div>
                    </div>

                ) : (
                        <div className="center-div">
                            <Progress type="circle" percent={70} status="exception" />
                            <div style={{ margin: '8px' }}>
                                <span>Por favor registra los datos de la empresa antes de añadir de añadir contenedores</span>
                            </div>
                            <Button className="back-button" key="back" onClick={() => returnToRegister()}>Registrar empresa</Button>
                        </div>
                    )}
            </div>

        );

    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(INITIAL_WAREHOUSE_PERMISSIONS, permissions)
});

export default connect(mapStateToProps)(FirstWarehouses);
