import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import {
    Breadcrumb,
    InputNumber,
    Checkbox,
    Divider,
    Tree,
    Row,
    Col,
    Button,
    Select,
    Spin,
} from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import { getSelectedDistributionPoint } from '../../../common/components/widgets/DistributionPoint/distributionPointStorage';

const { TreeNode } = Tree;
const { Option } = Select;

const getHoseUUIDs = dispensaries => {
    const hoseArray = dispensaries.map(dispensary =>
        dispensary.hoses
    );

    const hoseUUIDs = [];
    hoseArray.forEach(element => {
        element.forEach(hose => {
            hoseUUIDs.push(hose.hoseUUID);
        });
    });

    return hoseUUIDs;
};

class HosesPriceChange extends Component {
    _isMount = false;
    constructor(props) {
        super(props);
        this.state = {
            dispensaries: props.dispensaries,
            checkedKeys: [],
            hoses: getHoseUUIDs(props.dispensaries),
            products: [],
            subproducts: [],
            loading: false,
            enableButton: true,
            distributionPointId: getSelectedDistributionPoint(),
        };
    }

    componentDidMount() {
        this._isMount = true;
        this.fetchProducts();
        window.addEventListener("distributionPointChange",(e) => {
            if(this._isMount){
                this.setState({ distributionPointId: getSelectedDistributionPoint()}, ()=>{
                    this.props.form.resetFields();
                    this.fetchProducts();
                });
            }
        });
    }
    componentWillUnmount() {
        this._isMount = false;
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ hoses: getHoseUUIDs(nextProps.dispensaries) });
    }

    onCheckAllChange = (e) => {
        this.setState({
            checkedKeys: e.target.checked ? this.state.hoses : []
        });
    }

    onCheck = (checkedKeys, event) => {
        if (event.node.props.children) {
            const keys = event.node.props.children.map(child =>
                child.key
            );

            if (event.checked) {
                const hoses = keys.filter(item =>
                    !this.state.checkedKeys.includes(item)
                );
                this.setState({ checkedKeys: this.state.checkedKeys.concat(hoses) });
            } else {
                const hoses = this.state.checkedKeys.filter(item =>
                    !keys.includes(item)
                );
                this.setState({ checkedKeys: hoses });
            }
        } else if (!event.checked) { this.removeHoseUUID(event.node.props.eventKey); } else { this.addHoseUUID(event.node.props.eventKey); }
    }

    removeHoseUUID = hoseUUID => {
        const hoses = this.state.checkedKeys.filter(hose =>
            hose !== hoseUUID
        );

        this.setState({ checkedKeys: hoses });
    }

    addHoseUUID = hoseUUID => {
        this.setState({ checkedKeys: this.state.checkedKeys.concat(hoseUUID) });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                console.log('Received values of form: ', values);
                const hosePrice = {
                    price: values.price,
                    hoses: this.state.checkedKeys,
                    distributionPointId: this.state.distributionPointId
                };
                dispensaryServices.put('hose/updateHosePrice', hosePrice)
                    .then(response => {
                        console.log('SUBMIT', response);
                        this.props.onChangePrices(response);
                        this.setState({ loading: false })
                    });
            }
        });
    }
    fetchProducts = () => {
        dispensaryServices.get(`distributionPoints/${this.state.distributionPointId}/activeProducts`)
            .then(response => {
                console.log('GET', response);
                this.setState({
                    products: response
                });
            });
    };

    fetchSubProducts = idx => {
        dispensaryServices.get(`getProductSubProducts/${idx}`)
            .then(response => {
                console.log('GET', response);
                this.setState({
                    subproducts: response
                });
            });
    };

    enableButton = idSubproduct => {
        if (idSubproduct !== 0) {
            this.setState({ enableButton: false });
        } else {
            this.setState({ enableButton: true });
        }
    };

    changePriceSubproduct = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                console.log('Received values of form: ', values);
                const hosePrice = {
                    price: values.price,
                    idSubproduct: values.idSubproduct,
                    distributionPointId: this.state.distributionPointId
                };
                dispensaryServices.put('hose/updateHosePriceBySubproduct', hosePrice)
                    .then(response => {
                        console.log('SUBMIT', response);
                        this.props.onChangePrices(response);
                        this.setState({ loading: false })
                    });
            }
        });

    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { dispensaries, onCancelClick, } = this.props;

        const optionProducts = types => types.map(item => (
            <Option key={item.productCode} value={item.idProduct}>{item.productDescription}</Option>
        ));
        const optionSubproducts = types => types.map(item => (
            <Option key={item.code} value={item.idSubproduct}>{item.description}</Option>
        ));
        const treeItems = dispensaries.map(data =>
        (<Col key={data.dispensaryCode} span={6}>
            <Tree
                checkable
                defaultExpandedKeys={[data.dispensaryCode]}
                selectable={false}
                checkedKeys={this.state.checkedKeys}
                onCheck={(data, event) => this.onCheck(data, event)}
            >
                <TreeNode key={data.dispensaryCode} title={<b>Dispensario {data.dispensaryCode}</b>}>
                    {data.hoses.map(value =>
                        <TreeNode key={value.hoseUUID} title={`Manguera ${value.responseHoseCode} $${value.price.toFixed(2)}`} />)}
                </TreeNode>
            </Tree>
        </Col>)
        );

        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loading}>
                <div>
                    <Breadcrumb style={{ marginBottom: '16px' }}>
                        <Breadcrumb.Item>Dispensarios</Breadcrumb.Item>
                        <Breadcrumb.Item>Cambiar precios</Breadcrumb.Item>
                    </Breadcrumb>
                    <Form layout='vertical' >
                        <Form.Item >
                            <span> Define un nuevo precio por litro y selecciona los dispensarios y/o mangueras a los que quieras aplicarles el nuevo precio.</span>
                        </Form.Item>
                        <Form.Item
                            label="Nuevo precio por litro"
                        >
                            {getFieldDecorator('price', {
                                rules: [{ required: true, message: 'Por favor introduce el nuevo precio por litro.' }]
                            })(
                                <InputNumber
                                    style={{ width: '288px' }}
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    min={0.01}
                                    step={0.01}
                                    precision={2}
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Cambio de precio"

                        >
                            {getFieldDecorator('changePrice', {
                                rules: [{
                                    required: true, message: 'Por favor selecciona una opcion de cambio de precio.'
                                }],
                            })(
                                <Select style={{ width: '288px' }}
                                    placeholder="Seleccionar un tipo de cambio de precio"
                                    onChange={(value) => this.setState({ enableButton: value === 'XSUBPRODUCT' ? true : false })}
                                >
                                    <Option value="XSUBPRODUCT">Subproducto</Option>
                                    <Option value="XDISPENSARY">Dispensario</Option>
                                </Select>
                            )}
                        </Form.Item>
                        {this.props.form.getFieldValue('changePrice') === 'XDISPENSARY' &&
                            <Form.Item>
                                <Checkbox
                                    onChange={this.onCheckAllChange}
                                    checked={this.state.checkedKeys.length === this.state.hoses.length}
                                >
                                    Seleccionar todo
                            </Checkbox>
                                <Divider />
                                <Row>
                                    {treeItems}
                                </Row>
                                <Divider />
                                <Button className="customized-primary" type="primary" onClick={this.handleSubmit} disabled={this.state.checkedKeys.length === 0}>Cambiar precios</Button>
                                <Button className="customized-default" style={{ marginLeft: 16 }} onClick={onCancelClick}>Cancelar</Button>
                            </Form.Item>

                        }
                        {this.props.form.getFieldValue('changePrice') === 'XSUBPRODUCT' &&

                            <Form.Item>
                                <Row gutter={24}>
                                    <Col span={8}>
                                        <Form.Item label="Tipo de producto">
                                            {getFieldDecorator('idProduct', {
                                                rules: [{ required: true, message: 'Por favor selecciona el tipo de producto.' }]
                                            })(
                                                <Select
                                                    onChange={(idProduct) => this.fetchSubProducts(idProduct)}
                                                >
                                                    {optionProducts(this.state.products)}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item label="Tipo de subproducto">
                                            {getFieldDecorator('idSubproduct', {
                                                rules: [{ required: this.state.subproducts.length !== 0 ? true : false, message: 'Por favor selecciona el tipo de subproducto.' }]
                                            })(
                                                <Select
                                                    onChange={(idSubproduct) => this.enableButton(idSubproduct)}
                                                >
                                                    {optionSubproducts(this.state.subproducts)}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider />
                                <Form.Item>
                                    <Button className="customized-primary" type="primary" onClick={this.changePriceSubproduct} disabled={this.state.enableButton} >Cambiar precios</Button>
                                    <Button className="customized-default" style={{ marginLeft: 16 }} onClick={onCancelClick}>Cancelar</Button>
                                </Form.Item>

                            </Form.Item>
                        }

                    </Form>
                </div>
            </Spin>
        );
    }
}

export default Form.create({ name: 'hosesPriceChange' })(HosesPriceChange);
