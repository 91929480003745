import React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import dayjs from "dayjs";
import {
    Modal,
    Input,
    InputNumber,
    Button,
    Row,
    Col,
    DatePicker,
    Popover,
    message,
    Tooltip,
} from 'antd';
import moment from 'moment';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import { connect } from 'react-redux';
import {
    getPagePermissions,
    DISPENSARY_PERMISSION
} from '../../../security/permissions';
import tooltip from './tooltip.json';
import tooltipWarehouse from '../../wareHouse/components/tooltip.json';

const { TextArea } = Input;
const { confirm } = Modal;

const ContainerShowInfo = Form.create({ name: 'containerShowForm_into_modal' })(

    class extends React.Component {
        state = {
            container: {},
            visible: false,
            wasEdited: false,
            visibleEliminationFormContainer: false,
            edit: false,
            integrationCodeVS: {},
            integrationCodeExist: true,
            integrationCodeUpdated: '',
        };

        showModal = (idContainer) => {
            dispensaryServices.get(`container/${idContainer}`)
                .then(response => {
                    this.setState({
                        container: response,
                        visible: true,
                    });
                });
        }

        handleReturn = () => {
            this.setState({
                visible: false,
                wasEdited: false,
            });
            this.props.form.resetFields();
        };

        editFields = () => {
            this.setState({
                wasEdited: true,
            });
        }

        deleteContainer = (containerId) => {
            dispensaryServices.Delete(`container/delete/${containerId}`)
                .then(response => {
                    const jsonResponse = JSON.parse(JSON.stringify(response));
                    if (jsonResponse.hasOwnProperty('response')) {
                        message.error(jsonResponse.response.data);
                    } else {
                        this.props.onDeleteContainers();
                    }
                });
            this.setState({
                visible: false,
            });
        }

        editContainer = () => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    const { container } = this.state;
                    const filters = {
                        idContainer: container.idContainer,
                        containerDescription: values.containerDescription,
                        containerCalibrationDate: values.containerCalibrationDate !== null ? values.containerCalibrationDate.format('YYYY-MM-DD HH:mm:ss') : null,
                        integrationCode: values.integrationCode,
                        containerDuctDiameter: container.containerDuctDiameter,
                        containerFundingCapacity: container.containerFundingCapacity,
                        containerHeelGasCapacity: container.containerHeelGasCapacity,
                        containerKey: container.containerKey,
                        containerCode: container.containerCode,
                        containerMinOperationalCapacity: container.containerMinOperationalCapacity,
                        containerOperationalCapacity: container.containerOperationalCapacity,
                        containerStatus: container.containerStatus,
                        containerTotalCapacity: container.containerTotalCapacity,
                        containerType: container.containerType,
                        containerUsefulCapacity: container.containerUsefulCapacity,
                        gauges: null
                    };
                    dispensaryServices.put('container', filters)
                        .then(response => {
                            this.props.onDeleteContainers();
                        });
                    this.setState({
                        visible: false,
                        wasEdited: false,
                    });
                }
            });
        }

        showDeleteConfirm = (containerKey, containerId) => {
            const { container } = this.state;
            confirm({
                title: `¿Está seguro que desea eliminar el contenedor ${containerKey}`,
                content: 'Se perderán los datos del medidor permanentemente',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                centered: true,
                onOk: () => new Promise((resolve) => {
                    setTimeout(() => resolve(this.deleteContainer(containerId)), 2000);
                }).catch(() => console.log('error')),
                onCancel() {},
            });
        }

        showEditConfirm = ({ containerKey, integrationCode }) => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                const titleConfirm = integrationCode !== values.integrationCode
                    ? `¿Está seguro que desea editar el contenedor ${containerKey} y cambiar el código de integración de ${integrationCode} a ${values.integrationCode}?`
                    : `¿Está seguro que desea editar el contenedor ${containerKey}?`;
                const messageConfirm = integrationCode !== values.integrationCode
                    ? 'Todas las nuevas mediciones serán asociadas a este contenedor y las mediciones previas a este cambio no se moverán ni se serán afectadas'
                    : 'Se perderán los datos del medidor';
                if (!err) {
                    confirm({
                        title: titleConfirm,
                        content: messageConfirm,
                        okText: 'Sí',
                        okType: 'danger',
                        cancelText: 'No',
                        centered: true,
                        onOk: () => new Promise((resolve) => {
                            setTimeout(() => resolve(this.editContainer()), 2000);
                        }).catch(() => console.log('error')),
                        onCancel() {},
                    });
                }
            });
        }

        onchangeIntegrationCode = (event, preValue) => {
            const integrationCode = event.target.value;
            this.setState({ integrationCodeVS: {} });
            if (integrationCode === preValue) {
                return;
            }
            const params = {
                integrationCode
            };
            this.setState({ integrationCodeUpdated: integrationCode });
            dispensaryServices.get('container/integration-code/exists', params)
                .then(response => {
                    if (response === true) {
                        this.setState({
                            integrationCodeVS: {
                                ...this.validateIntegrationCode(integrationCode),
                            }
                        });
                    }
                    this.setState({ integrationCodeExist: response });
                });
            this.editFields();
        }
        validateIntegrationCode = (integrationCode) => ({
            validateStatus: 'error',
            errorMsg: `El código de integración ${integrationCode} ya existe actualmente.`,
        })

        render() {
            const { container, integrationCodeVS } = this.state;
            const { containerId, pagePermissions } = this.props;
            const { getFieldDecorator } = this.props.form;
            return (
                <div style={{ display: 'inline-block' }} >
                    <Popover content={pagePermissions.edit ? 'Editar contenedor' : 'Ver información de contenedor'}>
                        <a type="link" shape="circle" onClick={() => this.showModal(containerId)}>
                            <LegacyIcon type={pagePermissions.edit ? 'edit' : 'info-circle'} theme="twoTone" style={{ fontSize: '20px' }} />
                        </a>
                    </Popover>
                    <Modal
                        open={this.state.visible}
                        title="Información del contenedor"
                        onCancel={this.handleReturn}
                        footer={pagePermissions.edit ? [
                            <Button type="danger" onClick={() => this.showDeleteConfirm(container)} key={0}>Eliminar</Button>,
                            this.state.wasEdited === false ?
                                <Button onClick={this.handleReturn} key={1}>Volver</Button> :
                                <Button type="primary" onClick={() => this.showEditConfirm(container)} key={2}>Editar</Button>
                        ] : []}
                    >
                        <p>A continucación se muestra la información detallada del contenedor.</p>
                        <Form layout='vertical' >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Tooltip title={tooltip.tipoContenedor}>
                                        <Form.Item label="Tipo de contenedor">
                                            <Input className="common-input" defaultValue={container.containerType} disabled />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Tooltip title={tooltip.tanques.claveInstalacion}>
                                        <Form.Item label="Clave de identificación">
                                            <Input className="common-input" defaultValue={container.containerKey} disabled />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={12} hidden>
                                    <Form.Item label="Clave de usuario de identificación">
                                        <Input className="common-input" defaultValue={container.containerCode} disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12} hidden={container.containerTotalCapacity === null}>
                                    <Tooltip title={tooltip.tanques.capacidadTotal}>
                                        <Form.Item label="Capacidad total">
                                            <InputNumber
                                                className="common-input"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                style={{ width: '100%' }}
                                                defaultValue={container.containerTotalCapacity}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={12} hidden={container.containerMinOperationalCapacity === null}>
                                    <Tooltip title={tooltip.tanques.nivelMinimoOperativo}>
                                        <Form.Item label="Volumen mínimo de operación">
                                            <InputNumber
                                                className="common-input"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                style={{ width: '100%' }}
                                                defaultValue={container.containerMinOperationalCapacity}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12} hidden={container.containerOperationalCapacity === null}>
                                    <Tooltip title={tooltip.tanques.capacidadOperativa}>
                                        <Form.Item label="Capacidad operativa">
                                            <InputNumber
                                                className="common-input"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                style={{ width: '100%' }}
                                                defaultValue={container.containerOperationalCapacity}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={12} hidden={container.containerUsefulCapacity === null}>
                                    <Tooltip title={tooltip.tanques.capacidadUtil}>
                                        <Form.Item label="Capacidad útil">
                                            <InputNumber
                                                className="common-input"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                style={{ width: '100%' }}
                                                defaultValue={container.containerUsefulCapacity}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12} hidden={container.containerFundingCapacity === null}>
                                    <Tooltip title={tooltip.tanques.capacidadFondaje}>
                                        <Form.Item label="Capacidad fondaje">
                                            <InputNumber
                                                className="common-input"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                style={{ width: '100%' }}
                                                defaultValue={container.containerFundingCapacity}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={container.containerFundingCapacity !== null ? 12 : 24} hidden={container.containerHeelGasCapacity === null}>
                                    <Tooltip title={tooltip.nivelMinimoLlenado}>
                                        <Form.Item label="Capacidad gas talón">
                                            <InputNumber
                                                className="common-input"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                style={{ width: '100%' }}
                                                defaultValue={container.containerHeelGasCapacity}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Tooltip title={tooltipWarehouse.tipoProducto}>
                                        <Form.Item label="Tipo de producto">
                                            {getFieldDecorator('idProduct', {
                                                initialValue: (container.product || {}).productCode + ' - ' + (container.product || {}).productDescription,
                                            })(
                                                <Input className="common-input" disabled />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={12}>
                                    <Tooltip title={tooltipWarehouse.tipoSubProducto}>
                                        <Form.Item label="Tipo de subproducto">
                                            {getFieldDecorator('idSubproduct', {
                                                initialValue: (container.subproduct || {}).code + ' - ' + (container.subproduct || {}).description,
                                            })(
                                                <Input className="common-input" disabled />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24} hidden={container.containerCalibrationDate === null}>
                                    <Tooltip title={tooltip.tanques.calibracion}>
                                        <Form.Item label="Vigencia de calibración">
                                            {getFieldDecorator('containerCalibrationDate', {
                                                initialValue: container.containerCalibrationDate !== null ? dayjs(container.containerCalibrationDate) : null,
                                                rules: [{
                                                    required: container.containerCalibrationDate !== null ? true : false, message: 'Por favor introduce la vigencia de calibración'
                                                }],
                                            })(
                                                <DatePicker  lang='es' style={{ width: '100%' }} disabled={pagePermissions.edit === false} format="DD-MM-YYYY" onChange={this.editFields} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Tooltip title={tooltip.tanques.descripcion}>
                                        <Form.Item label="Localización y descripción">
                                            {getFieldDecorator('containerDescription', {
                                                initialValue: container.containerDescription,
                                                rules: [{
                                                    required: true, message: 'Por favor introduce la localización y/o descripción del contenedor'
                                                }],
                                            })(
                                                <TextArea autoSize={{ minRows: 1, maxRows: 6 }} disabled={pagePermissions.edit === false} onChange={this.editFields} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Tooltip title={tooltip.codigoIntegracion}>
                                        <Form.Item
                                            label="Código de integración"
                                            validateStatus={integrationCodeVS.validateStatus}
                                            help={integrationCodeVS.errorMsg}
                                        >
                                            {getFieldDecorator('integrationCode', {
                                                initialValue: container.integrationCode,
                                            })(
                                                <Input onChange={e => this.onchangeIntegrationCode(e, container.integrationCode)} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </div >
            );
        }
    }
);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISPENSARY_PERMISSION, permissions)
});

export default connect(mapStateToProps)(ContainerShowInfo);
