import moment from 'moment';
import dayjs from "dayjs";
import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { FileExcelOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import {
    Row,
    Col,
    Input,
    Button,
    Select,
    DatePicker,
    Table,
    Pagination,
    Divider,
    Cascader,
} from 'antd';
import { ManualRegistrationForm, ExpandIcon } from './components';
import {
    logbook as logbookServices
} from '../../services';
import {
    getPagePermissions,
    EVENTS_PERMISSION,
    EXPORT_REPORT_PERMISSION
} from '../../security/permissions';
import { getSelectedDistributionPoint } from '../../common/components/widgets/DistributionPoint/distributionPointStorage';
import { saveJsonToFile } from '../stockControl/components/jsonViewer';
import { serializeParams } from '../../common/helpers';
import request from '../../services/request';
import { apiUrl } from 'app.config';

const Search = Input.Search;
const Option = Select.Option;
const { RangePicker } = DatePicker;

const options = [];

const registryTypes = [{
    value: null,
    text: 'Todos'
}, {
    value: '0',
    text: 'Evento'
}, {
    value: '1',
    text: 'Alarma'
}, {
    value: '2',
    text: 'Indeterminado'
}, {
    value: '40',
    text: 'Automático'
}];

const userProfiles = [{
    value: null,
    text: 'Todos'
}, {
    value: 'ADMINISTRATOR',
    text: 'Administrador'
}, {
    value: 'SUPERVISOR',
    text: 'Supervisor'
}, {
    value: 'OPERATOR',
    text: 'Operador'
}, {
    value: 'AUDITOR',
    text: 'Auditor fiscal'
}];


const filters = {
    displayPage: 0,
    displayAmount: 10,
    registryType: null,
    eventType: null,
    originType: 'all',
    originContent: null,
    eventDescription: null,
    responsibleUser: null,
    userProfile: null,
    startDate: moment('00:00:00', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment('23:59:59', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
};

class Logbook extends Component {
    _expandedRowKeys = new Set();

    _columns = [{
        title: 'Número de registro',
        dataIndex: 'eventFolio',
        width: '100px'
    },{
        title: 'Punto de Distribución',
        dataIndex: 'distributionPoint',
        width: '220px'
    }, {
        title: 'Fecha y hora de evento',
        dataIndex: 'eventDate',
        render: date => moment(date).format('YYYY/MM/DD HH:mm:ss'),
    }, {
        title: 'Descripción del evento',
        dataIndex: 'eventDescription',
        render: (text, record) => {
            const { eventFolio } = record;
            const expanded = this._expandedRowKeys.has(eventFolio);
            const maxCharacters = 150;
            const firstCharacters = text.substring(0, maxCharacters);
            const count = text.length;
            return (
                <span>
                    {firstCharacters}
                    {
                        count > maxCharacters &&
                        <span>
                            ...
                            <ExpandIcon
                                count={count}
                                maxCharacters={maxCharacters}
                                expanded={expanded}
                                onClick={() => this.onExpandIconClick(eventFolio)}
                            />
                        </span>
                    }
                </span>
            );
        }
    }, {
        title: 'Usuario responsable',
        dataIndex: 'responsibleUser',
    }, {
        title: 'Tipo de evento',
        dataIndex: 'eventType',
    }, {
        title: 'IP de origen',
        dataIndex: 'eventOriginIp',
    }];

    onExpand = (expanded, record) => {
        this.toggleExpandByEventFolio(record.eventFolio);
    };

    onExpandIconClick = (eventFolio) => {
        this.toggleExpandByEventFolio(eventFolio);
    };

    toggleExpandByEventFolio = (eventFolio) => {
        this._expandedRowKeys.has(eventFolio)
            ? this._expandedRowKeys.delete(eventFolio)
            : this._expandedRowKeys.add(eventFolio);

        this.setState({
            expandedRowKeys: Array.from(this._expandedRowKeys.values())
        });
    };

    constructor() {
        super();
        this.state = {
            visibleManualRegistrationModal: false,
            events: [],
            eventTypes: [],
            loading: false,
            options,
            expandedRowKeys: Array.from(this._expandedRowKeys.values()),
            distributionPointId: getSelectedDistributionPoint(),
            displayPage: 0,
            displayAmount: 10,
            pagination: {},
            currentPage:0,
        };
    }


    handleEventDescriptionSearch = value => {
        filters.eventDescription = value === '' ? null : value;
        this.fetchEvents(filters);
    }

    handleEventDescriptionChange = e => {
        filters.eventDescription = e.target.value === '' ? null : e.target.value;
        if (e.target.value === '') {
            this.fetchEvents(filters);
        }
    }

    handleOriginChange = (value, selectedOptions) => {
        filters.originType = value[0];
        filters.originContent = value[1];
        this.fetchEvents(filters);
    };

    handleRegistryTypeChange = (e) => {
        filters.registryType = e;
        this.fetchEvents(filters);
    }

    handleEventType = (e) => {
        filters.eventType = e;
        this.fetchEvents(filters);
    }

    handleUserProfile = (e) => {
        filters.userProfile = e;
        this.fetchEvents(filters);
    }

    handleResponsibleUserSearch = value => {
        filters.responsibleUser = value === '' ? null : value;
        this.fetchEvents(filters);
    }

    handleResponsibleUserChange = e => {
        filters.responsibleUser = e.target.value === '' ? null : e.target.value;
        if (e.target.value === '') {
            this.fetchEvents(filters);
        }
    }

    handlePeriodChange = (value) => {
        filters.startDate = value[0].format('YYYY-MM-DD HH:mm:ss');
        filters.endDate = value[1].format('YYYY-MM-DD HH:mm:ss');
        this.fetchEvents(filters);
    }

    showManualRegistrationModal = () => {
        this.setState({ visibleManualRegistrationModal: true });
    }

    handleCancelManualRegistrationModal = () => {
        const form = this.manualRegistrationFormRef.props.form;
        this.setState({ visibleManualRegistrationModal: false });
        form.resetFields();
    }

    saveManualRegistrationFormRef = (formRef) => {
        this.manualRegistrationFormRef = formRef;
    }

    handleManualRegister = () => {
        const form = this.manualRegistrationFormRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            const newManualRegister = {
                userId: 1,
                eventDescription: values.eventDescription,
                eventId: values.createEventType,
                distributionPointId: this.state.distributionPointId,
            };
            logbookServices.post('events/registerNewManualEvent', newManualRegister)
                .then(response => {
                    this.fetchEvents(filters);
                });

            form.resetFields();
            this.setState({ visibleManualRegistrationModal: false });
        });
    }

    fetchOrigins = () => {
        this.setState({ loading: true });
        logbookServices.get(`distributionPoints/${this.state.distributionPointId}/eventOriginTypes`)
            .then(response => {
                this.setState({
                    options: [...response],
                    loading: false
                });
            });
    };

    getEventTypes = () => {
        this.setState({ loading: true });
        logbookServices.get('events/getEventTypeFilter')
            .then(response => {
                this.setState({
                    eventTypes: response,
                    loading: false
                });
            });
    };

    fetchEvents = (params = {}, isPageChange = false) => {
        params.distributionPointId = this.state.distributionPointId;
        params.displayPage = this.state.displayPage;
        params.displayAmount = this.state.displayAmount;

        let queryParams = {
            page: isPageChange? this.state.displayPage: 0,
            size:this.state.displayAmount,
            sort: "eventDate,DESC"
        };
        request().post(`events${serializeParams(queryParams)}`, params).then((response) => {
            const pagination = { ...this.state.pagination };
            pagination.total = response.data.totalElements;
            this.setState({
                events: response.data.content,
                pagination,
                currentPage: isPageChange? this.state.displayPage + 1: 1,
            });
        }).finally(()=>{
            this.setState({loading: false});
        });
    }

    printLogbook = (type) => {
        filters.distributionPointId = this.state.distributionPointId;
        filters.reportType = type;
        this.setState({ loading: true });
        if(type === 'csv'){
            request().post(`events/reports/${filters.reportType}`, filters)
            .then(response => {
                saveJsonToFile(response.data, "text/csv", "bitacora.csv");
                this.setState({loading: false })                
            });
        }else{
            axios({
                url: `${apiUrl}/events/reports/${filters.reportType}`,
                method: 'POST',
                data: filters,
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'bitacora.pdf'); // or any other extension
                document.body.appendChild(link);
                link.click();
                this.setState({ loading: false });
            });
        }
    }

    resetFilters = (params) => {
        filters.displayPage = params.displayPage;
        filters.displayAmount = params.displayAmount;
        filters.registryType = params.registryType;
        filters.eventType = params.eventType;
        filters.originType = params.originType;
        filters.originContent = params.originContent;
        filters.eventDescription = params.eventDescription;
        filters.responsibleUser = params.responsibleUser;
        filters.userProfile = params.userProfile;
        filters.startDate = params.startDate;
        filters.endDate = params.endDate;
    }

    componentDidMount() {
        this._isMount = true;
        const initialValues = {
            displayPage: 0,
            displayAmount: 10,
            registryType: null,
            eventType: null,
            originType: 'all',
            originContent: null,
            eventDescription: null,
            responsibleUser: null,
            userProfile: null,
            startDate: moment('00:00:00', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment('23:59:59', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
        };
        this.resetFilters(initialValues);
        this.fetchEvents(initialValues);
        this.fetchOrigins();
        this.getEventTypes();

        window.addEventListener("distributionPointChange",(e) => {
            if(this._isMount){
                this.setState({ distributionPointId: getSelectedDistributionPoint()}, ()=>{
                    this.props.form.resetFields();
                    this.fetchEvents(initialValues);
                    this.fetchOrigins();
                });
            }
        });
    }

    componentWillUnmount() {
        this._isMount = false;
    }

    onPageChange = (page, pageSize) => {
        this.setState({displayPage: page-1}, ()=>{
            this.fetchEvents(filters, true);
        });
    }

    onShowSizeChange = (current, pageSize) => {
        this.setState({ displayPage: 0, displayAmount: pageSize}, ()=>{
            this.fetchEvents(filters);
        });
    }

    render() {
        const { pagePermissions, exportReportsPermissions } = this.props;
        const { getFieldDecorator } = this.props.form;

        const optionItems = types => types.map(item => (
            <Option key={item.value} value={item.value}>{item.label}</Option>
        ));

        const optionUserProfile = types => types.map(item => (
            <Option key={item.value} value={item.value}>{item.text}</Option>
        ));

        return (
            <div>
                <Form layout='vertical' style={{ background: '#F4FBFF', padding: '6px' }}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Search
                                placeholder="Buscar por descripción"
                                onChange={this.handleEventDescriptionChange}
                                onSearch={this.handleEventDescriptionSearch}
                                enterButton
                            />
                        </Col>
                        <Col span={6}>
                            {pagePermissions.add && <Button style={{ width: '100%' }} type="primary" icon={<PlusOutlined />} onClick={this.showManualRegistrationModal}>Agregar registro manual</Button>}
                        </Col>
                        <Col span={6}>
                            {exportReportsPermissions.read &&
                                <Button type="primary" icon={<FileExcelOutlined />} onClick={() => {this.printLogbook("csv");}}>
                                    Descargar CSV
                                </Button>}
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Origen"
                            >
                                {getFieldDecorator('origin', {
                                    initialValue: ['all']
                                })(
                                    <Cascader
                                        allowClear={false}
                                        options={this.state.options}
                                        onChange={this.handleOriginChange}
                                        showSearch
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Perfil de usuario"
                            >
                                {getFieldDecorator('userProfile', {
                                    initialValue: null
                                })(
                                    <Select
                                        onChange={this.handleUserProfile}
                                    >
                                        {optionUserProfile(userProfiles)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Tipo de registro"
                            >
                                {getFieldDecorator('registryType', {
                                    initialValue: null
                                })(
                                    <Select
                                        onChange={this.handleRegistryTypeChange}
                                    >
                                        {optionUserProfile(registryTypes)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Usuario responsable"
                            >
                                {getFieldDecorator('responsibleUser', {

                                })(
                                    <Search
                                        placeholder="Buscar por usuario"
                                        onChange={this.handleResponsibleUserChange}
                                        onSearch={this.handleResponsibleUserSearch}
                                        enterButton
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Tipo de evento"
                            >
                                {getFieldDecorator('eventType', {
                                    initialValue: '0'
                                })(
                                    <Select
                                        onChange={this.handleEventType}
                                    >
                                        {optionItems(this.state.eventTypes)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Seleccionar periodo"
                            >
                                {getFieldDecorator('period', {
                                    initialValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
                                })(
                                    <RangePicker
                                        lang='es'
                                        allowClear={false}
                                        style={{ width: '100%' }}
                                        format="YYYY/MM/DD HH:mm:ss"
                                        showTime={{ defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')] }}
                                        placeholder={['Desde', 'Hasta']}
                                        ranges={{
                                            Hoy: [dayjs().startOf('day'), dayjs().endOf('day')],
                                            'Esta semana': [dayjs().startOf('week'), dayjs().endOf('week')],
                                            'Este mes': [dayjs().startOf('month'), dayjs().endOf('month')],
                                        }}
                                        onOk={this.handlePeriodChange}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Table
                    columns={this._columns}
                    scroll={{ x: true }}
                    expandIconAsCell={false}
                    expandIcon={() => null}
                    expandedRowKeys={this.state.expandedRowKeys}
                    onExpand={this.onExpand}
                    expandedRowRender={record => <div><b>Descripción</b><p style={{ margin: 0 }}>{record.eventDescription}</p></div>}
                    rowKey={record => record.eventFolio}
                    dataSource={this.state.events}
                    pagination={false}
                    loading={this.state.loading}
                />
                <br />
                <Pagination
                    showSizeChanger 
                    onChange={this.onPageChange} 
                    current={this.state.currentPage} 
                    onShowSizeChange={this.onShowSizeChange} 
                    total={this.state.pagination.total} 
                    pageSizeOptions={['1','10', '20', '50', '100', '200']} 
                    showTotal={total => `Total ${total} elemento(s)`} />
                <Divider />
                <ManualRegistrationForm
                    wrappedComponentRef={this.saveManualRegistrationFormRef}
                    visible={this.state.visibleManualRegistrationModal}
                    onCancel={this.handleCancelManualRegistrationModal}
                    onRegister={this.handleManualRegister}
                    evenTypes={this.state.eventTypes}
                />
            </div>
        );
    }
}

const LogbookForm = Form.create({ name: 'logbook' })(Logbook);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(EVENTS_PERMISSION, permissions),
    exportReportsPermissions: getPagePermissions(EXPORT_REPORT_PERMISSION, permissions)
});

export default connect(mapStateToProps)(LogbookForm);
