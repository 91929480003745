import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Row, Col, InputNumber } from 'antd';

class DictumDetailsPR09And10 extends Component {
    state = {
        molarFractionValidatorVS: {},
    };

    calculateSum = () => {
        const num1 = parseFloat(this.props.form.getFieldValue('gnc01MolarFraction'));
        const num2 = parseFloat(this.props.form.getFieldValue('gnc02MolarFraction'));
        const num3 = parseFloat(this.props.form.getFieldValue('gnc03MolarFraction'));
        const num4 = parseFloat(this.props.form.getFieldValue('gnc04MolarFraction'));
        const num5 = parseFloat(this.props.form.getFieldValue('gnc05MolarFraction'));
        const num6 = parseFloat(this.props.form.getFieldValue('gnc06MolarFraction'));
        const num7 = parseFloat(this.props.form.getFieldValue('gnc07MolarFraction'));
        const num8 = parseFloat(this.props.form.getFieldValue('gnc08MolarFraction'));
        const num9 = parseFloat(this.props.form.getFieldValue('gnc09MolarFraction'));
        const num10 = parseFloat(this.props.form.getFieldValue('gnc10MolarFraction'));
        this.props.form.validateFieldsAndScroll(['gnc01MolarFraction', 'gnc02MolarFraction', 'gnc03MolarFraction', 'gnc04MolarFraction'
            , 'gnc05MolarFraction', 'gnc06MolarFraction', 'gnc07MolarFraction', 'gnc08MolarFraction', 'gnc09MolarFraction', 'gnc10MolarFraction'], (err, values) => {
                if (!err) {
                    var molarFractionTotalValue = num1 + num2 + num3 + num4 + num5 + num6 + num7 + num8 + num9 + num10;
                    var molarFractionTotalValue = Math.round((molarFractionTotalValue + Number.EPSILON) * 100) / 100;
                    console.log("MOLAR FRACTION TOTAL VALUE3: ", molarFractionTotalValue !== 1.000, molarFractionTotalValue);
                    if (molarFractionTotalValue === 1.000) {
                        this.setState({ molarFractionValidatorVS: { ...this.validateMolarFractionValue(true), } });
                    } else {
                        this.setState({ molarFractionValidatorVS: { ...this.validateMolarFractionValue(false), } });
                    }
                } else {
                    this.setState({ molarFractionValidatorVS: { ...this.validateMolarFractionFields(false), } });
                }
            });
    }

    validateMolarFractionValue(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `La suma de las fracciones molares de todos los componentes no puede ser mayor a 1.`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    validateMolarFractionFields(response) {
        if (!response) {
            return {
                validateStatus: 'error',
                errorMsg: `Por favor ingresa la fracción molar de todos los componentes.`,
            };
        }
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { pagePermissions } = this.props;
        const { molarFractionValidatorVS } = this.state;

        return (
            <div>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Fracción molar metano"
                            validateStatus={molarFractionValidatorVS.validateStatus}
                            help={molarFractionValidatorVS.errorMsg}
                        >
                            {getFieldDecorator('gnc01MolarFraction', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[0].responseDictumDetailValue : '',
                                rules: [{
                                    required: this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10',
                                    message: 'Por favor introduce la fracción molar del metano.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onBlur={this.calculateSum}
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                    precision={3}
                                    min={0} max={1}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Poder calorífico metano">
                            {getFieldDecorator('gnc01HeathPower', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[0].responseAdditionalDictumDetailValue : '',
                                rules: [{
                                    required: (this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10') && parseFloat(this.props.form.getFieldValue('gnc01MolarFraction')) > 0.000,
                                    message: 'Por favor introduce el poder calorífico del metano.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    disabled={(this.props.isEditable === false || pagePermissions.edit === false) || parseFloat(this.props.form.getFieldValue('gnc01MolarFraction')) === 0.000}
                                    precision={3}
                                    min={0.001} max={150000}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Fracción molar etano"
                            validateStatus={molarFractionValidatorVS.validateStatus}
                            help={molarFractionValidatorVS.errorMsg}
                        >
                            {getFieldDecorator('gnc02MolarFraction', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[1].responseDictumDetailValue : '',
                                rules: [{
                                    required: this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10',
                                    message: 'Por favor introduce la fracción molar del etano.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onBlur={this.calculateSum}
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                    precision={3}
                                    min={0} max={1}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Poder calorífico etano">
                            {getFieldDecorator('gnc02HeathPower', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[1].responseAdditionalDictumDetailValue : '',
                                rules: [{
                                    required: (this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10') && parseFloat(this.props.form.getFieldValue('gnc02MolarFraction')) > 0.000,
                                    message: 'Por favor introduce el poder calorífico del etano.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    disabled={(this.props.isEditable === false || pagePermissions.edit === false) || parseFloat(this.props.form.getFieldValue('gnc02MolarFraction')) === 0.000}
                                    precision={3}
                                    min={0.001} max={150000}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Fracción molar propano"
                            validateStatus={molarFractionValidatorVS.validateStatus}
                            help={molarFractionValidatorVS.errorMsg}
                        >
                            {getFieldDecorator('gnc03MolarFraction', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[2].responseDictumDetailValue : 0.0,
                                rules: [{
                                    required: this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10',
                                    message: 'Por favor introduce la fracción molar del propano.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onBlur={this.calculateSum}
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                    precision={3}
                                    min={0} max={1}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Poder calorífico propano">
                            {getFieldDecorator('gnc03HeathPower', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[2].responseAdditionalDictumDetailValue : '',
                                rules: [{
                                    required: (this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10') && parseFloat(this.props.form.getFieldValue('gnc03MolarFraction')) > 0.000,
                                    message: 'Por favor introduce el poder calorífico del propano.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    disabled={(this.props.isEditable === false || pagePermissions.edit === false) || parseFloat(this.props.form.getFieldValue('gnc03MolarFraction')) === 0.000}
                                    precision={3}
                                    min={0.001} max={150000}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Fracción molar butanos"
                            validateStatus={molarFractionValidatorVS.validateStatus}
                            help={molarFractionValidatorVS.errorMsg}
                        >
                            {getFieldDecorator('gnc04MolarFraction', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[3].responseDictumDetailValue : 0.0,
                                rules: [{
                                    required: this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10',
                                    message: 'Por favor introduce la fracción molar de los butanos.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onBlur={this.calculateSum}
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                    precision={3}
                                    min={0} max={1}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Poder calorífico butanos">
                            {getFieldDecorator('gnc04HeathPower', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[3].responseAdditionalDictumDetailValue : '',
                                rules: [{
                                    required: (this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10') && parseFloat(this.props.form.getFieldValue('gnc04MolarFraction')) > 0.000,
                                    message: 'Por favor introduce el poder calorífico de los butanos.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    disabled={(this.props.isEditable === false || pagePermissions.edit === false) || parseFloat(this.props.form.getFieldValue('gnc04MolarFraction')) === 0.000}
                                    precision={3}
                                    min={0.001} max={150000}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Fracción molar pentanos"
                            validateStatus={molarFractionValidatorVS.validateStatus}
                            help={molarFractionValidatorVS.errorMsg}
                        >
                            {getFieldDecorator('gnc05MolarFraction', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[4].responseDictumDetailValue : 0.0,
                                rules: [{
                                    required: this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10',
                                    message: 'Por favor introduce la fracción molar de los pentanos.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onBlur={this.calculateSum}
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                    precision={3}
                                    min={0} max={1}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Poder calorífico pentanos">
                            {getFieldDecorator('gnc05HeathPower', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[4].responseAdditionalDictumDetailValue : '',
                                rules: [{
                                    required: (this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10') && parseFloat(this.props.form.getFieldValue('gnc05MolarFraction')) > 0.000,
                                    message: 'Por favor introduce el poder calorífico de los pentanos.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    disabled={(this.props.isEditable === false || pagePermissions.edit === false) || parseFloat(this.props.form.getFieldValue('gnc05MolarFraction')) === 0.000}
                                    precision={3}
                                    min={0.001} max={150000}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Fracción molar hexanos"
                            validateStatus={molarFractionValidatorVS.validateStatus}
                            help={molarFractionValidatorVS.errorMsg}
                        >
                            {getFieldDecorator('gnc06MolarFraction', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[5].responseDictumDetailValue : 0.0,
                                rules: [{
                                    required: this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10',
                                    message: 'Por favor introduce la fracción molar de los hexanos.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onBlur={this.calculateSum}
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                    precision={3}
                                    min={0} max={1}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Poder calorífico hexanos">
                            {getFieldDecorator('gnc06HeathPower', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[5].responseAdditionalDictumDetailValue : '',
                                rules: [{
                                    required: (this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10') && parseFloat(this.props.form.getFieldValue('gnc06MolarFraction')) > 0.000,
                                    message: 'Por favor introduce el poder calorífico de los hexanos.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    disabled={(this.props.isEditable === false || pagePermissions.edit === false) || parseFloat(this.props.form.getFieldValue('gnc06MolarFraction')) === 0.000}
                                    precision={3}
                                    min={0.001} max={150000}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Fracción molar heptanos"
                            validateStatus={molarFractionValidatorVS.validateStatus}
                            help={molarFractionValidatorVS.errorMsg}
                        >
                            {getFieldDecorator('gnc07MolarFraction', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[6].responseDictumDetailValue : 0.0,
                                rules: [{
                                    required: this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10',
                                    message: 'Por favor introduce la fracción molar de los heptanos.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onBlur={this.calculateSum}
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                    precision={3}
                                    min={0} max={1}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Poder calorífico heptanos">
                            {getFieldDecorator('gnc07HeathPower', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[6].responseAdditionalDictumDetailValue : '',
                                rules: [{
                                    required: (this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10') && parseFloat(this.props.form.getFieldValue('gnc07MolarFraction')) > 0.000,
                                    message: 'Por favor introduce el poder calorífico de los heptanos.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    disabled={(this.props.isEditable === false || pagePermissions.edit === false) || parseFloat(this.props.form.getFieldValue('gnc07MolarFraction')) === 0.000}
                                    precision={3}
                                    min={0.001} max={150000}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Fracción molar octanos"
                            validateStatus={molarFractionValidatorVS.validateStatus}
                            help={molarFractionValidatorVS.errorMsg}
                        >
                            {getFieldDecorator('gnc08MolarFraction', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[7].responseDictumDetailValue : 0.0,
                                rules: [{
                                    required: this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10',
                                    message: 'Por favor introduce la fracción molar de los octanos.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onBlur={this.calculateSum}
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                    precision={3}
                                    min={0} max={1}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Poder calorífico octanos">
                            {getFieldDecorator('gnc08HeathPower', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[7].responseAdditionalDictumDetailValue : '',
                                rules: [{
                                    required: (this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10') && parseFloat(this.props.form.getFieldValue('gnc08MolarFraction')) > 0.000,
                                    message: 'Por favor introduce el poder calorífico de los octanos.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    disabled={(this.props.isEditable === false || pagePermissions.edit === false) || parseFloat(this.props.form.getFieldValue('gnc08MolarFraction')) === 0.000}
                                    precision={3}
                                    min={0.001} max={150000}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Fracción molar nonanos"
                            validateStatus={molarFractionValidatorVS.validateStatus}
                            help={molarFractionValidatorVS.errorMsg}
                        >
                            {getFieldDecorator('gnc09MolarFraction', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[8].responseDictumDetailValue : 0.0,
                                rules: [{
                                    required: this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10',
                                    message: 'Por favor introduce la fracción molar de los nonanos.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onBlur={this.calculateSum}
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                    precision={3}
                                    min={0} max={1}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Poder calorífico nonanos">
                            {getFieldDecorator('gnc09HeathPower', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[8].responseAdditionalDictumDetailValue : '',
                                rules: [{
                                    required: (this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10') && parseFloat(this.props.form.getFieldValue('gnc09MolarFraction')) > 0.000,
                                    message: 'Por favor introduce el poder calorífico de los nonanos.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    disabled={(this.props.isEditable === false || pagePermissions.edit === false) || parseFloat(this.props.form.getFieldValue('gnc09MolarFraction')) === 0.000}
                                    precision={3}
                                    min={0.001} max={150000}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label="Fracción molar decanos"
                            validateStatus={molarFractionValidatorVS.validateStatus}
                            help={molarFractionValidatorVS.errorMsg}
                        >
                            {getFieldDecorator('gnc10MolarFraction', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[9].responseDictumDetailValue : 0.0,
                                rules: [{
                                    required: this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10',
                                    message: 'Por favor introduce la fracción molar de los heptanos.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    onBlur={this.calculateSum}
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                    precision={3}
                                    min={0} max={1}
                                />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Poder calorífico decanos">
                            {getFieldDecorator('gnc10HeathPower', {
                                initialValue: this.props.data !== null && (this.props.data.productType === 'PR09' || this.props.data.productType === 'PR10')
                                    ? this.props.data.dictumDetails[9].responseAdditionalDictumDetailValue : '',
                                rules: [{
                                    required: (this.props.selectedProduct === 'PR09' || this.props.selectedProduct === 'PR10') && parseFloat(this.props.form.getFieldValue('gnc10MolarFraction')) > 0.000,
                                    message: 'Por favor introduce el poder calorífico de los decanos.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    disabled={(this.props.isEditable === false || pagePermissions.edit === false) || parseFloat(this.props.form.getFieldValue('gnc10MolarFraction')) === 0.000}
                                    precision={3}
                                    min={0.001} max={150000}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Form.create({ name: 'dictumDetailsPR09And10' })(DictumDetailsPR09And10);
