import { Button, Modal, message } from 'antd';
import React from 'react';
import SockJS from 'sockjs-client';
import { apiUrl } from '../../../../../app.config';
import { Stomp } from '@stomp/stompjs';
import { getAllowedDistributionPoints, logOut, removeToken } from '../../../../../security';

class SubscriptionChange extends React.Component {
    stompClient = null;
    constructor() {
        super();
        this.state = {
            showLicenseAlert: false,
            isAdmin: false,
            refreshNow: true,
        };
    }

    componentDidMount() {
        this.setupWebSocket();
    }

    setupWebSocket = () => {
        const socket = SockJS(`${apiUrl}volumetric-control`);
        this.stompClient = Stomp.over(socket);
        this.stompClient.connect({}, this.connect);
    };

    connect = () => {
        this.stompClient.subscribe('/topic/subscriptionChange', (messageOutput) => {
            const updatedDP = JSON.parse(messageOutput.body)
            getAllowedDistributionPoints.then((allowedDistributionPoints) => {
                if (allowedDistributionPoints && allowedDistributionPoints.length > 0) {
                    const dpp = allowedDistributionPoints.filter(x => x.id === updatedDP.id);
                    if (dpp.length > 0) {
                        removeToken();
                        this.setState({ showLicenseAlert: true });
                    }
                } else {
                    this.setState({ isAdmin: true, showLicenseAlert: true });
                    if (!this.state.refreshNow) {
                        message.warning('No olvide recargar su navegador para aplicar los cambios');
                    }
                }
            });
        });
    };

    footerButtons = () => {
        return [
            this.state.isAdmin && <Button key="back" type='ghost' onClick={() => { this.setState({ showLicenseAlert: false, refreshNow: false }) }}>
                Recargar después
            </Button>,
            <Button key="submit" type="primary" onClick={() => {
                (this.state.isAdmin) ? window.location.reload() : logOut();
                this.setState({ showLicenseAlert: false });
            }}>
                {this.state.isAdmin ? "Recargar" : "Cerrar Sesión"}
            </Button>
        ];
    }

    render() {
        return (
            <Modal
                open={this.state.showLicenseAlert && this.state.refreshNow}
                title={"Se detectó un cambio que afecta tu sesión"}
                closable={false}
                footer={this.footerButtons()}
            >
                <span>
                    Un punto de distribución relacionado a tu usuario ha cambiado.
                    Por favor te pedimos {this.state.isAdmin ? "recargar" : "iniciar sesión nuevamente"}  para que los cambios realizados se vean reflejados
                </span>
            </Modal>
        );
    }
}

export default SubscriptionChange;