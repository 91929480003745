import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Modal } from "antd";
import React from 'react';


export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
}

export const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
        openBase64InModal(file.url || file.preview);
    }
}
export const openBase64InModal = (base64) => {
    Modal.info({
        width: '80%',
        maskClosable: true,
        icon: <LegacyIcon type={null} />,
        content: (<img alt="example" style={{ width: '100%' }} src={base64} />)
    });
}