/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 11:26:40
 * @Last Modified by: Alejandro Leonor
 * @Last Modified time: 2018-03-14 15:03:46
 */
import axios from 'axios';

export const fetchData = (username = '') =>
    axios.get(`https://api.github.com/users/${username}`)
        .then(data => data.data);
