import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Input, Button, notification } from 'antd';
import { login, saveToken, saveIsTemporalPassword, onSuccess, getIsTemporalPasswordFromClaims, saveRefreshToken } from '../../../security';
import powered_by_ssf from './../../../assets/images/powered_by_ssf.png';
import { getRemoteIp } from '../../../common/helpers/remoteIp';


class LoginForm extends Component {
    constructor() {
        super();
        this.state = {
            email: {},
            password: {},
            loading: false,
            useIp: '',
        };
    }
    componentDidMount() {
        getRemoteIp().then(ip => {
            this.setState({ useIp: ip })
        });
    }

    handleErrorCode(errorResponse) {
        const code = errorResponse.status;
        switch (code) {
            case 401:
                if (errorResponse.message.startsWith("El usuario con email") || errorResponse.message.includes("La cuenta de usuario fue desactivada y no puede iniciar sesión.")) {
                    this.setState({
                        email: {
                            ...this.validateErrorResponse(errorResponse.message)
                        }
                    });
                } else if (errorResponse.message.startsWith("La contraseña ingresada no es valida")) {
                    this.setState({
                        password: {
                            ...this.validateErrorResponse(errorResponse.message)
                        }
                    });
                }
                break;
            case 412:
                notification.error({
                    message: "Error en Suscripción",
                    description: errorResponse.message
                });
                break;
            default:
                break;
        }
    }

    validateErrorResponse(message) {
        return {
            validateStatus: 'error',
            errorMsg: message,
        };
    }

    login(email, password, localIp) {
        login(email, password, localIp)
            .then((tokenReponse) => {
                let token = tokenReponse.token;
                saveToken(token);
                saveRefreshToken(tokenReponse.idRefreshToken);
                getIsTemporalPasswordFromClaims(token).then((isTemporalPassword) => {
                    saveIsTemporalPassword(isTemporalPassword);
                }).finally(() => {
                    onSuccess();
                });
            }).catch((error) => {
                this.setState({ loading: false });
                this.handleErrorCode(error);
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                this.login(values.email, values.password, this.state.useIp);
            }
        });
    }

    handlePasswordFocus = () => {
        this.setState({
            password: {
                validateStatus: 'success',
                errorMsg: null,
            }
        });
    }

    handleEmailFocus = () => {
        this.setState({
            email: {
                validateStatus: 'success',
                errorMsg: null,
            }
        });
    }

    handlePasswordChange = (e) => {
        if (e.target.value.length === 0) {
            this.setState({
                password: {
                    validateStatus: 'error',
                    errorMsg: 'Por favor introduce tu contraseña',
                }
            });
        } else {
            this.setState({
                password: {
                    validateStatus: 'success',
                    errorMsg: null,
                }
            });
        }
    }

    handleEmailChange = (e) => {
        if (e.target.value.length === 0) {
            this.setState({
                email: {
                    validateStatus: 'error',
                    errorMsg: 'Por favor introduce tu correo electrónico',
                }
            });
        } else {
            this.setState({
                email: {
                    validateStatus: 'success',
                    errorMsg: null,
                }
            });
        }
    }

    handlePasswordBlur = (e) => {
        if (e.target.value.length === 0) {
            this.setState({
                password: {
                    validateStatus: 'error',
                    errorMsg: 'Por favor introduce tu contraseña',
                }
            });
        } else {
            this.setState({
                password: {
                    validateStatus: 'success',
                    errorMsg: null,
                }
            });
        }
    }

    handleEmailBlur = (e) => {
        if (e.target.value.length === 0) {
            this.setState({
                email: {
                    validateStatus: 'error',
                    errorMsg: 'Por favor introduce tu correo electrónico',
                }
            });
        } else {
            this.setState({
                email: {
                    validateStatus: 'success',
                    errorMsg: null,
                }
            });
        }
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleSubmit(event);
        }
    }

    render() {
        const { onForget } = this.props;
        const { email, password } = this.state;
        const { getFieldDecorator } = this.props.form;
        this.es_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

        return (
            <Form layout='vertical' >
                <Form.Item>
                    <h2 style={{ textAlign: 'center' }}>Iniciar sesión</h2>
                </Form.Item>
                <Form.Item
                    label="Correo electrónico"
                    validateStatus={email.validateStatus}
                    help={email.errorMsg}
                >
                    {getFieldDecorator('email', {
                        rules: [{
                            required: true, message: 'Por favor introduce tu correo electrónico'
                        }]
                    })(
                        <Input type="email" id="txtUserName" onKeyPress={this.handleKeyPress} onFocus={this.handleEmailFocus} onChange={this.handleEmailChange} onBlur={this.handleEmailBlur} />
                    )}
                </Form.Item>
                <Form.Item
                    label="Contraseña"
                    validateStatus={password.validateStatus}
                    help={password.errorMsg}
                >
                    {getFieldDecorator('password', {
                        rules: [{
                            required: true, message: 'Por favor introduce tu contraseña'
                        }]
                    })(
                        <Input type={this.es_firefox ? "password" : "text"} id="loginForm_password" onKeyPress={this.handleKeyPress} autoComplete="off" onFocus={this.handlePasswordFocus} onChange={this.handlePasswordChange} onBlur={this.handlePasswordBlur} />
                    )}
                </Form.Item>
                <Form.Item>
                    <a onClick={onForget} >¿Olvidaste tu contraseña?</a>
                    <Button type="primary" onClick={this.handleSubmit} className="login-form-button" loading={this.state.loading}>Iniciar sesión</Button>
                </Form.Item>
                <Form.Item className="powered-by">
                    <img src={powered_by_ssf} alt="ssf" />
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create({ name: 'loginForm' })(LoginForm);
