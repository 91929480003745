import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Row, Col, Button, Divider, message, Spin, Select, Table } from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';

const {Option} = Select;


class EmailSenderConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading: false,
            users: [],
            emailConfigTypes: [],
            emailConfigurations: [],
            loadingForm: false,
        }
    };

    _columns = [{
        title: 'Enviar correo cuando',
        dataIndex: 'emailConfigurationTypes.configurationDescription',
        width: '30%'
    }, {
        title: 'Usuario',
        dataIndex: 'email',
        width: '15%'
    }, {
        title: 'Eliminar Configuración',
        dataIndex: 'id',
        width: '30%',
        render: (text) => {
            return (
                <Button key="submit" type="danger" loading={this.state.loadingForm} onClick={() => this.handleDeleteConfig(text)}>
                    Eliminar
                </Button>
            );
        }
    }];

    componentDidMount() {
        this.getEmailConfigTypes();
        this.getActiveUsers();
        this.getEmailConfigurations();
    }


    getEmailConfigTypes = () => {
        this.setState({ loadingForm: true });
        dispensaryServices.get('outputEmail/getEmailConfigurationTypes')
            .then(response => {
                console.log('GET CONFIG TYPES', response);
                this.setState({
                    emailConfigTypes: response
                });
                this.setState({ loadingForm: false });

            });
    }

    getActiveUsers = () => {
        this.setState({ loadingForm: true });
        dispensaryServices.get('user',)
            .then(response => {
                console.log('FETCH USERS', response);
                this.setState({
                    users: response,
                });
                this.setState({ loadingForm: false });
            });
    };

    getEmailConfigurations = () => {
        this.setState({ loadingForm: true });
        dispensaryServices.get('outputEmail/getEmailConfigurations',)
            .then(response => {
                console.log('FETCH CONFIGS', response);
                this.setState({
                    emailConfigurations: response,
                });
                this.setState({ loadingForm: false });
            });
    };

    handleSubmit = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({ loading: true });
                console.log('Received values of form: ', values);
                dispensaryServices.post('outputEmail/saveEmailConfiguration', values)
                    .then(response => {
                        const jsonResponse = JSON.parse(JSON.stringify(response));
                        if (jsonResponse.hasOwnProperty('response')) {
                            message.error(jsonResponse.response.data);
                            this.getEmailConfigurations();
                            this.setState({ loading: false });
                        } else {
                            message.success("Configuración actualizada correctamente");
                            this.getEmailConfigurations();
                            this.setState({ loading: false });
                        }
                    });
            }
        });
    };

    handleDeleteConfig = (idEmailConfiguration) => {
        this.setState({ loadingModal: true });

        dispensaryServices.Delete(`outputEmail/deleteEmailConfiguration/${idEmailConfiguration}`)
            .then(response => {
                const jsonResponse = JSON.parse(JSON.stringify(response));
                if (jsonResponse.hasOwnProperty('response')) {
                    message.error(jsonResponse.response.data);
                } else {
                    message.success(response);
                    this.getEmailConfigurations();
                }
            });
    }


    render() {
        const { uploading } = this.state;
        const { form } = this.props;
        const { getFieldDecorator } = form;

        const optionEmailConfigType = types => types.map(item => (
            <Option key={item.configurationType} value={item.id}>{item.configurationDescription}</Option>
        ));

        const optionUsers = types => types.map(item => (
            item.active === true ? <Option key={item.id} value={item.email}>{item.role} - {item.email}</Option> : ""
        ));

        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loadingForm}>
                <Form layout='vertical' >
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Configurar correos a enviar</Divider>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Tipo de configuración de correo"
                            >
                                {getFieldDecorator('idEmailConfigType', {
                                    rules: [{
                                        required: true, message: 'Por favor selecciona el tipo de configuración de correo a enviar.'
                                    }]
                                })(
                                    <Select>
                                        {optionEmailConfigType(this.state.emailConfigTypes)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Usuarios"
                            >
                                {getFieldDecorator('email', {
                                    rules: [{
                                        required: true, message: 'Por favor selecciona el usuario.'
                                    }]
                                })(
                                    <Select>
                                        {optionUsers(this.state.users)}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Agregar Registro"
                            >
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={uploading}
                                    onClick={this.handleSubmit}
                                >
                                    {uploading ? 'Agregando' : 'Agregar'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Table
                        columns={this._columns}
                        expandIconAsCell={false}
                        rowKey={record => record.id}
                        dataSource={this.state.emailConfigurations}
                        pagination={false}
                        loading={this.state.loading}
                    />
                </Form>
            </Spin>
        );


    }

}

export default Form.create({ name: 'emailSenderConfiguration' })(EmailSenderConfiguration);
