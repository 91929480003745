import React, { Component } from 'react';
import { Row, Col, Divider } from 'antd';

class Permissions extends Component {

    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }

    updateDimensions = () => {
        this.setState({ width: window.innerWidth });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        return (
            <div>
                <div style={{ paddingBottom: '41px' }}>
                    <label>A continuación se muestran los permisos que tiene cada perfil de usuario</label>
                </div>
                <Row gutter={24}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <h3 style={{ color: '#1B95CA' }}>Administrador {<span style={{ color: '#929292', fontSize: '13px' }}>tiene atributos para:</span>}</h3>
                        <p>Registro de usuarios</p>
                        <p>Configuración del control volumétrico</p>
                        <p>Definir desplegados gráficos de operación</p>
                        <p>Visualizar información almacenada</p>
                        <p>Registro de acciones o eventos en la bitácora de eventos</p>
                        <p>Consulta e impresión de informes de la base de datos</p>
                    </Col>
                    {(this.state.width < 992) && <Divider />}
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <h3 style={{ color: '#1B95CA' }}>Supervisor {<span style={{ color: '#929292', fontSize: '13px' }}>tiene atributos para:</span>}</h3>
                        <p>Configuración del control volumétrico</p>
                        <p>Definir desplegados gráficos de operación</p>
                        <p>Visualizar información almacenada</p>
                        <p>Registro de acciones o eventos en la bitácora de eventos</p>
                        <p>Consulta e impresión de informes de la base de datos</p>
                    </Col>
                    <Divider />
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <h3 style={{ color: '#1B95CA' }}>Operador {<span style={{ color: '#929292', fontSize: '13px' }}>tiene atributos para:</span>}</h3>
                        <p>Visualizar desplegados gráficos de operación</p>
                        <p>Visualizar información almacenada</p>
                        <p>Registro de acciones o eventos en la bitácora de eventos</p>
                    </Col>
                    {(this.state.width < 992) && <Divider />}
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                        <h3 style={{ color: '#1B95CA' }}>Auditor fiscal {<span style={{ color: '#929292', fontSize: '13px' }}>tiene atributos para:</span>}</h3>
                        <p>Visualizar desplegados gráficos de operación</p>
                        <p>Visualizar información almacenada</p>
                        <p>Consulta e impresión de informes de la base de datos</p>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Permissions;

