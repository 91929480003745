import React, { Component } from 'react';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import {
    Card,
    Row,
    Col,
    Button,
    Input,
    Divider,
    Breadcrumb,
    Modal,
    notification,
    Select,
    InputNumber,
} from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import TextArea from 'antd/lib/input/TextArea';
import { getSelectedDistributionPoint } from '../../../common/components/widgets/DistributionPoint/distributionPointStorage';

const { Option } = Select;

const openNotification = () => {
    const key = `open${Date.now()}`;
    const btn = (
        <Button style={{ background: '#1D98CD', color: '#FFFFFF' }} type="primary" size="small" onClick={() => notification.close(key)}>
            Entendido
        </Button>
    );
    notification.open({
        message: 'Enlaza los equipos',
        description: 'Introduce los códigos de enlace en los equipos para concluir el enlace.',
        btn,
        key,
    });
};

let id = 1;
const removeEmptyElements = array => (
    array.filter(element => element != null)
);

let hoseCodes = [''];
let auditableHoseCodes = [''];
function hasDuplicatedValues(array) {
    return (new Set(array)).size !== array.length;
}

class DispensaryAggregation extends Component {
    _isMount = false;
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            auditableDispensaryCodeVS: {},
            dispensaryCodeVS: {},
            dispensaryCodeExist: true,
            networkAddressVS: {},
            hoseIdentificatorVS: {},
            auditableHoseIdentificatorVS: {},
            hoseCodeExist: false,
            auditableHoseCodeExist: false,
            visibleModal: false,
            loading: false,
            equipment: [{ equipmentKey: '' }],
            existedHoseCodes: [],
            existingAuditableHoseCodes: [],
            products: [],
            hoseCount: 1,
            distributionPointId: getSelectedDistributionPoint(),
        };
    }

    componentDidMount() {
        this._isMount = true;
        window.addEventListener("distributionPointChange",(e) => {
            if(this._isMount){
                this.setState({ distributionPointId: getSelectedDistributionPoint()}, ()=>{
                    let finalList = this.props.form.getFieldValue('data').map(item => {
                        item.idWarehouse = undefined;
                        return item;
                    })
                    this.props.form.setFieldsValue({ 'data': finalList });
                });
            }
        });
    }
    componentWillUnmount() {
        this._isMount = false;
    }

    validateDispensaryCode(equipmentKey) {
        return {
            validateStatus: 'error',
            errorMsg: `El número de dispensario ${equipmentKey} ya existe actualmente.`,
        };
    }

    validateLocalHoseCode(response) {
        if (response) {
            this.setState({ hoseCodeExist: true });
            return {
                validateStatus: 'error',
                errorMsg: 'Cada identificador de manguera tiene que ser único.',
            };
        }

        this.setState({ hoseCodeExist: false });
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    validateLocalAuditableHoseCode(response) {
        if (response) {
            this.setState({ hoseCodeExist: true });
            return {
                validateStatus: 'error',
                errorMsg: 'Cada identificador auditable de manguera tiene que ser único.',
            };
        }

        this.setState({ hoseCodeExist: false });
        return {
            validateStatus: 'success',
            errorMsg: null,
        };
    }

    handleDispensaryCodeChange = event => {
        this.setState({ dispensaryCodeVS: {} });
        const equipmentKey = event.target.value.toUpperCase();
        console.log(equipmentKey);
        if (equipmentKey.length === 2) {
            dispensaryServices.get(`equipment/checkIfEquipmentKeyExist/${equipmentKey}`)
                .then(response => {
                    if (response === true) {
                        this.setState({
                            dispensaryCodeVS: {
                                ...this.validateDispensaryCode(equipmentKey),
                            }
                        });
                    }
                    this.setState({ dispensaryCodeExist: response });
                });
        }
    }

    handleAuditableDispensaryCodeChange = event => {
        this.setState({ auditableDispensaryCodeVS: {} });
        const auditableEquipmentKey = 'DISP-' + event.target.value;

        if (auditableEquipmentKey.length === 9) {
            dispensaryServices.get(`equipment/checkIfAuditableEquipmentKeyExist/${auditableEquipmentKey}`)
                .then(response => {
                    if (response === true) {
                        this.setState({
                            auditableDispensaryCodeVS: {
                                ...this.validateDispensaryCode(auditableEquipmentKey),
                            }
                        });
                    }
                });
        }
    }

    validateAuditableHoseCode(equipmentKey) {
        return {
            validateStatus: 'error',
            errorMsg: `El identificador auditable de manguera ${equipmentKey} ya existe actualmente.`,
        };
    }

    validateHoseLength() {
        return {
            validateStatus: 'error',
            errorMsg: `El identificador de manguera debe de ser de exactamente dos caracteres`,
        };
    }

    validateAuditableHoseLength() {
        return {
            validateStatus: 'error',
            errorMsg: `El identificador de manguera debe de ser de exactamente cuatro caracteres`,
        };
    }

    validateRegisteredDispensaryAuditableCode() {
        return {
            validateStatus: 'error',
            errorMsg: `Por favor registra primero el código auditable de dispensario.`,
        };
    }

    validateAuditableEquipmentKey() {
        return {
            validateStatus: 'error',
            errorMsg: `El código auditable de manguera únicamente permite valores numéricos.`,
        };
    }

    handleAddHoseCode = () => {
        hoseCodes = hoseCodes.concat(['']);
        auditableHoseCodes = auditableHoseCodes.concat(['']);
    };

    handleRemoveHoseCode = idx => {
        let existingHoses = this.state.hoseCount;
        existingHoses = existingHoses - 1;
        this.setState({ hoseCount: existingHoses, });
        let hcs = hoseCodes;
        hcs = hcs.filter((s, sidx) => idx !== sidx);
        hoseCodes = hcs;

        this.reviewArchivedHoseCodes();

        let ahcs = auditableHoseCodes;
        ahcs = ahcs.filter((s, sidx) => idx !== sidx);
        auditableHoseCodes = ahcs;

        this.reviewAuditableArchivedHoseCodes();
    };

    reviewArchivedHoseCodes(equipmentKey) {
        const arr1 = hoseCodes;
        const arr2 = this.state.existedHoseCodes;
        const found = arr1.some(r => arr2.includes(r));

        if (found) {
            if (equipmentKey.length !== 2) {
                this.setState({
                    hoseCodeExist: true,
                    hoseIdentificatorVS: {
                        validateStatus: 'error',
                        errorMsg: 'Identificador de manguera debe tener exactamente dos caracteres.',
                    }
                });
            } else {
                this.setState({
                    hoseCodeExist: true,
                    hoseIdentificatorVS: {
                        validateStatus: 'error',
                        errorMsg: 'Identificador de manguera existente.',
                    }
                });
            }
        } else {
            this.setState({
                hoseCodeExist: false,
                hoseIdentificatorVS: {
                    validateStatus: 'success',
                    errorMsg: null,
                }
            });
        }
    }

    reviewAuditableArchivedHoseCodes(auditableEquipmentKey) {
        const arr1 = auditableHoseCodes;
        const arr2 = this.state.existingAuditableHoseCodes;
        const found = arr1.some(r => arr2.includes(r));

        if (found) {
            if (auditableEquipmentKey.length !== 4) {
                this.setState({
                    hoseCodeExist: true,
                    auditableHoseIdentificatorVS: {
                        validateStatus: 'error',
                        errorMsg: 'Identificador auditable de manguera debe tener exactamente cuatro caracteres.',
                    }
                });
            } else {
                this.setState({
                    hoseCodeExist: true,
                    auditableHoseIdentificatorVS: {
                        validateStatus: 'error',
                        errorMsg: 'Identificador auditable de manguera existente.',
                    }
                });
            }
        } else {
            this.setState({
                hoseCodeExist: false,
                auditableHoseIdentificatorVS: {
                    validateStatus: 'success',
                    errorMsg: null,
                }
            });
        }
    }

    handleHoseCodeBlur = idx => evt => {
        const equipmentKey = evt.target.value.toUpperCase();

        this.reviewArchivedHoseCodes(equipmentKey);
    }

    handleAuditableHoseCodeBlur = idx => evt => {
        const auditableEquipmentKey = evt.target.value;

        this.reviewAuditableArchivedHoseCodes(auditableEquipmentKey);
    }

    reviewLocalHoseCodes(idx, equipmentKey) {
        const newHoseCodes = hoseCodes.map((hose, sidx) => {
            if (idx !== sidx) return hose;
            return equipmentKey;
        });
        hoseCodes = newHoseCodes;
        this.setState({
            hoseIdentificatorVS: {
                ...this.validateLocalHoseCode(hasDuplicatedValues(newHoseCodes))
            }
        });
    }

    reviewLocalAuditableHoseCodes(idx, auditableEquipmentKey) {
        const newAuditableHoseCodes = auditableHoseCodes.map((hose, sidx) => {
            if (idx !== sidx) return hose;
            return auditableEquipmentKey;
        });
        auditableHoseCodes = newAuditableHoseCodes;
        this.setState({
            auditableHoseIdentificatorVS: {
                ...this.validateLocalAuditableHoseCode(hasDuplicatedValues(newAuditableHoseCodes))
            }
        });
    }

    cacheExistedHoseCodes(equipmentKey) {
        let newHoseCodes = this.state.existedHoseCodes;

        newHoseCodes = newHoseCodes.concat([equipmentKey]);
        this.setState({ existedHoseCodes: newHoseCodes });
    }

    cacheExistingAuditableHoseCodes(equipmentKey) {
        let newAuditableHoseCodes = this.state.existingAuditableHoseCodes;

        newAuditableHoseCodes = newAuditableHoseCodes.concat([equipmentKey]);
        this.setState({ existingAuditableHoseCodes: newAuditableHoseCodes });
    }

    handleHoseCodeChange = idx => evt => {
        const hoseKey = evt.target.value.toUpperCase();

        if (hoseKey.length === 2) {
            dispensaryServices.get(`hose/checkIfHoseKeyExist/${hoseKey}`)
                .then(response => {
                    if (response === true) {
                        this.cacheExistedHoseCodes(hoseKey);
                        this.setState({
                            hoseCodeExist: true,
                            hoseIdentificatorVS: {
                                ...this.validateAuditableHoseCode(hoseKey),
                            }
                        });
                    }
                });

            this.reviewLocalHoseCodes(idx, hoseKey);
        } else {
            this.setState({
                hoseCodeExist: true,
                hoseIdentificatorVS: {
                    ...this.validateHoseLength(),
                }
            });
        }
    }

    handleAuditableHoseCodeChange = idx => evt => {
        const { form } = this.props;
        if (form.getFieldValue("auditableEquipmentKey") !== undefined && form.getFieldValue("auditableEquipmentKey").length === 4) {

            const reg = /^[0-9-]+$/;
            if (reg.test(evt.target.value) || evt.target.value === '') {

                const auditableHoseKey = 'DISP-'.concat(form.getFieldValue("auditableEquipmentKey")).concat('-MGA-').concat(evt.target.value);

                if (auditableHoseKey.length === 18) {
                    dispensaryServices.get(`hose/checkIfAuditableHoseKeyExist/${auditableHoseKey}`)
                        .then(response => {
                            if (response === true) {
                                this.cacheExistingAuditableHoseCodes(auditableHoseKey);
                                this.setState({
                                    hoseCodeExist: true,
                                    auditableHoseIdentificatorVS: {
                                        ...this.validateAuditableHoseCode(auditableHoseKey),
                                    }
                                });
                            }
                        });



                    this.reviewLocalAuditableHoseCodes(idx, evt.target.value);
                } else {
                    this.setState({
                        hoseCodeExist: true,
                        auditableHoseIdentificatorVS: {
                            ...this.validateAuditableHoseLength(),
                        }
                    });
                }

            } else {
                this.setState({
                    hoseCodeExist: true,
                    auditableHoseIdentificatorVS: {
                        ...this.validateAuditableEquipmentKey(),
                    }
                });
            }
        } else {
            this.setState({
                hoseCodeExist: true,
                auditableHoseIdentificatorVS: {
                    ...this.validateRegisteredDispensaryAuditableCode(),
                }
            });
        }
    }

    remove = (idx, k) => {
        this.handleRemoveHoseCode(idx);
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        // We need at least one passenger
        if (keys.length === 1) {
            return;
        }

        // can use data-binding to set
        form.setFieldsValue({
            keys: keys.filter(key => key !== k)
        });
    };

    add = () => {
        let existingHoses = this.state.hoseCount;
        existingHoses = existingHoses + 1;
        this.setState({ hoseCount: existingHoses, });
        this.handleAddHoseCode();
        const { form } = this.props;
        // can use data-binding to get
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(id++);
        // can use data-binding to set
        // important! notify form to detect changes
        form.setFieldsValue({
            keys: nextKeys
        });
    };

    showModal = () => {
        this.setState({
            visibleModal: true,
        });
    }

    handleOk = () => {
        this.setState({ loading: true });

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const hoseModifiedFormData = values.data.map((item) => {
                    item.auditableHoseKey = "DISP-".concat(values.auditableEquipmentKey).concat("-MGA-").concat(item.auditableHoseKey)
                    return item
                })

                const newDispensary = {
                    equipmentKey: values.equipmentKey,
                    auditableEquipmentKey: "DISP-".concat(values.auditableEquipmentKey),
                    hoses: removeEmptyElements(hoseModifiedFormData),
                    distributionPointId: this.state.distributionPointId
                };
                console.log("DISPENSARIO!", newDispensary);
                dispensaryServices.post('dispensary', newDispensary).then(response => {
                    setTimeout(() => {
                        this.setState({ loading: false, visibleModal: false });
                        openNotification();
                        this.props.onAddHoseClick();
                    }, 3000);
                });
            }
        });
    }

    handleCancel = () => {
        this.setState({ visibleModal: false, dispensaryCodeVS: {} });
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err && !this.state.hoseCodeExist) {
                this.showModal();
            }
        });
    };

    validateNetworkAddress(netAddress) {
        const { form } = this.props;
        const equipment = form.getFieldValue('data');

        if (equipment.filter(hose => hose.address === netAddress).length === 0) {
            return {
                validateStatus: 'success',
                errorMsg: null,
            };
        }
        return {
            validateStatus: 'error',
            errorMsg: 'Cada dirección de red tiene que ser única.',
        };
    }

    validateHoseIdentificator(equipmentKey) {
        const { form } = this.props;
        const equipment = form.getFieldValue('data');

        if (equipment.filter(hose => hose.equipmentKey === equipmentKey).length === 0) {
            this.setState({ hoseCodeExist: false });
            return {
                validateStatus: 'success',
                errorMsg: null,
            };
        }
        this.setState({ hoseCodeExist: true });
        return {
            validateStatus: 'error',
            errorMsg: 'Cada identificador de manguera tiene que ser único.',
        };
    }

    onChangeAuditableCodeInput = (auditableCode) => {
        const reg = /^[0-9-]+$/;
        let checkedAuditableCode = '';
        for (let i = 0; i < auditableCode.length; i++) {
            if (reg.test(auditableCode.charAt(i)) || auditableCode === '') {
                checkedAuditableCode = checkedAuditableCode.concat(auditableCode.charAt(i));
            }
        }
        this.props.form.setFieldsValue({ 'auditableEquipmentKey': checkedAuditableCode });
    };

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { dispensaryCodeVS, auditableDispensaryCodeVS, dispensaryCodeExist, hoseIdentificatorVS, auditableHoseIdentificatorVS,
            hoseCodeExist, visibleModal, loading } = this.state;
        const { onCancelClick } = this.props;

        const optionWarehouseDropdown = types => types.map(item => (
            <Option key={item.idWareHouse} value={item.idWareHouse}>{
                item.subproducts !== null ?
                    `${item.warehouseKey} - ${item.products.productDescription} - ${item.subproducts.description}` :
                    `${item.warehouseKey} - ${item.products.productDescription}`}
            </Option>
        ));

        getFieldDecorator('keys', { initialValue: [0] });
        const keys = getFieldValue('keys');
        const formItems = keys.map((k, index) => (
            <Row key={k}>
                <Col span={18}>
                    <Card>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item
                                    label="Código de la manguera"
                                    validateStatus={hoseIdentificatorVS.validateStatus}
                                    help={hoseIdentificatorVS.errorMsg || 'Introduce un valor a 2 caracteres, puedes usar sólo números (01), sólo letras (AB) o una combinación (1A)'}
                                >
                                    {getFieldDecorator(`data[${k}].hoseKey`, {
                                        rules: [{
                                            required: true, len: 2, message: 'Por favor introduce el identificador de la manguera.'
                                        }],
                                    })(
                                        <Input maxLength={2} onChange={this.handleHoseCodeChange(index)} onBlur={() => this.handleHoseCodeBlur(index)} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Código auditable de manguera"
                                    validateStatus={auditableHoseIdentificatorVS.validateStatus}
                                    help={auditableHoseIdentificatorVS.errorMsg || 'Introduce un valor de 4 caracteres, puedes usar sólo números.'}
                                >
                                    {getFieldDecorator(`data[${k}].auditableHoseKey`, {
                                        rules: [{
                                            required: true, len: 4, message: 'Por favor introduce el identificador auditable de la manguera.'
                                        }],
                                    })(
                                        <Input addonBefore={"MGA-"} maxLength={4} onChange={this.handleAuditableHoseCodeChange(index)} onBlur={() => this.handleAuditableHoseCodeBlur(index)} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Precio"
                                >
                                    {getFieldDecorator(`data[${k}].price`, {
                                        rules: [{
                                            required: true, message: 'Por favor introduce el precio de la manguera.'
                                        }],
                                    })(
                                        <InputNumber className="common-input" precision={2} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{ width: '100%' }} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label="Almacén"
                                >
                                    {getFieldDecorator(`data[${k}].idWarehouse`, {
                                        rules: [{
                                            required: true, message: 'Por favor selecciona un almacén.'
                                        }],
                                    })(
                                        <Select
                                            placeholder="Selecciona un almacén"
                                        >
                                            {optionWarehouseDropdown(this.props.warehouses)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label="Descripción"
                                >
                                    {getFieldDecorator(`data[${k}].description`, {
                                        rules: [{
                                            required: true, message: 'Por favor introduce la descripción de la manguera.'
                                        }]
                                    })(
                                        <TextArea placeholder="Descripción de la manguera." autosize={{ minRows: 1, maxRows: 6 }} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col className="container-minus-button" span={2} offset={1} >
                    <div className="vertical-center">
                        {k > -0 ? (
                            <Button shape="circle" icon={<MinusOutlined />} onClick={() => this.remove(index, k)} />
                        ) : null}
                    </div>
                </Col>
            </Row>
        ));

        return (
            <div>
                <Breadcrumb style={{ marginBottom: '16px' }}>
                    <Breadcrumb.Item>Dispensarios</Breadcrumb.Item>
                    <Breadcrumb.Item>Agregar dispensario</Breadcrumb.Item>
                </Breadcrumb>
                <Form layout='vertical' onSubmit={this.handleSubmit}>
                    <div className="hole-instructions">
                        <label>Llena los campos a continuación para agregar un dispensario y sus mangueras</label>
                    </div>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                label="Código de dispensario"
                                validateStatus={dispensaryCodeVS.validateStatus}
                                help={dispensaryCodeVS.errorMsg || 'Introduce un valor a 2 caracteres, puedes usar sólo números (01), sólo letras (AB) o una combinación (1A)'}
                            >
                                {getFieldDecorator('equipmentKey', {
                                    rules: [{
                                        required: true, len: 2, message: 'Por favor introduce el número de dispensario.'
                                    }],
                                })(
                                    <Input maxLength={2} onChange={this.handleDispensaryCodeChange} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Código auditable de dispensario"
                                validateStatus={auditableDispensaryCodeVS.validateStatus}
                                help={auditableDispensaryCodeVS.errorMsg || 'Introduce un valor de 4 caracteres, puedes usar sólo números.'}
                            >
                                {getFieldDecorator('auditableEquipmentKey', {
                                    rules: [{
                                        required: true, len: 4, message: 'Por favor introduce el código auditable de dispensario.'
                                    }],
                                })(
                                    <Input addonBefore="DISP-" maxLength={4} onChange={this.handleAuditableDispensaryCodeChange} onBlur={({ target: { value } }) => this.onChangeAuditableCodeInput(value)} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="hole-instructions">
                        <Divider orientation="left">Mangueras</Divider>
                        <label>Agrega las mangueras que utilizará tu dispensario</label>
                    </div>
                    {formItems}
                    <Row>
                        <Col span={18} style={{ paddingTop: 16 }}>
                            <Button className="add-hose-button" type="dashed" onClick={this.add} icon={<PlusOutlined />} disabled={hoseCodeExist | this.state.hoseCount === 10}>Agregar manguera</Button>
                        </Col>
                    </Row>
                    <Button className="customized-primary" type="primary" htmlType="submit" disabled={dispensaryCodeExist}>Agregar dispensario</Button>
                    <Button className="customized-default" style={{ marginLeft: 16 }} onClick={onCancelClick}>Cancelar</Button>
                </Form>
                <Modal
                    open={visibleModal}
                    title="Atención"
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button className="back-button" key="back" onClick={this.handleCancel}>Revisar información</Button>,
                        <Button className="submit-button" key="submit" loading={loading} onClick={this.handleOk}>Agregar dispensario</Button>
                    ]}
                >
                    <p>Una vez que presione "Agregar dispensario" solamente podrá editar el dispensario mientras no se haya efectuado ninguna venta en las mangueras que lo componen.</p>
                </Modal>
            </div>
        );
    }
}

export default Form.create({ name: 'dispensaryAggregation' })(DispensaryAggregation);
