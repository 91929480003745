import React from 'react';
import Snap from 'snapsvg-cjs';

import hoseVector from '../../assets/images/hose.svg';

class Hose extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hoseStatus: props.hoseStatus
        };
    }

    componentDidMount() {
        const s = Snap(`#svg${this.props.id}`);
        const g = s.group();
        Snap.load(hoseVector, (loadedFragment) => {
            loadedFragment.select('#hose').attr({
                fill: this.state.hoseStatus === 'DESHABILITADO' ? 'gray' :
                    this.state.hoseStatus === 'SURTIENDO' ? 'green' :
                        this.state.hoseStatus === 'SIN_COMUNICACION' ? 'red' :
                            this.state.hoseStatus === 'ERR_COMUNICACION' ? 'red' : '#47B4E3'
            });
            g.append(loadedFragment);
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ hoseStatus: nextProps.hoseStatus });
        const s = Snap(`#svg${this.props.id}`);
        s.select('g').select('#hose').attr({
            fill: nextProps.hoseStatus === 'DESHABILITADO' ? 'gray' :
                nextProps.hoseStatus === 'SURTIENDO' ? 'green' :
                    nextProps.hoseStatus === 'SIN_COMUNICACION' ? 'red' :
                        nextProps.hoseStatus === 'ERR_COMUNICACION' ? 'red' : '#47B4E3'
        });
    }

    render() {
        const idKey = `svg${this.props.id}`;
        return (
            <svg id={idKey} width="30" height="30" />
        );
    }
}

export default Hose;
