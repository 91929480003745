import React from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Modal, Input, InputNumber, Button, Row, Col, DatePicker, Popover, Tooltip } from 'antd';
import dayjs from "dayjs";
import {
    dispensaries as dispensaryServices
} from '../../../services';
import { connect } from 'react-redux';
import {
    getPagePermissions,
    DISPENSARY_PERMISSION
} from '../../../security/permissions';
import tooltip from '../../containers/components/tooltip.json';

const { TextArea } = Input;

const ContainerShowInfo = Form.create({ name: 'containerShowForm_into_modal' })(

    class extends React.Component {
        state = {
            container: {},
            visible: false,
        };

        showModal = (idContainer) => {
            dispensaryServices.get(`container/${idContainer}`)
                .then(response => {
                    console.log('GET_ContainerInfo', response);
                    this.setState({
                        container: response,
                        visible: true,
                    });
                });
        }

        handleReturn = () => {
            this.setState({
                visible: false,
            });
            this.props.form.resetFields();
        };

        render() {
            const { container } = this.state;
            const { containerId } = this.props;
            const { getFieldDecorator } = this.props.form;
            return (
                <div style={{ display: 'inline-block' }} >
                    <Popover content="Ver detalles">
                        <a type="link" shape="circle" onClick={() => this.showModal(containerId)}>
                            <InfoCircleTwoTone style={{ fontSize: '20px' }} />
                        </a>
                    </Popover>

                    <Modal
                        open={this.state.visible}
                        title="Información del contenedor"
                        onCancel={this.handleReturn}
                        footer={<Button onClick={this.handleReturn}>Volver</Button>}
                    >
                        <p>A continucación se muestra la información detallada del contenedor.</p>
                        <Form layout='vertical' >
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Tooltip title={tooltip.tipoContenedor}>
                                        <Form.Item label="Tipo de contenedor">
                                            <Input className="common-input" defaultValue={container.containerType} disabled />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Tooltip title={tooltip.tanques.claveInstalacion}>
                                        <Form.Item label="Clave de identificación">
                                            <Input className="common-input" defaultValue={container.containerKey} disabled />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={12} hidden={container.integrationCode === null}>
                                    <Tooltip title={tooltip.codigoIntegracion}>
                                        <Form.Item label="Clave de usuario de identificación">
                                            <Input className="common-input" defaultValue={container.integrationCode} disabled />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12} hidden={container.containerTotalCapacity === null}>
                                    <Tooltip title={tooltip.tanques.capacidadTotal}>
                                        <Form.Item label="Capacidad total">
                                            <InputNumber
                                                className="common-input"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                style={{ width: '100%' }}
                                                defaultValue={container.containerTotalCapacity}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={12} hidden={container.containerMinOperationalCapacity === null}>
                                    <Tooltip title={tooltip.tanques.nivelMinimoOperativo}>
                                        <Form.Item label="Volumen mínimo de operación">
                                            <InputNumber
                                                className="common-input"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                style={{ width: '100%' }}
                                                defaultValue={container.containerMinOperationalCapacity}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12} hidden={container.containerOperationalCapacity === null}>
                                    <Tooltip title={tooltip.tanques.capacidadOperativa}>
                                        <Form.Item label="Capacidad operativa">
                                            <InputNumber
                                                className="common-input"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                style={{ width: '100%' }}
                                                defaultValue={container.containerOperationalCapacity}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={12} hidden={container.containerUsefulCapacity === null}>
                                    <Tooltip title={tooltip.tanques.capacidadUtil}>
                                        <Form.Item label="Capacidad útil">
                                            <InputNumber
                                                className="common-input"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                style={{ width: '100%' }}
                                                defaultValue={container.containerUsefulCapacity}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12} hidden={container.containerFundingCapacity === null}>
                                    <Tooltip title={tooltip.tanques.capacidadFondaje}>
                                        <Form.Item label="Capacidad fondaje">
                                            <InputNumber
                                                className="common-input"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                style={{ width: '100%' }}
                                                defaultValue={container.containerFundingCapacity}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={container.containerFundingCapacity !== null ? 12 : 24} hidden={container.containerHeelGasCapacity === null}>
                                    <Tooltip title={tooltip.tanques.nivelMinimoOperativo}>
                                        <Form.Item label="Capacidad gas talón">
                                            <InputNumber
                                                className="common-input"
                                                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                style={{ width: '100%' }}
                                                defaultValue={container.containerHeelGasCapacity}
                                                disabled
                                            />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24} hidden={container.containerCalibrationDate === null}>
                                    <Tooltip title={tooltip.tanques.calibracion}>
                                        <Form.Item label="Vigencia de calibración">
                                            {getFieldDecorator('containerCalibrationDate', {
                                                initialValue: container.containerCalibrationDate !== null ? dayjs(container.containerCalibrationDate) : null,
                                                rules: [{
                                                    required: container.containerCalibrationDate !== null ? true : false, message: 'Por favor introduce la vigencia de calibración'
                                                }],
                                            })(
                                                <DatePicker  lang='es' style={{ width: '100%' }} disabled format="DD-MM-YYYY" />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Tooltip title={tooltip.tanques.descripcion}>
                                        <Form.Item label="Localización y descripción">
                                            {getFieldDecorator('containerDescription', {
                                                initialValue: container.containerDescription,
                                                rules: [{
                                                    required: true, message: 'Por favor introduce la localización y/o descripción del contenedor'
                                                }],
                                            })(
                                                <TextArea autoSize={{ minRows: 1, maxRows: 6 }} disabled />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </div >
            );
        }
    }
);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISPENSARY_PERMISSION, permissions)
});

export default connect(mapStateToProps)(ContainerShowInfo);
