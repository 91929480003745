import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Tooltip, Divider, Switch, message} from 'antd';
import { EquipmentUnlinkForm } from '../components';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import {
    getPagePermissions,
    EVM_PERMISSION
} from '../../../security/permissions';
import { getIsCloudModeActive } from '../../../security';

import input_equipment from '../../../assets/images/mini_input_equipment.svg';
import disconnect from '../../../assets/images/disconnect.svg';
import EquipmentShowInfo from './equipmentShowInfo';

class EntryEquipment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibleUnlinkModal: false,
            visible: false
        };
    }

    columns = [
        {
            title: 'Número de equipo',
            dataIndex: 'equipmentKey',
            render: (text) => (
                <Tooltip title="Número del Equipo registrado en G4S">
                    <label className="dispensary-id">{text}</label>
                </Tooltip>
            )
        },
        {
            title: 'Almacén vinculado',
            dataIndex: 'wareHouseKey',
            render: (text) => (
                <Tooltip title="Almacén vinculado">
                    <label style={{color: 'green', fontWeight: 'bold'}}> {text}</label>
                </Tooltip>
            )
        },
        {
            title: 'Enlazar',
            key: 'link',
            align: 'right',
            render: (text, record) => (
                <span>
                    {(!getIsCloudModeActive() && this.props.pagePermissions.edit && record.serialNumber === null) &&
                        <EquipmentShowInfo gaugeCode={record.equipmentKey} linkCode={record.synchronizationKey}
                        pagePermissions={this.props.pagePermissions} onEquipmentLink={this.onEquipmentLink}/>
                    }
                </span>
            )
        },
        {
            title: 'Acción',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                <span>
                    {(!getIsCloudModeActive() && this.props.pagePermissions.edit && record.serialNumber !== null) &&
                        <Tooltip title="Desenlazar">
                            <a onClick={() => this.setState({ visibleUnlinkModal: true })}>
                                <img src={disconnect} alt="Equipo de entrada" />
                            </a>
                        </Tooltip>
                    }
                    <Divider type="vertical" />
                    {(this.props.pagePermissions.edit && record.status !== 'PENDIENTE_ENLACE') &&
                    <Tooltip title={record.isActive? "Deshabilitar Registro de Entrada": "Habilitar Registro de Entrada"}>
                        <Switch checked={record.isActive} onChange={this.handleSwitchChange} />
                    </Tooltip> }
                </span>
            )
        }
    ];

    data = [{
        key: this.props.equipment.equipmentKey,
        idEquipment: this.props.equipment.idEquipment,
        equipmentKey: this.props.equipment.equipmentKey,
        isActive: this.props.equipment.isActive,
        hash: this.props.equipment.hash,
        synchronizationKey: this.props.equipment.synchronizationKey
    }];

    handleSwitchChange = (checked) => {
        const { idEquipment } = this.data[0];
        this.props.onEquipmentActiveStatusChange(idEquipment, checked);
    }

    handleCancelUnlinkModal = () => {
        this.setState({ visibleUnlinkModal: false });
    }

    saveUnlinkFormRef = (formRef) => {
        this.unlinkFormRef = formRef;
    }

    handleEquipmentUnlink = () => {
        const form = this.unlinkFormRef.props.form;

        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            let params = {
                unlinkReason:`${values.unlinkReason}`
            }


            dispensaryServices.put(`equipment/unlinkDispensary/${values.equipmentKey}`,params)
                .then(response => {
                    const jsonResponse = JSON.parse(JSON.stringify(response));
                    if (jsonResponse.hasOwnProperty('response')) {
                        message.error(jsonResponse.response.data);
                    } else {
                        message.success("Registro de entrada desenlazado exitosamente");
                        this.props.onEquipmentLink();
                    }
                });
            form.resetFields();
            this.setState({ visibleUnlinkModal: false });
        });
    }

    onEquipmentLink = () => {
        this.props.onEquipmentLink();
    }

    render() {
        return (
            <div>
                <Table
                    className="dispensary-table"
                    columns={this.columns}
                    expandIcon={() => <img src={input_equipment} alt="Equipo de entrada" />}
                    expandedRowRender={record => 
                        <div>
                            <div>El registro de entrada se encuentra ligado al almacén con clave: <Tooltip title="Clave auto-incrementable del almacén">{record.warehouseCode}</Tooltip> </div>
                            <br/>
                            <div hidden={record.serialNumber === null}>
                                <div style={{  color: record.lastCommunicationDate === "Sin comunicación" ? 'red' : 'green' }}>
                                    <p>Última comunicación: <Tooltip title="Momento de la ultima comunicación">{record.lastCommunicationDate}</Tooltip></p>
                                    <p>Enlazado a equipo con número de serie: <Tooltip title="Número de Serie del equipo GasPar">{record.serialNumber}</Tooltip></p>
                                </div>
                            </div>
                        </div>
                        }
                    defaultExpandAllRows
                    dataSource={
                        [{
                            key: this.props.equipment.equipmentKey,
                            idEquipment: this.props.equipment.idEquipment,
                            equipmentKey: this.props.equipment.equipmentKey,
                            isActive: this.props.equipment.isActive,
                            hash: this.props.equipment.hash,
                            synchronizationKey: this.props.equipment.synchronizationKey,
                            serialNumber: this.props.equipment.serialNumber,
                            lastCommunicationDate: this.props.equipment.lastCommunicationDate,
                            warehouseCode: this.props.equipment.warehouseCode,
                            idWarehouse: this.props.equipment.idWarehouse,
                            status: this.props.equipment.status,
                            wareHouseKey: this.props.equipment.wareHouseKey
                        }]
                    }
                    showHeader={false}
                    pagination={false}
                />
                <EquipmentUnlinkForm
                    wrappedComponentRef={this.saveUnlinkFormRef}
                    visible={this.state.visibleUnlinkModal}
                    onCancel={this.handleCancelUnlinkModal}
                    onUnlink={this.handleEquipmentUnlink}
                    equipment={this.props.equipment}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(EVM_PERMISSION, permissions)
});

export default connect(mapStateToProps)(EntryEquipment);
