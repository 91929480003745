import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Spin } from 'antd';
import { DispensaryAggregation, HosesPriceChange } from './components';
import DispensaryHoses from './components/dispensaryHoses';
import request from '../../services/request';
import {
    getPagePermissions,
    DISPENSARY_PERMISSION
} from '../../security/permissions';

import gas_pump from './../../assets/images/gas_pump.svg';
import './styles.css';
import { getSelectedDistributionPoint } from '../../common/components/widgets/DistributionPoint/distributionPointStorage';
import { getIsCloudModeActive } from '../../security';

class Dispensaries extends Component {
    _isMount = false;
    constructor() {
        super();
        this.state = {
            mainView: true,
            addDispensaryForm: false,
            changePricesForm: false,
            areThereDispensaries: false,
            dispensaries: [],
            loadingDispensaries: false,
            distributionPointId: getSelectedDistributionPoint(),
            isCloudEnable: getIsCloudModeActive(),
            warehousesInDistributionPoint: [],
        };
    }


    componentDidMount() {
        this._isMount = true;
        this.fetchDispensaries();
        window.addEventListener("distributionPointChange", (e) => {
            if (this._isMount) {
                if (this.state.distributionPointId === getSelectedDistributionPoint())
                    return;
                this.setState({ distributionPointId: getSelectedDistributionPoint() },
                    () => {
                        this.fetchDispensaries();
                    }
                );
            }
        });
    }

    componentWillUnmount() {
        this._isMount = false;
    }

    fetchDispensaries = () => {
        this.setState({ loadingDispensaries: true }, () => {
            request().get(`distributionPoints/${this.state.distributionPointId}/dispensaries`)
                .then(response => {
                    if (response.data.length > 0) {
                        this.setState({
                            areThereDispensaries: true,
                            dispensaries: response.data
                        });
                    } else {
                        this.setState({
                            areThereDispensaries: false,
                            dispensaries: []
                        });
                    }
                }).finally(() => {
                    this.setState({ loadingDispensaries: false });
                });
            this.fetchWareHouses();
        });

    }

    fetchWareHouses() {
        request().get(`distributionPoints/${this.state.distributionPointId}/warehouses`)
            .then(response => {
                this.setState({
                    warehousesInDistributionPoint: response.data
                });
            });
    }

    showAddDispensaryForm = () => {
        this.setState({
            mainView: false,
            addDispensaryForm: true
        });
    }

    showChangePricesForm = () => {
        this.setState({
            mainView: false,
            changePricesForm: true
        });
    }

    handleAddHoseClick = () => {
        this.fetchDispensaries();
        this.setState({
            mainView: true,
            addDispensaryForm: false
        });
    }

    handleCancelClick = (form) => {
        this.setState({
            mainView: true,
            [form]: false
        });
    }

    handleChangePricesClick = (response) => {
        this.setState({
            dispensaries: response,
            mainView: true,
            changePricesForm: false
        });
    }

    handleUpdateDispensaries = () => {
        this.fetchDispensaries();
    }

    onDispensaryActiveStatusChange = (dispensaryCode, isActive) => {
        const enableDisable = isActive ? 'activar' : 'desactivar';
        request().put(`equipment/changeDispensaryStatus/${dispensaryCode}/${isActive}`)
            .then(() => alert(`Se ha solicitado ${enableDisable} el equipo con codigo ${dispensaryCode}. Actualice la página para conocer el estatus en el que se encuentra el equipo. La solicitud puede tardar un tiempo en ser ejecutada.`))
            .catch(error => {
                const errorMessage = error.response.data;
                if (errorMessage) {
                    alert(`Error al Activar/Desactivar: ${error.response.data}`);
                }
            })
            .finally(() => {
                this.fetchDispensaries();
            });
    }

    render() {
        const { pagePermissions } = this.props;

        const cardItems = this.state.dispensaries.map((value, index) => (
            <Col key={value.dispensaryCode} span={12} style={{ marginBottom: '24px' }}>
                <DispensaryHoses
                    dispensary={value}
                    warehouses={this.state.warehousesInDistributionPoint}
                    onUpdateDispensaries={() => this.handleUpdateDispensaries()}
                    onDispensaryActiveStatusChange={this.onDispensaryActiveStatusChange} />
            </Col>
        ));

        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loadingDispensaries}>
                <div>
                    {this.state.mainView &&
                        <div>
                            {this.state.areThereDispensaries ? (
                                <div>
                                    <div style={{ marginBottom: '24px' }}>
                                        {pagePermissions.edit && <Button className="customized-default" style={{ marginRight: 16 }} onClick={this.showChangePricesForm} hidden={this.state.isCloudEnable}>$ Cambiar precios</Button>}
                                        {pagePermissions.add && <Button className="customized-primary" type="primary" icon={<PlusOutlined />} onClick={this.showAddDispensaryForm} hidden={this.state.isCloudEnable}>Agregar dispensario</Button>}
                                    </div>
                                    <Row gutter={24}>
                                        {cardItems}
                                    </Row>
                                </div>
                            ) : (
                                <div className="center-div">
                                    <img src={gas_pump} alt="Dispensario" />
                                    <div style={{ margin: '8px' }}>
                                        <span>Sin dispensarios agregados</span>
                                    </div>
                                    {pagePermissions.add && <Button className="customized-primary" type="primary" icon={<PlusOutlined />} onClick={this.showAddDispensaryForm} disabled={this.state.isCloudEnable}>Agregar dispensario</Button>}
                                </div>
                            )}
                        </div>
                    }
                    {this.state.addDispensaryForm && <DispensaryAggregation
                        warehouses={this.state.warehousesInDistributionPoint}
                        onAddHoseClick={() => this.handleAddHoseClick()}
                        onCancelClick={() => this.handleCancelClick('addDispensaryForm')} />}

                    {this.state.changePricesForm && <HosesPriceChange dispensaries={this.state.dispensaries} onChangePrices={this.handleChangePricesClick} onCancelClick={() => this.handleCancelClick('changePricesForm')} />}
                </div>
            </Spin>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISPENSARY_PERMISSION, permissions)
});

export default connect(mapStateToProps)(Dispensaries);
