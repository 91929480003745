import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Row, Col, message } from 'antd';
import { removeIsTemporalPassword, removeToken } from '../../security';
import { LoginForm, TemporaryPasswordChange, PasswordReset, OutputEmailForm, FirstAdminRegisterForm } from './components';
import {
    users as usersServices
} from '../../services';
import sicom_logo from './../../assets/images/sicom_logo.png';
import './styles.css';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            forgottenPassword: false,
            visibleEmail: false,
            visibleFirstAdmin: false,
            checkedUseSSL: false,
            checkedStarttls: false,
            testEmailVaid: false,
            canEdit: true,
            sendingEmail: false,
            canSave: false,
            isTemporalPassword: false,

        };

    }
    componentDidMount() {
        console.log("componect did mount has smtp");
        usersServices.get('initial-config/smtp/exists')
            .then(response => {
                this.setState({ visibleEmail: !response }, () => {
                    if (!this.state.visibleEmail) {
                        usersServices.get('initial-config/has-company-administator')
                            .then(response => {
                                this.setState({ visibleFirstAdmin: !response });
                            });
                    }
                });
            });
    }

    handleForgetPassword = () => {
        this.setState({ forgottenPassword: true });
    }

    handleReturnToLogin = () => {
        this.setState({ forgottenPassword: false });
    }

    handleReturnToLoginTemporalPassword = () => {
        removeIsTemporalPassword(this.state.isTemporalPassword);
        removeToken();
    }

    handleSubmit = () => {
        this.props.form.validateFields(['protocol', 'host', 'port', 'auth', 'email', 'password', 'startTtls', 'useSSL'], (err, values) => {
            if (!err) {
                values.auth = values.auth === 'login' ? true : false;
                values.startTtls = this.state.checkedStarttls;
                values.useSSL = this.state.checkedUseSSL;
                console.log('Received values of form: ', values);
                usersServices.post('initial-config/smtp-config', values)
                    .then(response => {
                        usersServices.get('initial-config/has-company-administator')
                            .then(response => {
                                this.setState({ visibleEmail: false, visibleFirstAdmin: !response });
                            });
                        this.props.form.resetFields();
                    });
            }
        });
    };

    onSendTest = () => {
        this.setState({ sendingEmail: true })
        this.props.form.validateFields(['protocol', 'host', 'port', 'auth', 'email', 'password', 'startTtls', 'useSSL', 'emailTest'], (err, values) => {
            if (!err) {
                values.auth = values.auth === 'login' ? true : false;
                values.startTtls = this.state.checkedStarttls;
                values.useSSL = this.state.checkedUseSSL;
                usersServices.post('initial-config/smtp/test', values)
                    .then(response => {
                        console.log(response);
                        if (response) {
                            this.setState({ canSave: true })
                            message.success("Configuración correcta para enviar correo");
                        } else {
                            message.error("Configuración de correo Incorrecta");
                            this.setState({ canSave: false })
                        }
                        this.setState({ sendingEmail: false })
                    });
            } else {
                this.setState({ sendingEmail: false })
            }

        });
    };

    handleChangestarttls = (checked) => {
        this.setState({ checkedStarttls: checked });
        this.handleChangeValue();
    }

    handleChangeSsl = (checked) => {
        this.setState({ checkedUseSSL: checked });
        this.handleChangeValue();
    }

    onChangeAuthType = (e) => {
        this.setState({ canEdit: e === "login" })
    }
    handleChangeValue = (e) => {
        this.setState({ canSave: false });
    }

    handleSubmitFirstAdmin = () => {
        this.props.form.validateFields(['email', 'name', 'role'], (err, values) => {
            if (!err) {
                values.firstUser = true;
                values.isCompanyAdministrator = true;
                console.log('Received values of form: ', values);
                usersServices.post('initial-config/user', values)
                    .then(response => {
                        usersServices.get('initial-config/has-company-administator')
                            .then(response => {
                                this.setState({ visibleFirstAdmin: !response });
                                this.props.form.resetFields();
                            });
                    });
            }
        });
    };

    render() {
        return (
            <Row className="login-vh" type="flex" align="middle">
                <Col span={12} className="login-left-side">
                    <img src={sicom_logo} alt="Sicom" />
                </Col>
                <Col span={12} className="login-form">
                    {this.props.isTemporalPassword ?
                        <TemporaryPasswordChange onReturn={this.handleReturnToLoginTemporalPassword}/> :
                        !this.state.forgottenPassword ?
                            <LoginForm onForget={this.handleForgetPassword} /> :
                            <PasswordReset onReturn={this.handleReturnToLogin} />
                    }
                </Col>
                <OutputEmailForm visibleEmail={this.state.visibleEmail} form={this.props.form}

                    handleSubmit={this.handleSubmit}
                    onSendTest={this.onSendTest}
                    handleChangestarttls={this.handleChangestarttls}
                    handleChangeSsl={this.handleChangeSsl}
                    onChangeAuthType={this.onChangeAuthType}
                    canEdit={this.state.canEdit}
                    sendingEmail={this.state.sendingEmail}
                    canSave={this.state.canSave}
                    handleChangeValue={this.handleChangeValue}

                />

                <FirstAdminRegisterForm visibleFirstAdmin={this.state.visibleFirstAdmin} form={this.props.form}
                    handleSubmitFirstAdmin={this.handleSubmitFirstAdmin} />
            </Row>
        );
    }
}

export default Form.create({ name: 'login' })(Login);
