import React from 'react';
import { Form } from '@ant-design/compatible';

import { Modal, Input, Button } from 'antd';

const DispensaryDeleteForm = Form.create({ name: 'dispensaryDeleteForm_into_modal' })(

    class extends React.Component {
        render() {
            const {
                visible, onCancel, onDelete, form, dispensary
            } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Modal
                    open={visible}
                    title={`Eliminar dispensario ${dispensary.dispensaryCode}`}
                    onCancel={onCancel}
                    onOk={onDelete}
                    footer={[
                        <Button className="back-button" key="back" onClick={onCancel}>Cancelar</Button>,
                        <Button className="submit-button" key="submit" onClick={onDelete}>Eliminar</Button>
                    ]}
                >
                    <p>Al eliminar el dispensario se eliminarán todas sus mangueras</p>
                    <p>{`¿Estás seguro que quieres eliminar el dispensario ${dispensary.dispensaryCode}?`}</p>
                    <Form layout='vertical' hidden>
                        <Form.Item label="Código de dispensario">
                            {getFieldDecorator('idEquipment', {
                                initialValue: dispensary.idEquipment,
                                rules: [{ required: true, message: 'Por favor introduce el código de dispensario' }]
                            })(
                                <Input maxLength={2} disabled />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);

export default DispensaryDeleteForm;
