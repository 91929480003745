import React from 'react';
import { Form } from '@ant-design/compatible';

import { Modal, Input, InputNumber, Button } from 'antd';

const StockRegisterForm = Form.create({ name: 'stockAddForm_into_modal' })(

    class extends React.Component {
        render() {
            const { title, visible, onCancel, onRegister, form, inventoryControlCurrentUUID, inventoryControlCurrentDate, inventoryControlCurrentStaticVolume, inventoryControlProduct } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Modal
                    
                    open={visible}
                    title={`${title} existencias ${inventoryControlCurrentDate}`}
                    onCancel={onCancel}
                    onOk={onRegister}
                    footer={[
                        <Button className="back-button" key="back" onClick={onCancel}>Cancelar</Button>,
                        <Button className="submit-button" key="submit" onClick={onRegister}>{title}</Button>
                    ]}
                >
                    <p><b>ATENCIÓN: Esta opción sólo debe ser usada como contingencia cuando los medidores de nivel presenten algún fallo o no se encuentren presentes,</b>
                        <b> el uso del registro manual de volumen estático sobreescribirá las mediciones de los medidores.</b>
                    </p>
                    <p>Llena el campo a continuación para registrar las exitencias de {inventoryControlProduct} </p>
                    <p>* Esta información permanecerá editable hasta las 11:59 h.</p>
                    <Form layout='vertical' >
                        <Form.Item style={{ display: 'none' }} label="UUID del control de existencias actual">
                            {getFieldDecorator('inventoryControlCurrentUUID', {
                                initialValue: inventoryControlCurrentUUID,
                                rules: [{ required: true, message: 'Por favor introduce el UUID del control de existencias actual' }]
                            })(
                                <Input disabled />
                            )}
                        </Form.Item>
                        <Form.Item label={`Volumen de existencias de ${inventoryControlProduct} (manual)`}>
                            {getFieldDecorator('volume', {
                                initialValue: inventoryControlCurrentStaticVolume === 0 ? null : inventoryControlCurrentStaticVolume,
                                rules: [{ required: true, message: 'Por favor introduce el volumen de existencias.' }]
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={0.001}
                                    step={0.001}
                                />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);

export default StockRegisterForm;
