import React from 'react';
import { connect } from 'react-redux';
import { Table, Tooltip } from 'antd';
import {
    getPagePermissions,
    DISPENSARY_PERMISSION
} from '../../../security/permissions';
import ContainerShowInfo from './containerShowInfo';


class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.containers,
            activateAllGauges: props.activateAllGauges,
            wareHouseStatus: props.wareHouseStatus,
            currentGaugeInfo: {},
        };
        this.columns = (pagePermissions) => {
            return [
                {
                    title: 'Código',
                    dataIndex: 'containerKey',
                    editable: false,
                    render: (text) => (
                        <Tooltip title="Código del Contenedor">
                            {text}
                        </Tooltip>)
                },
                {
                    title: 'Información',
                    dataIndex: 'idContainer',
                    align: 'left',
                    key: 'idContainer',
                    render: (text) => (
                        <ContainerShowInfo containerId={text} />
                    )
                },
            ];
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.gauges, activateAllGauges: nextProps.activateAllGauges, wareHouseStatus: nextProps.wareHouseStatus });
    }

    render() {
        const { pagePermissions } = this.props;
        return (
            <div>
                <Table
                    size="middle"
                    rowKey={record => record.idContainer}
                    title={() => 'Contenedores  (Producto: ' + this.props.wareHouse.products.productDescription + ' - ' + this.props.wareHouse.subproducts.description + ')'}
                    dataSource={this.props.containers}
                    columns={this.columns(pagePermissions)}
                    showHeader={false}
                    pagination={false}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISPENSARY_PERMISSION, permissions)
});

export default connect(mapStateToProps)(EditableTable);
