import { apiUrl } from '../../../app.config';
import React, { Component } from 'react';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import Snap from 'snapsvg-cjs';

// import model_plant from '../../../assets/images/model_plant.svg';
import dispensario_reposo from '../../../assets/images/dispensario_reposo.svg';
import dispensario_en_uso from '../../../assets/images/dispensario_en_uso.svg';
import dispensario_deshabilitado from '../../../assets/images/dispensario_deshabilitado.svg';
import dispensario_sin_comunicacion from '../../../assets/images/dispensario_sin_comunicacion.svg';
import manguera_reposo from '../../../assets/images/manguera_reposo.svg';
import manguera_en_uso from '../../../assets/images/manguera_en_uso.svg';
import manguera_deshabilitada from '../../../assets/images/manguera_deshabilitada.svg';
import manguera_sin_comunicacion from '../../../assets/images/manguera_sin_comunicacion.svg';

class ModelPlant extends Component {
    stompClient = null;

    componentDidMount() {
        this.setupWebSocket();

        const s = Snap('#svgModelPlant');
        const g = s.group();
        // TODO: fix
        // Snap.load(model_plant, (loadFragment) => {
        //     g.append(loadFragment);
        // });

        s.group().attr({
            id: 'GROUP'
        });
    }

    setupWebSocket = () => {
        const socket = SockJS(`${apiUrl}volumetric-control`);
        this.stompClient = Stomp.over(socket);
        this.stompClient.connect({}, this.connect);
    };


    getDispensaryStatus = (lsHoseDetail) => {
        if (lsHoseDetail.some(hose => hose.lsHoseStatus === 'SURTIENDO')) {
            return { image: dispensario_en_uso, text: 'En uso' };
        }
        if (lsHoseDetail.every(hose => hose.lsHoseStatus === 'DESHABILITADO')) {
            return { image: dispensario_deshabilitado, text: 'Deshabilitado' };
        }
        if (lsHoseDetail.every(hose => hose.lsHoseStatus === 'SIN_COMUNICACION')) {
            return { image: dispensario_sin_comunicacion, text: 'Sin comunicación' };
        }
        if (lsHoseDetail.every(hose => hose.lsHoseStatus === 'ERR_COMUNICACION')) {
            return { image: dispensario_sin_comunicacion, text: 'Error en comunicación' };
        }
        return { image: dispensario_reposo, text: 'Reposo' };
    };

    getHoseStatus = (lsHoseStatus) => {
        switch (lsHoseStatus) {
            case 'SURTIENDO':
                return { image: manguera_en_uso, text: 'En uso' };

            case 'DESHABILITADO':
                return { image: manguera_deshabilitada, text: 'Deshabilitada' };

            case 'SIN_COMUNICACION':
                return { image: manguera_sin_comunicacion, text: 'Sin comunicación' };

            case 'ERR_COMUNICACION':
                return { image: manguera_sin_comunicacion, text: 'Error en comunicación' };

            default:
                return { image: manguera_reposo, text: 'Reposo' };
        }
    };

    connect = () => {
        this.stompClient.subscribe('/topic/liveStatusReply', (messageOutput) => {
            const data = JSON.parse(messageOutput.body);
            console.log('+data+', data);

            const s = Snap('#svgModelPlant');
            s.select('#GROUP').remove();
            const g = s.group().attr({
                id: 'GROUP'
            });

            console.log('++++++', data.lsRegistryDetail[0]);

            if (data.lsRegistryDetail[0] !== undefined) {
                s.select('g').select('#inputRegistry').attr({
                    fill: data.lsRegistryDetail[0].lsInputStatus === 'DESHABILITADO' ? '#E9E9E9' :
                        data.lsRegistryDetail[0].lsInputStatus === 'SURTIENDO' ? '#98FFB5' :
                            data.lsRegistryDetail[0].lsInputStatus === 'SIN_COMUNICACION' ? '#DB121F' :
                                data.lsRegistryDetail[0].lsInputStatus === 'ERR_COMUNICACION' ? '#DB121F' : '#DDF5FF'
                });
                s.select('g').select('#inputRegistryCode').attr({
                    text: data.lsRegistryDetail[0].lsInputCode
                });
                s.select('g').select('#inputRegistryStatus').attr({
                    text: data.lsRegistryDetail[0].lsInputStatus === 'DESHABILITADO' ? 'Deshabilitado' :
                        data.lsRegistryDetail[0].lsInputStatus === 'SURTIENDO' ? 'En uso' :
                            data.lsRegistryDetail[0].lsInputStatus === 'SIN_COMUNICACION' ? 'Sin comunicación' :
                                data.lsRegistryDetail[0].lsInputStatus === 'ERR_COMUNICACION' ? 'Error en comunicación' : 'Reposo'
                });
            }

            // const dispX = 704;
            const dispX = 763;
            let dispY = 33;
            // let dispY = 0;
            // const hoseX = 863;
            const hoseX = 853;
            let hoseY = 0;
            data.lsDispensaryDetail.forEach((dispensary, indexDisp) => {
                const dispensaryStatus = this.getDispensaryStatus(dispensary.lsHoseDetail);

                if (s.select(`#ddispensary${dispensary.lsDispensaryCode}`) !== null) {
                    s.select(`#ddispensary${dispensary.lsDispensaryCode}`).remove();
                    s.select(`#ddispensary${dispensary.lsDispensaryCode}Code`).remove();
                    s.select(`#ddispensary${dispensary.lsDispensaryCode}Status`).remove();
                }

                if (s.select(`#ddispensary${dispensary.lsDispensaryCode}`) === null) {
                    g.append(s.image(
                        dispensaryStatus.image,
                        // dispX, indexDisp === 0 ? dispY : dispY+=113, 40, 53
                        dispX, indexDisp === 0 ? dispY : dispY += 111, 40, 53
                    ).attr({
                        id: `ddispensary${dispensary.lsDispensaryCode}`
                    }));

                    g.append(s.text(dispX + 12, dispY + 45, dispensary.lsDispensaryCode).attr({
                        id: `ddispensary${dispensary.lsDispensaryCode}Code`,
                        fontSize: 15
                    }));

                    g.append(s.text(dispX - 6, dispY + 67, dispensaryStatus.text).attr({
                        id: `ddispensary${dispensary.lsDispensaryCode}Status`,
                        fill: '#787878'
                    }));
                }
                s.select(`#ddispensary${dispensary.lsDispensaryCode}`).attr({
                    href: dispensaryStatus.image
                });
                s.select(`#ddispensary${dispensary.lsDispensaryCode}Code`).attr({
                    text: dispensary.lsDispensaryCode
                });
                s.select(`#ddispensary${dispensary.lsDispensaryCode}Status`).attr({
                    text: dispensaryStatus.text
                });

                if (indexDisp > 0) {
                    // hoseY = hoseY + 73;
                    hoseY += 40;
                }
                dispensary.lsHoseDetail.forEach((hose, indexHose) => {
                    const hoseStatus = this.getHoseStatus(hose.lsHoseStatus);

                    if (s.select(`#hhose${hose.lsHoseCode}_disp${dispensary.lsDispensaryCode}`) !== null) {
                        s.select(`#hhose${hose.lsHoseCode}_disp${dispensary.lsDispensaryCode}`).remove();
                        s.select(`#hhose${hose.lsHoseCode}Code_disp${dispensary.lsDispensaryCode}`).remove();
                        s.select(`#hhose${hose.lsHoseCode}Status_disp${dispensary.lsDispensaryCode}`).remove();
                    }

                    if (s.select(`#hhose${hose.lsHoseCode}_disp${dispensary.lsDispensaryCode}`) === null) {
                        g.append(s.image(hoseStatus.image, hoseX, hoseY, 21, 23).attr({
                            id: `hhose${hose.lsHoseCode}_disp${dispensary.lsDispensaryCode}`
                        }));

                        g.append(s.text(hoseX + 33, hoseY + 17, hose.lsHoseCode).attr({
                            id: `hhose${hose.lsHoseCode}Code_disp${dispensary.lsDispensaryCode}`,
                            fill: '#787878'
                        }));

                        g.append(s.text(hoseX + 59, hoseY + 17, hoseStatus.text).attr({
                            id: `hhose${hose.lsHoseCode}Status_disp${dispensary.lsDispensaryCode}`,
                            fill: '#787878'
                        }));
                    }
                    hoseY += 20;

                    s.select(`#hhose${hose.lsHoseCode}_disp${dispensary.lsDispensaryCode}`).attr({
                        href: hoseStatus.image
                    });
                    s.select(`#hhose${hose.lsHoseCode}Code_disp${dispensary.lsDispensaryCode}`).attr({
                        text: hose.lsHoseCode
                    });
                    s.select(`#hhose${hose.lsHoseCode}Status_disp${dispensary.lsDispensaryCode}`).attr({
                        text: hoseStatus.text

                    });
                });
            });

            s.select('g').select('#volumePercentage').attr({
                text: `${data.lsCurrentVolumePercentage}%`
            });
            s.select('g').select('#volume').attr({
                text: `${data.lsCurrentVolume} L`
            });
            s.select('g').select('#gasLevel').attr({
                d: data.lsCurrentVolumePercentage >= 60 ? 'M180.723 130C163.522 148.48 153 173.261 153 200.5C153 257.661 199.339 304 256.5 304H471.5C528.661 304 575 257.661 575 200.5C575 173.261 564.478 148.48 547.277 130H180.723Z' :
                    (data.lsCurrentVolumePercentage >= 50 && data.lsCurrentVolumePercentage < 60) ? 'M153 200.5C153 257.661 199.339 304 256.5 304H471.5C528.661 304 575 257.661 575 200.5H153Z' :
                        (data.lsCurrentVolumePercentage >= 30 && data.lsCurrentVolumePercentage < 50) ? 'M158 231.27C171.018 273.544 210.387 304.27 256.933 304.27H471.933C518.478 304.27 557.848 273.544 570.866 231.27H158Z' :
                            data.lsCurrentVolumePercentage < 30 && 'M179.105 269C198.075 290.621 225.91 304.27 256.933 304.27H471.933C502.956 304.27 530.791 290.621 549.76 269H179.105Z',
                fill: data.lsCurrentVolumePercentage >= 60 ? '#4DC16E' :
                    (data.lsCurrentVolumePercentage >= 30 && data.lsCurrentVolumePercentage < 60) ? '#E6DD85' :
                        data.lsCurrentVolumePercentage < 30 && '#FF7171'
            });
        });
    };

    componentWillUnmount() {
        this.stompClient.disconnect();
    }

    render() {
        return (
            <svg id="svgModelPlant" width="1013" height="423" />
        );
    }
}

export default ModelPlant;
