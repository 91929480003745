import { CloseOutlined, CrownOutlined } from '@ant-design/icons';
import { Switch, Table, Input, Row } from "antd";
import DistributionPointShowInfo from "./DistributionPointShowInfo";
import DistributionPointWarehousesDetail from "./DistributionPointWarehousesDetail";
import React, { Fragment } from 'react';
import request from "../../../services/request";
import gasIcon from '../../../assets/images/mini_gas_pump.svg';

class DistributionPointTable extends React.Component {

    constructor() {
        super();
        this.searchTimeout = null;
        this.state = {};
    }

    toggleIsEnabled(enabled, distributionPointId) {
        request().put(`distributionPoints/${distributionPointId}/${enabled}`)
            .then(() => { this.props.refreshOnUpdate(); });
    }

    columns = [
        {
            title: '',
            dataIndex: '',
            key: 'icon',
            width: '5%',
            render: () => (<img src={gasIcon} alt="Punto de distribución" />)
        },
        {
            title: 'Nombre',
            dataIndex: 'subsidiary',
            key: 'subsidiary',
            sorter: true,
            width: '30%',
        },
        {
            title: 'Número de Permiso',
            dataIndex: 'senerLicense',
            key: 'senerLicense',
            sorter: true,
            width: '30%',
        },
        {
            title: 'En Uso',
            dataIndex: 'entitled',
            key: 'entitled',
            filters: [{ text: 'En Uso', value: true }, { text: 'Inhabilitado', value: false }],
            render: (entitled, record) => (
                <Switch
                    checked={entitled}
                    onChange={(checked) => { this.toggleIsEnabled(checked, record.id) }}
                    checkedChildren={<CrownOutlined />}
                    title={entitled ? "En Uso" : "Inhabilitado"}
                    unCheckedChildren={<CloseOutlined />} />
            )
        },
        {
            title: 'Info',
            dataIndex: 'id',
            key: 'id',
            render: (id) => (<DistributionPointShowInfo distributionPointId={id} />)
        }
    ];

    handleSearch = (value) => {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.props.handleSearchChange(value);
        }, 500);
    };

    render() {
        return (
            <Fragment>
                <Row>
                    <Input.Search
                        placeholder="Busca por Nombre o Número de Permiso"
                        enterButton
                        onSearch={(value) => {
                            this.props.handleSearchChange(value)
                        }}
                        onChange={(e) => {
                            this.handleSearch(e.target.value);
                        }}
                        style={{ float: 'inline-end' }}
                    />
                </Row>
                <Row>
                    <Table
                        columns={this.columns}
                        expandedRowRender={record => <DistributionPointWarehousesDetail warehouses={record.warehouseList} />}
                        dataSource={this.props.distributionPointList}
                        defaultExpandAllRows
                        scroll={{ x: false, y: 400 }}
                        style={{ overflowX: 'hidden' }}
                        pagination={this.props.pagination}
                        onChange={this.props.handleTableChange}
                        footer={() => {
                            return (<div>Total: {this.props.pagination.total}</div>)
                        }}
                    />
                </Row>



            </Fragment>
        );
    }
}

export default DistributionPointTable;

