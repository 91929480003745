import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Row, Button, Spin, Col, } from 'antd';
import {
    getPagePermissions,
    PERMISSION_NUMBER_PERMISSION
} from '../../security/permissions';
import {
    dispensaries as dispensaryServices
} from '../../services';
import PermissionNumberAddForm from './components/permissionNumberAddForm';
import PermissionNumberInfo from './components/permissionNumberInfo';

class PermissionNumbers extends Component {
    constructor() {
        super();
        this.state = {
            visiblePermissionNumberAddModal: false,
            loadingFormRegistry: false,
            serviceNumbers: [],
            unavialableServiceNumbers: [],
        };
    }

    componentDidMount() {
        this.fetchServiceNumbers();
        this.fetchUnavialableServiceNumbers();
    }

    fetchServiceNumbers = () => {
        dispensaryServices.get('serviceNumber/getAll')
            .then(response => {
                console.log("GET ALL SERVICE NUMBERS: ", response);
                this.setState({ serviceNumbers: response });
            });
    }

    fetchUnavialableServiceNumbers = () => {
        dispensaryServices.get('serviceNumber/getUnavialableEquipments')
            .then(response => {

                if (response.length === 0) {
                    dispensaryServices.get('hose/getHoses')
                        .then(response => {
                            this.setState({ unavialableServiceNumbers: response });
                        });
                } else {
                    dispensaryServices.post('hose/getHosesForPermissionNumberAssociation', response)
                        .then(response => {
                            this.setState({ unavialableServiceNumbers: response });
                        });
                }
            });
    }

    showPermissionNumberAddModal = () => {
        this.setState({ visiblePermissionNumberAddModal: true });
    }

    handleCancelPermissionNumberAddModal = () => {
        const form = this.permissionNumberAddFormRef.props.form;

        this.setState({ visiblePermissionNumberAddModal: false });
        form.resetFields();
    }

    savePermissionNumberAddFormRef = (formRef) => {
        this.permissionNumberAddFormRef = formRef;
    }

    handlePermissionNumberAdd = () => {
        const form = this.permissionNumberAddFormRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            console.log('Received values of form: ', values);
            const params = {
                serie: values.CFDISerial,
                startFolio: values.CFDIFolio,
                endFolio: values.CFDIFolio,
                status: true,
                numberPermission: values.numberPermission,
                idCompany: values.idCompanyInfo,
                equipment: values.hose,
            }

            dispensaryServices.post('serviceNumber/saveServiceNumber', params)
                .then(response => {
                    console.log('GET RESPONSE POST SERVICE NUMBER', response);
                    window.location.replace('/permissionNumbers');
                });
        });
    }

    render() {
        const cardItems = this.state.serviceNumbers.map((value, index) => (
            <Col key={value.idServiceNumber} span={12} style={{ marginBottom: '24px' }}>
                <PermissionNumberInfo serviceNumber={value} />
            </Col>
        ));

        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loadingFormRegistry}>
                <div>
                    <div style={{ marginBottom: '24px' }}>
                        {this.props.pagePermissions.add &&
                            <Button className="customized-primary" type="primary" icon={<PlusOutlined />} onClick={this.showPermissionNumberAddModal}>Agregar número de permiso.</Button>}
                    </div>
                    <Row gutter={24}>
                        {cardItems}
                    </Row>
                </div>
                <div>
                    <PermissionNumberAddForm
                        wrappedComponentRef={this.savePermissionNumberAddFormRef}
                        visible={this.state.visiblePermissionNumberAddModal}
                        onCancel={this.handleCancelPermissionNumberAddModal}
                        onAdd={this.handlePermissionNumberAdd}
                        unavialableServiceNumbers={this.state.unavialableServiceNumbers}
                    />
                </div>
            </Spin>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(PERMISSION_NUMBER_PERMISSION, permissions)
});

export default connect(mapStateToProps)(PermissionNumbers);