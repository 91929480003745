import axios from 'axios';
import { notification, Modal } from 'antd';
import { apiUrl } from '../app.config';
import { CV_CONTEXT_PATH, getToken, logOut } from '../security';
import { getRemoteIp } from '../common/helpers/remoteIp';


const openNotification = (errorMessage) => {
    notification.error({
        message: 'Sesión Expirada',
        description: errorMessage.message,
        onClose: () => {
            logOut();
        }
    });
};
const openNotificationSubscription = (errorMessage, logout = false) => {
    if (!logout) {
        Modal.error({
            title: 'Error en suscripción',
            content: errorMessage.message,
            onOk: () => {
                window.location.replace(`${CV_CONTEXT_PATH}/subscription`);
            },
            onCancel: () => {
                window.location.replace(`${CV_CONTEXT_PATH}/subscription`);
            }
        });
    } else {
        notification.error({
            message: 'Error en suscripción',
            description: errorMessage.message,
            onClose: () => logout ? logOut() : undefined,
        });
    }
};

/* export const tryToRefresToken = () => {
    if(isAuthenticated()){
        let tokenRequest = {
            token: getToken(),
            idRefreshToken: getRefreshToken()
        };
        removeToken();
        request().post('auth/refresh', tokenRequest).then((response)=>{
            if (response.hasOwnProperty('data')) {
                saveToken(response.data.token);
            }
            if (response.hasOwnProperty('response')) {
                openNotification(response.response.data);
            }
            
        }).catch((error)=>{
            console.error(error);
            openNotification(error.response.data);
        })
    }else{
        openNotification("No estas logeado");
    }
    
}; */

axios.interceptors.response
    .use(response => response, error => {
        const isLoggedIn = getToken() !== null;
        if (!isLoggedIn) { return error; }

        if (axios.isCancel(error)) {
            return Promise.reject("Petición Cancelada");
        }
        if (error.response === undefined) {
            openNotification("No se ha podido conectar con el servidor");
            return Promise.reject(error);
        }

        switch (error.response.status) {
            case 410:
            case 401:
                openNotification(error.response.data);
                break;
            case 412:
                openNotificationSubscription(error.response.data, true);
                break
            case 409:
                openNotificationSubscription(error.response.data);
                break
            default:
                break;
        }

        return Promise.reject(error);
    });

const request = () => {

    return {
        get: (url, options = {}) => getRemoteIp().then(ip => {
            const defaultOptions = {
                headers: {
                    Authorization: getToken(),
                    originIp: ip
                }
            };
            return axios.get(`${apiUrl}${url}`, { ...defaultOptions, ...options })
        }),
        post: (url, data, options = {}) => getRemoteIp().then(ip => {

            const defaultOptions = {
                headers: {
                    Authorization: getToken(),
                    originIp: ip
                }
            };
            return axios.post(`${apiUrl}${url}`, data, { ...defaultOptions, ...options })
        }),
        put: (url, data, options = {}) => getRemoteIp().then(ip => {
            const defaultOptions = {
                headers: {
                    Authorization: getToken(),
                    originIp: ip
                }
            };
            return axios.put(`${apiUrl}${url}`, data, { ...defaultOptions, ...options })
        }),
        patch: (url, data, options = {}) => getRemoteIp().then(ip => {
            const defaultOptions = {
                headers: {
                    Authorization: getToken(),
                    originIp: ip
                }
            };
            return axios.patch(`${apiUrl}${url}`, data, { ...defaultOptions, ...options })
        }),
        delete: (url, data, options = {}) => getRemoteIp().then(ip => {
            const defaultOptions = {
                headers: {
                    Authorization: getToken(),
                    originIp: ip
                }
            };
            return axios.delete(`${apiUrl}${url}`, { ...data, ...defaultOptions, ...options })
        }),
    };
};

export default request;
