import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Modal, Button, Input, Row, Col, Select } from 'antd';

const { TextArea } = Input;
const Option = Select.Option;

const ManualRegistrationForm = Form.create({ name: 'manualRegistrationForm_into_modal' })(

    class extends Component {
        constructor() {
            super();
            this.state = {
                counter: 0,
            };
        }

        handleTextChange = event => {
            this.setState({
                counter: event.target.value.length
            });
        }

        render() {
            const { visible, onCancel, onRegister, form, evenTypes } = this.props;
            const { getFieldDecorator } = form;

            const optionItems = types => types.map(item => (
                item.value > 0 && item.value <= 7 ? <Option key={item.value} value={item.value}>{item.label}</Option> : NaN
            ));

            return (
                <Modal
                    open={visible}
                    title="Agregar registro manual"
                    onCancel={onCancel}
                    width={700}
                    footer={[
                        <Button className="back-button" key="back" onClick={onCancel}>Cancelar</Button>,
                        <Button className="submit-button" key="submit" onClick={onRegister}>Agregar</Button>
                    ]}
                >
                    <p>Describe brevemente el motivo del registro.</p>
                    
                    <Form layout='vertical' >
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label="Tipo de evento a registrar"
                                >
                                    {getFieldDecorator('createEventType', {
                                        rules: [{ required: true, message: 'Por favor selecciona el tipo de evento a registrar.' }]
                                        //initialValue: '1'
                                    })(
                                        <Select>
                                            {optionItems(evenTypes)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row> 
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item label="Descripción">
                                    {getFieldDecorator('eventDescription', {
                                        rules: [{ required: true, message: 'Por favor introduce una descripción' }]
                                    })(
                                        <TextArea maxLength={150} placeholder="Escribe aquí" autosize={{ minRows: 4, maxRows: 5 }} onChange={this.handleTextChange} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>   
                    </Form>
                    <div style={{ textAlign: 'right', color: '#A3A3A3' }}>{`${this.state.counter} / 150`}</div>
                </Modal>
            );
        }
    }
);

export default ManualRegistrationForm;
