/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 11:42:42
 * @Last Modified by: Alejandro Leonor
 * @Last Modified time: 2018-03-14 11:05:21
 */
import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';

import * as playground from '../routes/playground/redux';
import * as generalData from './general';

export const rootEpic = combineEpics(playground.epics);

const userDataReducer = (state = {}, { type }) => {
    switch (type) {
        default:
            return state;
    }
};

export const rootReducer = combineReducers({
    playground: playground.reducer,
    generalData: generalData.reducer,
    userData: userDataReducer
});
