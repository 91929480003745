import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import {
    Input,
    Row,
    message,
    Divider,
    Col,
    Button,
    Spin,
    Select,
    InputNumber,
    Switch,
} from 'antd';

import {
    dispensaries as dispensaryServices
} from '../../../services';

class OutputEmailEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingForm: false,
            outputEmail: [],
            canEdit: true,
            canSave: false,
        }
    };


    componentDidMount() {
        this.getOutputEmail();
    }

    getOutputEmail = () => {
        this.setState({ loadingForm: true });
        dispensaryServices.get('outputEmail/getOutputEmail')
            .then(response => {
                this.setState({
                    outputEmail: response
                });
                this.setState({ loadingForm: false });


            });

    }
    handleSubmit = () => {
        this.props.form.validateFields(['protocol', 'host', 'port', 'auth', 'email', 'password', 'startTtls', 'useSSL'], (err, values) => {
            if (!err) {
                this.setState({ loadingForm: true });
                values.auth = values.auth === 'login' ? true : false;
                dispensaryServices.put('outputEmail/changeOutputEmail', values)
                    .then(response => {
                        const jsonResponse = JSON.parse(JSON.stringify(response));
                        if (jsonResponse.hasOwnProperty('response')) {
                            message.error(jsonResponse.response.data);
                        } else {
                            message.success("Información actualizada correctamente")
                        }
                        this.setState({ loadingForm: false });
                    });
            }
        });
    };

    onSendTest = () => {
        this.setState({ loadingForm: true })
        this.props.form.validateFields(['protocol', 'host', 'port', 'auth', 'email', 'password', 'startTtls', 'useSSL', 'emailTest'], (err, values) => {
            if (!err) {
                values.auth = values.auth === 'login' ? true : false;
                dispensaryServices.post('initial-config/smtp/test', values)
                    .then(response => {
                        if (response) {
                            this.setState({ canSave: true })
                            message.success("Configuración correcta para enviar correo");
                        } else {
                            message.error("Configuración de correo Incorrecta");
                            this.setState({ canSave: false })
                        }
                        this.setState({ loadingForm: false })
                    });
            } else {
                this.setState({ loadingForm: false })
            }

        });
    };

    onChangeAuthType = (e) => {
        this.setState({ canEdit: e === "login" })
    }
    handleChangeValue = (e) => {
        this.setState({ canSave: false });
    }


    render() {
        const { outputEmail } = this.state;
        const { form } = this.props;
        const { getFieldDecorator } = form;

        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loadingForm}>

                <Form layout='vertical' >
                    <Row>
                        <span className="instructions">Llena los campos a continuación para configurar el correo de salida para la compañia.</span>
                        <Form.Item extra="* Campos obligatorios" />
                    </Row>
                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Datos de configuración</Divider>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item label="Protocolo">
                                {getFieldDecorator('protocol', {
                                    initialValue: outputEmail.protocol,
                                    rules: [{
                                        required: true, message: 'El protocolo es requerido.'
                                    }]
                                })(
                                    <Select style={{ width: '100%' }}  >
                                        <Select.Option value="smtp" >SMTP</Select.Option>
                                    </Select>
                                )}

                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="Dominio">
                                {getFieldDecorator('host', {
                                    initialValue: outputEmail.host,
                                    rules: [{
                                        required: true, message: "El dominio es requerido"
                                    }]
                                })(
                                    <Input onChange={this.handleChangeValue} />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Puerto">
                                {getFieldDecorator('port', {
                                    initialValue: outputEmail.port,
                                    rules: [{
                                        required: true, message: 'Por favor introduce un puerto.'
                                    }]
                                })(
                                    <InputNumber min={1} max={65535} style={{ width: '100%' }} onChange={this.handleChangeValue} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Form.Item label="Tipo de autenticación">
                                {getFieldDecorator('auth', {
                                    initialValue: outputEmail.auth === true ? "login" : "nologin",
                                    rules: [{
                                        required: true, message: 'Tipo de autenticación es requerido.'
                                    }]
                                })(
                                    <Select onChange={this.onChangeAuthType}>
                                        <Select.Option value="login" >Login</Select.Option>
                                        <Select.Option disabled value="nologin" >Ninguno</Select.Option>
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    {this.state.canEdit && (
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item label="Correo electrónico de salida">
                                    {getFieldDecorator('email', {
                                        initialValue: outputEmail.email,
                                        rules: [{
                                            type: 'email', message: 'Correo electrónico de salida no válido'
                                        }, {
                                            required: true, message: 'Por favor introduce un correo electrónico.'
                                        }]
                                    })(
                                        <Input onChange={this.handleChangeValue} />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Contraseña">
                                    {getFieldDecorator('password', {
                                        initialValue: outputEmail.password,
                                        rules: [{
                                            message: 'Contraseña.'
                                        }, {
                                            required: true, message: 'Por favor introduce una contraseña.'
                                        }
                                        ]
                                    })(
                                        <Input type="password" onChange={this.handleChangeValue} />
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                    }
                    <Row gutter={24} >
                        <Col span={12}>
                            <Form.Item label="Usar STARTTLS si esta disponible" />
                        </Col>
                        <Col span={12}>
                            <Form.Item name="startTtls">
                                {getFieldDecorator('startTtls', {
                                    valuePropName: 'checked',
                                    initialValue: outputEmail.startTtls,

                                })(
                                    <Switch onChange={this.handleChangeValue} />
                                )}
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row gutter={24} >
                        <Col span={12}>
                            <Form.Item label="Usar conexión SSL" />
                        </Col>
                        <Col span={12}>
                            <Form.Item name="useSSL">
                                {getFieldDecorator('useSSL', {
                                    valuePropName: 'checked',
                                    initialValue: outputEmail.useSSL,

                                })(
                                    <Switch onChange={this.handleChangeValue} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="Correo electrónico de prueba (configuraión)">
                                {getFieldDecorator('emailTest', {
                                    rules: [{
                                        type: 'email', message: 'Correo electrónico de prueba no válido'
                                    }, {
                                        required: true, message: 'Por favor introduce un correo electrónico.'
                                    }]
                                })(
                                    <Input onChange={this.handleChangeValue} />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Button ghost type="primary" className="submit-button" onClick={this.onSendTest}>Probar configuracion</Button>
                        </Col>
                        <Col span={12}>
                            <Button className="submit-button" onClick={this.handleSubmit} disabled={this.state.canSave === false}>Guardar</Button>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        );
    }
}

export default Form.create({ name: 'outputEmailEdit' })(OutputEmailEdit);
