import { message } from 'antd';
import axios from 'axios';
import { apiUrl } from '../../app.config';

let cachedIp;

//TODO improve this logic to be cached for some time and add  fault tolerance adding other providers
export const getRemoteIp = () => {
    if (cachedIp) {
        return new Promise((resolve, reject) => resolve(cachedIp));
    }

    console.log("Requesting remote IP Address");
    return axios.get(`${apiUrl}initial-config/company`)
        .then(response => {
            let remoteIp;
            if(response.hasOwnProperty('headers')){
                remoteIp = response.headers['x-remote-ip'];
            }else{
                message.error("No se ha podido establecer una conexión al servidor");
            }
             
            cachedIp = remoteIp || '127.0.0.1';
            return cachedIp;
        });
}