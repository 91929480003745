import React, { Component } from 'react';
import { Table } from 'antd';

const columns = [{
    title: 'Identificador manguera',
    dataIndex: 'equipmentKey',
    width: '20%'
}, {
    title: 'Tipo de producto',
    dataIndex: 'productDesc',
    width: '20%'
}, {
    title: 'Número de operaciones',
    dataIndex: 'equipmentMovements',
    width: '20%'
}, {
    title: 'Litros vendidos',
    dataIndex: 'equipmentVolume',
    width: '20%'
}, {
    title: 'Total venta',
    dataIndex: 'equipmentAmount',
    render: text => `$${text}`,
    width: '20%'
}];

const footerColumns = [{
    title: 'Código de dispensario',
    dataIndex: 'dispensaryCode',
    render: text => `TOTAL Dispensario ${text}`,
    width: '40%'
}, {
    title: 'Total de operaciones',
    dataIndex: 'dispensaryMovement',
    render: text => <b>{text}</b>,
    width: '20%'
}, {
    title: 'Total de litros vendidos',
    dataIndex: 'dispensaryVolume',
    render: text => <b>{text}</b>,
    width: '20%'
}, {
    title: 'Total de ventas',
    dataIndex: 'dispensaryAmount',
    render: text => <b>{`$${text}`}</b>,
    width: '20%'
}];

class DispensarySalesDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            movementDetail: [props.movementDetail]
        };
    }

    render() {
        return (
            <div>
                <Table
                    columns={columns}
                    rowKey={record => record.equipmentKey}
                    dataSource={this.props.movementDetail.generalDetail}
                    bordered={false}
                    title={() => <span style={{ color: '#1B95CA' }}>{`Dispensario ${this.props.movementDetail.dispensaryCode}`}</span>}
                    pagination={false}

                />
                <Table
                    style={{ background: '#F1FAFF' }}
                    rowKey={record => record.dispensaryCode}
                    columns={footerColumns}
                    dataSource={[this.props.movementDetail]}
                    showHeader={false}
                    bordered={false}
                    pagination={false}
                />
                <br />
            </div>
        );
    }
}

export default DispensarySalesDetail;
