import React from 'react';
import { connect } from 'react-redux';
import { Table, } from 'antd';
import {
    getPagePermissions,
    DISPENSARY_PERMISSION
} from '../../../security/permissions';



class PermissionNumberHoses extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: props.hoseCodes, };
    }


    columns = [
        {
            title: 'Código de manguera',
            key: 'hoseCode',
            render: (text) => (
                <span>
                    <label className="dispensary-id">{text}</label>
                </span>
            )
        },
    ];

    render() {
        return (
            <div>
                <Table
                    size="middle"
                    columns={this.columns}
                    rowKey={this.state.data}
                    title={() => 'Mangueras asociadas:'}
                    dataSource={this.state.data}
                    showHeader={false}
                    pagination={false}
                />
            </div>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISPENSARY_PERMISSION, permissions)
});

export default connect(mapStateToProps)(PermissionNumberHoses);
