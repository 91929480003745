import React from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import {
    Modal,
    Input,
    Button,
    Row,
    Col,
    DatePicker,
    Popover,
    InputNumber,
    Spin,
    message,
    Tooltip,
} from 'antd';
import dayjs from "dayjs";
import {
    dispensaries as dispensaryServices
} from '../../../services';
import tooltip from './tooltip.json'
import { getIsCloudModeActive } from '../../../security';

const { TextArea } = Input;

const HoseShowInfo = Form.create({ name: 'hoseAddForm_into_modal' })(

    class extends React.Component {
        state = {
            hoseInfo: {},
            hoseId: undefined,
            visible: false,
            incertidumbre: undefined,
            loading: false,
        };

        showModal = (hoseUUID) => {
            dispensaryServices.get(`hose/${hoseUUID}`)
                .then(response => {
                    this.setState({
                        hoseInfo: response,
                        hoseId: hoseUUID,
                        visible: true,
                    });
                });
        }

        handleReturn = () => {
            this.setState({
                visible: false,
                hoseId: undefined,
                loading: false
            });
        };

        updateHose = () => {
            this.setState({
                loading: true,
            }, ()=>{
                let params = {
                    idEquipment: this.state.hoseId,
                    uncertaintyMeasurement: this.state.incertidumbre
                };
                dispensaryServices.put(`hose/${this.state.hoseId}`, params)
                    .then(response => {
                        message.info(response.message);
                        this.setState({
                            hoseInfo: response.hose
                        });
                    }).catch((error)=>{
                        message.error(error);
                    }).finally(()=>{
                        this.setState({loading: false});
                        this.showModal(this.state.hoseId);
                    });
            });
        }

        render() {
            const { hoseUUID, priceSynchronized } = this.props;
            const { hoseInfo } = this.state;
            const isCloudMode= getIsCloudModeActive();

            return (
                <div style={{ display: 'inline-block' }}>
                    <Popover content="Ver detalles">
                        <a type="link" shape="circle" onClick={() => this.showModal(hoseUUID)}>
                            <InfoCircleTwoTone style={{ fontSize: '20px' }} />
                        </a>
                    </Popover>
                    <Modal
                        open={this.state.visible}
                        title="Información de la manguera"
                        onCancel={this.handleReturn}
                        footer={[
                            <Button className="back-button" key="back" onClick={this.handleReturn}>Volver</Button>,
                            <Button hidden={this.state.incertidumbre===undefined} className="submit-button" key="save" onClick={this.updateHose}>Actualizar Manguera</Button>
                        ]}
                    >
                        <p>A continuación se muestra la información detallada de la manguera.</p>
                        <Spin spinning={this.state.loading}>
                            <Form layout='vertical' >
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Tooltip title={tooltip.codigoDispensario}>
                                            <Form.Item label="Código de dispensario">
                                                <Input defaultValue={hoseInfo.requestEquipmentKey} disabled />
                                            </Form.Item>
                                        </Tooltip>
                                    </Col>
                                    <Col span={12}>
                                        <Tooltip title={tooltip.codigoAuditable}>
                                            <Form.Item label="Código auditable de dispensario">
                                                <Input defaultValue={hoseInfo.requestAuditableEquipmentKey} disabled />
                                            </Form.Item>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12} hidden={isCloudMode}>
                                        <Form.Item label="Código de manguera">
                                            <Input defaultValue={hoseInfo.requestHoseKey} disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Tooltip title={tooltip.codigoAuditableManguera}>
                                            <Form.Item label="Código  auditable de manguera">
                                                <Input defaultValue={hoseInfo.requestAuditableHoseKey} disabled />
                                            </Form.Item>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12} hidden={isCloudMode}>
                                        <Form.Item label="Precio">
                                            <Input style={{ color: priceSynchronized ? 'green' : 'red' }} defaultValue={hoseInfo.requestPrice} disabled />
                                        </Form.Item>
                                    </Col>
                                    {<Col span={12}>
                                        <Tooltip title={tooltip.status}>
                                            <Form.Item label="Status">
                                                <Input defaultValue={hoseInfo.requestStatus} disabled />
                                            </Form.Item>
                                        </Tooltip>
                                    </Col>}
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Tooltip title={tooltip.validacionCalibracion}>
                                            <Form.Item label="Validación de calibración">
                                                <DatePicker  lang='es' style={{ width: '100%' }} format="DD/MM/YYYY HH:mm:ss" defaultValue={dayjs(hoseInfo.requestValidityCalibration)} disabled />
                                            </Form.Item>
                                        </Tooltip>
                                    </Col>
                                    <Col span={12}>
                                        <Tooltip title={tooltip.incertidumbre}>
                                            <Form.Item label="Incertidumbre de medición">
                                                <InputNumber 
                                                    defaultValue={hoseInfo.requestMeasurementUncertainty} 
                                                    step={0.01}
                                                    onChange={(value)=>{
                                                        this.setState({
                                                            incertidumbre: (hoseInfo.requestMeasurementUncertainty===value)? undefined: value
                                                        });
                                                    }}
                                                />
                                            </Form.Item>
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Tooltip title={tooltip.descripcion}>
                                    <Form.Item label="Descripción">
                                        <TextArea defaultValue={hoseInfo.requestDescription} autoSize={{ minRows: 2, maxRows: 6 }} disabled />
                                    </Form.Item>
                                </Tooltip>
                            </Form>
                        </Spin>
                    </Modal>
                </div>
            );
        }
    }
);

export default HoseShowInfo;
