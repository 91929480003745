import React from 'react';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Badge } from 'antd';

const style = {
    marginLeft: 5
};

const iconStyle = {
    fontSize: 16,
    verticalAlign: 'middle',
    marginRight: 15,
    cursor: 'pointer',
    color: '#999'
};

const ExpandIconWithBadge = ({ iconType, count, maxCharacters, dot, onClick }) => (
    <Badge count={count} overflowCount={maxCharacters} dot={dot}>
        <LegacyIcon type={iconType} style={iconStyle} onClick={onClick} />
    </Badge>
);

const ExpandIcon = ({ expanded, ...props }) => (
    <span style={style}>
        <ExpandIconWithBadge
            iconType={expanded ? 'minus-square-o' : 'plus-square-o'}
            dot={expanded}
            {...props}
        />
    </span>
);

export default ExpandIcon;
