import React, { Component } from "react";
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import {
    Row,
    Col,
    Spin,
    Input,
    Button,
    Modal,
    Divider,
    Select,
    Checkbox,
    InputNumber,
    message,
    Popover,
    Tooltip,
    notification,
} from "antd";
import {
	isValidRfc,
	patternRFC,
	patternRfcPersonaFisica,
} from "./../../../services/utils/rfcValidator";
import {
	regulatoryCharacter,
	timeZones,
} from "./../../../services/utils/StaticInfoEnums";
import request from "../../../services/request";
import { register as registerServices } from "../../../services";
import { CV_CONTEXT_PATH, ToFirstWarehouseRegistry } from "../../../security";
import EmailsModal from "./emailsModal";
import tooltipList from './tooltip.json';

const { TextArea } = Input;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;



class DistributionPointCreatorForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: props.form,
			loading: props.loading,
			data: {},
			products: [],
			installationKeys: [],
			companyActivityTypes: [],
			permissionKeys: [],
			activeProducts: [],
			legalRepresentative: false,
			isAldreadyAccepted: false,
			isConfigApplicationRegistered: props.isConfigApplicationRegistered,
			allowEdit: props.allowEdit !== undefined ? props.allowEdit : true,
			hideModalConfirmation: true,
			distributionPoint: props.distributionPoint,
			showReturnButton: props.showReturnButton,
			isDistributionPoint: props.isDistributionPoint,
			warningText: '',
			visibleEmailsModal: false,
			availableEmails: [],
			
		};
		this.mounted = false;
	}
	

	componentDidMount() {
		if(!this.mounted){
			this.mounted = true
			this.fetch();
			this.getActiveProducts();
			this.getPermissionKeys();
			this.getInstallationKeys();
			this.getCompanyActivityTypes();
			this.getProducts();
			this.setWarningText();
			request().get('emailList').then((responseEmail) => {
				this.setState({ availableEmails: responseEmail.data });
			}).catch((error) => {
				console.error(error);
			});
		}
	}

	showEmailsModal = () => {
		this.setState({ visibleEmailsModal: true });
	}
	hideEmailsModal = () => {
		this.setState({ visibleEmailsModal: false });
	}

	filter = (input, option) => {
		return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
	}

	render() {
		const { getFieldDecorator, getFieldValue } = this.state.form;
		return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loading}>
				<div hidden={this.state.form === undefined}>
					<Form layout='vertical' onSubmit={this.showModalConfirmation}>
						<Row hidden={!this.state.allowEdit}>
							<span className="instructions">
								<div hidden={this.state.isConfigApplicationRegistered}>
									Llena los campos a continuación para registrar el punto de
									distribución
								</div>
								<div hidden={!this.state.isConfigApplicationRegistered}>
									Llena los campos a continuación para registrar la compañía
								</div>

							</span>
							<Form.Item extra="* Campos obligatorios" />
						</Row>
						<Divider
							orientation="left"
							style={{ fontSize: "15px", color: "#606676" }}
						>
							<div hidden={this.state.isConfigApplicationRegistered}>
								Datos del punto de distribución
							</div>
							<div hidden={!this.state.isConfigApplicationRegistered}>
								Datos de la compañía
							</div>

						</Divider>
						<Row gutter={24}>
							<Col span={8}>
								<Tooltip title={tooltipList.RFC} >
									<Form.Item label="RFC">
										{getFieldDecorator("rfc", {
											initialValue: this.state.data.rfc,
											normalize: (value) => (value || "").toUpperCase(),
											rules: [
												{
													required: true,
													message:
														"Por favor introduce el RFC del contribuyente.",
												},
												{
													min: 12,
													message: "Un RFC válido tiene al menos 12 caracteres.",
												},
												{
													max: 13,
													message: "Un RFC válido tiene no más de 13 caracteres.",
												},
												{
													pattern: patternRFC,
													message: "EL RFC tiene un formato no válido",
												},
											],
										})(
											<Input
												className="common-input"
												placeholder="Introduce el RFC del contribuyente"
												onBlur={this.onBlurRfc}
												autoComplete="off"
												disabled={!this.state.allowEdit}

											/>
										)}
									</Form.Item>
								</Tooltip>

							</Col>
							<Col span={8}>
								<Tooltip title={tooltipList.razonSocial}>
									<Form.Item label="Nombre o razón social de la empresa">
										{getFieldDecorator("name", {
											initialValue: this.state.data.name,
											normalize: (value) => (value || "").toUpperCase(),
											rules: [
												{
													required: true,
													message:
														"Por favor introduce el nombre o razón social del contribuyente.",
												},
											],
										})(
											<Input
												className="common-input"
												placeholder="Introduce el nombre o razón social del contribuyente"
												disabled={!this.state.allowEdit}
												autoComplete="off"
												suffix={
													<Popover content="No es necesario poner S.A. de C.V.">
														<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
													</Popover>
												}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
							<Col span={8}>
								<Tooltip title={tooltipList.nombrePDD}>
									<Form.Item label="Nombre del punto de distribución">
										{getFieldDecorator("subsidiary", {
											initialValue: this.state.data.subsidiary,
											rules: [
												{
													required: true,
													message:
														"Por favor introduce el nombre del punto de distribución",
												},
											],
										})(
											<Input
												className="common-input"
												placeholder="Por favor introduce el del punto de distribución"
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={8}>
								<Tooltip title={tooltipList.actividadReportada}>
									<Form.Item label="Actividad reportada ante la autoridad fiscal">
										{getFieldDecorator("companyActivityType", {
											initialValue: this.state.data.companyActivityType,
											rules: [
												{
													required: true,
													message:
														"Por favor selecciona el tipo de actividad realizada",
												},
											],
										})(
											<Select
												disabled={!this.state.allowEdit}
												placeholder="Selecciona un tipo de actividad"
												showSearch filterOption={this.filter}
											>
												{this.getOptionItemCodeDescription(
													this.state.companyActivityTypes
												)}
											</Select>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
							<Col
								span={8}
								hidden={this.state.data.identificationKey !== undefined}
							>
								<Tooltip title={tooltipList.codigoClaveInstalacion}>
									<Form.Item label="Codigo de clave de instalación">
										{getFieldDecorator("installationKey", {
											rules: [
												{
													required: true,
													message:
														"Por favor selecciona la clave de instalación de la planta.",
												},
											],
										})(
											<Select disabled={!this.state.allowEdit} showSearch filterOption={this.filter}>
												{this.getOptionItemInstallationKey(
													this.state.installationKeys
												)}
											</Select>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
							<Col
								span={this.state.data.identificationKey !== undefined ? 16 : 8}
							>
								<Tooltip title={tooltipList.numeroClaveInstalacion}>
									<Form.Item label="Número de clave de instalación">
										{getFieldDecorator("installationKeyNumber", {
											initialValue: this.state.data.identificationKey,
											rules: [
												{
													required: true,
													message:
														"Por favor introduce el número de la clave de instalación.",
												},
												{
													pattern: /^[0-9-]{4}$/,
													message:
														"Introduce un valor de 4 caracteres, puedes usar sólo números.",
												},
											],
										})(
											<Input
												addonBefore={this.props.form.getFieldValue('installationKey')}
												className="common-input"
												maxLength={4}
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={8}>
								<Tooltip title={tooltipList.numeroOficio}>
									<Form.Item label="Número de oficio">
										{getFieldDecorator("operationLicense", {
											initialValue: this.state.data.operationLicense,
											rules: [
												{
													required: true,
													message:
														"Por favor introduce el número de oficio del aviso de inicio de operaciones.",
												},
											],
										})(
											<Input
												className="common-input"
												placeholder="Introduce el número de oficio del aviso de inicio de operaciones"
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
							<Col span={8}>
								<Tooltip title={tooltipList.zonaHoraria}>
									<Form.Item label="Zona horaria">
										{getFieldDecorator("timeZone", {
											initialValue: this.state.data.localTimeZone,
											rules: [
												{
													required: true,
													message: "Por favor selecciona una zona horaria.",
												},
											],
										})(
											<Select
												disabled={!this.state.allowEdit}
												placeholder="Selecciona una zona horaria"
												showSearch filterOption={this.filter}
											>
												{this.getOptionItemTextValue(timeZones)}
											</Select>
										)}
									</Form.Item>
								</Tooltip>
							</Col>

							<Col span={8}>
								<Tooltip title={tooltipList.numeroPermiso}>
									<Form.Item label="Número de permiso">
										{getFieldDecorator("senerLicense", {
											initialValue: this.state.data.senerLicense,
											rules: [
												{
													required: true,
													message:
														"Por favor introduce el número de permiso otorgado por la CRE o la Secretaría de Energía.",
												},
											],
										})(
											<Input
												className="common-input"
												placeholder="Introduce el número de permiso otorgado por la CRE o la Secretaría de Energía"
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={24}>
								<Tooltip title={tooltipList.descripcion}>
									<Form.Item label="Descripción">
										{getFieldDecorator("description", {
											initialValue: this.state.data.description,
											rules: [
												{
													required: true,
													message: "Por favor introduce una descripción.",
												},
											],
										})(
											<TextArea
												className="common-input"
												placeholder="Introduce una descripción"
												autoSize
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
						</Row>
						<Divider
							hidden={this.state.legalRepresentative === false}
							orientation="left"
							style={{ fontSize: "15px", color: "#606676" }}
						>
							Datos del representante legal
						</Divider>
						<Row gutter={24} hidden={this.state.legalRepresentative === false}>
							<Col span={8}>
								<Tooltip title={tooltipList.representanteLegal.RFC}>
									<Form.Item label="RFC">
										{getFieldDecorator("legalRepresentativeRfc", {
											initialValue: this.state.data.legalRepresentativeRfc,
											normalize: (value) => (value || "").toUpperCase(),
											rules: [
												{
													required: this.state.legalRepresentative,
													message:
														"Por favor introduce el RFC del representante legal.",
												},
												{
													min: 13,
													message:
														"Un RFC Persona Física válido tiene exactamente 13 caracteres.",
												},
												{
													max: 13,
													message:
														"Un RFC Persona Física válido tiene exactamente 13 caracteres.",
												},
												{
													pattern: patternRfcPersonaFisica,
													message: "Introduce un RFC Persona Física válido.",
												},
											],
										})(
											<Input
												className="common-input"
												placeholder="Introduce el RFC del representante legal"
												disabled={!this.state.allowEdit}
												autoComplete="off"
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
							<Col span={8}>
								<Tooltip title={tooltipList.representanteLegal.nombre}>
									<Form.Item label="Nombre del representante legal">
										{getFieldDecorator("legalRepresentative", {
											initialValue: this.state.data.legalRepresentative,
											normalize: (value) => (value || "").toUpperCase(),
											rules: [
												{
													required: this.state.legalRepresentative,
													message:
														"Por favor introduce el RFC del representante legal.",
												},
											],
										})(
											<Input
												className="common-input"
												placeholder="Introduce el nombre o razón social del repredatasetDatesentante legal"
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
						</Row>
						<Divider
							orientation="left"
							style={{ fontSize: "15px", color: "#606676" }}
						>
							Caracter regulatorio
						</Divider>
						<Row gutter={24}>
							<Col span={12}>
								<Tooltip title={tooltipList.caracter.descripcion}>
									<Form.Item label="Caracter regulatorio">
										{getFieldDecorator("character", {
											initialValue: this.state.data.character,
											rules: [
												{
													required: true,
													message:
														"Por favor selecciona el carácter con el que actúa para efectos regulatorios.",
												},
											],
										})(
											<Select
												disabled={!this.state.allowEdit}
												placeholder="Selecciona el carácter con el que actúa para efectos regulatorios"
												onChange={this.handleCharacterRegulatoryClick}
												showSearch filterOption={this.filter}
											>
												{this.getOptionItemTextValue(regulatoryCharacter)}
											</Select>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
							<Col
								span={12}
								hidden={getFieldValue("character") !== "PERMISIONARIO"}
							>
								<Tooltip title={tooltipList.caracter.permisionario}>
									<Form.Item label="Número de permiso regulatorio">
										{/* {getFieldDecorator("permissionNumber", {
											initialValue: this.state.data.regulatoryCharacterField1,
										})( */}
										<Input
											className="common-input"
											placeholder="Introduce el número de permiso regulatorio"
											disabled={true}
											value={this.state.data.regulatoryCharacterField1 || this.props.form.getFieldValue('senerLicense')}
										/>
										{/* )} */}
									</Form.Item>
								</Tooltip>
							</Col>
							<Col
								span={12}
								hidden={
									getFieldValue("character") === "PERMISIONARIO" ||
									getFieldValue("character") === "USUARIO" ||
									getFieldValue("character") === undefined
								}
							>
								<Tooltip title={tooltipList.caracter.contratistaAsigantario}>
									<Form.Item label="Número de contrato o asignación">
										{getFieldDecorator("contractAsignationNumber", {
											initialValue: this.state.data.regulatoryCharacterField1,
											rules: [
												{
													required: !!(
														getFieldValue("character") === "CONTRATISTA" ||
														getFieldValue("character") === "ASIGNATARIO"
													),
													message:
														"Por favor indroduce el número de asignación otorgada por la Secretaría de Energía o el número de contrato celebrado con la Comisión Nacional de Hidrocarburos.",
												},
												{ min: 14, message: "Introduce mínimo 14 caracteres." },
												{ max: 24, message: "Introduce máximo 24 caracteres." },
											],
										})(
											<Input
												className="common-input"
												placeholder="Introduce el número de contrato o asignación"
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col
								span={24}
								hidden={getFieldValue("character") !== "PERMISIONARIO"}
							>
								<Tooltip title={tooltipList.caracter.modalidadPermiso}>
									<Form.Item label="Modalidad de permiso">
										{getFieldDecorator("permissionModality", {
											initialValue: this.state.data.regulatoryCharacterField2,
											rules: [
												{
													required:
														getFieldValue("character") === "PERMISIONARIO",
													message:
														"Por favor selecciona la modalidad del permiso.",
												},
											],
										})(
											<Select
												disabled={!this.state.allowEdit}
												placeholder="Selecciona la modalidad de permiso"
												showSearch filterOption={this.filter}
											>
												{this.getOptionItemRegulatoryPermission(
													this.state.permissionKeys
												)}
											</Select>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
							<Col span={24} hidden={getFieldValue("character") !== "USUARIO"}>
								<Tooltip title={tooltipList.caracter.descripcionInstalacion}>
									<Form.Item label="Descripción de instalación">
										{getFieldDecorator("naturalGasStorageInstalation", {
											initialValue: this.state.data.regulatoryCharacterField1,
											rules: [
												{
													required: getFieldValue("character") === "USUARIO",
													message:
														"Por favor describe la instalación del almacen.",
												},
												{ min: 16, message: "Introduce mínimo 16 caracteres." },
												{ max: 250, message: "Introduce máximo 250 caracteres." },
											],
										})(
											<TextArea
												autoSize={{ minRows: 1, maxRows: 2 }}
												placeholder="Introduce la descripción de la instalación"
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
						</Row>
						<Divider
							orientation="left"
							style={{ fontSize: "15px", color: "#606676" }}
						>
							Domicilio fiscal
						</Divider>
						<Row gutter={24}>
							<Col span={8}>
								<Tooltip title={tooltipList.domicilioFiscal.calle}>
									<Form.Item label="Calle">
										{getFieldDecorator("street", {
											initialValue: this.state.data.street,
											rules: [
												{
													required: true,
													message: "Por favor introduce el nombre de la calle.",
												},
											],
										})(
											<Input
												className="common-input"
												placeholder="Introduce el nombre de la calle"
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
							<Col span={8}>
								<Tooltip title={tooltipList.domicilioFiscal.numero} >
									<Form.Item label="Número">
										{getFieldDecorator("number", {
											initialValue: this.state.data.number,
											rules: [
												{
													required: true,
													message: "Por favor introduce el número de la calle.",
												},
											],
										})(
											<Input
												className="common-input"
												placeholder="Introduce el número de la calle"
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
							<Col span={8}>
								<Tooltip title={tooltipList.domicilioFiscal.numeroInterior}>
									<Form.Item label="Número Interior">
										{getFieldDecorator("insideNumber", {
											initialValue: this.state.data.insideNumber,
										})(
											<Input
												className="common-input"
												placeholder="Introduce el número interior"
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={8}>
								<Tooltip title={tooltipList.domicilioFiscal.colonia}>
									<Form.Item label="Colonia">
										{getFieldDecorator("colony", {
											initialValue: this.state.data.colony,
											rules: [
												{
													required: true,
													message: "Por favor introduce el nombre de la colonia.",
												},
											],
										})(
											<Input
												className="common-input"
												placeholder="Introduce el nombre de la colonia"
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
							<Col span={8}>
								<Tooltip title={tooltipList.domicilioFiscal.codigoPostal}>
									<Form.Item label="Código postal">
										{getFieldDecorator("postalCode", {
											initialValue: this.state.data.postalCode,
											rules: [
												{
													required: true,
													message: "Por favor introduce el código postal.",
												},
												{
													pattern: /^\d{5}$/,
													message:
														"Un código postal válido tiene exactamente 5 digitos.",
												},
											],
										})(
											<Input
												className="common-input"
												style={{ width: "100%" }}
												placeholder="Introduce el código postal"
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
							<Col span={8}>
								<Tooltip title={tooltipList.domicilioFiscal.estado}>
									<Form.Item label="Estado">
										{getFieldDecorator("state", {
											initialValue: this.state.data.state,
											rules: [
												{
													required: true,
													message: "Por favor introduce el Estado.",
												},
											],
										})(
											<Input
												className="common-input"
												placeholder="Introduce el Estado"
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
						</Row>
						<Row gutter={24}>
							<Col span={8}>
								<Tooltip title={tooltipList.domicilioFiscal.municipio}>
									<Form.Item label="Municipio">
										{getFieldDecorator("municipality", {
											initialValue: this.state.data.municipality,
											rules: [
												{
													required: true,
													message: "Por favor introduce el municipio.",
												},
											],
										})(
											<Input
												className="common-input"
												placeholder="Introduce el municipio"
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
						</Row>
						<Divider
							orientation="left"
							style={{ fontSize: "15px", color: "#606676" }}
						>
							Geolocalización
						</Divider>
						<Row gutter={24}>
							<Col span={10}>
								<Tooltip title={tooltipList.domicilioFiscal.geolocalizacion.latitud}>
									<Form.Item label="Latitud">
										{getFieldDecorator("latitude", {
											initialValue:
												this.state.data.latitude === undefined
													? ""
													: this.state.data.latitude,
											rules: [
												{
													required: true,
													message:
														"Por favor introduce la latitud de la ubicación de la planta o haz click en el mapa en donde se encuentra ubicada.",
												},
												{
													type: "number",
													message: "Introduce una latitud válida.",
												},
											],
										})(
											<InputNumber
												className="common-input"
												style={{ width: "100%" }}
												precision={7}
												placeholder="Introduce o selecciona la latitud de la ubicación de la planta"
												onBlur={this.onChangeLatLng}
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
							<Col span={10}>
								<Tooltip title={tooltipList.domicilioFiscal.geolocalizacion.longitud}>
									<Form.Item label="Longitud">
										{getFieldDecorator("longitude", {
											initialValue:
												this.state.data.longitude === undefined
													? ""
													: this.state.data.longitude,
											rules: [
												{
													required: true,
													message:
														"Por favor introduce la longitud de la ubicación de la planta o haz click en el mapa en donde se encuentra ubicada.",
												},
												{
													type: "number",
													message: "Introduce una longitud válida.",
												},
											],
										})(
											<InputNumber
												className="common-input"
												style={{ width: "100%" }}
												precision={7}
												placeholder="Introduce o selecciona la longitud de la ubicación de la planta"
												onBlur={this.onChangeLatLng}
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
						</Row>
						<Divider
							orientation="left"
							style={{ fontSize: "15px", color: "#606676" }}
							hidden={!this.state.isDistributionPoint && !this.state.allowEdit}
						>
							Configuración del Control de Existencias
						</Divider>
						<Row gutter={24} hidden={!this.state.isDistributionPoint && !this.state.allowEdit}>
							<Col span={10}>
								<Tooltip title={tooltipList.controlExistencias.listaCorreos}>
									<Form.Item label="Lista de Correos">
										{getFieldDecorator("emails", {
											value: this.state.data.emails,
											initialValue:
												this.state.data.emails === undefined
													? []
													: this.state.data.emails,
										})(
											<Input className="common-input" onClick={this.showEmailsModal} onChange={this.showEmailsModal} disabled={!this.state.allowEdit} />
										)}
									</Form.Item>
								</Tooltip>
							</Col>
							<Col span={10}>
								<Tooltip title={tooltipList.controlExistencias.diferenciaMaxima}>
									<Form.Item label="Diferencia Máxima Permitida">
										{getFieldDecorator("maxDifferencePorcentage", {
											initialValue:
												this.state.data.maxDifferencePorcentage === undefined
													? ""
													: this.state.data.maxDifferencePorcentage,
											rules: [
												{
													type: "number",
													message: "Introduce una diferencia máxima válida.",
												},
												{
													validator(rule, value) {
														return (value !== null) ? parseFloat(value) >= 0.1 : true;
													},
													message: "El valor porcentaje de diferencia debe ser de cuando menos 0.1",
												},
												{
													validator(rule, value) {
														return (value !== null) ? parseFloat(value) <= 10 : true;
													},
													message: "El valor porcentaje de diferencia debe ser menor a 10",
												}, {
													required: true,
													message:
														"Por favor introduce una diferencia máxima válida",
												},

											],
										})(
											<InputNumber
												className="common-input"
												style={{ width: "100%" }}
												precision={7}
												placeholder="Introduce la diferencia máxima permitida en el control de existencias"
												onBlur={this.onChangeLatLng}
												disabled={!this.state.allowEdit}
											/>
										)}
									</Form.Item>
								</Tooltip>
							</Col>
						</Row>
						<Divider
							orientation="left"
							style={{ fontSize: "15px", color: "#606676" }}
							hidden={!this.state.isDistributionPoint && !this.state.allowEdit}
						>
							Productos
						</Divider>
						<Row hidden={!this.state.isDistributionPoint && !this.state.allowEdit}>
							<Row hidden={!this.state.allowEdit}>
								<span className="instructions">
									Selecciona las casillas en donde parezcan los productos que se
									venderán en la planta
								</span>
							</Row>
							<Col>
								<Tooltip title={tooltipList.productos}>
									<Form.Item label="Productos a vender">
										{getFieldDecorator("productsSold", {
											initialValue: [7],
											rules: [
												{
													required: true,
													message: "Por favor introduce los productos a vender.",
												},
											],
										})(
											(this.state.allowEdit)
												? (
													<CheckboxGroup
														options={this.getCheckBoxOptionItems(
															this.state.products
														)}
														disabled={!this.state.allowEdit}
													/>
												) : (
													<div>
														<ul hidden={this.state.distributionPoint === undefined}>
															{this.getDefaultCheckedBoxItems(
																this.state.activeProducts
															)}
														</ul>
														<div hidden={this.state.distributionPoint !== undefined}>
															<CheckboxGroup
																options={this.getCheckBoxOptionItems(
																	this.state.products
																)}
																value={getFieldValue("productsSold")}
																disabled
																hidden={!this.state.isDistributionPoint}
															/>
															<div hidden={this.state.isDistributionPoint}>
																<ul hidden={(this.state.activeProducts.length === 0)}>
																	{this.getDefaultCheckedBoxItems(
																		this.state.activeProducts
																	)}
																</ul>
																<div hidden={(this.state.activeProducts.length > 1)}>
																	<CheckboxGroup
																		options={this.getCheckBoxOptionItems(
																			this.state.products
																		)}
																		value={getFieldValue("productsSold")}
																		disabled />

																</div>

															</div>
														</div>
													</div>
												)
										)}
									</Form.Item>
								</Tooltip>
							</Col>
						</Row>
						<Divider hidden={!this.state.allowEdit} />
						<Form.Item style={{ float: 'right' }}>

							<Button
								className="customized-default"
								hidden={!this.state.showReturnButton}
								style={{ marginLeft: 16 }}
								onClick={this.props.onCancel}
							>
								Regresar
							</Button>
							<Button
								className="customized-primary"
								hidden={!this.state.allowEdit}
								style={{ marginLeft: 16, width: 170, color: 'white' }}
								htmlType="submit"
							>
								Registrar
							</Button>
						</Form.Item>
						<Divider hidden={!this.state.allowEdit} />

					</Form>
				</div>
				<div>
					{!this.state.hideModalConfirmation &&
					<Modal
						open={!this.state.hideModalConfirmation}
						title={"Confirma la Información"}
						width='80%'
						closable={false}
						footer={[
							<Button
								className="back-button"
								key="cancel"
								onClick={this.handleOnCancel}
							>
								Revisar información
							</Button>,
							<Button
								className="submit-button"
								key="continue"
								onClick={this.handleOk}
							>
								Confirmar registro
							</Button>,
						]}
					>
						<div>
							<p style={{ fontSize: "20px" }}>
								{this.state.warningText}
							</p>
							<DistributionPointCreatorForm
								form={this.state.form}
								loading={this.state.loading}
								isConfigApplicationRegistered={this.state.isConfigApplicationRegistered}
								allowEdit={false}
								isDistributionPoint={this.state.isDistributionPoint}
							/>
						</div>
					</Modal>
					}
				</div>
				<EmailsModal
					visible={this.state.visibleEmailsModal}
					onAccept={this.hideEmailsModal}
					onCancel={this.hideEmailsModal}
					onUpdateEmailList={this.updateEmailsList}
					originalEmailist={this.state.data.emails}
					availableEmails={this.state.availableEmails}
				/>
			</Spin>
        );
	}

	displayModalInfo = () => {
		if (this.state.allowEdit) {
			Modal.info({
				title: "Atención",
				content: (
					<div>
						<br />
						<br />
						<p style={{ fontSize: "20px" }}>
							{this.state.warningText}
						</p>

						<br />
					</div>
				),
				width: '80%',
				onOk() { },
			});
		}
	};

	showModalConfirmation = (e) => {
		e.preventDefault();
		this.state.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				this.setState({ hideModalConfirmation: false });
			}
		});
	};

	handleOnCancel = () => {
		this.setState({ hideModalConfirmation: true });
	};

	handleOk = () => {
		Modal.confirm({
			width: '80%',
			onOk: (values) => {
				this.handleOnCancel();
				Modal.destroyAll();
				this.onSubmit();
			},
			title:
				"Una vez que presione confirmar registro no será posible modificar la información que ha introducido, le sugerimos la verifique si lo considera necesario.",
			okText: "Acepto que es correcta la información.",
			cancelText: "Cancelar",
			onCancel: () => {
				this.handleOnCancel();
				Modal.destroyAll();
			},
		});
	};

	onSubmit = () => {
		this.setState({ loading: true });
		this.state.form.validateFieldsAndScroll((err, values) => {
			if (!err) {
				if (values.character === 'PERMISIONARIO') {
					values.permissionNumber = values.senerLicense;
				}

				if (this.state.isConfigApplicationRegistered) {
					registerServices.post("company", values).then((response) => {
						const jsonResponse = JSON.parse(JSON.stringify(response));
						if (jsonResponse.hasOwnProperty("response")) {
							message.error(jsonResponse.response.data);
						} else {
							setTimeout(() => {
								this.setState({
									loading: false,
								});
								this.fetch();
								ToFirstWarehouseRegistry();
							}, 7000);
						}
					});
				} else {
					request()
						.post("distributionPoints", values)
						.then((response) => {
							const jsonResponse = JSON.parse(JSON.stringify(response));
							if (jsonResponse.hasOwnProperty("response")) {
								message.error(jsonResponse.response.data);
							} else {
								this.setState({ loading: false });
								this.props.onCancel();
							}
						}).catch((error) => {
							const { message } = error.response.data;
							notification.error({
								message: "Falla al Registrar punto de distribución",
								description: message,
								onClose: () => { window.location.replace(`${CV_CONTEXT_PATH}/subscription`); }
							})
						});
				}
			}
		});
	};

	fetch = () => {
		if (this.state.isConfigApplicationRegistered) {
			registerServices.get("company").then((response) => {
				if (response.length > 0) {
					this.setState({ data: response[0], selectLocation: false }, () =>
						this.checkLegalRepresentative()
					);
				} else if (this.state.isConfigApplicationRegistered)
					this.displayModalInfo();
			});
		} else if (this.state.distributionPoint !== undefined) {
			this.setState({
				data: this.state.distributionPoint,
			});
		}
	};

	checkLegalRepresentative = () => {
		if (this.state.data.rfc.length === 13) {
			this.setState({ legalRepresentative: true });
		}
	};

	getProducts = () => {
		registerServices.get("products").then((response) => {
			this.setState({
				products: response,
			});
		});
	};

	getActiveProducts = () => {
		if (this.state.distributionPoint !== undefined) {
			this.setState({ activeProducts: this.state.distributionPoint.products })
		} else {
			registerServices.get("getActiveProducts").then((response) => {
				this.setState({
					activeProducts: response,
				});
			});
		}

	};

	getPermissionKeys = () => {
		registerServices
			.get("company/regulatoryPermissionKeys")
			.then((response) => {
				this.setState({
					permissionKeys: response,
				});
			});
	};

	getInstallationKeys = () => {
		registerServices.get("company/installationKeys").then((response) => {
			this.setState({
				installationKeys: response,
			});
		});
	};

	getCompanyActivityTypes = () => {
		registerServices.get("company/companyActivityTypes").then((response) => {
			this.setState({
				companyActivityTypes: response,
			});
		});
	};

	/* FORM UTILS */

	onBlurRfc = (e) => {
		const { value } = e.target;

		if (isValidRfc(value)) {
			if (value.length === 12) {
				this.setState({ legalRepresentative: true });
				message.info(
					"Para personas morales en necesario capturar un representante legal"
				);
			} else {
				this.setState({ legalRepresentative: false });
			}
		}
	};

	getOptionItemTextValue = (types) =>
		types.map((item) => (
			<Option key={item.value} value={item.value}>
				{item.text}
			</Option>
		));

	getOptionItemCodeDescription = (types) =>
		types.map((item) => (
			<Option key={item.code} value={item.code}>
				{"".concat(item.code).concat(" - ").concat(item.description)}
			</Option>
		));

	getOptionItemInstallationKey = (types) =>
		types.map((item) => (
			<Option key={item.id} value={item.code}>
				{item.description}
			</Option>
		));

	getOptionItemRegulatoryPermission = (types) =>
		types.map((item) => (
			<Option
				key={item.regulatoryPermissionCode}
				value={item.regulatoryPermissionCode}
			>
				{item.regulatoryPermissionDescription}
			</Option>
		));

	getCheckBoxOptionItems = (types) =>
		types.map((item) => ({
			value: item.idProduct,
			label: item.productDescription,
			disabled: !item.activeProduct,
		}));

	getDefaultCheckedBoxItems = (types) =>
		types.map((item) => (
			<li key={item.idProduct}> {item.productDescription} </li>
		));

	setWarningText = () => {
		let warningText = "La información agregada al registrar la compañía no puede ser " +
			"editada, por lo cual es de suma importancia ingresar y verificar " +
			"la información cuidadosamente ya que este registro queda a " +
			"responsabilidad del usuario.";
		warningText = warningText.replace('la compañía', (!this.state.isConfigApplicationRegistered) ? 'el punto de distribución' : 'la compañía');
		this.setState({ warningText: warningText });
	}

	updateEmailsList = (emailList) => {
		let allconfig = this.state.data;
		allconfig.emails = emailList;
		this.setState({ data: allconfig });
		this.props.form.setFieldsValue({ emails: emailList });
	}
}

export default DistributionPointCreatorForm = Form.create({
	name: "distributionPointCreatorForm",
})(DistributionPointCreatorForm);

export { DistributionPointCreatorForm };
