import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import dayjs from 'dayjs'
import 'moment/locale/es';
import {
    CameraOutlined,
    CheckCircleOutlined,
    DownloadOutlined,
    IssuesCloseOutlined,
    PlusSquareOutlined,
    RedoOutlined,
    ReloadOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';

import {
    Row,
    Col,
    Statistic,
    Button,
    DatePicker,
    Table,
    message,
    Pagination,
    Divider,
    Select,
    Modal,
    Popover,
    Spin,
    Tooltip,
    InputNumber,
    Card,
} from 'antd';
import { StockRegisterForm, CauseRegisterForm, CauseView } from './components';
import {
    stockControl as stockServices
} from '../../services';
import {
    getPagePermissions,
    INVENTORY_CONTROL_PERMISSION,
    EXPORT_REPORT_PERMISSION
} from '../../security/permissions';

import './styles.css';
import { getSelectedDistributionPoint, getSelectedDistributionPointName } from '../../common/components/widgets/DistributionPoint/distributionPointStorage';
import request from '../../services/request';
import { dispensaries as dispensaryServices } from '../../services';
import Text from 'antd/lib/typography/Text';
import { apiUrl } from 'app.config';
const { RangePicker } = DatePicker;
const { Option } = Select;

const success = () => {
    message.success('Información de la inconsistencia guardada exitosamente');
};

let displayPage = 0;
let displayAmount = 10;

const containersDetailsVolume = array => array.map((item, index) => (
    {
        key: index,
        container: item.warehouse.warehouseKey,
        dailyFinalVolume: item.dailyFinalVolume,
        dailyInitialVolume: item.dailyInitialVolume,
        dailyInputs: item.dailyInputs,
        dailySales: item.dailySales,
        dailyStaticVolume: item.dailyStaticVolume,
        idWIC: item.idWarehouseInventoryControl,
        invalidLastVolumeRegistry: item.invalidLastVolumeRegistry,
    }
));

class StockControl extends Component {
    _isMount = false;
    constructor() {
        super();
        this.state = {
            data: {},
            details: [],
            visibleStockRegisterModal: false,
            visibleCauseRegisterModal: false,
            visibleCauseViewModal: false,
            inventoryControl: {},
            pagination: {},
            activeProducts: [],
            activeSubproducts: [],
            cargado: false,
            loading: false,
            distributionPointId: getSelectedDistributionPoint(),
            distributionPointListSelected: new Array(getSelectedDistributionPoint()),
            todayStock: {},
            desiredMeasurement: 0,
            distributionPointName: getSelectedDistributionPointName(),
            allowManualWarehouseLevel: false
        };
    }

    componentDidMount() {
        this._isMount = true;
        this.fetchConfig();
        this.setState({ loading: true });
        this.getActiveProducts();
        displayPage = 0;
        displayAmount = 10;

        window.addEventListener("distributionPointChange", (e) => {
            if (this._isMount) {
                if (this.state.distributionPointId === getSelectedDistributionPoint()) {
                    return;
                }
                this.setState({ distributionPointId: getSelectedDistributionPoint() }, () => {
                    this.setState({
                        distributionPointListSelected: new Array(this.state.distributionPointId),
                        distributionPointName: getSelectedDistributionPointName(),
                        todayStock: {},
                    }, () => {
                        this.getActiveProducts();
                    });
                });
            }
        });

    }
    componentWillUnmount() {
        this._isMount = false;
    }

    reCalculateGic = (jsonFilters) => {
        this.setState({ loading: true }, () => {
            if (jsonFilters !== undefined) {
                jsonFilters.startDate = jsonFilters.dateToCreateJson.format('YYYY-MM-DD');
                jsonFilters.reportType = 'Regeneración de GIC';
                jsonFilters.distributionPointId = this.state.distributionPointId;
                dispensaryServices.post(`inventoryControl/calculatedGics`, jsonFilters)
                    .then(response => {
                        message.success("El calculo de existencias para el punto de distribución "
                            .concat(this.state.distributionPointName)
                            .concat(" en la fecha ").concat(jsonFilters.startDate)
                            .concat(" ha sido actualizado exitosamente"));
                    }).catch((error) => {
                        message.error(error.response.data.message);
                    }).finally(() => {
                        this.setState({ loading: false });
                        this.handleSubmit();
                    });

            }
            else {
                dispensaryServices.get(`distributionPoints/${this.state.distributionPointId}/lastMeasurement`)
                    .then(lastMeasurement => {
                        this.setState({ desiredMeasurement: lastMeasurement.volumen });
                        Modal.confirm({
                            title: "Generación de Control de Existencias | ".concat(moment().format('YYYY-MM-DD')),
                            width: "65%",
                            centered: true,
                            icon: <WarningOutlined />,
                            maskClosable: true,
                            content: (
                                <div>
                                    <div>
                                        <div>
                                            <p>
                                                Para realizar el proceso de control de existencias, el sistema de Control Volumetrico requiere un volumen inicial,
                                                el sistema detecta que la medición de volumen para este punto de distribución corresponde a &nbsp;&nbsp;
                                                <strong>
                                                    {lastMeasurement.volumen}
                                                </strong>
                                                &nbsp;&nbsp; Litros.
                                            </p>
                                            <div hidden={lastMeasurement.date === null}>
                                                El marcador de dicha medición correponde a &nbsp;&nbsp;
                                                <strong>
                                                    {lastMeasurement.date}
                                                </strong>
                                            </div>
                                            <div hidden={lastMeasurement.date !== null}>
                                                <strong>
                                                    No existe ninguna medición de nivel para el dia {moment().subtract(1, 'days').format('YYYY-MM-DD')}
                                                </strong>
                                            </div>
                                        </div>
                                        <p>
                                            Puede dejar dicha medición como volumen inicial o puede cambiarla interactuando con el siguiente cuadro de texto;
                                            cuando este listo para continuar de click sobre el botón 'Generar Control de Existencias'
                                        </p>
                                    </div>
                                    <div>
                                        <InputNumber
                                            placeholder="Volumen inicial"
                                            style={{ width: '100%' }}
                                            precision={3}
                                            defaultValue={this.state.desiredMeasurement}
                                            onChange={(value) => { this.setState({ desiredMeasurement: value }); }}
                                        />
                                    </div>
                                </div>),
                            okText: "Generar Control de Existencias",
                            cancelText: "Cancelar",
                            onCancel: () => { },
                            onOk: () => {
                                if (this.state.desiredMeasurement === undefined || Number(this.state.desiredMeasurement) <= 0) {
                                    message.error("Debe haber un volumen inicial mayor a cero");
                                    this.reCalculateGic(jsonFilters);
                                    return;
                                }
                                let createInventoryControl = {
                                    dateToCreateJson: moment().subtract(1, 'days'),
                                    desiredInitialVolume: this.state.desiredMeasurement,
                                    idSubproduct: this.props.form.getFieldValue('idSubproduct')
                                }
                                this.reCalculateGic(createInventoryControl);
                            },
                        });
                    }).finally(() => {
                        this.setState({ loading: false });
                        this.handleSubmit();
                    });
            }
        });
    }

    getActiveProducts() {
        if (!this._isMount) {
            return;
        }
        stockServices.post('distributionPoints/activeProducts', this.state.distributionPointListSelected)
            .then(response => {
                this.setState({
                    activeProducts: response,
                    activeSubproducts: []
                }, () => {
                    this.props.form.getFieldDecorator('productCode', { initialValue: '' })
                    this.props.form.getFieldDecorator('idSubproduct', { initialValue: '' })
                    if (this.state.activeProducts.length > 0) {
                        this.getActiveProductSubproducts(response[0].idProduct);
                        this.props.form.getFieldDecorator('productCode', { initialValue: response[0].idProduct })

                    } else {
                        this.setState({
                            data: {}, pagination: {}, details: [], currentPage: 0,
                        }, () => {
                            this.handleSubmit();
                        });

                    }
                });
            });
    }

    getActiveProductSubproducts = (idProduct) => {
        this.setState({
            cargado: false,
            todayStock: {},
        });
        stockServices.get(`getProductSubProducts/${idProduct}`)
            .then(response => {
                this.setState({
                    activeSubproducts: response,
                    cargado: true
                });
                this.handleSubmit();
            });
    }

    onPageChange = (page, pageSize) => {
        displayPage = page - 1;
        this.handleSubmit();
    }

    onShowSizeChange = (current, pageSize) => {
        displayPage = 0;
        displayAmount = pageSize;
        this.handleSubmit();
    }

    disabledDate = (current) =>
        // Can not select days after today and today
        current && current > moment()/* .subtract(1, 'days') */
        ;

    fetchStock = (params = {}) => {
        params.distributionPointIds = this.state.distributionPointListSelected;

        stockServices.post('inventoryControl/getRegisters', params)
            .then(response => {
                const pagination = { ...this.state.pagination };
                pagination.total = response.inventoryControlTotalRecords;
                this.setState({
                    data: response,
                    pagination,
                    details: response.details,
                    currentPage: displayPage + 1,
                    loading: false
                });
            });
    };

    handleSubmit = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const rangeTimeValue = fieldsValue.period;
                const filters = {
                    requestInventoryControlDisplayPage: displayPage,
                    requestInventoryControlDisplayAmount: displayAmount,
                    requestInventoryControlStartDate: rangeTimeValue[0].format('YYYY-MM-DD 01:00:00'),
                    requestInventoryControlEndDate: rangeTimeValue[1].format('YYYY-MM-DD 23:59:59'),
                    idSubproduct: fieldsValue.idSubproduct,
                };
                this.fetchStock(filters);
            }
        });
    };

    fetchConfig = () => {
        request().get('configApplication/getConfig/allowManualWarehouseLevel')
            .then(response => {
                this.setState({ allowManualWarehouseLevel: response.data });
            });
    }

    handlePeriodChange = e => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                fieldsValue.period = e;
                const rangeTimeValue = fieldsValue.period;
                const filters = {
                    requestInventoryControlDisplayPage: displayPage,
                    requestInventoryControlDisplayAmount: displayAmount,
                    requestInventoryControlStartDate: rangeTimeValue[0].format('YYYY-MM-DD 01:00:00'),
                    requestInventoryControlEndDate: rangeTimeValue[1].format('YYYY-MM-DD 23:59:59'),
                    idSubproduct: fieldsValue.idSubproduct,
                };
                this.fetchStock(filters);
            }
        });
    };

    dropdownProductsChange = e => {
        this.getActiveProductSubproducts(e);
    };

    dropdownSubproductsChange = e => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const rangeTimeValue = fieldsValue.period;
                const filters = {
                    requestInventoryControlDisplayPage: displayPage,
                    requestInventoryControlDisplayAmount: displayAmount,
                    requestInventoryControlStartDate: rangeTimeValue[0].format('YYYY-MM-DD 01:00:00'),
                    requestInventoryControlEndDate: rangeTimeValue[1].format('YYYY-MM-DD 23:59:59'),
                    idSubproduct: e,
                };
                this.fetchStock(filters);
            }
        });
    }

    updateStockControl = (e) => {
        let params = {
            distributionPointIds: this.state.distributionPointListSelected,
            idSubproduct: this.props.form.getFieldValue('idSubproduct'),
        };
        this.setState({ loading: true }, () => {
            stockServices.post('inventoryControl/currentInventory', params)
                .then(response => {
                    this.setState({ todayStock: response });
                }).finally(() => {
                    this.setState({ loading: false });

                    Modal.info({
                        width: '80%',
                        icon: <LegacyIcon type={'none'} />,
                        content: (
                            <div>
                                <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Existencias del día de hoy</Divider>
                                <div className="day-summary">
                                    <Row>
                                        <Col span={6}>
                                            <Statistic title="Hoy" value={moment().format('YYYY/MM/DD')} />
                                        </Col>
                                        <Col span={8}>
                                            <Statistic title="Volumen inicial del día" value={this.state.todayStock.inventoryControlCurrentInitialVolume} />
                                        </Col>
                                        <Col span={8}>
                                            <Statistic title="Volumen actual"
                                                value={
                                                    (this.state.todayStock.inventoryControlTotalVolume) === undefined
                                                        ? ""
                                                        : this.state.todayStock.inventoryControlTotalVolume
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={24}>
                                        <Col span={6} />
                                        <Col span={8}>
                                            <Statistic title="Volumen actual de ventas"
                                                value={
                                                    (this.state.todayStock.inventoryControlTotalSales) === undefined
                                                        ? ""
                                                        : this.state.todayStock.inventoryControlTotalSales
                                                }
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Statistic title="Volumen actual de recepciones"
                                                value={
                                                    (this.state.todayStock.inventoryControlTotalInputs) === undefined
                                                        ? ""
                                                        : this.state.todayStock.inventoryControlTotalInputs
                                                } />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        ),
                    });
                });
        });
    };

    showCauseRegisterForm = (inventoryControl) => {
        this.setState({
            inventoryControl,
            visibleCauseRegisterModal: true
        });
    };

    showCauseView = (inventoryControl) => {
        this.setState({
            inventoryControl,
            visibleCauseViewModal: true
        });
    };

    handleCancelStockRegisterModal = () => {
        const form = this.stockRegisterFormRef.props.form;

        this.setState({ visibleStockRegisterModal: false });
        form.resetFields();
    };

    handleCancelCauseRegisterModal = () => {
        const form = this.causeRegisterFormRef.props.form;

        this.setState({ visibleCauseRegisterModal: false });
        form.resetFields();
    };

    handleCancelCauseViewModal = () => {
        this.setState({ visibleCauseViewModal: false });
    };

    handleStockRegister = () => {
        const form = this.stockRegisterFormRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            const newStockRegister = {
                requestInventoryControlUUID: values.inventoryControlCurrentUUID,
                requestInventoryControlStaticVolume: values.volume
            };
            this.setState({ loading: true });
            stockServices.put('inventoryControl/editStaticVolume', newStockRegister)
                .then(response => {
                    this.handleSubmit();
                }, () => form.resetFields());
            this.setState({ visibleStockRegisterModal: false });
        });
    };

    handleCauseRegister = () => {
        const form = this.causeRegisterFormRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            this.setState({ loading: true });
            stockServices.put('inventoryControl/editReasonAndSolution', values)
                .then(response => {
                    if (response === true) {
                        success();
                    }
                    this.handleSubmit();
                });

            this.setState({ visibleCauseRegisterModal: false });
        });
    };

    saveStockRegisterFormRef = (formRef) => {
        this.stockRegisterFormRef = formRef;
    };

    saveCauseRegisterFormRef = (formRef) => {
        this.causeRegisterFormRef = formRef;
    };

    titleTableStockCOntrol = () =>
        <div>
            <Card title={"Punto de Distribución: ".concat(this.state.distributionPointName)} >
                <span style={{ marginLeft: '10px' }} >Presione <PlusSquareOutlined /> para ver detalles</span>
            </Card>

        </div>

    handlePrint = () => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const rangeTimeValue = fieldsValue.period;
                const filters = {
                    requestInventoryControlDisplayPage: displayPage,
                    requestInventoryControlDisplayAmount: displayAmount,
                    requestInventoryControlStartDate: rangeTimeValue[0].format('YYYY-MM-DD 01:00:00'),
                    requestInventoryControlEndDate: rangeTimeValue[1].format('YYYY-MM-DD 23:59:59'),
                    idSubproduct: fieldsValue.idSubproduct,
                };
                this.printStockControl(filters);
            }
        });
    }

    showIsValidIcon = (isInvalid) => {
        return isInvalid
            ? (<Tooltip title='Mediciones de nivel invalidas'> <WarningOutlined style={{ color: 'orange', fontSize: '20px' }} /></Tooltip>)
            : (<Tooltip title='Mediciones validas'> <CheckCircleOutlined style={{ color: 'green', fontSize: '20px' }} /></Tooltip>);
    }

    printStockControl = (params = {}) => {
        this.setState({ loading: true });
        axios({
            url: `${apiUrl}/inventoryControl/getRegisters/ExportPDF`,
            method: 'POST',
            data: params,
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'controlExistencias.pdf'); // or any other extension
            document.body.appendChild(link);
            link.click();
            this.setState({ loading: false });
        });
    }

    columns = [
        {
            title: 'Folio',
            dataIndex: 'inventoryControlDetailsSequence',
            render: (text) => text
        }, {
            title: 'Fecha',
            dataIndex: 'inventoryControlDetailsRegisteredDate',
            render: (date) => moment(date).format('YYYY/MM/DD')
        },
        {
            title: 'Producto',
            dataIndex: 'productSubProduct',
            render: (text) => text
        },
        {
            title: 'Volumen inicial del día',
            dataIndex: 'inventoryControlDetailsDailyInitialVolume',
            render: (text) => `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }, {
            title: 'Entradas',
            dataIndex: 'inventoryControlDetailsDailyInputs',
            render: (text) => `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }, {
            title: 'Salidas',
            dataIndex: 'inventoryControlDetailsDailySales',
            render: (text) => `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }, {
            title: 'Volumen final del día (Lógico)',
            dataIndex: 'inventoryControlDetailsDailyFinalVolume',
            render: (text) => `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }, {
            title: 'Volumen final del día (Físico)',
            dataIndex: 'inventoryControlDetailsDailyStaticVolume',
            render: (text) => text !== null ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : text
        }, {
            title: 'Litros de diferencia',
            dataIndex: 'inventoryControlDetailsVolumeDifferenceLiters',
            render: (text) =>
                <span style={{ color: (text < -1 || text > 1) && '#FF7171' }}>
                    {(text !== null) ? `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '-'} {(text !== null) ? 'Litros' : ''}
                </span>
        }, {
            title: 'Porcentaje de diferencia',
            dataIndex: 'inventoryControlDetailsVolumeDifference',
            render: (text) => <span style={{ color: (text < -1 || text > 1) && '#FF7171' }}>{`${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} %</span>
        }, {
            title: 'Status',
            dataIndex: 'containerInventoryControl',
            render: (list) => <span>{this.showIsValidIcon((list.filter(wic => wic.invalidLastVolumeRegistry).length > 0))}</span>
        }, {
            title: 'Inconsistencias',
            render: (text, record) => {
                const volumeDifference = record.inventoryControlDetailsVolumeDifference;
                const isEdited = record.inventoryControlDetailsIsEdited;
                const isEditable = record.inventoryControlDetailsIsEditable;
                return (
                    <div>
                        {(
                            isEditable ?
                                <span>
                                    {this.props.pagePermissions.edit &&
                                        <div>
                                            {(volumeDifference < -1 || volumeDifference > 1) && <Tooltip title={(!isEdited) ? "Registrar causa" : "Editar causa"}>
                                                <Button icon={<IssuesCloseOutlined />} onClick={() => this.showCauseRegisterForm(record)} style={{ margin: '5px' }} />
                                            </Tooltip>}
                                            <Tooltip title="Regenerar Control de Existencias">
                                                <Button icon={<RedoOutlined />} style={{ color: 'green' }} onClick={() => {
                                                    this.reCalculateGic({ dateToCreateJson: moment(record.inventoryControlDetailsRegisteredDate) });
                                                }} />
                                            </Tooltip>
                                        </div>
                                    }
                                </span>
                                :
                                isEdited && !isEditable ?
                                    <a onClick={() => this.showCauseView(record)}>Ver causa</a>
                                    :
                                    null
                        )}
                    </div>
                );
            }
        }
    ];

    columnsNestedTable = [
        {
            title: 'Almacén',
            dataIndex: 'container',
        }, {
            title: 'Volumen inicial del día',
            dataIndex: 'dailyInitialVolume',
            render: (text) => `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }, {
            title: 'Entradas',
            dataIndex: 'dailyInputs',
            render: (text) => `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }, {
            title: 'Salidas',
            dataIndex: 'dailySales',
            render: (text) => `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }, {
            title: 'Volumen final del día (Lógico)',
            dataIndex: 'dailyFinalVolume',
            render: (text) => `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        , {
            title: 'Volumen final del día (Físico)',
            dataIndex: 'dailyStaticVolume',
            render: (text) => `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }, {
            title: 'Status',
            dataIndex: 'invalidLastVolumeRegistry',
            render: (isInvalid) => <span>{this.showIsValidIcon(isInvalid)}</span>
        }, {
            title: 'Detalle ',
            render: (item) => <Tooltip title="Snapshot"><Button icon={<CameraOutlined />} onClick={() => {
                this.openModalSnapshot(item)
            }}></Button></Tooltip>
        }
    ];

    openModalSnapshot(wic) {
        request().get(`measurements/${wic.idWIC}`)
            .then((resp) => {
                if (resp.data.entries !== undefined) {
                    const entries = resp.data.entries.map((entrie, index) => { entrie.key = index; return entrie; });
                    const columns = [
                        {
                            title: 'Código del tanque',
                            dataIndex: 'tankCode',
                            render: text => <strong>{text}</strong>,
                        },
                        {
                            title: 'Volumen reportado',
                            dataIndex: 'volume',
                            render: (text) => `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        },
                        {
                            title: 'Momento de la medición',
                            dataIndex: 'measurementDate',
                        },
                        {
                            title: 'Resultado',
                            dataIndex: 'invalid',
                            render: bool => !bool ? <Text style={{ 'color': 'green' }}>Valida</Text> : <Text type="danger" strong>Invalida</Text>,
                        },
                        {
                            title: 'Mensaje Error',
                            render: (item) => item.invalid ? <Text type="danger" strong>{item.messageError}</Text> : <Text type="secondary">{item.messageError}</Text>,
                        },
                        {
                            title: 'Tipo de medición',
                            dataIndex: 'type',
                            render: (text) => text ? text : <Text type="danger" strong>N/A</Text>,
                        },
                    ];

                    Modal.info({
                        title: 'Detalle del control de existencias del almacén: '.concat(wic.container),
                        width: '80%',
                        okText: 'Aceptar',
                        content: (
                            <div>
                                <Table
                                    columns={columns}
                                    dataSource={entries}
                                    bordered
                                    title={() => 'Mediciones de volumen por tanque'}
                                    pagination={false}
                                />
                            </div>
                        )
                    });

                } else {
                    Modal.error({
                        title: 'Sin Snapshot del Control de Existencias del Tanque',
                        width: '80%',
                        okText: 'Aceptar',
                        content: (
                            <div>
                                No existen mediciones de nivel para este registro
                            </div>
                        )
                    });
                }
            }).catch((error) => {
                console.error(error);
                message.error(error.response.data.message);
            });
    }

    render() {
        const { exportReportsPermissions } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { data } = this.state;

        const getActiveProductsDropdown = types => types.map(item => (
            <Option key={item.idProduct} value={item.idProduct}>{item.productDescription}</Option>
        ));

        const getActiveSubproductsDropdown = types => types.map(item => (
            <Option key={item.idSubproduct} value={item.idSubproduct}>{item.description}</Option>
        ));

        return (
            <div>
                <Spin spinning={this.state.loading}>
                    <Form layout='vertical' >
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item label="Producto">
                                    {getFieldDecorator('productCode', {
                                        initialValue: (this.state.activeProducts[0] || {}).idProduct,
                                        rules: [{
                                            required: true, message: 'Por favor selecciona un producto.'
                                        }]
                                    })(
                                        <Select
                                            onChange={this.dropdownProductsChange}
                                        >
                                            {getActiveProductsDropdown(this.state.activeProducts)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            {this.state.cargado && <Col span={16}>
                                <Form.Item label="Subproducto">
                                    {getFieldDecorator('idSubproduct', {
                                        initialValue: (this.state.activeSubproducts[0] || {}).idSubproduct,
                                        rules: [{
                                            required: true, message: 'Por favor selecciona un subproducto.'
                                        }]
                                    })(
                                        <Select
                                            onChange={this.dropdownSubproductsChange}
                                        >
                                            {getActiveSubproductsDropdown(this.state.activeSubproducts)}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>}
                        </Row>
                        <Row gutter={24}>
                            <Col span={16}>
                                <Form.Item
                                    label="Consultar periodo"
                                >
                                    {getFieldDecorator('period', {
                                        initialValue: [dayjs().subtract(8, 'days'), dayjs().subtract(1, 'days')]
                                    })(
                                        <RangePicker
                                            lang='es'
                                            disabledDate={this.disabledDate}
                                            format="YYYY/MM/DD"
                                            placeholder={['Desde', 'Hasta']}
                                            onChange={this.handlePeriodChange}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    className="transparent-label"
                                    label=" "
                                >
                                    {exportReportsPermissions.read &&
                                        <Popover content="Ver Existencias del Dia">
                                            <Button
                                                type="primary"
                                                icon={<ReloadOutlined />}
                                                style={{ width: '100%', fontSize: 'smaller' }}
                                                onClick={(e) => { this.updateStockControl(e) }}>
                                                Ver Existencias del Dia</Button>
                                        </Popover>}
                                </Form.Item>
                            </Col>
                            <Col span={4} hidden>
                                <Form.Item
                                    className="transparent-label"
                                    label=" "
                                >
                                    {exportReportsPermissions.read &&
                                        <Popover content="Descargar información">
                                            <Button
                                                type="primary"
                                                icon={<DownloadOutlined />}
                                                style={{ width: '100%', fontSize: 'smaller' }}
                                                onClick={this.handlePrint}>
                                                Descargar información
                                            </Button>
                                        </Popover>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        {this.state.allowManualWarehouseLevel &&
                            <Row gutter={16} hidden={data.isUpToDate} justify="center">
                                <Col span={16}>
                                    <Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Inconsistencias en el Control de Existencias</Divider>
                                    <span>
                                    Se ha detectado que el proceso que ejecuta el control de existencias no se ha ejecutado correctamente, para corregir el problema
                                    de click en el siguiente botón y confirme la información para realizar el proceso de control de existencias de forma manual,
                                    y que a partir de hoy el proceso se ejecute de manera correcta.
                                </span>
                                </Col>
                                <Col span={8} offset={6}>
                                    <Button
                                        type="danger"
                                        onClick={() => this.reCalculateGic()}
                                    >
                                        Calcular Existencias
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </Form>
                    <br />
                    <Table
                        title={this.titleTableStockCOntrol}
                        className="general-table"
                        columns={this.columns}
                        scroll={{ x: true }}
                        dataSource={this.state.details}
                        rowKey={record => record.inventoryControlDetailsUUID}
                        expandedRowRender={record => <Table className="nested-table" columns={this.columnsNestedTable} dataSource={containersDetailsVolume(record.containerInventoryControl)} pagination={false} />}
                        pagination={false}
                    />
                    <br />
                    <Pagination showSizeChanger onChange={this.onPageChange} current={this.state.currentPage} onShowSizeChange={this.onShowSizeChange} total={this.state.pagination.total} pageSizeOptions={['10', '20', '50', '100', '200']} showTotal={total => `Total ${total} elementos`} />
                    <Divider />
                    <StockRegisterForm
                        wrappedComponentRef={this.saveStockRegisterFormRef}
                        visible={this.state.visibleStockRegisterModal}
                        onCancel={this.handleCancelStockRegisterModal}
                        onRegister={this.handleStockRegister}
                        title={this.state.data.title}
                        inventoryControlCurrentUUID={this.state.data.inventoryControlCurrentUUID}
                        inventoryControlCurrentDate={moment(this.state.data.inventoryControlCurrentDate).format('DD MMM YYYY')}
                        inventoryControlCurrentStaticVolume={this.state.data.inventoryControlCurrentStaticVolume}
                        inventoryControlProduct={this.state.data.inventoryControlCurrentProduct}
                    />
                    <CauseRegisterForm
                        wrappedComponentRef={this.saveCauseRegisterFormRef}
                        visible={this.state.visibleCauseRegisterModal}
                        onCancel={this.handleCancelCauseRegisterModal}
                        onRegister={this.handleCauseRegister}
                        inventoryControl={this.state.inventoryControl}
                    />
                    <CauseView
                        visible={this.state.visibleCauseViewModal}
                        onCancel={this.handleCancelCauseViewModal}
                        inventoryControl={this.state.inventoryControl}
                    />
                </Spin>
            </div>
        );
    }
}

const StockControlForm = Form.create({ name: 'stockControl' })(StockControl);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(INVENTORY_CONTROL_PERMISSION, permissions),
    exportReportsPermissions: getPagePermissions(EXPORT_REPORT_PERMISSION, permissions)
});

export default connect(mapStateToProps)(StockControlForm);
