import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Row, Col, Input, Select } from 'antd';
import {
    register as registerServices
} from '../../../services';

const Option = Select.Option;

class DictumDetailsPR15 extends Component {
    state = {
        subproducts: [],
        selectedSubproduct: 'SP20',
    };

    componentDidMount() {
        this.fetchSubproducts();
    }

    fetchSubproducts = () => {
        registerServices.get('getProductSubProducts/10')
            .then(response => {
                this.setState({
                    subproducts: response,
                });
            });
    };

    dropdownSubproductsChange = subproductCode => {
        this.setState({ selectedSubproduct: subproductCode });

        if (subproductCode !== 'SP20') {
            this.props.form.setFieldsValue({ bioenergeticComponents: '' });
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { pagePermissions } = this.props;

        const optionItems = types => types.map(item => (
            <Option key={item.idSubproduct} value={item.code}>{item.description} ({item.code})</Option>
        ));

        return (
            <div>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item label="Subproducto del bioenergético">
                            {getFieldDecorator('bioenergeticSubproductType', {
                                initialValue: this.props.data.dictumDetails !== null && this.props.data.productType === 'PR15'
                                    ? this.props.data.dictumDetails[0].responseDictumDetailValue : '',
                                rules: [{
                                    required: this.props.selectedProduct === 'PR15', message: 'Por favor selecciona un subproducto del bioenergético.'
                                }],
                            })(
                                <Select
                                    placeholder="Selecciona un subproducto"
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                    onChange={(code) => this.dropdownSubproductsChange(code)}
                                >{optionItems(this.state.subproducts)}</Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24} hidden={this.state.selectedSubproduct !== 'SP20'}>
                        <Form.Item label="Componentes del bioenergético">
                            {getFieldDecorator('bioenergeticComponents', {
                                initialValue: this.props.data.dictumDetails !== null && this.props.data.productType === 'PR15'
                                    ? this.props.data.dictumDetails[0].responseAdditionalDictumDetailValue : '',
                                rules: [{
                                    required: this.props.selectedProduct === 'PR15' && this.state.selectedSubproduct === 'SP20', message: 'Por favor introduce los componentes del bioenergético.'
                                }],
                            })(
                                <Input disabled={this.props.isEditable === false || pagePermissions.edit === false} />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Form.create({ name: 'dictumDetailsPR15' })(DictumDetailsPR15);
