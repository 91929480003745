import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Modal, Button, message, Popover } from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';

const success = (successMessage) => {
    message.success(successMessage);
};

class DictumElimination extends React.Component {
    constructor() {
        super();
        this.state = {
            loadingModal: false,
            visibleModal: false,
        };
    }

    showModal = () => {
        this.setState({
            visibleModal: true,
        });
    };

    handleOkModal = () => {
        this.setState({ loadingModal: true });

        dispensaryServices.Delete(`dictum/deleteDictum/${this.props.dictumId}`)
            .then(response => {
                console.log(response);

                setTimeout(() => {
                    this.setState({ loadingModal: false, visibleModal: false, });
                    this.props.handleSubmit();
                    success(response);
                }, 3000);
            });
    }

    handleCancelModal = () => {
        this.setState({ visibleModal: false });
    }

    render() {
        const { visibleModal, loadingModal } = this.state;

        return (
            <div style={{ display: 'inline-block' }}>
                <Popover content="Borrar dictamen">
                    <Button type="danger" shape="circle" icon={<CloseOutlined />} onClick={this.showModal} />
                </Popover>
                <Modal
                    title="Eliminar dictamen"
                    open={visibleModal}
                    onCancel={this.handleCancelModal}
                    footer={[
                        <Button key="back" onClick={this.handleCancelModal}>
                            Cancelar
                        </Button>,
                        <Button key="submit" type="danger" loading={loadingModal} onClick={this.handleOkModal}>
                            Eliminar
                        </Button>,
                    ]}
                >
                    <p>¿Está seguro de eliminar el dictamen?</p>
                    <p>Se perderán los datos del dictamen asociado permanentemente</p>
                </Modal>
            </div>
        );
    }
}


export default Form.create({ name: 'dictumElimination' })(DictumElimination);
