import React, { Component } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Input, Button, Spin } from 'antd';
import { RestoredPassword } from './../components';
import request from './../../../services/request';

const antIcon = <LoadingOutlined style={{ fontSize: 33 }} spin />;

class PasswordReset extends Component {
    constructor() {
        super();
        this.state = {
            email: {},
            loading: false,
            restored: false
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.setState({ loading: true });
                this.recoverPassword(values);
            }
        });
    }

    handleError(errorResponse) {
        const message = errorResponse.data;

        this.setState({
            email: {
                ...this.validateErrorResponse(message)
            }
        });
    }

    validateErrorResponse(message) {
        return {
            validateStatus: 'error',
            errorMsg: message
        };
    }

    recoverPassword(params) {
       request().post('auth/recoverLostPassword', params)
            .then(response => {
                this.setState({ loading: false });
                if (response.hasOwnProperty('data') && response.data === true) {
                    console.log('SUBMIT', response);
                    this.setState({ restored: true });
                    setTimeout(() => {
                        window.location.reload();
                    }, 3000);
                } else {
                    const error = response.response;
                    this.handleError(error);
                }
            }).catch((error)=>{
                console.log(error);
            });
    }

    render() {
        const { onReturn } = this.props;
        const { email } = this.state;
        const { getFieldDecorator } = this.props.form;

        return (
            <Spin spinning={this.state.loading} indicator={antIcon}>
                {!this.state.restored ?
                    <Form layout='vertical' onSubmit={this.handleSubmit} autoComplete="off">
                        <h2>Restablecer contraseña</h2>
                        <p>Ingresa la dirección de correo electrónico que utilizaste para registrar tu cuenta y te enviaremos instrucciones para restablecer tu contraseña</p>
                        <Form.Item
                            label="Correo electrónico"
                            validateStatus={email.validateStatus}
                            help={email.errorMsg}
                        >
                            {getFieldDecorator('email', {
                                rules: [{
                                    type: 'email', message: 'Correo electrónico no válido'
                                }, {
                                    required: true, message: 'Por favor introduce tu correo electrónico'
                                }]
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">Enviar</Button>
                        </Form.Item>
                        <Form.Item>
                            <div style={{ textAlign: 'center' }}>
                                <a style={{ color: '#8F8F8F' }} onClick={onReturn}>Ir al login</a>
                            </div>
                        </Form.Item>
                    </Form> :
                    <RestoredPassword />
                }
            </Spin>
        );
    }
}

export default Form.create({ name: 'passwordReset' })(PasswordReset);
