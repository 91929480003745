import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { Table, Button, } from 'antd';
import {
    getPagePermissions,
    DISPENSARY_PERMISSION
} from '../../../security/permissions';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import PermissionNumberHoses from './../components/permissionNumberHoses';
import PermissionNumberEditForm from './permissionNumberEditForm';

class PermissionNumberInfo extends Component {
    state = {
        visiblePermissionNumberEditModal: false,
        hoses: [],
    }
    columns = [
        {
            title: 'Número de serie',
            dataIndex: 'serie',
            key: 'serie',
            render: (text) => (
                <span>
                    <label>Serie: {text}</label>
                </span>
            )
        },
        {
            title: 'Folio de inicio',
            dataIndex: 'startFolio',
            key: 'startFolio',
            render: (text) => (
                <span>
                    <label>Folio de Inicio: {text}</label>
                </span>
            )
        },
        {
            title: 'Folio de fin',
            dataIndex: 'endFolio',
            key: 'endFolio',
            render: (text) => (
                <span>
                    <label>Folio de Fin: {text}</label>
                </span>
            )
        },
        {
            title: 'Número de permiso',
            dataIndex: 'permissionNumber',
            key: 'permissionNumber',
            render: (text) => (
                <span>
                    <label>Número de permiso: {text}</label>
                </span>
            )
        },
        {
            title: 'Acción',
            key: 'action',
            align: 'right',
            render: (text, record) => {
                return (
                    <span>
                        <Button shape="circle" icon={<EditOutlined />} onClick={this.showPermissionNumberEditModal} />
                        <div>
                            <PermissionNumberEditForm
                                wrappedComponentRef={this.savePermissionNumberEditFormRef}
                                visible={this.state.visiblePermissionNumberEditModal}
                                onCancel={this.handleCancelPermissionNumberEditModal}
                                onAdd={this.handlePermissionNumberEdit}
                                permissionInfo={record}
                            />
                        </div>
                    </span>
                );
            }
        }
    ];

    data = [
        {
            key: this.props.serviceNumber.idServiceNumber,
            serie: this.props.serviceNumber.serie,
            startFolio: + this.props.serviceNumber.startFolio,
            endFolio: + this.props.serviceNumber.endFolio,
            permissionNumber: this.props.serviceNumber.numberPermission,
            equipments: this.props.serviceNumber.equipment,
        }
    ];

    showPermissionNumberEditModal = () => {
        this.setState({ visiblePermissionNumberEditModal: true });
    }

    savePermissionNumberEditFormRef = (formRef) => {
        this.permissionNumberEditFormRef = formRef;
    }

    handleCancelPermissionNumberEditModal = () => {
        const form = this.permissionNumberEditFormRef.props.form;

        this.setState({ visiblePermissionNumberEditModal: false });
        form.resetFields();
    }

    handlePermissionNumberEdit = () => {
        const form = this.permissionNumberEditFormRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            const params = {
                serie: values.CFDISerial,
                startFolio: values.CFDIStartFolio,
                endFolio: values.CFDIEndFolio,
                status: true,
                numberPermission: values.numberPermission,
                idCompany: values.idCompanyInfo,
                equipment: values.hose,
                idServiceNumber: values.idPermission,
            }
            console.log('Received values of form: ', params);

            dispensaryServices.put('serviceNumber/editServiceNumber', params)
                .then(response => {
                    console.log('GET RESPONSE POST SERVICE NUMBER', response);
                    window.location.replace('/permissionNumbers');
                });
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ hoses: nextProps.unavialableServiceNumbers, });
    }

    expandedRowRender = () => <PermissionNumberHoses hoseCodes={this.props.serviceNumber.equipment} />

    render() {

        return (
            <div>
                <div>
                    <Table
                        className="dispensary-table"
                        columns={this.columns}
                        expandedRowRender={this.expandedRowRender}
                        defaultExpandAllRows
                        dataSource={this.data}
                        showHeader={false}
                        size="small"
                        pagination={false}
                    />
                </div>

            </div>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISPENSARY_PERMISSION, permissions)
});

export default connect(mapStateToProps)(PermissionNumberInfo);
