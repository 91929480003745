import React from 'react';
import HoseShowInfo from './hoseShowInfo';
import { connect } from 'react-redux';
import { Form } from '@ant-design/compatible';

import { Table, Input, InputNumber, Switch, Tooltip } from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import {
    getPagePermissions,
    DISPENSARY_PERMISSION
} from '../../../security/permissions';
import { getIsCloudModeActive } from '../../../security';

const FormItem = Form.Item;
const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
    getInput = () => {
        if (this.props.inputType === 'number') {
            return <InputNumber />;
        }
        return <Input />;
    };

    render() {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            ...restProps
        } = this.props;
        return (
            <EditableContext.Consumer>
                {form => {
                    const { getFieldDecorator } = form;
                    return (
                        <td {...restProps}>
                            {editing ? (
                                <FormItem style={{ margin: 0 }}>
                                    {getFieldDecorator(dataIndex, {
                                        rules: [{
                                            required: true,
                                            message: `Please Input ${title}!`,
                                        }],
                                        initialValue: record[dataIndex],
                                    })(this.getInput())}
                                </FormItem>
                            ) : restProps.children}
                        </td>
                    );
                }}
            </EditableContext.Consumer>
        );
    }
}

class EditableTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: props.hoses, editingKey: '', activateAllHoses: props.activateAllHoses, dispensaryStatus: props.dispensaryStatus };
        const isCloudEnable = getIsCloudModeActive();
        this.columns = [
            {
                title: 'Código',
                dataIndex: 'responseHoseCode',
                editable: false,
                visible: false,
                render: (text, record) => {
                    return (
                        <span >
                            <Tooltip title="Llave auditable de la manguera">
                                <label style={{color: 'green', fontWeight: 'bold'}}> {record.auditableEquipmentKey} </label>
                            </Tooltip>
                            <Tooltip title="Código de la manguera"> ({text}) </Tooltip>
                        </span>
                    );
                }
            },
            {
                title: 'Precio',
                dataIndex: 'price',
                editable: false,
                render: (text, record) => {
                    return (
                        <div style={{ height: '19px' }} hidden={isCloudEnable}>
                            <Tooltip title="Precio sincronizado de la manguera">
                                <span style={{ color: record.priceSynchronized ? 'green' : 'red' }}>
                                    {`$${text.toFixed(2)}`}
                                </span>
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                title: 'Tipo de producto',
                dataIndex: 'responseProductDescription',
                render: (text) => (<Tooltip title="Producto que vende">{text}</Tooltip>)
            },
            {
                title: 'Información',
                render: (text, record) => <HoseShowInfo hoseUUID={record.hoseUUID} priceSynchronized={record.priceSynchronized} />
            },
            {
                title: 'Acción',
                dataIndex: 'action',
                render: (text, record) => {
                    return (
                        <div style={{ height: '19px' }} hidden={isCloudEnable}>
                            <span>
                                {this.props.pagePermissions.edit && 
                                <Tooltip title={record.responseStatus? "Deshabilitar manguera": "Habilitar manguera"}>
                                    <Switch checked={record.responseStatus} onChange={(e) => this.handleHoseStatus(record.hoseUUID, e)} />
                                </Tooltip>
                                }
                            </span>
                        </div>
                    );
                },
            },
        ];
    }

    isEditing = record => record.hoseUUID === this.state.editingKey;

    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.hoses, activateAllHoses: nextProps.activateAllHoses, dispensaryStatus: nextProps.dispensaryStatus });
    }

    handleHoseStatus = (idHose, checked) => {
        dispensaryServices.put(`hose/changeHoseStatus/${idHose}/${checked}`)
            .then(response => {
                console.log('PUT', response);
            }).then(() => {
                this.props.onSomeHoseStatusChange();
            });
    };

    render() {
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };

        const columns = this.columns.map((col) => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    inputType: col.dataIndex === 'address' ? 'number' : 'text',
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: this.isEditing(record),
                }),
            };
        });

        return (
            <Table
                size="middle"
                components={components}
                rowKey={record => record.hoseUUID}
                title={() => 'Mangueras'}
                dataSource={this.state.data}
                columns={columns}
                showHeader={false}
                pagination={false}
            />
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISPENSARY_PERMISSION, permissions)
});

export default connect(mapStateToProps)(EditableTable);
