/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-14 10:10:30
 * @Last Modified by: Alejandro Leonor
 * @Last Modified time: 2018-03-14 11:44:23
 */
export default {
    layout: { height: '100vh' },
    header: { background: '#fff', padding: 0, position: 'relative' },
    content: {
        margin: '0 16px', padding: 24, background: '#fff',
    },
    spin: { fontSize: 42 },
};
