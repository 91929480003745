import React, { Component } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Input, Button, Spin } from 'antd';
import { UpdatedPassword } from './../components';
import { getToken, logOut } from './../../../security';
import { users as usersServices } from './../../../services';

const antIcon = <LoadingOutlined style={{ fontSize: 33 }} spin />;

class TemporaryPasswordChange extends Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            equalPasswords: false,
            updated: false
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                console.log('Received values of form: ', values);
                this.setState({ loading: true });
                this.changePassword(values.confirmPassword, getToken());
            }
        });
    }

    changePassword(password, token) {
        const newPassword = {
            password,
            Authorization: token
        };

        usersServices.post('user/changeTemporalPassword', newPassword)
            .then(response => {
                console.log('SUBMIT', response);
                this.setState({ loading: false });
                if (response === true) {
                    this.setState({ updated: true });
                    setTimeout(() => {
                        logOut();
                    }, 3000);
                }
            });
    }

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({ equalPasswords: this.state.equalPasswords || !!value });
    }

    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('newPassword')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.equalPasswords) {
            form.validateFields(['confirmPassword'], { force: true });
        }
        callback();
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleSubmit(event);
        }
    }

    render() {
        const { onReturn } = this.props;
        const { getFieldDecorator } = this.props.form;
        this.es_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

        return (
            <Spin spinning={this.state.loading} indicator={antIcon}>
                {!this.state.updated ?
                    <Form layout='vertical' autoComplete="off">
                        <p>Has iniciado sesión con una contraseña temporal, para continuar favor de crear una nueva contraseña</p>
                        <p>La contraseña debe:</p>
                        <p>* Tener al menos una letra mayúscula</p>
                        <p>* Tener al menos un número</p>
                        <p>* Tener al menos uno de estos símbolos: $  .  /  ?  !  -  #  _  %  </p>
                        <p>* Tener un mínimo de 8 caracteres</p>
                        <Form.Item
                            label="Nueva contraseña"
                            hasFeedback
                            help=""
                        >
                            {getFieldDecorator('newPassword', {
                                rules: [{
                                    required: true, pattern: new RegExp('^(?=.*[A-Z])(?=.*[0-9])(?=.*[$./?!\\-#_%])(?=.{8,})')
                                }, {
                                    validator: this.validateToNextPassword,
                                }]
                            })(
                                <Input type={this.es_firefox ? "password" : "text"} id="change_password_second" onKeyPress={this.handleKeyPress} />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Confirmar nueva contraseña"
                            hasFeedback
                            help=""
                        >
                            {getFieldDecorator('confirmPassword', {
                                rules: [{
                                    required: true
                                }, {
                                    validator: this.compareToFirstPassword,
                                }]
                            })(
                                <Input type={this.es_firefox ? "password" : "text"} id="change_password_second" onBlur={this.handleConfirmBlur}
                                    onKeyPress={this.handleKeyPress} />
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={this.handleSubmit} className="login-form-button">Actualizar contraseña</Button>
                        </Form.Item>
                        <Form.Item>
                            <div style={{ textAlign: 'center' }}>
                                <a style={{ color: '#8F8F8F' }} onClick={onReturn}>Ir al login</a>
                            </div>
                        </Form.Item>
                    </Form> :
                    <UpdatedPassword />
                }
            </Spin>
        );
    }
}

export default Form.create({ name: 'temporaryPasswordChange' })(TemporaryPasswordChange);
