export const patternRFC = /^([A-ZÑ]|\&){3,4}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$/;
export const patternRfcPersonaFisica = /^([A-ZÑ]|\&){4}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$/;
export const patternRfcPersonaMoral = /^([A-ZÑ]|\&){3}[0-9]{2}(0[1-9]|1[0-2])([12][0-9]|0[1-9]|3[01])[A-Z0-9]{3}$/;
export const patternEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const isValidRfc = (rfcInput = '') => patternRFC.test(rfcInput);

export const isValidRfcPersonaFisica = (rfcInput = '') => patternRfcPersonaFisica.test(rfcInput);

export const isValidRfcPersonaMoral = (rfcInput = '') => patternRfcPersonaMoral.test(rfcInput);

export const isValidEmail = (emailInput = '') => patternEmail.test(emailInput);

export const validateCantidadMonetaria = (_, value) => {
    if (value > 1000000000000) return Promise.reject('El precio no puede sobrepasar $1000000000000 MXN.');
    if (value < 0) return Promise.reject('El precio no puede ser negativo.');
    return Promise.resolve();
};

export const validateValorNumerico = (_, value) => {
    if (value > 100000000000) return Promise.reject('El volumen no puede sobrepasar 100000000000 Litros.');
    if (value < 0) return Promise.reject('El volumen no puede ser negativo.');
    return Promise.resolve();
};

export const filterCombo = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
