import React, {Fragment} from 'react';
import { InfoCircleTwoTone, MailTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Button, Modal, message, Tooltip } from 'antd';
import {DISTRIBUTION_POINT_PERMISSIONS, getPagePermissions} from '../../../security/permissions';
import {connect} from 'react-redux';
import request from '../../../services/request';
import {DistributionPointCreatorForm} from "./DistributionPointCreatorForm";
import EmailsModal from './emailsModal';

const DistributionPointShowInfo = Form.create({ name: 'distributionShowForm_into_modal' })(
    class extends React.Component {
        state= {
            distributionPoint: {},
            activityDescriptions: {},
            visibleModal: false,
            visibleEmailsModal: false,
            availableEmails: [],
        }

        getDistributionPoint = () => new Promise((resolve, reject) => {
            request().get(`distributionPoints/${this.props.distributionPointId}`)
            .then(response => {
                this.setState({
                    distributionPoint: response.data
                });
                request().get('emailList').then((responseEmail)=>{
                    this.setState({availableEmails: responseEmail.data});
                    resolve();
                }).catch((error)=>{
                    reject(error);
                });
            }).catch((error)=>{
                reject(error);
            });
        })



        showModal = (pointDistributionId) => {
            request().get(`distributionPoints/${pointDistributionId}`)
                .then(response => {
                    this.setState({
                        distributionPoint: response.data,
                        visibleModal: true,
                    });
                });
        }
        handleReturn = () => {
            this.setState({
                visibleModal: false,
                wasEdited: false,
            });
        };
        showEmailsModal = () => {
            this.getDistributionPoint().then(()=>{
                this.setState({visibleEmailsModal:true});
            });
            
        } 
        hideEmailsModal = () => {
            this.setState({visibleEmailsModal:false});
        } 
        updateEmailConfig = (newEmailConfig) => {
            console.log(newEmailConfig);
            this.hideEmailsModal();
            let distributionPoint = this.state.distributionPoint;
            console.log("DPP", distributionPoint);
            distributionPoint.emails = newEmailConfig.emailsList;
            distributionPoint.maxDifferencePorcentage = newEmailConfig.maxDifferencePorcentage;
            request().put(`distributionPoints/${distributionPoint.id}`, distributionPoint).then(response=> {
                message.success("Información almacenada correctamente");
            }).catch((error)=>{
                message.error("No fue posible almacenar la información =>", error);
            });


        }
        render() {
            const { distributionPoint } = this.state;
            return (
                <Fragment>
                    <div style={{ display: 'flex' }} >
                        <Tooltip title="Ver información del punto de distribución">
                            <a type="link" shape="circle" onClick={() => this.showModal(this.props.distributionPointId)}>
                                <InfoCircleTwoTone style={{ fontSize: '20px', margin: '3px' }} />
                            </a>
                        </Tooltip>
                        <Tooltip title="Configuración de la Diferencia Permitida del punto de distribución">
                            <a type="link" shape="circle" onClick={() => {this.showEmailsModal()}}>
                                <MailTwoTone style={{ fontSize: '20px', margin: '3px'  }} />
                            </a>
                        </Tooltip>
                    </div>
                    <Modal
                        open={this.state.visibleModal}
                        title="Información del punto de distribución"
                        onCancel={this.handleReturn}
                        width={'80%'}
                        footer={<Button onClick={this.handleReturn}>Volver</Button>}
                    >
                        <DistributionPointCreatorForm
                            isConfigApplicationRegistered={false}
                            distributionPoint={distributionPoint}
                            loading={false}
                            allowEdit={false}
                            isDistributionPoint
                        />
                    </Modal>
                    <EmailsModal
                        visible={this.state.visibleEmailsModal}
                        onAccept={this.updateEmailConfig}
                        onCancel={this.hideEmailsModal}
                        onUpdateEmailList={(emails)=>{
                            let pdd = this.state.distributionPoint;
                            pdd.emails = emails;
                            this.setState({distributionPoint: pdd});
                        }}
                        originalEmailist={this.state.distributionPoint.emails}
                        showMaxDifferencePorcentage
                        maxDifferencePorcentage={this.state.distributionPoint.maxDifferencePorcentage}
                        updateMaxDifferencePorcentage={(newValue)=>{
                            let distributionPoint = this.state.distributionPoint;
                            distributionPoint.maxDifferencePorcentage = newValue;
                            this.setState({distributionPoint: distributionPoint});
                        }}
                        availableEmails={this.state.availableEmails}
                    />
                </Fragment>
            );
        }
    }
);
const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISTRIBUTION_POINT_PERMISSIONS, permissions)
});

export default connect(mapStateToProps)(DistributionPointShowInfo);
