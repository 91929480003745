import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Table } from 'antd';

class FirstListContainersSaved extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: 'Clave de identificación',
                dataIndex: 'containerKey',
            },
            {
                title: 'Capacidad total',
                dataIndex: 'containerTotalCapacity',
                editable: false,
                render: (text, record) => {
                    const udm = record.product.measurementUnit.measurementDescription;
                    const resutlText = `${text}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    return (
                        <span precision={3} min={0}
                            style={{ width: '100%' }} >
                            {`${resutlText} ${udm} `}
                        </span>
                    );
                }
            },
            {
                title: 'Tipo de producto',
                dataIndex: 'product.productDescription',
            },
            {
                title: 'Tipo de subproducto',
                dataIndex: 'subproduct.description',
            },
        ];
    }

    render() {

        return (
            <Table
                rowKey={record => record.idContainer}
                dataSource={this.props.containersSaved}
                columns={this.columns}
                pagination={false}
            />
        );
    }
}

export default Form.create({ name: 'listContainersSaved' })(FirstListContainersSaved);
