import React from 'react';
import { Form, Icon as LegacyIcon } from '@ant-design/compatible';
import dayjs from "dayjs";
import {
    Modal,
    Input,
    Button,
    Row,
    Col,
    DatePicker,
    Popover,
    message,
    InputNumber,
    Tooltip,
} from 'antd';
import moment from 'moment';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import request from '../../../services/request';
import { connect } from 'react-redux';
import {
    getPagePermissions,
    WAREHOUSE_PERMISSIONS
} from '../../../security/permissions';
import {getSubProductInfo} from '../../../common/helpers/productConstants';
import tooltip from './tooltip.json';
const { TextArea } = Input;
const { confirm } = Modal;

const WareHouseShowInfo = Form.create({ name: 'wareHouseShowForm_into_modal' })(

    class extends React.Component {
        state = {
            wareHouse: {},
            visible: false,
            wasEdited: false,
            visibleEliminationFormContainer: false,
            edit: false,
            observedDensityStatus: {validateStatus: 'success', errorMsg: null,},
            observedTemperatureStatus: {validateStatus: 'success', errorMsg: null,},
            observedPressureStatus: {validateStatus: 'success', errorMsg: null,},
            errorMessage: '',
            submitButtonDisabled: false,
        };

        showModal = (idWarehouse) => {
            dispensaryServices.get(`warehouse/${idWarehouse}`)
                .then(response => {
                    this.setState({
                        wareHouse: response,
                        visible: true,
                    });
                });
        }

        handleReturn = () => {
            this.setState({
                visible: false,
                wasEdited: false,
                errorMessage: '',
                submitButtonDisabled: false,
            });
            this.props.form.resetFields();
        };

        editFields = () => {
            this.setState({
                wasEdited: true,
            });
        }

        DeleteContainer = (containerId) => {
            dispensaryServices.Delete(`container/delete/${containerId}`)
                .then(response => {
                    const jsonResponse = JSON.parse(JSON.stringify(response));
                    if (jsonResponse.hasOwnProperty('response')) {
                        message.error(jsonResponse.response.data);
                    } else {
                        this.props.onDeleteContainers();
                    }
                });
            this.setState({
                visible: false,
            });
        }

        editWareHouse = () => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    this.setState({submitButtonDisabled: true});
                    const { wareHouse } = this.state;
                    const filters = {
                        idWareHouse: wareHouse.idWareHouse,
                        description: values.description,
                        calibrationDate: values.calibrationDate !== null ? values.calibrationDate.format('YYYY-MM-DD HH:mm:ss') : null,
                        product: wareHouse.product,
                        subproduct: wareHouse.subproduct,
                        status: wareHouse.status,
                        warehouseKey: wareHouse.warehouseKey,
                        observedPressure: values.observedPressure,
                        observedTemperature: values.observedTemperature,
                        observedDensity: values.observedDensity,
                        baseDensity: values.baseDensity,

                    };
                    request().put('warehouse', filters)
                        .then(response => {
                            this.props.onEditWareHouses();
                            this.setState({
                                visible: false,
                                wasEdited: false,
                                errorMessage: '',
                                submitButtonDisabled: false,
                            });
                        }).catch(err => {
                            this.setState({errorMessage: err.response.data.message, submitButtonDisabled : false})
                        });
                    
                }
            });
        }

        showDeleteConfirm = (containerKey, containerId) => {
            confirm({
                title: `¿Está seguro que desea eliminar el almacén ${containerKey}`,
                content: 'Se perderán los datos de contenedor permanentemente',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                centered: true,
                onOk: () => new Promise((resolve) => {
                    setTimeout(() => resolve(this.DeleteContainer(containerId)), 2000);
                }).catch(() => console.log('error')),
                onCancel() {
                    console.log('Cancel');
                },
            });
        }

        showEditConfirm = (warehouseKey) => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    confirm({
                        title: `¿Está seguro que desea editar el almacén ${warehouseKey}`,
                        content: 'Se perderán los datos anteriores permanentemente',
                        okText: 'Sí',
                        okType: 'danger',
                        cancelText: 'No',
                        centered: true,
                        onOk: () => new Promise((resolve) => {
                            setTimeout(() => resolve(this.editWareHouse()), 3000);
                        }).catch(() => console.log('error')),
                        onCancel() {
                            console.log('Cancel');
                        },
                    });
                }
            });
        }

        calculateBaseDensity = () => {
            this.props.form.validateFieldsAndScroll(['observedDensity','observedPressure','observedTemperature'], (err, values) => {
                    if (!err) {
                        this.setState({ 
                            observedDensityStatus: {validateStatus: 'success', errorMsg: null,}, 
                            observedPressureStatus: {validateStatus: 'success', errorMsg: null,},
                            observedTemperatureStatus: {validateStatus: 'success', errorMsg: null,},
                        });
    
                        const params = {
                            density: this.props.form.getFieldValue('observedDensity'),
                            temperature: this.props.form.getFieldValue('observedTemperature'),
                            pressure: this.props.form.getFieldValue('observedPressure'),
                        };
                        
                        dispensaryServices.post('warehouse/requestBaseDensityCalculation', params)
                        .then(response => {
                            const jsonResponse = JSON.parse(JSON.stringify(response));
                            if (jsonResponse.hasOwnProperty('response')) {
                                message.error(jsonResponse.response.data);
                            } else {
                                this.props.form.setFieldsValue({ baseDensity: response });
                            }
                            this.setState({ loadingForm: false });
                        });
    
    
                    } else {
                        this.setState({ 
                            observedDensityStatus: {validateStatus: 'error', errorMsg: `Por favor ingrese la densidad observada antes de hacer el cálculo`,}, 
                            observedPressureStatus
                            : {validateStatus: 'error', errorMsg: `Por favor ingrese la presión observada antes de hacer el cálculo`,}, 
                            observedTemperatureStatus: {validateStatus: 'error', errorMsg: `Por favor ingrese la temperatura observada antes de hacer el cálculo`,} 
                        });
                    }
                });
        }

        render() {
            const { wareHouse } = this.state;
            const { pagePermissions } = this.props;
            const { getFieldDecorator } = this.props.form;
            const subProduct = getSubProductInfo(wareHouse.subproduct);

            return (
                <div style={{ display: 'inline-block' }} >
                    <Popover content={pagePermissions.edit ? "Editar almacén" : "Ver información del almacén"}>
                        <a type="link" shape="circle" onClick={() => this.showModal(this.props.wareHouseId)}>
                            <LegacyIcon type={pagePermissions.edit ? "edit" : "info-circle"} theme="twoTone" style={{ fontSize: '20px' }} />
                        </a>
                    </Popover>
                    <Modal
                        open={this.state.visible}
                        title="Información del almacén"
                        onCancel={this.handleReturn}
                        width = {800}
                        footer={pagePermissions.edit ? [
                            this.state.wasEdited === false ?
                                <Button onClick={this.handleReturn} key={'return'}>Volver</Button> :
                                <Button disabled={this.state.submitButtonDisabled} type="primary" onClick={() => this.showEditConfirm(wareHouse.warehouseKey)} key={'save'}>Actualizar Información</Button>
                        ] : []}
                    >
                        <p>A continucación se muestra la información detallada del almacén.</p>
                        <Form layout='vertical' >
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Tooltip title={tooltip.tanques.claveInstalacion}>
                                        <Form.Item label="Clave de identificación">
                                            <Input className="common-input" defaultValue={wareHouse.warehouseKey} disabled />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={8} hidden={wareHouse.calibrationDate === null}>
                                    <Tooltip title={tooltip.fechaCalibracion}>
                                        <Form.Item label="Vigencia de calibración">
                                            {getFieldDecorator('calibrationDate', {
                                                initialValue: wareHouse.calibrationDate !== null ? dayjs(wareHouse.calibrationDate) : null,
                                                rules: [{
                                                    required: wareHouse.calibrationDate !== null ? true : false, message: 'Por favor introduce la vigencia de calibración'
                                                }],
                                            })(
                                                <DatePicker  lang='es' style={{ width: '100%' }} disabled={pagePermissions.edit === false} format="DD-MM-YYYY" onChange={this.editFields} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Tooltip title={tooltip.tipoProducto}>
                                        <Form.Item label="Tipo de producto">
                                            {getFieldDecorator('idProduct', {
                                                initialValue: (wareHouse.product || {}).productCode + ' - ' + (wareHouse.product || {}).productDescription,
                                            })(
                                                <Input className="common-input" disabled />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={16}>
                                    <Tooltip title={tooltip.tipoSubProducto}>
                                        <Form.Item label="Tipo de subproducto">
                                            {getFieldDecorator('idSubproduct', {
                                                initialValue: (wareHouse.subproduct || {}).code + ' - ' + (wareHouse.subproduct || {}).description,
                                            })(
                                                <Input className="common-input" disabled />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Tooltip title={tooltip.descripcion}>
                                        <Form.Item label="descripción">
                                            {getFieldDecorator('description', {
                                                initialValue: wareHouse.description,
                                                rules: [{
                                                    required: true, message: 'Por favor introduce la localización y/o descripción del contenedor'
                                                }],
                                            })(
                                                <TextArea autoSize={{ minRows: 1, maxRows: 6 }} disabled={pagePermissions.edit === false} onChange={this.editFields} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Tooltip title={tooltip.densidadObservada}>
                                        <Form.Item 
                                            label="Densidad observada"
                                            validateStatus={this.state.observedDensityStatus.validateStatus}
                                            help={this.state.observedDensityStatus.errorMsg}
                                        >
                                            {getFieldDecorator(`observedDensity`, {
                                                initialValue: wareHouse.observedDensity,
                                                rules: [{
                                                    required: true, message: 'Densidad requerida'
                                                }],
                                            })(
                                                <InputNumber min={0} precision={4} style={{ width: '100%' }} className="common-input" onChange={this.editFields}/>
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            <Col span={8}>
                                <Tooltip title={tooltip.presionObservada}>
                                    <Form.Item 
                                        label="Presión observada"
                                        validateStatus={this.state.observedPressureStatus.validateStatus}
                                        help={this.state.observedPressureStatus.errorMsg}
                                    >
                                            {getFieldDecorator(`observedPressure`, {
                                            initialValue: wareHouse.observedPressure,
                                            rules: [{
                                                required: true, message: 'Presión requerida'
                                            }],
                                        })(
                                            <InputNumber min={0} precision={2} style={{ width: '100%' }} className="common-input" onChange={this.editFields}/>
                                        )}

                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col span={8}>
                                <Tooltip title={tooltip.temperaturaObservada}>
                                    <Form.Item 
                                        label="Temperatura observada"
                                        validateStatus={this.state.observedTemperatureStatus.validateStatus}
                                        help={this.state.observedTemperatureStatus.errorMsg}
                                    >
                                        {getFieldDecorator(`observedTemperature`, {
                                            initialValue: wareHouse.observedTemperature,
                                            rules: [{
                                                required: true, message: 'Temperatura requerida'
                                            }],
                                        })(
                                            <InputNumber precision={1} style={{ width: '100%' }} className="common-input" onChange={this.editFields}/>
                                        )}

                                    </Form.Item>
                                </Tooltip>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Tooltip title={tooltip.densidadACondifcionesBase}>
                                    <Form.Item label="Densidad a condiciones base (kg/L a 20 °C y 1 atm)">
                                        {getFieldDecorator(`baseDensity`, {
                                            initialValue: wareHouse.baseDensity,
                                            rules: [{
                                                required: true, message: 'Densidad a condiciones base requerida'
                                            }],
                                        })(
                                            <InputNumber placeholder={`Inserta un valor entre ${subProduct.minBaseDensity} y ${subProduct.maxBaseDensity}`} min={subProduct.minBaseDensity} max={subProduct.maxBaseDensity} precision={4} style={{ width: '100%' }} className="common-input" onChange={this.editFields}/>
                                        )}
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            {/*<Col span={8}>
                                <Form.Item label="   ">
                                    <Button className="customized-primary" type="primary" onClick={this.calculateBaseDensity}>Calcular Densidad</Button>
                                </Form.Item>                                
                            </Col>*/}
                        </Row>
                        {this.state.errorMessage.length > 0 && <div style={{color: 'red'}}>{this.state.errorMessage}</div>}
                        </Form>
                    </Modal>
                </div >
            );
        }
    }
);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(WAREHOUSE_PERMISSIONS, permissions)
});

export default connect(mapStateToProps)(WareHouseShowInfo);
