import React from 'react';
import { Form } from '@ant-design/compatible';

import { Modal, Input, Select } from 'antd';

const FirstAdminRegisterForm = Form.create({ name: 'firstAdminRegisterForm_into_modal' })(

    class extends React.Component {
        constructor() {
            super();
            this.state = {
                visibleModal: false
            };
        }

        render() {
            const {
                form
            } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Modal
                    open={this.props.visibleFirstAdmin}
                    title="Registro de administrador inicial"
                    closable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    okText="Agregar usuario"
                    onOk={this.props.handleSubmitFirstAdmin}
                >
                    <p>Llena los campos a continuación para registrar un nuevo usuario</p>
                    <Form layout='vertical' >
                        <Form.Item label="Nombre">
                            {getFieldDecorator('name', {
                                rules: [{
                                    required: true, message: 'Por favor introduce un nombre de usuario.'
                                }]
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item label="Correo electrónico">
                            {getFieldDecorator('email', {
                                rules: [{
                                    type: 'email', message: 'Correo electrónico no válido'
                                }, {
                                    required: true, message: 'Por favor introduce un correo electrónico.'
                                }]
                            })(
                                <Input />
                            )}
                        </Form.Item>
                        <Form.Item label="Perfil">
                            {getFieldDecorator('role', {
                                rules: [{
                                    required: true, message: 'Por favor selecciona un perfil.'
                                }],
                                initialValue: 'ADMINISTRATOR'
                            })(
                                <Select disabled>
                                    <Select.Option value="ADMINISTRATOR">Administrador</Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);

export default FirstAdminRegisterForm;
