import { apiUrl } from '../../../../app.config';
import React, { Component } from 'react';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { Alert } from 'antd';

class LidAlert extends Component {
    stompClient = null;

    constructor() {
        super();
        this.state = {
            showLidAlert: false
        };
    }

    componentDidMount() {
        this.setupWebSocket();
    }

    setupWebSocket = () => {
        const socket = SockJS(`${apiUrl}volumetric-control`);
        this.stompClient = Stomp.over(socket);
        this.stompClient.connect({}, this.connect);
    };

    connect = () => {
        this.stompClient.subscribe('/topic/lidStatusReply', (messageOutput) => {
            const lidStatus = JSON.parse(messageOutput.body);
            this.setState({ showLidAlert: lidStatus });
        });
    };

    render() {
        return (
            this.state.showLidAlert &&
            <Alert style={{ background: '#db3b21', color: 'white' }} message="Módulo concentrador de Unidad Central de Control se encuentra abierto." type="error" banner />
        );
    }
}

export default LidAlert;
