import React from 'react';
import { Form } from '@ant-design/compatible';

import { Modal, Input, Select, InputNumber, Row, Col, Switch, Button, Spin } from 'antd';

const OutputEmailForm = Form.create({ name: 'outputEmailForm_into_modal' })(

    class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                visibleModal: false,
            };
        }

        render() {

            const { getFieldDecorator } = this.props.form;
            return (
                <Modal
                    open={this.props.visibleEmail}
                    title="Registro de correo de salida para compañia inicial"
                    closable={false}
                    cancelButtonProps={{ style: { display: 'none' } }}
                    footer={null}
                >


                    <p>Llena los campos a continuación para registrar un correo de salida por parte de la compañia</p>
                    <Spin size="large" tip="Cargando..." spinning={this.props.sendingEmail}>
                        <Form layout='vertical' >
                            <Form.Item label="Protocolo">
                                {getFieldDecorator('protocol', {
                                    initialValue: "smtp",
                                    rules: [{
                                        required: true, message: 'El protocolo es requerido.'
                                    }]
                                })(
                                    <Select style={{ width: '100%' }}  >
                                        <Select.Option value="smtp" >SMTP</Select.Option>
                                    </Select>
                                )}

                            </Form.Item>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Dominio">
                                        {getFieldDecorator('host', {
                                            rules: [{
                                                required: true, message: "El dominio es requerido"
                                            }]
                                        })(
                                            <Input onChange={this.props.handleChangeValue} />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Puerto">
                                        {getFieldDecorator('port', {
                                            rules: [{
                                                required: true, message: 'Por favor introduce un puerto.'
                                            }]
                                        })(
                                            <InputNumber min={1} max={65535} style={{ width: '100%' }} onChange={this.props.handleChangeValue} />
                                        )}
                                    </Form.Item>
                                </Col>

                            </Row>

                            <Form.Item label="Tipo de autenticación">
                                {getFieldDecorator('auth', {
                                    initialValue: "login",
                                    rules: [{
                                        required: true, message: 'Tipo de autenticación es requerido.'
                                    }]
                                })(
                                    <Select onChange={this.props.onChangeAuthType}>
                                        <Select.Option value="login" >Login</Select.Option>
                                        <Select.Option disabled value="nologin" >Ninguno</Select.Option>
                                    </Select>
                                )}

                            </Form.Item>
                            {this.props.canEdit && (
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item label="Correo electrónico de salida">
                                            {getFieldDecorator('email', {
                                                rules: [{
                                                    type: 'email', message: 'Correo electrónico de salida no válido'
                                                }, {
                                                    required: true, message: 'Por favor introduce un correo electrónico.'
                                                }]
                                            })(
                                                <Input onChange={this.props.handleChangeValue} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Contraseña">
                                            {getFieldDecorator('password', {
                                                rules: [{
                                                    message: 'Contraseña.'
                                                }, {
                                                    required: true, message: 'Por favor introduce una contraseña.'
                                                }
                                                ]
                                            })(
                                                <Input type="password" onChange={this.props.handleChangeValue} />
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )
                            }
                            <Row gutter={24} >
                                <Col span={12}>
                                    <Form.Item label="Usar STARTTLS si esta disponible" />
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="startTtls">
                                        <Switch onChange={this.props.handleChangestarttls} />
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row gutter={24} >
                                <Col span={12}>
                                    <Form.Item label="Usar conexión SSL" />
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="useSSL">
                                        <Switch onChange={this.props.handleChangeSsl} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="Correo electrónico de prueba (configuraión)">
                                        {getFieldDecorator('emailTest', {
                                            rules: [{
                                                type: 'email', message: 'Correo electrónico de prueba no válido'
                                            }, {
                                                required: true, message: 'Por favor introduce un correo electrónico.'
                                            }]
                                        })(
                                            <Input onChange={this.props.handleChangeValue} />
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Button ghost type="primary" className="submit-button" onClick={this.props.onSendTest}>Probar configuracion</Button>
                                </Col>
                                <Col span={12}>
                                    <Button className="submit-button" onClick={this.props.handleSubmit} disabled={this.props.canSave === false}>Agregar</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Spin>

                </Modal>
            );
        }
    }
);

export default OutputEmailForm;