/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 11:41:48
 * @Last Modified by: Misael Jimenez
 * @Last Modified time: 2024-04-30 13:37:54
 */
import { createStore, applyMiddleware, compose } from "redux";
import { createEpicMiddleware } from "redux-observable";
import { rootEpic, rootReducer } from "./modules";

const epicMiddleware = createEpicMiddleware(rootEpic);
const composeEnhacers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line

const configureStore = (defaults) => {
  const store = createStore(
    rootReducer,
    defaults,
    composeEnhacers(applyMiddleware(epicMiddleware))
  );

  return store;
};

export default configureStore;
