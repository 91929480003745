import React from 'react';
import { Form } from '@ant-design/compatible';

import { Modal, Input, Button, Select, InputNumber } from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import TextArea from 'antd/lib/input/TextArea';

const { Option } = Select;

const HoseAddForm = Form.create({ name: 'hoseAddForm_into_modal' })(

    class extends React.Component {
        state = {
            hoseIdentificatorVS: {},
            hoseAuditableIdentificatorVS: {},
            hoseCodeExist: false,
            auditableHoseCodeExist: false,
            products: [],
        };

        validateHoseIdentificator(hoseKey) {
            return {
                validateStatus: 'error',
                errorMsg: `El identificador de manguera ${hoseKey} ya existe actualmente.`,
            };
        }

        validateAuditableHoseIdentificator(autidableHoseKey) {
            return {
                validateStatus: 'error',
                errorMsg: `El identificador auditable de manguera ${autidableHoseKey} ya existe actualmente.`,
            };
        }

        validateAuditableHoseIdentificatorNumbers(autidableHoseKey) {
            return {
                validateStatus: 'error',
                errorMsg: `El identificador auditable de manguera debe contener únicamente números`,
            };
        }

        handleHoseKeyOnBlur = (event) => {
            const hoseCode = event.target.value.toUpperCase();
            if (hoseCode.length === 2) {
                dispensaryServices.get(`hose/checkIfHoseKeyExist/${hoseCode}`)
                    .then(response => {
                        if (response === true) {
                            this.setState({
                                hoseIdentificatorVS: {
                                    ...this.validateHoseIdentificator(hoseCode),
                                }
                            });
                        }
                        this.setState({ hoseCodeExist: response });
                    });
            }
        }

        handleHoseKeyOnChange = (event) => {
            this.setState({ hoseIdentificatorVS: {} });
            const hoseCode = event.target.value.toUpperCase();

            if (hoseCode.length === 2) {
                dispensaryServices.get(`hose/checkIfHoseKeyExist/${hoseCode}`)
                    .then(response => {
                        if (response === true) {
                            this.setState({
                                hoseIdentificatorVS: {
                                    ...this.validateHoseIdentificator(hoseCode),
                                }
                            });
                        }
                        this.setState({ hoseCodeExist: response });
                    });
            }
        }

        handleAuditableHoseKeyOnBlur = (event) => {
            const auditableKey = event.target.value;
            const auditablehoseKey = this.props.dispensary.auditableDispensaryCode + "-MGA-" + event.target.value;

            const reg = /^[0-9-]+$/;
            if (reg.test(event.target.value) || event.target.value === '') {
                if (auditableKey.length === 4) {
                    dispensaryServices.get(`hose/checkIfAuditableHoseKeyExist/${auditablehoseKey}`)
                        .then(response => {
                            if (response === true) {
                                this.setState({
                                    hoseAuditableIdentificatorVS: {
                                        ...this.validateAuditableHoseIdentificator(auditablehoseKey),
                                    }
                                });
                            }
                            this.setState({ auditableHoseCodeExist: response });
                        });
                }
            } else {
                this.setState({ auditableHoseCodeExist: true });
                this.setState({
                    hoseAuditableIdentificatorVS: { ...this.validateAuditableHoseIdentificatorNumbers(), }
                });
            }
        }

        handleAuditableHoseKeyOnChange = (event) => {
            this.setState({ hoseAuditableIdentificatorVS: {} });
            const auditableKey = event.target.value;
            const auditablehoseKey = this.props.dispensary.auditableDispensaryCode + "-MGA-" + event.target.value;

            const reg = /^[0-9-]+$/;
            if (reg.test(event.target.value) || event.target.value === '') {
                if (auditableKey.length === 4) {
                    dispensaryServices.get(`hose/checkIfAuditableHoseKeyExist/${auditablehoseKey}`)
                        .then(response => {
                            if (response === true) {
                                this.setState({
                                    hoseAuditableIdentificatorVS: {
                                        ...this.validateAuditableHoseIdentificator(auditablehoseKey),
                                    }
                                });
                            }
                            this.setState({ auditableHoseCodeExist: response });
                        });
                }
            } else {
                this.setState({ auditableHoseCodeExist: true });
                this.setState({
                    hoseAuditableIdentificatorVS: { ...this.validateAuditableHoseIdentificatorNumbers(), }
                });
            }
        }

        render() {
            const {
                visible, onCancel, onAdd, form, dispensary
            } = this.props;
            const { getFieldDecorator } = form;
            const { hoseIdentificatorVS, hoseAuditableIdentificatorVS, hoseCodeExist, auditableHoseCodeExist } = this.state;
            const optionWarehouseDropdown = types =>  {
                if(types===undefined)
                    return;
                return types.map(item => (
                <Option key={item.idWareHouse} value={item.idWareHouse}>{
                    item.subproducts !== null ?
                        `${item.warehouseKey} - ${item.products.productDescription} - ${item.subproducts.description}` :
                        `${item.warehouseKey} - ${item.products.productDescription}`}
                </Option>)
            )};

            const modalTitle = "Agregar manguera a dispensario " + dispensary.auditableDispensaryCode
            return (
                <Modal
                    open={visible}
                    title={modalTitle}
                    onCancel={onCancel}
                    onOk={onAdd}
                    footer={[
                        <Button className="back-button" key="back" onClick={onCancel}>Cancelar</Button>,
                        <Button className="submit-button" key="submit" onClick={onAdd} disabled={hoseCodeExist | auditableHoseCodeExist}>Agregar</Button>
                    ]}
                >
                    <Form layout='vertical' >
                        <Form.Item style={{ display: 'none' }} label="Código de dispensario">
                            {getFieldDecorator('equipmentKey', {
                                initialValue: dispensary.dispensaryCode,
                                rules: [{ required: true, message: 'Por favor introduce el código de dispensario' }]
                            })(
                                <Input disabled />
                            )}
                        </Form.Item>
                        <Form.Item style={{ display: 'none' }} label="Código de dispensario">
                            {getFieldDecorator('auditableEquipmentKey', {
                                initialValue: dispensary.auditableDispensaryCode,
                                rules: [{ required: true, message: 'Por favor introduce el código de dispensario' }]
                            })(
                                <Input disabled />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Clave de la manguera"
                            validateStatus={hoseIdentificatorVS.validateStatus}
                            help={hoseIdentificatorVS.errorMsg || 'Introduce un valor a 2 caracteres, puedes usar sólo números (01), sólo letras (AB) o una combinación (1A)'}
                        >
                            {getFieldDecorator('hoseKey', {
                                rules: [{ required: true, len: 2, message: 'Por favor introduce el identificador de la manguera.' }]
                            })(
                                <Input maxLength={2} onChange={this.handleHoseKeyOnChange} onBlur={this.handleHoseKeyOnBlur} />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Clave auditable de la manguera"
                            validateStatus={hoseAuditableIdentificatorVS.validateStatus}
                            help={hoseAuditableIdentificatorVS.errorMsg || 'Introduce un valor de 4 caracteres, puedes usar sólo números.'}
                        >
                            {getFieldDecorator('auditableHoseKey', {
                                rules: [{ required: true, len: 4, message: 'Por favor introduce el identificador auditable de la manguera.' }]
                            })(
                                <Input addonBefore={"MGA-"} maxLength={4} onChange={this.handleAuditableHoseKeyOnChange} onBlur={this.handleAuditableHoseKeyOnBlur} />
                            )}
                        </Form.Item>
                        <Form.Item
                            label="Precio de la manguera"
                        >
                            {getFieldDecorator('price', {
                                rules: [{ required: true, message: 'Por favor introduce el precio de la manguera.' }]
                            })(
                                <InputNumber className="common-input" precision={2} formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} style={{ width: '100%' }} />
                            )}
                        </Form.Item>
                        <Form.Item label="Almacén">
                            {getFieldDecorator('idWarehouse', {
                                rules: [{
                                    required: true, message: 'Por favor selecciona un almacén.'
                                }],
                            })(
                                <Select placeholder="Selecciona un almacén">
                                    {optionWarehouseDropdown(this.props.warehouses)}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Descripción de la manguera">
                            {getFieldDecorator('description', {
                                rules: [{ required: true, message: 'Por favor introduce la descripción de la manguera.' }]
                            })(
                                <TextArea placeholder="Descripción de la manguera" autosize={{ minRows: 2, maxRows: 6 }} />
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);

export default HoseAddForm;
