import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Table, Tooltip, Button, Popover, Modal, message } from 'antd';
import {
    getPagePermissions,
    EVM_DETAILS
} from '../../../security/permissions';
import {dispensaries as dispensaryServices} from '../../../services';

class EditableTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataSource: this.props.dataSource,
        };

        this.columns = (handleAddComplement) => [
            {
                title: ()=> <Tooltip title="Requerido para expresar el número de registro único y consecutivo de cada recepción, generado por el programa informático.">
                                Número de registro
                            </Tooltip>,
                dataIndex: 'inputRegisterFolio',
                
            },
            {
                title: ()=> <Tooltip title="Requerido para expresar el volumen inicial antes de la recepción del producto por operación.">
                                Volumen Inicial del Tanque
                            </Tooltip>,
                dataIndex: 'initialVolumeTank',
                render: inputRegisterVolume => Intl.NumberFormat('en', { notation: 'standard' }).format(inputRegisterVolume)
            },
            {
                title: ()=> <Tooltip title="Requerido para expresar el volumen final después de la recepción del producto por operación">
                                Volumen Final del Tanque
                            </Tooltip>,
                dataIndex: 'finalVolumeTank',
                render: finalVolumeTank => Intl.NumberFormat('en', { notation: 'standard' }).format(finalVolumeTank)
            },
            {
                title: ()=> <Tooltip title="Requerido para expresar el volumen final después de la recepción del producto por operación">
                                Volumen Recepción
                            </Tooltip>,
                dataIndex: 'inputRegisterVolume',
                render: receptionVolume => Intl.NumberFormat('en', { notation: 'standard' }).format(receptionVolume)
            },
            {
                title: ()=> <Tooltip title="Requerido para expresar la temperatura del tanque a la que se realizó la cuantificación y/o totalización de la masa o volumen del hidrocarburo o petrolífero de que se trate, tomando en consideración las condiciones de referencia. La unidad de medida son grados Celsius.">
                                Temperatura
                            </Tooltip>,
                dataIndex: 'temperature',
                render: temperature => Intl.NumberFormat('en', { notation: 'standard' }).format(temperature)
            },
            {
                title: ()=> <Tooltip title="Requerido para expresar la presión absoluta a la que se realizó la cuantificación y/o totalización de la masa o volumen del hidrocarburo o petrolífero de que se trate, tomando en consideración las condiciones de referencia. La unidad de medida es kPa.">
                                Presión Absoluta
                            </Tooltip>,
                dataIndex: 'absolutePressure',
                render: absolutePressure => Intl.NumberFormat('en', { notation: 'standard' }).format(absolutePressure)
            },
            {
                title: ()=> <Tooltip title="Requerido para expresar la fecha y hora inicial de cada operación de recepción, deberá expresarse la hora en UTC con la indicación del huso horario yyyy-mm-ddThh:mm:ss+-hh:mm, de acuerdo con la especificación ISO 8601.">
                                Fecha de inicio
                            </Tooltip>,
                dataIndex: 'inputRegisterReceptionDate',
                render: date => moment(date).format('YYYY/MM/DD HH:mm:ss')
            },{
                title: ()=> <Tooltip title="Requerido para expresar la fecha y hora final de cada operación de recepción, deberá expresarse la hora en UTC con la indicación del huso horario yyyy-mm-ddThh:mm:ss+-hh:mm, de acuerdo con la especificación ISO 8601.">
                                Fecha de fin
                            </Tooltip>,
                dataIndex: 'inputRegisterEndReceptionDate',
                render: date => moment(date).format('YYYY/MM/DD HH:mm:ss')
            },{
                title: 'Almacén',
                dataIndex: 'inputRegisterWarehouseKey'
            },       
            {
                title: 'Edición',
                dataIndex: 'action',
                render: (text, record) => (
                    <div>
                        <span>
                            <Button 
                            icon={<EditOutlined />}
                                title='Editar Traspaso'
                                onClick={()=>{
                                    this.props.editTraspaso(record);
                            }} />
                        </span>
                    </div>
                ),
            },
            {
                title: 'Complementos',
                align: 'center',
                render: (text, record) =>
                    (record.isEditAllowed && (record.cfdiComplementInfo === null) && (record.storageComplementInfo === null) && (record.transportComplementInfo === null) && (record.internationalTransactionComplement === null) && record.clarifications === null) ?
                        <Popover content="Agregar información para complemento">
                            <Button icon={<PlusOutlined />} onClick={() =>
                                handleAddComplement({
                                    mainView:false,
                                    addComplementForm:true,
                                    currentCfdiComplement: record.cfdiComplementInfo,
                                    currentTransportComplement: record.transportComplementInfo,
                                    currentStorageComplement: record.storageComplementInfo,
                                    currentInternationalComplement: record.internationalTransactionComplement,
                                    isCurrentEditable: record.isEditAllowed,
                                    inputRegisterUUID: record.inputRegisterUUID,
                                    clarifications: record.clarifications,
                                })}>
                            </Button>
                        </Popover>
                        :
                        (record.isEditAllowed && (record.cfdiComplementInfo !== null || record.storageComplementInfo !== null || record.transportComplementInfo !== null || record.internationalTransactionComplement !== null || record.clarifications !== null)) ?
                            <div>
                                <Popover content="Editar información de complemento">
                                    <Button icon={<EditOutlined />} onClick={() =>
                                        handleAddComplement({
                                            mainView:false,
                                            addComplementForm:true,
                                            currentCfdiComplement: record.cfdiComplementInfo,
                                            currentTransportComplement: record.transportComplementInfo,
                                            currentStorageComplement: record.storageComplementInfo,
                                            currentInternationalComplement: record.internationalTransactionComplement,
                                            isCurrentEditable: record.isEditAllowed,
                                            inputRegisterUUID: record.inputRegisterUUID,
                                            clarifications: record.clarifications,
                                        })}>
                                    </Button>
                                </Popover>
                                <Popover content="Eliminar información de complemento">
                                    <Button icon={<DeleteOutlined />} onClick={() =>
                                        this.handleDelete({
                                            inputRegisterUUID: record.inputRegisterUUID,
                                            currentCfdiComplement: record.cfdiComplementInfo,
                                            currentTransportComplement: record.transportComplementInfo,
                                            currentStorageComplement: record.storageComplementInfo,
                                            currentInternationalComplement: record.internationalTransactionComplement,
                                            cfdiComplementRegisterType: record.cfdiComplementRegisterType,
                                            clarifications: record.clarifications,
                                            delete: true,
                                        })} type={'danger'}></Button>
                                </Popover>
                            </div>
                            :
                            (!record.isEditAllowed && (record.cfdiComplementInfo !== null || record.storageComplementInfo !== null || record.transportComplementInfo !== null || record.internationalTransactionComplement !== null)) ?
                                <Popover content="Ver información de complemento">
                                    <Button icon={<EyeOutlined />} onClick={() =>
                                        handleAddComplement({
                                            mainView:false,
                                            addComplementForm:true,
                                            currentCfdiComplement: record.cfdiComplementInfo,
                                            currentTransportComplement: record.transportComplementInfo,
                                            currentStorageComplement: record.storageComplementInfo,
                                            currentInternationalComplement: record.internationalTransactionComplement,
                                            isCurrentEditable: record.isEditAllowed,
                                            inputRegisterUUID: record.inputRegisterUUID,
                                            clarifications: record.clarifications,
                                        })}>
                                    </Button>
                                </Popover>
                                :
                                <div>
                                    <span>Sin complemento asociado</span>
                                </div>
            }
        ];
    }

    handleDelete = (params) => {
        this.setState({visible:true});

        const filters = {
            //General info
            receptionUUID: params.inputRegisterUUID,
            transactionType: this.props.transactionType,

            cfdiComplementRegisterType: params.cfdiComplementRegisterType,

            //CFDI Complement Data
            isCfdiComplement: false,
            currentcfdiComplementUUID: params.currentCfdiComplement !== null ? params.currentCfdiComplement.id : null,

            //Transport Complement Data
            isTransportComplement: false,
            transportComplementUUID: params.currentTransportComplement !== null ? params.currentTransportComplement.id : null,

            //Storage Complement Data
            isStorageComplement: false,
            storageComplementUUID: params.currentStorageComplement !== null ? params.currentStorageComplement.id : null,

            //International Complement Data
            isInternationalComplement: false,
            internationalComplementUUID: params.currentInternationalComplement !== null ? params.currentInternationalComplement.id : null,

            delete: (params.delete !== undefined) ? params.delete : false,
        };
        this.setState({infoToDelete: filters});
    }
    handleCancel = () => {
        this.setState({ visible: false });
    }

    handleOk = () => {
        this.setState({ loading: true });

        dispensaryServices.put('complements/editComplement', this.state.infoToDelete)
            .then(response => {
                const jsonResponse = JSON.parse(JSON.stringify(response));
                if (jsonResponse.hasOwnProperty('response')) {
                    message.error(jsonResponse.response.data);
                } else {
                    setTimeout(() => {
                        this.setState({ loading: false, visible: false });
                        message.success("Complemento eliminado");
                        window.location.reload();
                    }, 3000);
                }
            });
    }


    render() {
        return (
            <div>
                <Table
                    dataSource={this.props.dataSource}
                    columns={this.columns(this.props.handleAddComplement)}
                    rowKey={record => record.inputRegisterUUID}
                    scroll={{ x: true }}
                />
                <Modal
                    open={this.state.visible}
                    title="Atención"
                    onCancel={this.handleCancel}
                    footer={[
                        <Button className="back-button" key="cancel-delete" onClick={this.handleCancel}>Cancelar</Button>,
                        <Button type="danger" key="delete" loading={this.state.loading} onClick={this.handleOk}>Confirmar</Button>
                    ]}
                >
                    <p>¿Está seguro que desea eliminar este complemento? Una vez realizada la acción, la información no podrá ser recuperada.</p>
                </Modal>
            </div>
        );
    }
}

const EditableTraspasosTableForm = Form.create({ name: 'editableReceptionsTable' })(EditableTable);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(EVM_DETAILS, permissions)
});

export default connect(mapStateToProps)(EditableTraspasosTableForm);
