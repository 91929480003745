import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Row, Col, InputNumber, Checkbox } from 'antd';

class DictumDetailsPR07 extends Component {
    state = {
        hasNonFosilCombustible: false,
    };

    handleNonFosilFuelChange = e => {
        this.setState({ hasNonFosilCombustible: e.target.checked });

        if (e.target.checked === false)
            this.props.form.setFieldsValue({ gasolineNonFosilCombustibleComposValue: '' });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { pagePermissions } = this.props;

        return (
            <div>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item>
                            {getFieldDecorator('gasolineNonFosilCombustibleValue', {
                                valuePropName: 'checked',
                                initialValue: this.props.data.dictumDetails !== null && this.props.data.productType === 'PR07'
                                    ? this.props.data.dictumDetails[1].responseDictumDetailValue : true,
                            })(
                                <Checkbox
                                    onChange={this.handleNonFosilFuelChange}
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                >¿Contiene combustible no fósil?</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="Composición de octanaje en gasolina">
                            {getFieldDecorator('gasolineOctaneValue', {
                                initialValue: this.props.data.dictumDetails !== null && this.props.data.productType === 'PR07'
                                    ? this.props.data.dictumDetails[0].responseDictumDetailValue : '',
                                rules: [{
                                    required: this.props.selectedProduct === 'PR07', message: 'Por favor introduce el octanaje de la gasolina.'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={87} max={130}
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                    precision={0}
                                />
                            )}
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item label="Composición de combustible no fósil en gasolina">
                            {getFieldDecorator('gasolineNonFosilCombustibleComposValue', {
                                initialValue: this.props.data.dictumDetails !== null && this.props.data.productType === 'PR07'
                                    ? this.props.data.dictumDetails[2].responseDictumDetailValue : '',
                                rules: [{
                                    required: this.state.hasNonFosilCombustible !== false && this.props.selectedProduct === 'PR07', message: 'Ingrese la composición de combustible no fósil en gasolina'
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={1} max={99}
                                    precision={0}
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Form.create({ name: 'dictumDetailsPR07' })(DictumDetailsPR07);
