import React, { Component } from 'react';
import request from '../../services/request';
import { CalendarOutlined, CarryOutOutlined, CrownOutlined, IdcardOutlined } from '@ant-design/icons';
import { Card, Col, Row, Statistic, Timeline } from 'antd';

class Subscriptions extends Component {
	constructor() {
		super();
		this.state = {
			width: window.innerWidth,
			subscription: {}
		};
	}


	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);

		request().get('subscription/subscriptionDetail').then(response => {
			this.setState({
				subscription: response.data
			});
		});
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
	}
	updateDimensions = () => {
		this.setState({ width: window.innerWidth });
	};

	displaySubscriptionWarnings() {
		if (this.state.subscription.subscriptionMessageList !== undefined && this.state.subscription.subscriptionMessageList.length > 0) {
			return (
				<Card title="Alertas de Suscripción">
					<Timeline>
						{
							this.state.subscription.subscriptionMessageList.map(warning => (
								<Timeline.Item color="red" key={warning}>{warning}</Timeline.Item>
							))
						}
					</Timeline>
				</Card>
			);
		}
	}

	getSubscriptionMode() {
		if (this.state.subscription.subscriptionMessages && this.state.subscription.subscriptionMessages.find((x) => x === "SUBSCRIPTIONS_ON_FREE_MODE")) {
			return "Uso Libre";
		} else {
			return "Suscripción Activa";
		}
	}


	render() {
		return (
            <div style={{ background: '#ECECEC', padding: '30px' }}>
				<Card title="Detalles de la suscripción">
					<Row gutter={16}>
						<Col lg={6} sm={24}>
							<Card>
								<Statistic
									title="Modo"
									value={this.getSubscriptionMode()}
									precision={0}
									valueStyle={{ color: '#3f8600' }}
									prefix={<IdcardOutlined />}
								/>
							</Card>
						</Col>
						<Col lg={6} sm={24}>
							<Card>
								<Statistic
									title="Puntos de Distribución Activos"
									value={this.state.subscription.activeDistributionPoints}

									precision={0}
									valueStyle={{ color: '#3f8600' }}
									prefix={<CarryOutOutlined />}
								/>
							</Card>
						</Col>
						<Col lg={6} sm={24}>
							<Card>
								<Statistic
									title="Puntos de Distribución Contratados"
									value={this.getSubscriptionMode() === "Uso Libre" ? "Sin Limite" : this.state.subscription.equipmentsNumber}
									precision={0}
									valueStyle={{ color: '#3f8600' }}
									prefix={<CrownOutlined />}
								/>
							</Card>
						</Col>
						<Col lg={6} sm={24}>
							<Card>
								<Statistic
									title="Fecha de Expiración"
									value={this.state.subscription.dueDate === null ? "Sin Expiración" : this.state.subscription.dueDate}
									precision={0}
									valueStyle={{ color: '#3f8600' }}
									prefix={<CalendarOutlined />}
								/>
							</Card>
						</Col>
					</Row>
				</Card>
				{this.displaySubscriptionWarnings()}
			</div>
        );
	}
}

export default Subscriptions;
