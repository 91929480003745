import React from 'react';
import { Progress } from 'antd';

const UpdatedPassword = () => (
    <div style={{ textAlign: 'center' }}>
        <Progress type="circle" percent={100} width={80} />
        <h3 style={{ marginTop: 40.4 }}>Contraseña actualizada exitosamente</h3>
    </div>
);

export default UpdatedPassword;
