import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Table } from 'antd';

class FirstListWareHousesSaved extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: 'Clave de identificación',
                dataIndex: 'warehouseKey',
            },
            {
                title: 'Descripción',
                dataIndex: 'description',
            },
            {
                title: 'Tipo de producto',
                dataIndex: 'products.productDescription',
            },
            {
                title: 'Tipo de subproducto',
                dataIndex: 'subproducts.description',
            },
        ];
    }

    render() {

        return (
            <Table
                rowKey={record => record.idWareHouse}
                dataSource={this.props.wareHouseSaved}
                columns={this.columns}
                pagination={false}
            />
        );
    }
}

export default Form.create({ name: 'listWareHousesSaved' })(FirstListWareHousesSaved);
