import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Row, Col, notification, Spin } from 'antd';
import { EntryEquipment, EquipmentAggregation } from './components';
import {
    getPagePermissions,
    EVM_PERMISSION
} from '../../security/permissions';

import input_equipment from './../../assets/images/input_equipment.svg';
import { getSelectedDistributionPoint } from '../../common/components/widgets/DistributionPoint/distributionPointStorage';
import { getIsCloudModeActive } from '../../security';
import request from '../../services/request';

const openNotification = (message, description) => {
    const key = `open${Date.now()}`;
    const btn = (
        <Button style={{ background: '#1D98CD', color: '#FFFFFF' }} type="primary" size="small" onClick={() => notification.close(key)}>
            Entendido
        </Button>
    );
    notification.open({
        message,
        description,
        btn,
        key,
    });
};

class EntryVolumeMeasurer extends Component {
    _isMount = false;
    constructor() {
        super();
        this.state = {
            mainView: true,
            entryEquipments: [],
            addEquipmentForm: false,
            loadingEntryVolumeMeasurer: false,
            distributionPointId: getSelectedDistributionPoint(),
            isCloudEnable: getIsCloudModeActive(),
        };
    }

    componentDidMount() {
        this._isMount = true;
        this.fetchEntryEquipments();
        window.addEventListener("distributionPointChange", (e) => {
            if (this._isMount) {
                if (this.state.distributionPointId === getSelectedDistributionPoint())
                    return;
                this.setState({ distributionPointId: getSelectedDistributionPoint() }, () => {
                    this.fetchEntryEquipments();
                });
            }
        });
    }
    componentWillUnmount() {
        this._isMount = false;
    }

    fetchEntryEquipments = () => {
        this.setState({ loadingEntryVolumeMeasurer: true }, () => {
            const params = {
                applicationType: 'REGENTRADA'
            };
            request().get(`distributionPoints/${this.state.distributionPointId}/inputEquipment`, { params })
                .then(response => {
                    this.setState({ entryEquipments: response.data });
                }).finally(() => {
                    this.setState({ loadingEntryVolumeMeasurer: false });
                });
        });

    }

    showAddEquipmentForm = () => {
        this.setState({
            mainView: false,
            addEquipmentForm: true
        });
    }

    handleCancel = () => {
        this.setState({
            mainView: true,
            addEquipmentForm: false
        });
    }

    handleAdd = () => {
        this.fetchEntryEquipments();
        this.setState({
            mainView: true,
            addEquipmentForm: false
        });
        openNotification('Enlaza el equipo', 'Introduce el código de enlace en el equipo para concluir el enlace.');
    }

    onEquipmentLink = () => {
        this.setState({ entryEquipments: [] });
        this.fetchEntryEquipments();
    }

    onEquipmentActiveStatusChange = (equipmentId, isActive) => {
        const enableDisable = isActive ? 'activar' : 'desactivar';
        request().put(`equipment/changeInputEquipmentStatus/${equipmentId}/${isActive}`)
            .then(() => alert(`Se ha solicitado ${enableDisable} el equipo. Actualice la página para conocer el estatus en el que se encuentra el equipo. La solicitud puede tardar un tiempo en ser ejecutada.`))
            .catch(error => {
                const errorMessage = error.response.data.message;
                if (errorMessage) {
                    alert(`Error al Activar/Desactivar: ${error.response.data.message}`);
                }
            })
            .finally(() => {
                this.fetchEntryEquipments();
            });
    }

    render() {
        const { pagePermissions } = this.props;

        const cardItems = this.state.entryEquipments.map((value, index) => (
            <Col key={value.idEquipment} span={16} style={{ marginBottom: '24px' }}>
                <EntryEquipment equipment={value} onEquipmentLink={this.onEquipmentLink}
                    pagePermissions={pagePermissions} onEquipmentActiveStatusChange={this.onEquipmentActiveStatusChange} />
            </Col>
        ));

        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loadingEntryVolumeMeasurer}>
                <div>
                    {this.state.mainView &&
                        <div>
                            {this.state.entryEquipments.length > 0 ? (
                                <div>
                                    <div style={{ marginBottom: '24px' }}>
                                        {pagePermissions.add && <Button type="primary" icon={<PlusOutlined />} onClick={this.showAddEquipmentForm} hidden={this.state.isCloudEnable}>Agregar equipo de entrada</Button>}
                                    </div>
                                    <Row gutter={24}>
                                        {cardItems}
                                    </Row>
                                </div>
                            ) : (
                                <div className="center-div">
                                    <img src={input_equipment} alt="Equipo de entrada" />
                                    <div style={{ margin: '8px' }}>
                                        <label>Sin equipos de entrada agregados</label>
                                    </div>
                                    {pagePermissions.add && <Button className="customized-primary" type="primary" icon={<PlusOutlined />} onClick={this.showAddEquipmentForm} disabled={this.state.isCloudEnable}>Agregar equipo de entrada</Button>}
                                </div>
                            )}
                        </div>
                    }
                    {this.state.addEquipmentForm && <EquipmentAggregation onCancel={this.handleCancel} onAdd={this.handleAdd} />}
                </div>
            </Spin>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(EVM_PERMISSION, permissions)
});

export default connect(mapStateToProps)(EntryVolumeMeasurer);
