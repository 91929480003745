/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 11:07:38
 * @Last Modified by: Alejandro Leonor
 * @Last Modified time: 2018-03-07 11:08:17
 */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isAuthenticated } from './index';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={
            props => (
                isAuthenticated() ?
                    <Component {...props} /> :
                    <Redirect to={{ pathname: '/login' }} />
            )
        }
    />
);

PrivateRoute.propTypes = {
    component: PropTypes.any.isRequired,
};

export default PrivateRoute;
