/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-14 09:53:11
 * @Last Modified by: Alejandro Leonor
 * @Last Modified time: 2018-03-16 11:17:58
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { LoadingOutlined, UserOutlined } from '@ant-design/icons';
import { Layout, Menu, Spin, notification, Button, Dropdown, Avatar, Table } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import LidAlert from './lidAlert';
import ValidateDailyReportSchema from './validateDailyReportSchema';
import ValidateMonthReportSchema from './validateMonthReportSchema';
import {
    stockControl as stockServices
} from '../../../../services';
import {
    getPagePermissions,
    INVENTORY_CONTROL_PERMISSION
} from '../../../../security/permissions';
import sicom_logo from '../../../../assets/images/mini_sicom_logo.png';
import s_logo from '../../../../assets/images/mini_s.png';
import './styles.css';
import styles from './styles';
import { getAllowedDistributionPoints, logOut } from '../../../../security';
import IdleTimerContainer from './IdleTimerContainer';
import DistributionPointSelector from '../../widgets/DistributionPoint/distributionPointSelector';
import request from '../../../../services/request';
import { getSelectedDistributionPoint, saveSelectedDistributionPoint } from '../../widgets/DistributionPoint/distributionPointStorage';
import SubscriptionChange from './subscriptions/subscriptionChange';

const { SubMenu } = Menu;

class MainLayout extends Component {
    
    constructor() {
        super();
        this.state = {
            appVersion: "",
            allowedDistributionPoints: [],
            selectedDistributionPoint: "",
        };
    }
    state = {
        collapsed: false,
    };

    componentDidMount() {
        if (this.props.stockNotificationPermissions.edit) {
            this.interval = setInterval(this.stockTimer, 1800000);
        }

        request().get('general/getApplicationVersion').then(response => {
            console.log('Version de Aplicación: ', response);
            this.setState({
                appVersion: response.data,
            });
        });

        getAllowedDistributionPoints.then((allowedDistributionPoints) => {
            if(allowedDistributionPoints === undefined || allowedDistributionPoints.length <= 0){
                request()
                    .get("distributionPoints")
                    .then((resp) => {
                        let beforeSelected = getSelectedDistributionPoint();
                        let savedID = (resp.data.length > 0)? resp.data[0].id : '';
                        if(beforeSelected !== undefined){
                            let dpp = resp.data.find(item => { return item.id === beforeSelected});
                            if(dpp!==undefined)
                                savedID = beforeSelected;
                        }
                        this.setState({
                            allowedDistributionPoints: resp.data,
                            selectedDistributionPoint: savedID
                        });
                        this.saveSelectedDPP(savedID);
                    });
            }else{
                this.setState({
                    allowedDistributionPoints: allowedDistributionPoints,
                    selectedDistributionPoint: allowedDistributionPoints[0].id
                });
                this.saveSelectedDPP(allowedDistributionPoints[0].id);
            }
        });
    }

    saveSelectedDPP(selected){
        let dpp  = this.state.allowedDistributionPoints.find(item => { return item.id === selected});
        if(dpp===undefined){
            return;
        }
        saveSelectedDistributionPoint(selected, dpp.name);
        window.dispatchEvent(new Event("distributionPointChange"));
    }

    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    redirectToStockControl = (key) => {
        notification.close(key);
        window.location.replace('/stockControl');
    };

    openStockNotification = (response) => {
        const key = `open${Date.now()}`;
        const columns = [
            {
                title: 'Producto',
                dataIndex: 'inventoryControlNotificationProductName'
            },
            {
                title: 'Diferencia',
                dataIndex: 'inventoryControlPercentageDifference'
            }
        ];
        const dataTable = response.map(item => ({
            key: item.inventoryControlNotificationProductName,
            inventoryControlNotificationProductName: item.inventoryControlNotificationProductName,
            inventoryControlPercentageDifference: item.inventoryControlPercentageDifference
        }));
        const btn = ([
            <Button key="later" style={{ color: '#1D98CD', marginRight: '14px' }} size="small" onClick={() => notification.close(key)}>
                Registrar después
      </Button>,
            <Button key="now" style={{ marginLeft: '14px', background: '#1D98CD', color: '#FFFFFF' }} type="primary" size="small" onClick={() => this.redirectToStockControl(key)}>
                Registar ahora
      </Button>
        ]
        );
        notification.info({
            message: 'Inconsistencias en la información volumétrica',
            description:
                <div>
                    <p style={{ textAlign: 'justify' }}>{`Se encontraron algunas inconsistencias entre el volumen lógico y el volumen manual del día de ayer ${response[0].inventoryControlNotificationDay} 
          de ${response[0].inventoryControlNotificationMonth} de ${response[0].inventoryControlNotificationYear} en los siguientes productos: `}
                    </p>
                    <Table columns={columns} dataSource={dataTable} size="small" pagination={false} />
                    <br />
                    <p style={{ textAlign: 'justify' }}>Favor de ir al menú "Control de existencias" para registrar la posible causa y las acciones que se tomarán
                    para su correción y seguimiento de cada una de las inconsistencias.
          </p>
                    <p>Dispones del día de hoy para completar esa información.</p>
                </div>,
            style: {
                width: 522,
                marginLeft: 335 - 522,
            },
            duration: 0,
            btn,
            key,
        });
    };

    stockTimer = () => {
        stockServices.get('inventoryControl/dailyInventoryControlNotification')
            .then(response => {
                console.log('Inconsistencias', response, response.some(elem => elem.inventoryControlUserNotified === false));
                if (response.some(elem => elem.inventoryControlUserNotified === false)) {
                    this.openStockNotification(response);
                }
            });
    }

    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }

    tittlePage = (pathname) => {
        switch (pathname) {
            /*case '/':
              return <span className="section-name">Dashboard</span>;*/

            case '/configurationApplication':
                return <span className="section-name">Configuración del sistema</span>;

            case '/configurationOutputEmail':
                return <span className="section-name">Configuración correo de salida</span>;

            case '/':
                return <span className="section-name">Permisos</span>;

            case '/register':
                return <span className="section-name">Registro</span>;

            case '/firstContainerRegistry':
                return <span className="section-name">Contenedores</span>;

            case '/containers':
                return <span className="section-name">Contenedores</span>;

            case '/users':
                return <span className="section-name">Usuarios</span>;

            case '/dispensaries':
                return <span className="section-name">Monitor de control</span>;

            case '/dispensarySale':
                return <span className="section-name">Venta de dispensarios</span>;

            case '/hoseSale':
                return <span className="section-name">Venta de mangueras</span>;

            case '/entryVolumeMeasurer':
                return <span className="section-name">Equipos de entrada</span>;

            case '/receptions':
                return <span className="section-name">Recepciones</span>;

            case '/traspasos':
                return <span className="section-name">Traspasos</span>;

            case '/dictum':
                return <span className="section-name">Dictamenes</span>;

            case '/stockControl':
                return <span className="section-name">Control de existencias</span>;

            case '/logbook':
                return <span className="section-name">Bitácora</span>;

            /*case '/billing':
                return <span className="section-name">Facturación</span>;*/

            case '/operationCertificates':
                return <span className="section-name">Certificados de operación</span>;

            case '/healthApplication':
                return <span className="section-name">Información de la aplicación</span>;

            case '/corruptedDataVisualizer':
                return <span className="section-name">Visualizador de descargas fallidas de comunicación</span>;

            case '/permissionNumbers':
                return <span className="section-name">Números de permiso</span>;

            case '/volumeMeasurerRegistries':
                return <span className="section-name">Mediciones de nivel</span>;

            case '/wareHouse':
                return <span className="section-name">Almacenes</span>;

            case '/firstWareHouseRegistry':
                return <span className="section-name">Almacenes</span>;
            
            case '/distributionPoints':
                return <span className="section-name">Instalaciones</span>;
            case '/subscription':
                return <span className="section-name">Suscripción</span>;
            default:
                return <span className="section-name" />;
        }
    }

    userProfile = (userRole) => {
        switch (userRole) {
            case 'ADMINISTRATOR':
                return <span className="section-profile"> Administrador </span>;

            case 'SUPERVISOR':
                return <span className="section-profile">Supervisor </span>;

            case 'OPERATOR':
                return <span className="section-profile">Operador </span>;

            default:
                return <span className="section-profile">Auditor fiscal </span>;
        }
    }

    openSubMenu = () => {
        if (this.props.location.pathname === '/dispensaries' || this.props.location.pathname === '/dispensarySale' || this.props.location.pathname === '/hoseSale') {
            return ['/dispensaries'];
        } else if (this.props.location.pathname === '/entryVolumeMeasurer' || this.props.location.pathname === '/receptions') {
            return ['/entryVolumeMeasurer'];
        }
        return [this.props.location.pathname];
    }

    closeSession = () => {
        stockServices.get('events/userLogout').then(response => {
            console.log('Inconsistencias', response);
            logOut();
        });
    }

    onChangeDistributionPoint = (distributionPointID) => {
        this.setState({selectedDistributionPoint: distributionPointID})
        this.saveSelectedDPP(distributionPointID);
    }

    render() {
        const {
            children,
            menu,
            // error,
            loading,
            userData,
        } = this.props;

        const menuSession = (
            <Menu>
                <Menu.Item style={{ pointerEvents: 'none' }}> {this.userProfile(userData.userRole)} </Menu.Item>
                <Menu.Item style={{ pointerEvents: 'none' }}> {` ${userData.userEmail}`} </Menu.Item>
                {/*<Menu.Item onClick={this.stockTimer}> Inconsistencias </Menu.Item>*/}
                <Menu.Divider />
                <Menu.Item>
                    <Link to="/cv" title="Cerrar sesión" onClick={this.closeSession}>
                        Cerrar sesión
          </Link>
                </Menu.Item>
            </Menu>
        );

        return (
            <div>
                <IdleTimerContainer></IdleTimerContainer>
                <LidAlert />
                <SubscriptionChange />
                <ValidateDailyReportSchema />
                <ValidateMonthReportSchema />
                <Layout style={styles.layout}>
                    <Layout.Sider
                        breakpoint="xl"
                        width="315"
                        style={{ overflow: 'auto' }}
                        collapsible
                        collapsed={this.state.collapsed}
                        onCollapse={this.onCollapse}
                    >
                        <div className="logo">
                            <img src={!this.state.collapsed ? sicom_logo : s_logo} alt="Sicom" />
                        </div>
                        <div className="appVersion">
                            <p><b>Control Volumétrico v-{this.state.appVersion}</b></p>
                        </div>
                        <div hidden={this.state.allowedDistributionPoints.length <= 0}>
                            <DistributionPointSelector
                                allowedDistributionPoints={this.state.allowedDistributionPoints}
                                selectedDistributionPoint={(this.state.selectedDistributionPoint !==undefined)? this.state.selectedDistributionPoint : "" }
                                onChangeDistributionPoint={this.onChangeDistributionPoint}
                            />
                        </div>
                        <Menu
                            theme="dark"
                            mode="inline"
                            defaultSelectedKeys={[this.props.location.pathname]}
                            defaultOpenKeys={this.openSubMenu()}
                        >
                            {
                                menu.map(item => (
                                    !item.submenu ? (
                                        item.show &&
                                        <Menu.Item className="customized" key={item.path}>
                                            <Link to={item.path} title={item.title}>
                                                <LegacyIcon type={`${item.icon}`} />
                                                <span>{item.title}</span>
                                            </Link>
                                        </Menu.Item>
                                    ) : (
                                        <SubMenu key={item.path} title={<span><LegacyIcon type={`${item.icon}`} /><span>{item.title}</span></span>}>
                                            {
                                                item.items.map(i => (
                                                    <Menu.Item key={i.path}>
                                                        <Link to={i.path} title={i.title}>
                                                            <span>{i.title}</span>
                                                        </Link>
                                                    </Menu.Item>
                                                ))
                                            }
                                        </SubMenu>
                                    )
                                ))
                            }
                        </Menu>
                        <div style={{ height: '48px' }} />
                    </Layout.Sider>
                    <Layout>
                        <Layout.Header style={styles.header}>
                            <div className="header-title">
                                {this.tittlePage(this.props.location.pathname)}
                                <Dropdown
                                    overlay={menuSession}
                                    trigger={['click']}
                                    placement="bottomRight"
                                    className="settingSession"
                                >
                                    <Avatar icon={<UserOutlined />} />
                                </Dropdown>
                            </div>
                            {/* <Icon
                className="trigger"
                type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                onClick={this.toggle}
              /> */}
                        </Layout.Header>
                        <Layout.Content style={styles.content}>
                            {
                                loading &&
                                <div className="justify-center">
                                    <Spin indicator={<LoadingOutlined style={styles.spin} spin />} />
                                </div>
                            }
                            {/* error && <Error error={error} /> */}
                            {children}
                        </Layout.Content>
                    </Layout>
                </Layout>
            </div>
        );
    }
}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    stockNotificationPermissions: getPagePermissions(INVENTORY_CONTROL_PERMISSION, permissions)
});

export default withRouter(
    connect(mapStateToProps)(MainLayout)
);
