import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Row, Col, Select, DatePicker, Button, Statistic, Spin } from 'antd';
import { DispensarySalesDetail } from './components';
import {
    dispensaries as dispensaryServices
} from '../../services';
import { connect } from 'react-redux';
import {
    getPagePermissions,
    EXPORT_REPORT_PERMISSION
} from '../../security/permissions';
import { getSelectedDistributionPoint } from '../../common/components/widgets/DistributionPoint/distributionPointStorage';


const { Option } = Select;
const { RangePicker } = DatePicker;

class DispensarySale extends Component {
    _isMount = false;
    constructor() {
        super();
        this.state = {
            dispensaries: [],
            data: {},
            visible: false,
            movementDetail: [],
            loadingDispensaries: false,
            distributionPointId: getSelectedDistributionPoint(),
        };
    }

    fetchDispensaryMovements = (params = {}) => {
        this.setState({ loadingDispensaries: true });
        dispensaryServices.post('movements/getGeneralMovements', params)
            .then(response => {
                this.setState({
                    data: response,
                    movementDetail: response.movementDetail
                });
            }).finally(() => {
                this.setState({ loadingDispensaries: false });
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                this.setState({
                    visible: true,
                    selectedDispensaries: fieldsValue.dispensaries
                });
                const rangeTimeValue = fieldsValue.period;
                const values = {
                    startDate: rangeTimeValue === undefined ? null : rangeTimeValue[0].format('YYYY-MM-DD 00:00:00'),
                    endDate: rangeTimeValue === undefined ? null : rangeTimeValue[1].format('YYYY-MM-DD 23:59:59'),
                    dispensaryCode: fieldsValue.dispensaries,
                    distributionPointId: this.state.distributionPointId
                };
                this.fetchDispensaryMovements(values);
            }
        });
    }

    fetchDispensaries = (params = {}) => {
        this.setState({ loadingDispensaries: true });
        dispensaryServices.get(`distributionPoints/${this.state.distributionPointId}/dispensaries`)
            .then(response => {
                if (response.length > 0) {
                    this.setState({
                        dispensaries: response,
                    });
                } else {
                    this.setState({
                        dispensaries: [],
                    });
                }
            }).finally(() => {
                this.setState({ loadingDispensaries: false });
            });
    }

    componentDidMount() {
        this._isMount = true;
        this.fetchDispensaries();
        window.addEventListener("distributionPointChange", (e) => {
            if (this._isMount) {
                this.setState({ distributionPointId: getSelectedDistributionPoint(), data: {}, movementDetail: [], }, () => {
                    this.props.form.setFieldsValue({ 'dispensaries': undefined });
                    this.fetchDispensaries();
                });
            }
        });
    }

    componentWillUnmount() {
        this._isMount = false;
    }

    /* printDispensarySales = (params = {}) => {
        this.setState({ loadingDispensaries: true });
        axios({
            url: `${apiUrl}/movements/getGeneralMovements/exportPDF`,
            method: 'POST',
            data: params,
            responseType: 'blob', // important
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'ventaDispensarios.pdf'); // or any other extension
            document.body.appendChild(link);
            link.click();
            this.setState({ loadingDispensaries: false });

        });
    } */


    render() {
        const { getFieldDecorator } = this.props.form;
        const optionItems = this.state.dispensaries.map((dispensary, index) => (
            <Option key={dispensary.dispensaryCode}>{dispensary.dispensaryCode}</Option>
        ));

        const tableItems = this.state.movementDetail.map(value => (
            <DispensarySalesDetail key={value.dispensaryCode} movementDetail={value} />
        ));

        return (
            <Spin size="large" tip="Cargando..." spinning={this.state.loadingDispensaries}>
                <Form layout='vertical' onSubmit={this.handleSubmit}>
                    <Row gutter={24}>
                        <Col span={10}>
                            <Form.Item
                                label="Seleccionar dispensarios"
                            >
                                {getFieldDecorator('dispensaries', {
                                    rules: [{ required: true, message: 'Por favor selecciona al menos un dispensario.' }]
                                })(
                                    <Select
                                        mode="multiple"
                                        placeholder="Selecciona uno o más dispensarios"
                                    >
                                        {optionItems}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Seleccionar periodo"
                            >
                                {getFieldDecorator('period', {

                                })(
                                    <RangePicker
                                        lang='es'
                                        format="YYYY/MM/DD"
                                        placeholder={['Desde', 'Hasta']}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                className="transparent-label"
                                label=" "
                            >
                                <Button type="primary" htmlType="submit">Consultar</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {
                    this.state.visible &&
                    <div>
                        <div style={{ background: '#E5E5E5', padding: '30px' }}>
                            <Row>
                                <Col span={3}>
                                    <h3 style={{ color: '#1B95CA' }}>TOTAL</h3>
                                </Col>
                                <Col span={7}>
                                    <Statistic title="Número de operaciones" value={this.state.data.totalMovement} />
                                </Col>
                                <Col span={7}>
                                    <Statistic title="Litros vendidos" value={this.state.data.totalVolume} />
                                </Col>
                                <Col span={7}>
                                    <Statistic title="Total venta" prefix="$" value={this.state.data.totalAmount} />
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
                {tableItems}
            </Spin>
        );
    }
}

const DispensarySaleForm = Form.create({ name: 'dispensarySale' })(DispensarySale);

const mapStateToProps = ({ userData, userData: { permissions } }) => {
    return {
        userData,
        exportReportsPermissions: getPagePermissions(EXPORT_REPORT_PERMISSION, permissions)
    };
};

export default connect(mapStateToProps)(DispensarySaleForm);
