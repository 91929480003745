import React from 'react';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import dayjs from 'dayjs'
import { Modal, Input, Button, Row, Col, DatePicker, Popover, Tooltip, InputNumber } from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../../services';
import { connect } from 'react-redux';
import {
    getPagePermissions,
    DISPENSARY_PERMISSION
} from '../../../security/permissions';
import tooltip from './tooltip.json';

const { TextArea } = Input;
const { confirm } = Modal;

const GaugeShowInfo = Form.create({ name: 'gaugeShowForm_into_modal' })(

    class extends React.Component {
        state = {
            gauge: {},
            visible: false,
            wasEdited: false,
            visibleEliminationFormGauge: false,
        };

        fetch = (gaugeId) => {
            dispensaryServices.get(`equipment/${gaugeId}`)
                .then(response => {
                    this.setState({
                        gauge: response,
                    });
                });
        };

        showModal = (gaugeId) => {
            this.setState({
                visible: true,
            });
            this.fetch(gaugeId);
        }

        handleReturn = () => {
            this.setState({
                visible: false,
                wasEdited: false,
            });
            this.props.form.resetFields();
        };

        editGauge = () => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    const filters = {
                        idEquipment: this.state.gauge.idEquipment,
                        description: values.description,
                        calibrationDate: values.validityCalibration.format('YYYY-MM-DD HH:mm:ss'),
                        uncertaintyMeasurement: values.measurementUncertainty
                    };
                    dispensaryServices.put('equipment/updateGauge', filters)
                        .then(response => {
                            this.props.onDeleteGauges();
                        });
                    this.setState({
                        visible: false,
                        wasEdited: false,
                    });
                }
            });
        }

        deleteGauge = (gaugeId) => {
            dispensaryServices.Delete(`equipment/${gaugeId}`)
                .then(response => {
                    this.props.onDeleteGauges();
                });
            this.setState({
                visible: false,
            });
        }

        showDeleteConfirm = (auditableEquipmentKey, equipmentId) => {
            confirm({
                title: `¿Está seguro que desea eliminar el medidor ${auditableEquipmentKey}`,
                content: 'Se perderán los datos del medidor permanentemente',
                okText: 'Sí',
                okType: 'danger',
                cancelText: 'No',
                centered: true,
                onOk: () => new Promise((resolve) => {
                    setTimeout(() => resolve(this.deleteGauge(equipmentId)), 2000);
                }).catch(() => console.log('error')),
                onCancel() {},
            });
        }

        showEditConfirm = (auditableEquipmentKey) => {
            this.props.form.validateFieldsAndScroll((err, values) => {
                if (!err) {
                    confirm({
                        title: `¿Está seguro que desea editar el medidor ${auditableEquipmentKey}`,
                        content: 'Se perderán los datos anteriores permanentemente',
                        okText: 'Sí',
                        okType: 'danger',
                        cancelText: 'No',
                        centered: true,
                        onOk: () => new Promise((resolve) => {
                            setTimeout(() => resolve(this.editGauge()), 2000);
                        }).catch(() => console.log('error')),
                        onCancel() {},
                    });
                }
            });
        }

        editFields = () => {
            this.setState({
                wasEdited: true,
            });
        }

        render() {
            const { gauge } = this.state;
            const { gaugeId, pagePermissions } = this.props;
            const { getFieldDecorator } = this.props.form;

            return (
                <div style={{ display: 'inline-block' }} >
                    <Popover content="Ver detalles">
                        <a type="link" shape="circle" onClick={() => this.showModal(gaugeId)}>
                            <InfoCircleTwoTone style={{ fontSize: '20px' }} />
                        </a>
                    </Popover>
                    <Modal
                        open={this.state.visible}
                        title="Información del medidor"
                        onCancel={this.handleReturn}
                        footer={pagePermissions.edit ? [
                            <Button type="danger" onClick={() => this.showDeleteConfirm(gauge.auditableEquipmentKey, gauge.idEquipment)} key={0}>Eliminar</Button>,
                            this.state.wasEdited === false ?
                                <Button onClick={this.handleReturn} key={1}>Volver</Button> :
                                <Button type="primary" onClick={() => this.showEditConfirm(gauge.auditableEquipmentKey)} key={2}>Editar</Button>
                        ] : []}
                    >
                        <p>A continucación se muestra la información detallada del medidor.</p>
                        <Form layout='vertical' >
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Tooltip title={tooltip.medidores.claveIdentificacion}>
                                        <Form.Item label="Clave de identificación">
                                            {getFieldDecorator('equipmentKey', {
                                                initialValue: gauge.auditableEquipmentKey,
                                            })(
                                                <Input className="common-input" disabled />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                                <Col span={12}>
                                    <Tooltip title={tooltip.medidores.incertidumbre}>
                                        <Form.Item label="Incertidumbre de medición">
                                            {getFieldDecorator('measurementUncertainty', {
                                                initialValue: gauge.measurementUncertainty,
                                            })(
                                                <InputNumber className="common-input" disabled={pagePermissions.edit === false} onChange={this.editFields} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                    
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Tooltip title={tooltip.medidores.fechaCalibracion}>
                                        <Form.Item label="Vigencia de calibración">
                                            {getFieldDecorator('validityCalibration', {
                                                initialValue: dayjs(gauge.validityCalibration),
                                                rules: [{
                                                    required: true, message: 'Por favor introduce la vigencia de calibración'
                                                }],
                                            })(
                                                <DatePicker  lang='es' style={{ width: '100%' }} disabled={pagePermissions.edit === false} format="YYYY-MM-DD" onChange={this.editFields} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Tooltip title={tooltip.medidores.descripcion}>
                                        <Form.Item label="Localización y descripción">
                                            {getFieldDecorator('description', {
                                                initialValue: gauge.description,
                                                rules: [{
                                                    required: true, message: 'Por favor introduce la localización y/o descripción del medidor'
                                                }],
                                            })(
                                                <TextArea autoSize={{ minRows: 1, maxRows: 6 }} disabled={pagePermissions.edit === false} onChange={this.editFields} />
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Tooltip title={tooltip.medidores.serialNumber}>
                                        <Form.Item label="Número de serie">
                                            {getFieldDecorator('serialNumber', {
                                                initialValue: gauge.serialNumber,
                                            })(
                                                <Input disabled/>
                                            )}
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>
                </div >
            );
        }
    }
);

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(DISPENSARY_PERMISSION, permissions)
});

export default connect(mapStateToProps)(GaugeShowInfo);
