import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';

import { Row, Col, InputNumber, Checkbox } from 'antd';

class DictumDetailsPR11 extends Component {
    state = {
        hasNonFosilCombustible: true,
    };

    handleNonFosilFuelChange = e => {
        this.setState({ hasNonFosilCombustible: e.target.checked });

        if (e.target.checked === false)
            this.props.form.setFieldsValue({ turbosineNonFosilCombustibleComposValue: '' });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        const { pagePermissions } = this.props;

        return (
            <div>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item>
                            {getFieldDecorator('turbosineNonFosilCombustibleValue', {
                                valuePropName: 'checked',
                                initialValue: this.props.data.dictumDetails !== null && this.props.data.productType === 'PR11'
                                    ? this.props.data.dictumDetails[0].responseDictumDetailValue : true,
                            })(
                                <Checkbox
                                    key={1}
                                    onChange={this.handleNonFosilFuelChange}
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                >¿Contiene combustible no fósil?</Checkbox>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item label="Composición de combustible no fósil en diésel">
                            {getFieldDecorator('turbosineNonFosilCombustibleComposValue', {
                                initialValue: this.props.data.dictumDetails !== null && this.props.data.productType === 'PR11'
                                    ? this.props.data.dictumDetails[1].responseDictumDetailValue : true,
                                rules: [{
                                    required: this.state.hasNonFosilCombustible !== false && this.props.selectedProduct === 'PR11', message: 'Ingrese la composición de combustible no fósil en turbosina '
                                }],
                            })(
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={1} max={99}
                                    precision={0}
                                    disabled={this.props.isEditable === false || pagePermissions.edit === false}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Form.create({ name: 'dictumDetailsPR11' })(DictumDetailsPR11);
