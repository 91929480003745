import {
    //Dashboard,
    Permissions,
    Register,
    Users,
    Dispensaries,
    Containers,
    FirstContainers,
    DispensarySale,
    HoseSale,
    EntryVolumeMeasurer,
    Receptions,
    StockControl,
    Printer,
    Backups,
    Alerts,
    Logbook,
    //FirstFiles,
    //PermissionNumbers,
    Dictums,
    OperationCertificates,
    FirstConfigApplication,
    ConfigurationApplication,
    OutputEmailConfig,
    HealthInformation,
    CorruptedDataVisualizer,
    VolumeMeasurerConfig,
    FirstWarehouses,
    Warehouses,
    EmailSenderConfig,
    DistributionPoints,
    Traspasos,
    JsonReport,
    Subscriptions
} from './routes';
import { getIsCloudModeActive } from './security';
import {
    BACKUP_PERMISSION,
    //DASHBOARD_PERMISSION,
    DISPENSARY_PERMISSION,
    EVENTS_PERMISSION,
    EVM_PERMISSION,
    INVENTORY_CONTROL_PERMISSION,
    PRINTER_PERMISSION,
    REGISTER_PERMISSION,
    SALES_PERMISSION,
    SALE_RESUME_PERMISSION,
    USERS_PERMISSION,
    PERMISSIONS,
    EVM_DETAILS,
    DICTUM,
    INITIAL_CONTAINER,
    OPERATION_CERTIFICATE,
    CONFIG_APPLICATION,
    FIRST_CONFIG_APPLICATION,
    CORRUPTED_DATA_VISUALIZER_PERMISSION,
    INITIAL_WAREHOUSE_PERMISSIONS,
    WAREHOUSE_PERMISSIONS,
    DISTRIBUTION_POINT_PERMISSIONS,
} from './security/permissions';

/**
 * IMPORTANT
 * Item keys must match permission types
 */
const menu = [
    /*{
      key: DASHBOARD_PERMISSION,
      path: '/',
      component: Dashboard,
      icon: 'desktop',
      title: 'Dashboard',
      submenu: false,
      show: true
    },*/
    {
        key: PERMISSIONS,
        path: '/',
        component: Permissions,
        icon: 'solution',
        title: 'Permisos',
        submenu: false,
        show: true
    },
    {
        key: FIRST_CONFIG_APPLICATION,
        path: '/FirstConfigApplication',
        component: FirstConfigApplication,
        icon: 'setting',
        title: 'Configuración de aplicación',
        submenu: false,
        show: true,
    },
    {
        key: REGISTER_PERMISSION,
        path: '/register',
        component: Register,
        icon: 'form',
        title: 'Registro',
        submenu: false,
        show: true,
    },
    /*{
        key: PERMISSION_NUMBER_PERMISSION,
        path: '/permissionNumbers',
        component: PermissionNumbers,
        title: 'Números de permiso',
        icon: 'paperclip',
        submenu: false,
        show: false,
    },*/
    {
        key: DISTRIBUTION_POINT_PERMISSIONS,
        path: '/distributionPoints',
        component: DistributionPoints,
        icon: 'bank',
        title: 'Puntos de Distribución',
        submenu: false,
        show: true,
    },
    {
        key: WAREHOUSE_PERMISSIONS,
        path: '/wareHouse',
        component: Warehouses,
        icon: 'bank',
        title: 'Almacenes',
        submenu: false,
        show: true,
    },
    {
        key: INITIAL_WAREHOUSE_PERMISSIONS,
        path: '/firstWareHouseRegistry',
        component: FirstWarehouses,
        icon: 'container',
        title: 'Almacenes',
        submenu: false,
        show: true,
    },  
    {
        key: DISPENSARY_PERMISSION,
        path: '/containers',
        component: Containers,
        icon: 'container',
        title: 'Contenedores',
        submenu: false,
        show: true,
    },
    {
        key: INITIAL_CONTAINER,
        path: '/firstContainerRegistry',
        component: FirstContainers,
        icon: 'container',
        title: 'Contenedores',
        submenu: false,
        show: true,
    },
    {
        key: EVM_PERMISSION,
        path: '/entryVolumeMeasurer',
        component: EntryVolumeMeasurer,
        title: 'Registro de entrada'
    },
    {
        key: DISPENSARY_PERMISSION,
        path: '/dispensaries',
        component: Dispensaries,
        icon: 'sliders',
        title: 'Equipos',
        submenu: true,
        show: false,
        items: [
            {
                key: DISPENSARY_PERMISSION,
                path: '/dispensaries',
                component: Dispensaries,
                title: 'Dispensarios'
            },
            {
                key: EVM_PERMISSION,
                path: '/entryVolumeMeasurer',
                component: EntryVolumeMeasurer,
                title: 'Registro de entrada'
            },
        ]
    },
    {
        key: SALE_RESUME_PERMISSION,
        path: '/dispensarySale',
        component: DispensarySale,
        title: 'DispensarySale',
        submenu: false,
        show: false
    },
    {
        key: SALES_PERMISSION,
        path: '/hoseSale',
        component: HoseSale,
        title: 'HoseSale',
        submenu: false,
        show: false
    },
    {
        key: EVM_DETAILS,
        path: '/volumeMeasurerRegistries',
        component: VolumeMeasurerConfig,
        icon: 'snippets',
        title: 'Mediciones de nivel',
        submenu: false,
        show: false
    },
    {
        key: EVM_DETAILS,
        path: '/traspasos',
        component: Traspasos,
        icon: 'dashboard',
        title: 'Traspasos',
        submenu: false,
        show: false
    },
    {
        key: SALES_PERMISSION,
        path: '/receptions',
        component: Receptions,
        icon: 'dashboard',
        title: 'Reportes',
        submenu: true,
        show: false,
        items: [
            {
                key: EVM_DETAILS,
                path: '/receptions',
                component: Receptions,
                title: 'Recepciones'
            },
            {
                key: EVM_DETAILS,
                path: '/traspasos',
                component: Traspasos,
                title: 'Traspasos'
            },
            {
                key: SALE_RESUME_PERMISSION,
                path: '/dispensarySale',
                component: DispensarySale,
                submenu: false,
                show: false,
                title: 'Ventas por Dispensario',                
            },
            {
                key: SALES_PERMISSION,
                path: '/hoseSale',
                component: HoseSale,
                submenu: false,
                show: false,
                title: 'Ventas por Manguera',
            },
            {
                key: EVM_DETAILS,
                path: '/volumeMeasurerRegistries',
                component: VolumeMeasurerConfig,
                icon: 'snippets',
                title: 'Mediciones de volumen',
                submenu: false,
                show: true
            },
        ]
    },
    {
        key: DICTUM,
        path: '/dictum',
        component: Dictums,
        icon: 'snippets',
        title: 'Dictamenes',
        submenu: false,
        show: true
    },
    {
        key: OPERATION_CERTIFICATE,
        path: '/operationCertificates',
        component: OperationCertificates,
        icon: 'safety-certificate',
        title: 'Certificados',
        submenu: false,
        show: true
    },
    {
        key: INVENTORY_CONTROL_PERMISSION,
        path: '/jsonReports',
        component: JsonReport,
        submenu: false,
        show: false,
        title: 'Reportes Json',
    },
    {
        key: INVENTORY_CONTROL_PERMISSION,
        path: '/stockControl',
        component: StockControl,
        icon: 'calculator',
        title: 'Control de existencias',
        submenu: true,
        show: true,
        items: [
            {
                key: INVENTORY_CONTROL_PERMISSION,
                path: '/stockControl',
                component: StockControl,
                submenu: false,
                show: false,
                icon: 'calculator',
                title: 'Control de existencias',
            },
            {
                key: INVENTORY_CONTROL_PERMISSION,
                path: '/jsonReports',
                component: JsonReport,
                submenu: false,
                show: true,
                title: 'Reportes Json',
            },
        ]
    },
    {
        key: PRINTER_PERMISSION,
        path: '/printer',
        component: Printer,
        icon: 'printer',
        title: 'Impresora',
        submenu: false,
        show: false
    },
    {
        key: BACKUP_PERMISSION,
        path: '/backups',
        component: Backups,
        icon: 'save',
        title: 'Respaldos',
        submenu: false,
        show: false
    },
    {
        key: 'alerts',
        path: '/alerts',
        component: Alerts,
        title: 'Alertas',
        submenu: false,
        show: true
    },
    {
        key: EVENTS_PERMISSION,
        path: '/logbook',
        component: Logbook,
        icon: 'book',
        title: 'Bitácora',
        submenu: false,
        show: false
    },
    {
        key: EVENTS_PERMISSION,
        path: '/corruptedDataVisualizer',
        component: CorruptedDataVisualizer,
        icon: 'book',
        title: 'Descargas fallidas',
        submenu: false,
        show: false
    },
    {
        key: EVENTS_PERMISSION,
        path: '/logbook',
        component: Logbook,
        icon: 'book',
        title: 'Bitácora',
        submenu: true,
        show: false,
        items: [
            {
                key: EVENTS_PERMISSION,
                path: '/logbook',
                component: Logbook,
                title: 'Eventos y alarmas',
            },
            {
                key: CORRUPTED_DATA_VISUALIZER_PERMISSION,
                path: '/corruptedDataVisualizer',
                component: CorruptedDataVisualizer,
                title: 'Descargas fallidas',
            },
        ]
    },
    {
        key: USERS_PERMISSION,
        path: '/users',
        component: Users,
        icon: 'team',
        title: 'Usuarios',
        submenu: false,
        show: true
    },
    {
        key: PERMISSIONS,
        path: '/subscription',
        component: Subscriptions,
        icon: 'desktop',
        title: 'Suscripción',
        submenu: false,
        show: true
    },
    {
        key: PERMISSIONS,
        path: '/healthApplication',
        component: HealthInformation,
        icon: 'dashboard',
        title: 'Estatus de aplicacíon',
        submenu: false,
        show: false
    },
    {
        key: CONFIG_APPLICATION,
        path: '/configurationOutputEmail',
        component: OutputEmailConfig,
        icon: 'mail',
        title: 'Correo de salida',
        submenu: false,
        show: false
    },
    {
        key: CONFIG_APPLICATION,
        path: '/emailSenderConfiguration',
        component: EmailSenderConfig,
        icon: 'mail',
        title: 'Envío de correos',
        submenu: false,
        show: false
    },
    {
        key: CONFIG_APPLICATION,
        path: '/configurationApplication',
        component: ConfigurationApplication,
        icon: 'setting',
        title: 'Configuración',
        submenu: true,
        show: false,
        items: [
            {
                key: CONFIG_APPLICATION,
                path: '/configurationApplication',
                component: ConfigurationApplication,
                title: 'Configuración',
            },
            {
                key: CONFIG_APPLICATION,
                path: '/configurationOutputEmail',
                component: OutputEmailConfig,
                title: 'Correo de salida',
            },
            {
                key: PERMISSIONS,
                path: '/healthApplication',
                component: HealthInformation,
                title: 'Estatus de aplicación',
            },
            ((typeof getIsCloudModeActive === 'function') && getIsCloudModeActive())? null:
            {
                key: CONFIG_APPLICATION,
                path: '/emailSenderConfiguration',
                component: EmailSenderConfig,
                title: 'Envío de Correos',
            }
        ].filter(obj => obj)
    },
];

export const getMenu = (permissions = {}) => {
  return menu.filter(
    (item) => permissions[item.key] && permissions[item.key].read === true
  );
};
