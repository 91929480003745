import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EmailSenderConfiguration } from './components';
import { Row } from 'antd';
import {
    getPagePermissions,
    CONFIG_APPLICATION
} from '../../security/permissions';

class EmailSenderConfig extends Component {


    render() {
        return (
            <Row>

                <EmailSenderConfiguration />
            </Row>
        );
    }

}

const mapStateToProps = ({ userData, userData: { permissions } }) => ({
    userData,
    pagePermissions: getPagePermissions(CONFIG_APPLICATION, permissions)
});

export default connect(mapStateToProps)(EmailSenderConfig);
