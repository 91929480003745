/*
 * @Author: Alejandro Leonor 
 * @Date: 2018-04-17 16:49:11 
 * @Last Modified by: Alejandro Leonor
 * @Last Modified time: 2018-04-17 16:55:22
 */

// TODO: make strong validations and adapt this for your API specific response
export const parseApiError = (error) => {
    try {
        const parsedError = JSON.parse(JSON.stringify(error));
        return {
            status: parsedError.response.status,
            message: parsedError.response.data.message,
        };
    } catch (ex) {
        return {
            status: 500,
            message: 'Something went wrong!',
        };
    }
};
