import React, { Component } from 'react';

class Backups extends Component {
    render() {
        return (
            <h1>Respaldos</h1>
        );
    }
}

export default Backups;
