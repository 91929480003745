import { apiUrl } from '../../../../app.config';
import { Component } from 'react';
import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { message } from 'antd';

class ValidateMonthReportSchema extends Component {
    stompClient = null;

    constructor() {
        super();
        this.state = {
            showValidateJsonSchema: "",
        };
    }

    componentDidMount() {
        this.setupWebSocket();
    }

    setupWebSocket = () => {
        const socket = SockJS(`${apiUrl}volumetric-control`);
        this.stompClient = Stomp.over(socket);
        this.stompClient.connect({}, this.connect);
    };

    connect = () => {
        this.stompClient.subscribe('/topic/validateMonthJsonSchemaReport', (messageOutput) => {
            const errorJsonSchema = messageOutput.body;
            this.setState({ showValidateJsonSchema: errorJsonSchema });

        });
    };

    render() {
        const { showValidateJsonSchema } = this.state;
        return (
            this.state.showValidateJsonSchema &&
            message.error(showValidateJsonSchema, 15)

        );

    }
}

export default ValidateMonthReportSchema;
