import { Select } from 'antd';
import React from 'react';
import './styles.css';

class DistributionPointSelector extends React.Component {
    constructor() {
        super();
        this.state = {
        };
    }

    render() {
        return (
            <div hidden={this.props.allowedDistributionPoints.length <= 0}>
                <div style={{ width: '100%', textAlignLast: 'center', padding: '5px'}}>
                    <Select
                        placeholder='Selecciona un Punto de Distribución'
                        style={{ width: '95%' }}
                        className='PDDSelectorBox'
                        mode={(this.props.isMultiple)?'multiple': 'default'}
                        onChange={this.props.onChangeDistributionPoint}
                        value={this.props.selectedDistributionPoint}
                        showSearch
                        filterOption={(input, option) =>  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
                        maxTagCount={2}
                    >
                        {this.props.allowedDistributionPoints.map((dp) => (
                            <Select.Option key={dp.id} style={{ fontSize: 'small' }}>
                                {dp.name}
                            </Select.Option>
                        ))}
                    </Select>
                </div>
            </div>
        );
    }

}

DistributionPointSelector.defaultProps = {
    allowedDistributionPoints: [],
    selectedDistributionPoint: "",
}

export default DistributionPointSelector;
