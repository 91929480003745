import moment from 'moment';
import dayjs from "dayjs";
import React, { Component } from 'react';
import axios from 'axios';

import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    FileExcelOutlined,
    PlusOutlined,
    PrinterOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';


import {
    Row,
    Col,
    Statistic,
    Select,
    DatePicker,
    Table,
    Pagination,
    Button,
    Divider,
    Popover,
    Modal,
    message,
} from 'antd';
import {
    dispensaries as dispensaryServices
} from '../../services';
import { connect } from 'react-redux';
import {
    getPagePermissions,
    EXPORT_REPORT_PERMISSION,
    PRINTER_PERMISSION
} from '../../security/permissions';

import './styles.css';
import { ComplementsComponent } from '..';
import { getSelectedDistributionPoint } from '../../common/components/widgets/DistributionPoint/distributionPointStorage';
import { saveJsonToFile } from '../stockControl/components/jsonViewer';
import { getIsCloudModeActive } from '../../security';
import { serializeParams } from '../../common/helpers';
import request from '../../services/request';
import { apiUrl } from 'app.config';

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

let filters = {};

class HoseSale extends Component {
    _isMount = false;
    constructor() {
        super();
        this.state = {
            dispensaries: [],
            data: {},
            movementDetail: [],
            pagination: {},
            loading: false,
            visible: false,
            currentPage: 1,
            hoses: [],
            mainView: true,
            addComplementForm: false,
            currentComplementInfo: {},
            visibleModal: false,
            visiblePrintModal: false,
            saleFolio: "",
            printerNames: [],
            distributionPointId: getSelectedDistributionPoint(),
            isCloudEnable: getIsCloudModeActive(),
            filters: {
                equipmentKeys: null,
                startDate:  moment('00:00:00', 'HH:mm:ss').subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss'),
                endDate: moment('23:59:59', 'HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                displayPage: 0,
                displayAmount: 10,
                distributionPointId: getSelectedDistributionPoint(),
            },
            distributionPoint: {}
        };
    }

    columns = (printerPermission) => {
        return [
            {
                title: 'Fecha y hora',
                dataIndex: 'movementDate',
                render: date => moment(date).format('YYYY/MM/DD HH:mm:ss'),
            }, {
                title: 'Folio',
                dataIndex: 'movementFolio',
            }, {
                title: 'Tipo',
                dataIndex: 'movementType',
            },{
                title: 'Dispensario',
                dataIndex: 'responseDispensaryCode',
            },{
                title: 'Manguera',
                dataIndex: 'responseEquipmentKey',
            }, {
                title: 'Tipo de producto',
                dataIndex: 'responseProductDesc',
            }, {
                title: 'Litros vendidos',
                dataIndex: 'volume',
            }, {
                title: 'Precio por litro',
                dataIndex: 'price',
                render: text => `$${text}`,
            }, {
                title: 'Importe total',
                dataIndex: 'total',
                render: text => `$${text}`,
            },  {
                title: 'Complemento',
                dataIndex: 'print_invoice',
                render: (text, record) => 
                (record.isEditAllowed && (record.cfdiComplement === null) && (record.storageComplement === null) && (record.transportComplement === null) && (record.internationalComplement === null)  && record.clarifications === null) ?
                    <Popover content="Agregar información para complemento">
                        <Button icon={<PlusOutlined />} onClick={() => 
                            this.changeToRegisterForm({
                                mainView:false,
                                addComplementForm:true,
                                currentCfdiComplement: record.cfdiComplement,
                                currentTransportComplement: record.transportComplement,
                                currentStorageComplement: record.storageComplement,
                                currentInternationalComplement: record.internationalComplement,
                                isCurrentEditable: record.isEditAllowed,
                                inputRegisterUUID: record.idMovement,
                                clarifications: record.clarifications,
                            })}>
                        </Button>
                    </Popover>
                :   
                    (record.isEditAllowed && (record.cfdiComplement !== null || record.storageComplement !== null || record.transportComplement !== null || record.internationalComplement !== null || record.clarifications !== null)) ?
                        <div>
                            <Popover content="Editar información de complemento">
                                <Button icon={<EditOutlined />} onClick={() => 
                                    this.changeToRegisterForm({
                                        mainView:false,
                                        addComplementForm:true,
                                        currentCfdiComplement: record.cfdiComplement,
                                        currentTransportComplement: record.transportComplement,
                                        currentStorageComplement: record.storageComplement,
                                        currentInternationalComplement: record.internationalComplement,
                                        isCurrentEditable: record.isEditAllowed,
                                        inputRegisterUUID: record.idMovement,
                                        clarifications: record.clarifications,
                                    })}>
                                </Button>
                            </Popover>
                            <Popover content="Eliminar información de complemento">
                                <Button icon={<DeleteOutlined />} onClick={() => 
                                    this.handleDelete({
                                        inputRegisterUUID: record.idMovement,
                                        currentCfdiComplement: record.cfdiComplement,
                                        currentTransportComplement: record.transportComplement,
                                        currentStorageComplement: record.storageComplement,
                                        currentInternationalComplement: record.internationalComplement,
                                        cfdiComplementRegisterType: record.movementType,
                                        clarifications: record.clarifications,
                                        delete: true,
                                    })} type={'danger'}></Button>
                            </Popover>
                        </div>
                    :
                        (!record.isEditAllowed && (record.cfdiComplement !== null || record.storageComplement !== null || record.transportComplement !== null || record.internationalComplement !== null)) ?
                            <Popover content="Ver información de complemento">
                                <Button icon={<EyeOutlined />} onClick={() => 
                                    this.changeToRegisterForm({
                                        mainView:false,
                                        addComplementForm:true,
                                        currentCfdiComplement: record.cfdiComplement,
                                        currentTransportComplement: record.transportComplement,
                                        currentStorageComplement: record.storageComplement,
                                        currentInternationalComplement: record.internationalComplement,
                                        isCurrentEditable: record.isEditAllowed,
                                        inputRegisterUUID: record.idMovement,
                                        clarifications: record.clarifications,
                                    })}>
                                </Button>
                            </Popover>
                        :
                            <div>
                                <span>Sin complemento asociado</span>
                            </div>            
            }, !this.state.isCloudEnable && printerPermission ? {
                title: 'Imprimir',
                dataIndex: 'print_ticket',
                render: (text, record) => <Popover content="Imprimir ticket de factura"><Button icon={<PrinterOutlined />} onClick={() => this.showPrintModal(record.movementFolio)} /></Popover>
            } : {

            },  
                
        ];
    }
    

    componentDidMount() {
        this._isMount = true;
        this.fetchEquipmentMovements();
        this.fetchDispensaries();
        this.getPrinterNames();
        window.addEventListener("distributionPointChange",(e) => {
            if(this._isMount){
                this.setState({ distributionPointId: getSelectedDistributionPoint(), visible: false}, ()=>{
                    this.props.form.setFieldsValue({ 'hose': undefined });
                    let newFilters = this.state.filters;
                    newFilters.equipmentKeys=null;
                    newFilters.startDate= null;
                    newFilters.endDate= null;
                    newFilters.displayPage = 0;
                    newFilters.displayAmount = 10;
                    newFilters.distributionPointId = this.state.distributionPointId;
                    this.setState({filters: newFilters}, ()=>{
                        this.fetchEquipmentMovements();
                        this.fetchDispensaries();
                        this.getPrinterNames();
                    });
                });
            }
        });
        dispensaryServices.get(`distributionPoints/${this.state.distributionPointId}`)
            .then(response => {
                this.setState({
                    distributionPoint: response,
                });
            });
    }
    componentWillUnmount() {
        this._isMount = false;
    }

    onShowSizeChange = (current, pageSize) => {
        filters.displayPage = 0;
        filters.displayAmount = pageSize;
        filters.distributionPointId = this.state.distributionPointId;
        
        let newFilters = this.state.filters;
        newFilters.displayPage = 0;
        newFilters.displayAmount = pageSize;
        this.setState({filters: newFilters}, ()=>{
            this.fetchEquipmentMovements();
        });
    }

    onPageChange = (page, pageSize) => {
        filters.displayPage = page - 1;
        filters.distributionPointId = this.state.distributionPointId;

        let newFilters = this.state.filters;
        newFilters.displayPage =  page - 1;
        this.setState({filters: newFilters}, ()=>{
            this.fetchEquipmentMovements();
        });   
    }

    fetchDispensaries = (params = {}) => {
        this.setState({ loading: true });
        dispensaryServices.get(`distributionPoints/${this.state.distributionPointId}/dispensaries`)
            .then(response => {
                if (response.length > 0) {
                    this.setState({
                        dispensaries: response
                    });
                }else{
                    this.setState({
                        dispensaries: []
                    });
                }
                this.setState({ loading: false });
            });
    }

    getPrinterNames() {
        this.setState({ loading: true });
        dispensaryServices.get('sales/getPrinters')
            .then(response => {
                this.setState({ loading: false, printerNames: response });

            });
    }

    handlePrintHoseSales = (type) => {
        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const rangeTimeValue = fieldsValue['period'];
                filters = {
                    equipmentKeys: fieldsValue['hose'],
                    startDate: rangeTimeValue === undefined ? null : rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss'),
                    endDate: rangeTimeValue === undefined ? null : rangeTimeValue[1].format('YYYY-MM-DD HH:mm:ss'),
                    displayPage: 0,
                    displayAmount: 10,
                    distributionPointId: this.state.distributionPointId,
                    reportType: type,
                };
                this.printHoseSales(filters);
            }
        });
    }

    printHoseSales = (params = {}) => {
        this.setState({ loading: true });
        if(params.reportType === 'csv'){
            dispensaryServices.post(`movements/reports/${params.reportType}`, params)
            .then(response => {
                saveJsonToFile(response, "text/csv", "ventaMangueras.csv");    
            }).catch((error)=>{
                message.error(error.response.data.message);
            }).finally(()=>{
                this.setState({loading: false });
            });
        }else{
            axios({
                url: `${apiUrl}/movements/reports/${params.reportType}`,
                method: 'POST',
                data: params,
                responseType: 'blob', // important
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'ventaMangueras.pdf'); // or any other extension
                document.body.appendChild(link);
                link.click();
                this.setState({ loading: false });
            });
        }
    }


    fetchEquipmentMovements = () => {
        this.fetchMovementsPageable(this.state.filters);
    }

    fetchMovementsPageable = (params = {}) => {
        this.props.form.validateFields((err, fieldsValue) => {
            if(!err){
                this.setState({ loading: true }, ()=>{
                    let queryParams = {
                        page: params.displayPage,
                        size: params.displayAmount,
                        sort: "folio,DESC",
                    };
                    request().post(`movements/getMovementsByEquipment${serializeParams(queryParams)}`, params).then((response) => {
                        const pagination = { ...this.state.pagination };
                        pagination.total = response.data.movementDetail.totalElements;
                        this.setState({
                            data: response.data,
                            movementDetail: response.data.movementDetail.content,
                            pagination,
                            currentPage: params.displayPage + 1
                        });
                    }).finally(() => {
                        this.setState({ loading: false });
                    });
                });
            }
        });
        
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.form.validateFields((err, fieldsValue) => {
            if (!err) {
                const rangeTimeValue = fieldsValue.period;
                let newFilters = this.state.filters;
                newFilters.equipmentKeys= fieldsValue.hose;
                newFilters.startDate= rangeTimeValue === undefined ? null : rangeTimeValue[0].format('YYYY-MM-DD HH:mm:ss');
                newFilters.endDate= rangeTimeValue === undefined ? null : rangeTimeValue[1].format('YYYY-MM-DD HH:mm:ss');
                this.setState({filters: newFilters, visible: true, hose: fieldsValue.hose}, ()=>{
                    this.fetchEquipmentMovements();
                });
            }
        });
    }

    showPrintModal = saleFolio => {
        this.setState({ saleFolio : saleFolio, visiblePrintModal: true })
    };

    downloadPDF = cfdiComplementId => {
        this.setState({ loading: true });
        axios({
            url: `${apiUrl}/xmlInvoice/${cfdiComplementId}`, // your url
            method: 'GET',
            responseType: 'blob', // important            
        }).then((response) => {
            const fileNameHeader = "x-filename";
            const suggestedFileName = response.headers[fileNameHeader];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', suggestedFileName); // or any other extension           
            document.body.appendChild(link);
            link.click();
        }).catch(function (error) {
            const resposeError = new Blob([error.response.data]).text();
            resposeError.then(texto =>
                message.error(texto)

            );
            this.setState({ loadingDictums: false })

        });
    };

    downloadXML = cfdiComplementId => {
        this.setState({ loading: true });
        axios({
            url: `${apiUrl}/invoiceApp/xmlInvoice/${cfdiComplementId}`, // your url
            method: 'GET',
            responseType: 'blob', // important            
        }).then((response) => {
            const fileNameHeader = "x-filename";
            const suggestedFileName = response.headers[fileNameHeader];
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', suggestedFileName); // or any other extension           
            document.body.appendChild(link);
            link.click();
            this.setState({ loading: false });
        }).catch(function (error) {
            const resposeError = new Blob([error.response.data]).text();
            resposeError.then(texto =>
                message.error(texto)

            );
            this.setState({ loadingDictums: false })

        });
    };

    changeToRegisterForm = (params) => {
        let complement = {
            currentCfdiComplement: params.currentCfdiComplement,

            isCfdiComplement: params.currentCfdiComplement === null ? false : true,
            
            currentTransportComplement: params.currentTransportComplement,
            isTransportComplement: params.currentTransportComplement === null ? false : true,
            
            currentStorageComplement: params.currentStorageComplement,
            isStorageComplement: params.currentStorageComplement === null ? false : true,
            
            currentInternationalComplement: params.currentInternationalComplement,
            isInternationalComplement: params.currentInternationalComplement === null ? false : true,
            
            isCurrentEditAllowed: params.isCurrentEditable,

            currentCFDIRegisterType:"VENTA",
            currentCFDIType:"INGRESO",

            inputRegisterUUID: params.inputRegisterUUID,
            clarifications: params.clarifications,
        };

        this.setState({
            currentComplementInfo: complement,
            mainView: params.mainView, 
            addComplementForm: params.addComplementForm,
        });
    }

    handleCancel = () => {
        this.setState({ visible: true, visiblePrintModal: false, mainView: true,
            visibleModal: false,
            addComplementForm: false, });
    }

    handleOk = () => {
        this.setState({ loading: true });

        dispensaryServices.put('complements/editComplement', this.state.infoToDelete)
            .then(response => {
                const jsonResponse = JSON.parse(JSON.stringify(response));
                if (jsonResponse.hasOwnProperty('response')) {
                    message.error(jsonResponse.response.data);
                } else {
                    this.setState({ loading: false, visibleModal: false });
                    message.success('Complemento eliminado');
                    this.changeToMainTable();
                }
            });
    }

    handleDelete = (params) => {
        this.setState({visibleModal:true});

        const filters = {
            //General info
            receptionUUID: params.inputRegisterUUID,
            transactionType: 'venta',

            cfdiComplementRegisterType: params.cfdiComplementRegisterType,
            
            //CFDI Complement Data
            isCfdiComplement: false,
            currentcfdiComplementUUID: params.currentCfdiComplement !== null ? params.currentCfdiComplement.id : null,
            
            //Transport Complement Data
            isTransportComplement: false,
            transportComplementUUID: params.currentTransportComplement !== null ? params.currentTransportComplement.id : null,
            
            //Storage Complement Data
            isStorageComplement: false,
            storageComplementUUID: params.currentStorageComplement !== null ? params.currentStorageComplement.id : null,
            
            //International Complement Data
            isInternationalComplement: false,
            internationalComplementUUID: params.currentInternationalComplement !== null ? params.currentInternationalComplement.id : null,

            delete: (params.delete !== null) ? params.delete : false,

        };
        this.setState({infoToDelete: filters});
    }

    changeToMainTable = () => {
        this.setState({
            visible: true,
            mainView: true,
            visibleModal: false,
            addComplementForm: false,
        }, ()=>{
            this.fetchEquipmentMovements();
        });
       
    }

    printSaleTicket = () => {
        this.setState({ loading: true });

        if(this.props.form.getFieldValue('printer') !== null && this.props.form.getFieldValue('printer') !== undefined){
            const params = { 
                idMovement: this.state.saleFolio, 
                printerName: this.props.form.getFieldValue('printer')
            }
            dispensaryServices.post('sales/printMovement', params)
            .then(response => {
                if(response === true){
                    message.success("Impresión exitosa");
                }
                else{
                    message.error("Problema al imprimir, intente de nuevo");
                }
            }).finally(()=>{
                this.setState({loading: false, visible: true, visiblePrintModal: false });
            });
        }else{
            message.error("No se detectó ninguna impresora.");
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { exportReportsPermissions, printerPermissions } = this.props;

        const optionItems = this.state.dispensaries.map((dispensary, index) => (
            <OptGroup key={dispensary.dispensaryCode} label={`Dipensario ${dispensary.dispensaryCode}`}>
                {dispensary.hoses.map(hose => (
                    <Option key={hose.responseHoseCode} value={hose.responseHoseCode}>{`Manguera ${hose.responseHoseCode}`}</Option>
                ))}
            </OptGroup>
        ));
        const optionPrinterNames = types => types.map(item => (
            <Option key={item} value={item}>{item}</Option>
        ));
        return (
            <div>
                {this.state.mainView && <div>
                    <Form layout='vertical' onSubmit={this.handleSubmit}>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item
                                    label="Seleccionar mangueras"
                                >
                                    {getFieldDecorator('hose', {
                                        rules: [{ required: true, message: 'Por favor selecciona al menos una manguera.' }],
                                        initialValue: this.state.filters.equipmentKeys === null ? undefined: this.state.filters.equipmentKeys,
                                    })(
                                        <Select
                                            mode="multiple"
                                            placeholder="Selecciona una o más mangueras"
                                        >
                                            {optionItems}
                                        </Select>
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    label="Seleccionar periodo"
                                >
                                    {getFieldDecorator('period', {
                                        initialValue: [ dayjs('00:00:00', 'HH:mm:ss').subtract(7, 'days'), dayjs('23:59:59', 'HH:mm:ss')],
                                        rules: [{ required: true, message: 'Por favor selecciona una fecha.' }],
                                    })(
                                        <RangePicker
                                            lang='es'
                                            allowClear={false}
                                            style={{width: '100%', maxWidth: 'fit-content'}}
                                            format="YYYY/MM/DD HH:mm:ss"
                                            showTime={{ defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')] }}
                                            placeholder={['Desde', 'Hasta']}
                                        />
                                    )}
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item
                                    className="transparent-label"
                                    label=" "
                                >
                                    <Button type="primary" htmlType="submit">Consultar</Button>
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item
                                    className="transparent-label"
                                    label=" "
                                >
                                    {exportReportsPermissions.read && 
                                        <Button type="primary" icon={<FileExcelOutlined />} onClick={() => {this.handlePrintHoseSales("csv");}}>
                                            Descargar CSV
                                        </Button> }
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    {
                        this.state.visible &&
                        <div>
                            <div style={{ background: '#E5E5E5', padding: '30px' }}>
                                <Row>
                                    <Col span={6}>
                                        <h3 style={{ color: '#1B95CA' }}>TOTAL</h3>
                                    </Col>
                                    <Col span={6}>
                                        <Statistic title="Litros vendidos" value={this.state.data.totalVolume} />
                                    </Col>
                                    <Col span={6}>
                                        <Statistic title="Total venta" prefix="$" value={this.state.data.totalPrice} />
                                    </Col>
                                </Row>
                            </div>
                            <div style={{ margin: 16 }}>
                                <span style={{ color: '#1B95CA' }}>{`Mangueras ${this.state.hoses} `}</span>
                            </div>
                            <Table
                                columns={this.columns(printerPermissions.read)}
                                scroll={{ x: true }}
                                rowKey={record => record.idMovement}
                                dataSource={this.state.movementDetail}
                                pagination={false}
                                loading={this.state.loading}
                            />
                            <br />
                            <Pagination showSizeChanger onChange={this.onPageChange} current={this.state.currentPage} onShowSizeChange={this.onShowSizeChange} total={this.state.pagination.total} pageSizeOptions={['10', '20', '50', '100', '200']} showTotal={total => `Total ${total} elementos`} />
                            <Divider />
                        </div>
                    }
                </div>}
                <Modal
                    open={this.state.visibleModal}
                    title="Atención"
                    onCancel={this.handleCancel}
                    footer={[
                        <Button className="back-button" key="cancel-delete" onClick={this.handleCancel}>Cancelar</Button>,
                        <Button type="danger" key="delete" loading={this.state.loading} onClick={this.handleOk}>Confirmar</Button>
                    ]}
                >
                    <p>¿Está seguro que desea eliminar este complemento? Una vez realizada la acción, la información no podrá ser recuperada.</p>
                </Modal>
                {this.state.addComplementForm && 
                    <ComplementsComponent transactionType={'venta'} complementType={this.state.distributionPoint.companyActivityTypeDescription} currentComplementInfo={this.state.currentComplementInfo} handleReturnToTable={this.changeToMainTable} />
                }
                    <Modal
                        open={this.state.visiblePrintModal}
                        title="Atención"
                        footer={[
                            <Button className="back-button" key="cancel-delete" onClick={this.handleCancel}>Cancelar</Button>,
                            <Button type="danger" key="delete" onClick={this.printSaleTicket}>Imprimir</Button>
                        ]}
                    >
                        <p>Seleccione la impresora que imprimirá el ticket de venta.</p>
                        <Row gutter={24}>
                            <Col span={8}>
                                {getFieldDecorator('printer', {
                                    initialValue: this.state.printerNames.length >= 1 ? this.state.printerNames[0] : null,
                                })(
                                    <Select>
                                        {optionPrinterNames(this.state.printerNames)}
                                    </Select>
                                )}
                            </Col>
                        </Row>
                    </Modal>
            </div>
        );
    }
}

const DispensaryHoseForm = Form.create({ name: 'hoseSale' })(HoseSale);

const mapStateToProps = ({ userData, userData: { permissions } }) => {
    return {
        userData,
        exportReportsPermissions: getPagePermissions(EXPORT_REPORT_PERMISSION, permissions),
        printerPermissions: getPagePermissions(PRINTER_PERMISSION, permissions)
    };
};

export default connect(mapStateToProps)(DispensaryHoseForm);